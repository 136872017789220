import { useCallback, useEffect, useState } from "react";
import useFetch from "./useFetch";
import { MetaFilterPayload, OrderEnum, QueryFunctionParams, TableDataValue, TableStateValue } from "../helper/interface";
import { serializeQueryParams } from "../helper/helper";
import { useDispatch, useSelector } from "react-redux";
import { setTableData } from "../store/slice/TableDataSlice";

const DEFAULT_TOTAL_PAGE_VALUE = 0;
const MIN_PAGE_NUMBER = 1;

const useTable = <T>(tableFor: string, url: string, strict?: boolean, isChild?: string) => {
    const dispatch = useDispatch();

    const tableOffsetData: TableDataValue | null = useSelector((state: TableStateValue) => state.table.tableData);

    const initialTableData = <const>{ page: 1, limit: 10, maxPage: 1, search: null, orderBy: null, tableFor };

    let initialValues = tableOffsetData;

    if (isChild && tableOffsetData.child) {
        initialValues = tableOffsetData.child;
    } else if (isChild && typeof isChild === "string") {
        initialValues = { ...initialTableData, tableFor: isChild };
    }

    const [localTableData, setLocalTableData] = useState<TableDataValue>(initialValues);

    const { search, page, limit, maxPage, orderBy, metaFilter } = initialValues;

    let payloadForReload: QueryFunctionParams = {
        limit,
        page,
    };

    if (search) {
        payloadForReload.search = search;
    }

    if (orderBy) {
        payloadForReload.fieldName = orderBy.fieldName;
        payloadForReload.order = orderBy.order;
    }

    if (tableOffsetData.metaFilter && tableOffsetData.metaFilter.value) {
        payloadForReload.searchBy = tableOffsetData.metaFilter.searchBy;
        payloadForReload.value = tableOffsetData.metaFilter.value;
    }

    if (tableOffsetData.tableFor !== tableFor || (isChild && tableOffsetData?.child?.tableFor !== isChild)) {
        payloadForReload = { limit: 10, page: 1 };
    }

    const queryParam = strict ? "&" : "?";

    const { loading, res, fetchApi, error } = useFetch<T[]>(`${url}${queryParam}${serializeQueryParams(payloadForReload)}`);

    const tableData = res?.data ?? null;

    const total = res?.metaData?.total ?? DEFAULT_TOTAL_PAGE_VALUE;

    useEffect(() => {
        if (isChild) {
            dispatch(setTableData({ ...tableOffsetData, child: localTableData }));
        } else {
            dispatch(setTableData(localTableData));
        }
    }, [localTableData]);

    useEffect(() => {
        if (tableOffsetData.tableFor !== tableFor) {
            setLocalTableData({ ...initialTableData, maxPage: Math.max(Math.ceil(total / limit), MIN_PAGE_NUMBER) });
        } else {
            const offsetObject: TableDataValue = {
                page,
                limit,
                search,
                orderBy,
                maxPage: Math.max(Math.ceil(total / limit), MIN_PAGE_NUMBER),
                tableFor,
                metaFilter,
            };

            if (isChild && typeof isChild === "string") offsetObject.tableFor = isChild;

            setLocalTableData(offsetObject);
        }
    }, [total, limit]);

    const onChangePageOrLimit = useCallback(
        (pageNum: number, limitNum: number) => {
            const payload: QueryFunctionParams = {
                page: pageNum,
                limit: limitNum,
            };
            if (search) {
                payload.search = search;
            }
            if (orderBy) {
                payload.order = orderBy.order;
                payload.fieldName = orderBy.fieldName;
            }
            if (tableOffsetData.metaFilter && tableOffsetData.metaFilter.value) {
                payload.searchBy = tableOffsetData.metaFilter.searchBy;
                payload.value = tableOffsetData.metaFilter.value;
            }
            fetchApi(`${url}${queryParam}${serializeQueryParams(payload)}`);
        },
        [search, orderBy, tableOffsetData.metaFilter]
    );

    const onNext = useCallback(() => {
        const updatedPage = Math.min(page + MIN_PAGE_NUMBER, maxPage);

        setLocalTableData({
            ...localTableData,
            page: updatedPage,
        });
        onChangePageOrLimit(updatedPage, limit);
    }, [search, orderBy, page, maxPage, limit, onChangePageOrLimit]);

    const onPrevious = useCallback(() => {
        const updatedPage = Math.max(page - MIN_PAGE_NUMBER, MIN_PAGE_NUMBER);
        setLocalTableData({
            ...localTableData,
            page: updatedPage,
        });
        onChangePageOrLimit(updatedPage, limit);
    }, [search, orderBy, page, maxPage, limit, onChangePageOrLimit]);

    const onSetPage = useCallback(
        (pageNum: number) => {
            setLocalTableData({
                ...localTableData,
                page: pageNum,
            });
            onChangePageOrLimit(pageNum, limit);
        },
        [search, limit, maxPage, orderBy, onChangePageOrLimit]
    );

    const onSearch = useCallback(
        (value: string, metaFilter?: MetaFilterPayload) => {
            let tableFilterPayload: TableDataValue = {
                ...localTableData,
                page: 1,
                search: value,
            };

            const payload: QueryFunctionParams = {
                limit,
                page: 1,
            };

            if (metaFilter) {
                tableFilterPayload = {
                    ...localTableData,
                    metaFilter,
                    limit: 10,
                    page: 1,
                };

                payload.searchBy = metaFilter.searchBy;
                payload.value = metaFilter.value;
            } else {
                payload.search = value;
            }

            setLocalTableData(tableFilterPayload);

            if (orderBy) {
                payload.order = orderBy.order;
                payload.fieldName = orderBy.fieldName;
            }

            fetchApi(`${url}${queryParam}${serializeQueryParams(payload)}`);
        },
        [page, total, limit, search, orderBy]
    );

    const resetSearch = useCallback(() => {
        setLocalTableData({
            ...localTableData,
            page: 1,
            search: null,
            limit: 10,
        });

        const payload: QueryFunctionParams = {
            limit: 10,
            page: 1,
        };

        if (orderBy) {
            payload.fieldName = orderBy.fieldName;
            payload.order = orderBy.order;
        }

        fetchApi(`${url}${queryParam}${serializeQueryParams(payload)}`);
    }, [orderBy, tableOffsetData, localTableData]);

    const onSetOrderBy = useCallback(
        (fieldName: string, newOrderBy: OrderEnum) => {
            const newOrder = {
                fieldName,
                order: newOrderBy,
            };

            if (newOrder.fieldName === orderBy?.fieldName) {
                newOrder.order = orderBy.order === OrderEnum.ASC ? OrderEnum.DESC : OrderEnum.ASC;
            }

            setLocalTableData({
                ...localTableData,
                orderBy: newOrder,
                page: 1,
            });

            const payload: QueryFunctionParams = {
                limit,
                page: 1,
                fieldName: newOrder.fieldName,
                order: newOrder.order,
            };

            if (search) {
                payload.search = search;
            }

            if (localTableData.metaFilter) {
                payload.searchBy = localTableData.metaFilter?.searchBy;
                payload.value = localTableData.metaFilter?.value;
            }

            fetchApi(`${url}${queryParam}${serializeQueryParams(payload)}`);
        },
        [search, page, limit, maxPage, orderBy, onChangePageOrLimit, tableOffsetData, localTableData]
    );

    const onSetLimit = useCallback(
        (limitNum: number) => {
            setLocalTableData({
                ...localTableData,
                page: 1,
                limit: limitNum,
            });
            onChangePageOrLimit(MIN_PAGE_NUMBER, limitNum);
        },
        [search, maxPage, orderBy, onChangePageOrLimit]
    );

    const currentOrder = orderBy;

    const tableOffset = { onNext, onPrevious, onSetPage, onSetLimit, total, limit, page, maxPage };

    return { tableData, loading, fetchApi, tableOffset, error, onSearch, onSetOrderBy, resetSearch, currentOrder, search };
};

export default useTable;
