import { useState, useCallback, useMemo } from "react";
import { Form } from "formik";
import { useSelector } from "react-redux";
import { Listbox, Transition } from "@headlessui/react";

import { EducationYearPayload, FormParams, TableStateValue } from "../../../helper/interface";
import { year } from "../../../config/MethodUtils";

const AddEditForm = ({ errors, touched, values, dirty, setFieldValue, setFieldTouched }: FormParams<EducationYearPayload>) => {
    const loading = useSelector((state: TableStateValue) => state.table.loading);
    const [isPrizeListVisible, setIsPrizeListVisible] = useState<boolean>();
    const [isMarkSheetUploadable, setIsMarkSheetUploadable] = useState<boolean>();

    const onChangeYear = useCallback((value: string) => {
        setFieldValue("year", value);
    }, []);

    const years = useMemo(() => year(), []);

    return (
        <>
            <Form>
                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                    <div className="intro-y col-span-12 flex items-center justify-center">
                        <div className="intro-y box lg:w-[60%] md:w-[100%] sm:w-[100%]">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="font-medium text-base mr-auto">Education Year Details</h2>
                            </div>
                            <div id="vertical-form" className="p-5">
                                <div className="preview">
                                    {/* Dropdown for Year */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Year</label>
                                        <div className={`tom-select ${errors.year && touched.year && "dd-border-danger"}`}>
                                            <Listbox value={values.year} name="year" onChange={onChangeYear}>
                                                <Listbox.Button id="year" className={"ts-input"}>
                                                    <div className="item">{values?.year || "Select a year"}</div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("year", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {years.map((yr: string, i: number) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || yr === values.year ? "optionColor" : null
                                                                            }`
                                                                        }
                                                                        value={yr}
                                                                        key={i}
                                                                    >
                                                                        {yr}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.year && touched.year && <div className="text-danger">{errors.year}</div>}
                                    </div>

                                    {/* Prize list visible */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label>Prize List Visible</label>
                                        <div className="flex flex-col sm:flex-row mt-2">
                                            <div className="form-check mr-2">
                                                <input
                                                    id="isPrizeListVisibleTrue"
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="isPrizeListVisible"
                                                    checked={values.isPrizeListVisible === true}
                                                    onClick={() => setFieldValue("isPrizeListVisible", true)}
                                                    onChange={() => setIsPrizeListVisible(isPrizeListVisible)}
                                                />
                                                <label className="form-check-label" htmlFor="isPrizeListVisibleTrue">
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="form-check mr-2 mt-2 sm:mt-0">
                                                <input
                                                    id="isPrizeListVisibleFalse"
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="isPrizeListVisible"
                                                    checked={values.isPrizeListVisible === false}
                                                    onClick={() => setFieldValue("isPrizeListVisible", false)}
                                                    onChange={() => setIsPrizeListVisible(!isPrizeListVisible)}
                                                />
                                                <label className="form-check-label" htmlFor="isPrizeListVisibleFalse">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Prize list visible */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label>Marksheet Uploadable</label>
                                        <div className="flex flex-col sm:flex-row mt-2">
                                            <div className="form-check mr-2">
                                                <input
                                                    id="isMarkSheetUploadableTrue"
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="isMarkSheetUploadable"
                                                    checked={values.isMarkSheetUploadable === true}
                                                    onClick={() => setFieldValue("isMarkSheetUploadable", true)}
                                                    onChange={() => setIsMarkSheetUploadable(isMarkSheetUploadable)}
                                                />
                                                <label className="form-check-label" htmlFor="isMarkSheetUploadableTrue">
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="form-check mr-2 mt-2 sm:mt-0">
                                                <input
                                                    id="isMarkSheetUploadableFalse"
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="isMarkSheetUploadable"
                                                    checked={values.isMarkSheetUploadable === false}
                                                    onClick={() => setFieldValue("isMarkSheetUploadable", false)}
                                                    onChange={() => setIsMarkSheetUploadable(!isMarkSheetUploadable)}
                                                />
                                                <label className="form-check-label" htmlFor="isMarkSheetUploadableFalse">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                        <button className="btn btn-secondary w-24" type="reset" disabled={loading || !dirty}>
                            Reset
                        </button>
                        <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                            Save
                        </button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default AddEditForm;
