import { useCallback, RefObject, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "formik";
import { Listbox, Transition } from "@headlessui/react";
import { Camera, Calendar } from "react-feather";
import moment from "moment";

import { FamilyPayload, FormParams, Ocupation, TableStateValue, Village, ZIndexMaintainer } from "../../helper/interface";
import { BLOOD, GENDER } from "../../helper/constant";
import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import { dataGu } from "../../helper/dataGu";
import { getImageUrl, onDatePickerClose, onDatePickerOpen } from "../../helper/helper";
import CustomDatePicker from "../datepicker/CustomDatePicker";
import avatar from "./../../assets/images/userAvatar.png";
import { dataEn } from "../../helper/dataEn";
import Loading from "../../container/Loading";

const AddForm = ({
    errors,
    touched,
    handleChange,
    values,
    dirty,
    setFieldValue,
    setFieldTouched,
    handleBlur,
}: FormParams<FamilyPayload>) => {
    const loading = useSelector((state: TableStateValue) => state.table.loading);

    const [zIndex, setZIndex] = useState<ZIndexMaintainer>({});

    const profileImgRef = useRef() as RefObject<HTMLInputElement>;
    const { res: village } = useFetch<Village[]>(api.village);
    const { res: occupation } = useFetch<Ocupation[]>(api.occupation);

    const onChangeGender = useCallback((value: GENDER) => {
        setFieldValue("gender", value);
    }, []);
    const onChangeBloodGroup = useCallback((value: string) => {
        setFieldValue("bloodGroup", value);
    }, []);
    const onChangeVillageId = useCallback((value: number) => {
        setFieldValue("villageId", value);
    }, []);

    const onChangeOccupation = useCallback((value: number) => {
        setFieldValue("occupationId", value);
    }, []);

    return (
        <>
            <Form>
                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                    <div className="preview mb-5 col-span-12">
                        <div className="flex flex-1 px-5 items-center justify-center lg:justify-start flex-col">
                            <div className="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative">
                                <img
                                    alt="Profile picture"
                                    className="rounded-full img-bordered"
                                    src={values?.profileImg ? getImageUrl(values?.profileImg) : avatar}
                                />
                                <div
                                    className="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-primary rounded-full p-2 border-2 border-solid border-white cursor-pointer"
                                    onClick={() => profileImgRef?.current?.click()}
                                >
                                    <Camera className="lucide lucide-camera w-4 h-4 text-white" />
                                    <input
                                        ref={profileImgRef}
                                        name="profileImg"
                                        type="file"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            if (e?.target?.files?.[0]) {
                                                setFieldValue("profileImg", e?.target?.files?.[0]);
                                            }
                                        }}
                                        accept="image/*"
                                        className="hidden"
                                        disabled={loading}
                                    />
                                </div>
                            </div>
                            <div className="mt-4 text-center">
                                <div className="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg capitalize">
                                    {values.fullNameEn ? values.fullNameEn : "Member name"}
                                </div>
                            </div>
                        </div>
                        {errors.profileImg && touched.profileImg && <div className="text-danger">{errors.profileImg}</div>}
                    </div>
                    <div className="intro-y col-span-12 lg:col-span-6">
                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="font-medium text-base mr-auto">{dataEn.familyDetails}</h2>
                            </div>
                            <div id="vertical-form1" className="p-5">
                                <div className="preview">
                                    {/* Village name */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Village Name</label>
                                        <div
                                            className={`tom-select form-control ${
                                                errors.villageId && touched.villageId && "dd-border-danger"
                                            }`}
                                        >
                                            <Listbox
                                                value={values.villageId}
                                                name="villageId"
                                                onChange={onChangeVillageId}
                                                disabled={loading}
                                            >
                                                <Listbox.Button id="villageId" className={"ts-input"}>
                                                    <div className="item">
                                                        {village?.data?.map((v) => v?.villageId === values?.villageId && v?.nameEn)}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("villageId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {village?.data?.map((vlg: Village) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || vlg.villageId === values.villageId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={vlg.villageId}
                                                                        key={vlg.villageId}
                                                                    >
                                                                        {vlg.nameEn}
                                                                        {vlg.districtEn ? `${" (" + vlg.districtEn + ")"}` : null}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.villageId && touched.villageId && <div className="text-danger">{errors.villageId}</div>}
                                    </div>

                                    {/* Primary Member Name */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="fullNameEn" className="form-label">
                                            {dataEn.primaryMemberName}
                                        </label>
                                        <input
                                            id="fullNameEn"
                                            name="fullNameEn"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.fullNameEn}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.fullNameEn && touched.fullNameEn && "border-danger"}`}
                                            disabled={loading}
                                        />
                                        {errors.fullNameEn && touched.fullNameEn && <div className="text-danger">{errors.fullNameEn}</div>}
                                    </div>

                                    {/* Email */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="email" className="form-label">
                                            Email
                                        </label>
                                        <input
                                            id="email"
                                            name="email"
                                            type="text"
                                            onChange={(e) => setFieldValue("email", e.target.value)}
                                            value={values.email}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.email && touched.email && "border-danger"}`}
                                            disabled={loading}
                                        />
                                        {errors.email && touched.email && <div className="text-danger">{errors.email}</div>}
                                    </div>

                                    {/* Mobile Number */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="mobile" className="form-label">
                                            Mobile Number
                                        </label>
                                        <input
                                            id="mobile"
                                            name="mobile"
                                            type="text"
                                            onChange={(e) => setFieldValue("mobile", e.target.value)}
                                            value={values.mobile}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.mobile && touched.mobile && "border-danger"}`}
                                            disabled={loading}
                                        />
                                        {errors.mobile && touched.mobile && <div className="text-danger">{errors.mobile}</div>}
                                    </div>

                                    {/* Address */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="addressEn" className="form-label">
                                            {dataEn.address}
                                        </label>
                                        <textarea
                                            id="addressEn"
                                            name="addressEn"
                                            onChange={handleChange}
                                            value={values.addressEn}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.addressEn && touched.addressEn && "border-danger"}`}
                                            disabled={loading}
                                        />
                                        {errors.addressEn && touched.addressEn && <div className="text-danger">{errors.addressEn}</div>}
                                    </div>

                                    {/* Gender */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Gender</label>
                                        <div className={`tom-select form-control ${errors.gender && touched.gender && "dd-border-danger"}`}>
                                            <Listbox value={values.gender} name="gender" onChange={onChangeGender} disabled={loading}>
                                                <Listbox.Button id="gender" className={"ts-input"}>
                                                    <div className="item">{values?.gender || "Select a gender"}</div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("gender", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            <Listbox.Option
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                        active || values.gender === GENDER.MALE ? "optionColor" : null
                                                                    }`
                                                                }
                                                                value={GENDER.MALE}
                                                            >
                                                                {GENDER.MALE}
                                                            </Listbox.Option>
                                                            <Listbox.Option
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                        active || values.gender === GENDER.FEMALE ? "optionColor" : null
                                                                    }`
                                                                }
                                                                value={GENDER.FEMALE}
                                                            >
                                                                {GENDER.FEMALE}
                                                            </Listbox.Option>
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.gender && touched.gender && <div className="text-danger">{errors.gender}</div>}
                                    </div>

                                    {/* Blood Group */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Blood Group</label>
                                        <div className={`tom-select ${errors.bloodGroup && touched.bloodGroup && "dd-border-danger"}`}>
                                            <Listbox
                                                value={values.bloodGroup}
                                                name="bloodGroup"
                                                onChange={onChangeBloodGroup}
                                                disabled={loading}
                                            >
                                                <Listbox.Button id="bloodGroup" className={"ts-input"}>
                                                    <div className="item">{values?.bloodGroup || "Select a blood group"}</div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("bloodGroup", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            <Listbox.Option
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                        active || !values.bloodGroup ? "optionColor" : null
                                                                    }`
                                                                }
                                                                value={null}
                                                            >
                                                                Select Blood Group
                                                            </Listbox.Option>
                                                            {BLOOD.map((group: string) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || group === values.bloodGroup ? "optionColor" : null
                                                                            }`
                                                                        }
                                                                        value={group}
                                                                        key={group}
                                                                    >
                                                                        {group}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                    </div>

                                    {/* Date of Birth */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5" style={{ zIndex: zIndex.dob }}>
                                        <label htmlFor="dob" className="mb-2 form-label">
                                            Date of Birth
                                        </label>
                                        <div className="relative">
                                            <div className="absolute z-10 rounded-l w-10 h-full flex items-center justify-center bg-slate-100 border text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400">
                                                <Calendar />
                                            </div>
                                            <CustomDatePicker
                                                id="dob"
                                                name="dob"
                                                maxDate={new Date(moment().format("yyyy-MM-DD"))}
                                                selected={
                                                    values.dob ? new Date(moment(values.dob.toLocaleString()).format("yyyy-MM-DD")) : null
                                                }
                                                hasError={Boolean(errors.dob && touched.dob)}
                                                setFieldValue={setFieldValue}
                                                handleBlur={handleBlur}
                                                onCalendarOpen={onDatePickerOpen(setZIndex, "dob")}
                                                onCalendarClose={onDatePickerClose(setZIndex, "dob")}
                                                disabled={loading}
                                            />
                                        </div>
                                        {errors.dob && touched.dob && <div className="text-danger">{errors.dob}</div>}
                                    </div>

                                    {/* Occupation */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Occupation</label>
                                        <div className={`tom-select ${errors.occupationId && touched.occupationId && "dd-border-danger"}`}>
                                            <Listbox
                                                value={values.occupationId}
                                                name="occupationId"
                                                onChange={onChangeOccupation}
                                                disabled={loading}
                                            >
                                                <Listbox.Button id="occupationId" className={"ts-input"}>
                                                    <div className="item">
                                                        {occupation?.data?.map(
                                                            (v: Ocupation) => v?.occupationId === values?.occupationId && v?.nameEn
                                                        )}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("occupationId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {occupation?.data?.map((occ: Ocupation) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active ? "optionColor" : null
                                                                            }`
                                                                        }
                                                                        value={occ.occupationId}
                                                                        key={occ.occupationId}
                                                                    >
                                                                        {occ?.nameEn}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.occupationId && touched.occupationId && (
                                            <div className="text-danger">{errors.occupationId}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="intro-y col-span-12 lg:col-span-6">
                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="font-bold text-base mr-auto">{dataGu.familyDetails}</h2>
                            </div>
                            <div id="vertical-form2" className="p-5">
                                <div className="preview">
                                    {/* Primary member name */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="fullNameGu" className="mb-2 form-label">
                                            {dataGu.primaryMemberName}
                                        </label>
                                        <input
                                            id="fullNameGu"
                                            name="fullNameGu"
                                            type="text"
                                            value={values.fullNameGu}
                                            onChange={(e) => setFieldValue("fullNameGu", e.target.value)}
                                            className={`form-control ${errors.fullNameGu && touched.fullNameGu && "border-danger"}`}
                                            disabled={loading}
                                        />
                                        {errors.fullNameGu && touched.fullNameGu && <div className="text-danger">{errors.fullNameGu}</div>}
                                    </div>

                                    {/* Address */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="addressGu" className="mb-2 form-label">
                                            {dataGu.address}
                                        </label>
                                        <textarea
                                            id="addressGu"
                                            name="addressGu"
                                            value={values.addressGu}
                                            onChange={(e) => setFieldValue("addressGu", e.target.value)}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.addressGu && touched.addressGu && "border-danger"}`}
                                            disabled={loading}
                                        />
                                        {errors.addressGu && touched.addressGu && <div className="text-danger">{errors.addressGu}</div>}
                                    </div>

                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">User privacy</label>
                                        <div className="form-switch form-check" style={{ height: "37px" }}>
                                            <input
                                                id="privacy"
                                                name="privacy"
                                                onChange={() => setFieldValue("privacy", !values.privacy)}
                                                checked={values.privacy}
                                                className={"form-check-input"}
                                                type="checkbox"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                        <button className="btn btn-secondary w-24" type="reset" disabled={loading || !dirty}>
                            Reset
                        </button>
                        <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                            {loading ? <Loading isButton /> : "Save"}
                        </button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default AddForm;
