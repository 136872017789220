import React, { useCallback } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordPayload, TableStateValue } from "../../helper/interface";
import { onForgotPassword } from "../../service/auth";
import { forgotPasswordValidation } from "../../helper/validationSchema";
import Loading from "../../container/Loading";

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loading = useSelector((state: TableStateValue) => state.table.loading);

    const onSubmit = useCallback((value: ForgotPasswordPayload) => {
        onForgotPassword(value, dispatch, navigate);
    }, []);

    return (
        <div className="my-auto mx-auto xl:ml-20 bg-white dark:bg-darkmode-600 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
            <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">Forgot password</h2>
            <div className="intro-x mt-8">
                <Formik
                    initialValues={{
                        email: "",
                    }}
                    validationSchema={forgotPasswordValidation}
                    onSubmit={onSubmit}
                >
                    {({ errors, touched, handleChange, values, handleBlur }) => (
                        <>
                            <Form>
                                <input
                                    autoFocus
                                    name="email"
                                    type="text"
                                    className={`intro-x login__input form-control py-3 px-4 block ${
                                        errors.email && touched.email && "border-danger"
                                    }`}
                                    value={values.email}
                                    onChange={handleChange}
                                    placeholder="Enter email"
                                    onBlur={handleBlur}
                                />
                                {errors.email && touched.email && <div className="text-danger">{errors.email}</div>}
                                <div className="flex items-center justify-content-end mt-2 cursor-pointer">
                                    <span onClick={() => navigate("/auth/login")}>Back to sign in?</span>
                                </div>
                                <div className="intro-x text-center xl:text-left">
                                    <button
                                        type="submit"
                                        className="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                                        disabled={loading}
                                    >
                                        {loading ? <Loading isButton /> : "Submit"}
                                    </button>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default ForgotPassword;
