import { Formik, FormikHelpers } from "formik";
import { FC, useCallback } from "react";
import { ArrowLeftCircle } from "react-feather";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import usePathName from "../../hooks/usePathName";
import { onAddVillage } from "../../service/villages";
import { VillagePayload } from "../../helper/interface";
import { addVillageValidation } from "../../helper/validationSchema";
import AddEditForm from "./AddEditForm";
import { addSuccessToast } from "../../helper/toast";

const initialValues: VillagePayload = {
    nameEn: "",
    nameGu: "",
    subDistrictEn: "",
    subDistrictGu: "",
    districtEn: "",
    districtGu: "",
};

const AddVillages: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    usePathName([
        { pathName: "Villages", href: "/villages" },
        { pathName: "Add", href: "/villages/add " },
    ]);

    const onSubmit = useCallback((values: VillagePayload, { resetForm }: FormikHelpers<VillagePayload>) => {
        onAddVillage(values, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Add new",
                primaryLink: "/villages/add",
                secondaryLinkTitle: "Edit",
                secondaryLink: `/villages/edit/${res.data.villageId}`,
            };

            addSuccessToast(res.message, navigate, toastLink);
            resetForm();
            navigate("/villages");
        });
    }, []);

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/villages")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Add new village</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    <Formik initialValues={initialValues} validationSchema={addVillageValidation} onSubmit={onSubmit}>
                        {(props) => <AddEditForm {...props} />}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AddVillages;
