import { useCallback } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";

import usePathName from "../../hooks/usePathName";
import { api } from "../../config/Api";
import useFetch from "../../hooks/useFetch";
import Loading from "../../container/Loading";
import { editSuccessToast } from "../../helper/toast";
import { donorValidation } from "../../helper/validationSchema";
import { DonorPayload } from "../../helper/interface";
import { onEditDonor } from "../../service/donors";
import AddEditForm from "./AddEditForm";

const EditDonor = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { donorId = "" } = useParams();

    const { res, loading, error } = useFetch<DonorPayload>(`${api.donor}/${donorId}`);

    usePathName([
        { pathName: "Donor", href: "/donors" },
        { pathName: "Edit", href: `/donors/edit/${donorId}` },
    ]);

    const onSubmit = useCallback((value: DonorPayload) => {
        const payload = {
            donorId: +donorId,
            memberId: value.memberId,
            amount: value.amount,
            descriptionEn: value.descriptionEn,
            descriptionGu: value.descriptionGu,
            date: value.date,
            annualYear: value?.annualYear,
            lifeLong: value.lifeLong,
        };
        onEditDonor(payload, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Edit",
                primaryLink: `/donors/edit/${res?.data?.donorId ?? payload?.donorId}`,
            };

            editSuccessToast(res.message, navigate, toastLink);
            navigate("/donors");
        });
    }, []);

    const initialValues = res?.data;

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/donors")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Edit donor details</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400 mx-auto">
                    {error ? (
                        <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                            <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                        </div>
                    ) : loading ? (
                        <Loading />
                    ) : initialValues ? (
                        <Formik initialValues={initialValues} validationSchema={donorValidation} onSubmit={onSubmit}>
                            {(props) => <AddEditForm {...props} />}
                        </Formik>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default EditDonor;
