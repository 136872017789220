import { ReactElement, ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
    children: ReactNode;
}

const Marksheet = ({ children }: Props): ReactElement => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (
        <>
            <div>
                <div className="box">
                    <ul className="nav nav-boxed-tabs mt-8 max-[600px]:flex-col" role="tablist">
                        <li className="nav-item flex-1" role="presentation">
                            <button
                                className={`nav-link w-full py-2 h-full ${pathname.includes("education-year") ? "active" : ""}`}
                                data-tw-toggle="pill"
                                type="button"
                                role="tab"
                                aria-selected="true"
                                onClick={() => {
                                    navigate("/marksheet/education-year");
                                }}
                            >
                                Education Year
                            </button>
                        </li>
                        <li
                            className="nav-item flex-1"
                            role="presentation"
                            onClick={() => {
                                navigate("/marksheet/education-standard");
                            }}
                        >
                            <button
                                className={`nav-link w-full py-2 h-full ${pathname.includes("education-standard") ? "active" : ""}`}
                                data-tw-toggle="pill"
                                type="button"
                                role="tab"
                                aria-selected="false"
                            >
                                Standard
                            </button>
                        </li>
                        <li
                            className="nav-item flex-1"
                            role="presentation"
                            onClick={() => {
                                navigate("/marksheet/education-board");
                            }}
                        >
                            <button
                                className={`nav-link w-full py-2 h-full ${pathname.includes("education-board") ? "active" : ""}`}
                                data-tw-toggle="pill"
                                type="button"
                                role="tab"
                                aria-selected="false"
                            >
                                Board
                            </button>
                        </li>
                        <li
                            className="nav-item flex-1"
                            role="presentation"
                            onClick={() => {
                                navigate("/marksheet/education-medium");
                            }}
                        >
                            <button
                                className={`nav-link w-full py-2 h-full ${pathname.includes("education-medium") ? "active" : ""}`}
                                data-tw-toggle="pill"
                                type="button"
                                role="tab"
                                aria-selected="false"
                            >
                                Medium
                            </button>
                        </li>
                        <li
                            className="nav-item flex-1"
                            role="presentation"
                            onClick={() => {
                                navigate("/marksheet/marksheet-upload");
                            }}
                        >
                            <button
                                className={`nav-link w-full py-2 h-full ${pathname.includes("marksheet-upload") ? "active" : ""}`}
                                data-tw-toggle="pill"
                                type="button"
                                role="tab"
                                aria-selected="false"
                            >
                                Marksheet
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content mt-5">{children}</div>
            </div>
        </>
    );
};

export default Marksheet;
