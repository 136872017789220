import moment from "moment";
import { HUNDRED, ONE, TWO, ZERO } from "../helper/constant";
import { Option } from "../helper/interface";

const YEAR = "year";
const YEARS = "years";
const MONTH = "month";
const MONTHS = "months";
const DATEFORMAT = "YYYYMMDD";

/**
 *
 * @param dob pass date as argument
 * @returns returns difference between input date to current date in year or month
 */
export const calcAge = (dob: string) => {
    const diff = moment().diff(moment(dob, DATEFORMAT), YEARS);
    const diffInMonth = moment().diff(moment(dob, DATEFORMAT), MONTHS);

    if (diff === ZERO) return diffInMonth + ONE + ` ${diffInMonth === ZERO ? MONTH : MONTHS}`;

    if (diff === ONE) return diff + ` ${YEAR}`;

    return diff + ` ${YEARS}`;
};

/**
 * @returns returns list of last 100 years till current year
 */
export const year = (): string[] => {
    const startYear = new Date().getFullYear() - HUNDRED;
    const currentYear = new Date().getFullYear();

    if (startYear >= currentYear) return [String(currentYear)];

    const year = [];
    for (let i = startYear; i <= currentYear; i++) {
        const nextYear = String(i + ONE)
            .split("")
            .splice(-TWO)
            .join("");
        year.push(i + "-" + nextYear);
    }
    return year.reverse();
};

/**
 *
 * @returns return today's date
 */
export const currentDate = (): string => {
    const today = moment(new Date()).format("YYYY-MM-DD");
    return today;
};

/**
 *
 * @param arr will get array of data
 * @returns returns array of object with value and label
 */
export const selectTagsOptions = (arr: string[]) => {
    if (arr && !arr.length) return;

    const tagsOpt: Option[] = [];

    for (let i = 0; i < arr?.length; i++) tagsOpt.push({ value: arr[i], label: arr[i] });

    return tagsOpt;
};

/**
 *
 * @param str pass filename with extension
 * @returns extension
 */
export const isPDF = (str: string) => {
    return str?.slice(str?.lastIndexOf("."), str?.length) === ".pdf";
};
