import { useCallback, useState, useEffect } from "react";
import { Form } from "formik";
import { useSelector } from "react-redux";
import { Listbox, Transition } from "@headlessui/react";
import moment from "moment";
import { Calendar } from "react-feather";
import {
    AnnualContributionPayload,
    DonorPayload,
    Family,
    FormParams,
    IncludeApiPayload,
    Member,
    TableStateValue,
    ZIndexMaintainer,
} from "../../helper/interface";
import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import { dataGu } from "../../helper/dataGu";
import { dataEn } from "../../helper/dataEn";
import CustomDatePicker from "../datepicker/CustomDatePicker";
import { ZERO, currentYear } from "../../helper/constant";
import { onDatePickerClose, onDatePickerOpen, serializeQueryParams } from "../../helper/helper";
import Loading from "../../container/Loading";
import { useParams } from "react-router-dom";

const AddEditForm = ({
    errors,
    touched,
    values,
    dirty,
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleBlur,
}: FormParams<DonorPayload>) => {
    const loading = useSelector((state: TableStateValue) => state.table.loading);
    const { donorId = "" } = useParams();

    const [zIndex, setZIndex] = useState<ZIndexMaintainer>({});
    const [memberList, setMemberList] = useState<Member[]>([]);

    const payloadForAPI: IncludeApiPayload = {
        fieldName: "primaryMemberName",
        order: "ASC",
    };

    if (values.memberId) {
        payloadForAPI.includeMember = values.memberId;
    }

    const { res: families } = useFetch<Family[]>(`${api.family}?${serializeQueryParams(payloadForAPI)}`);
    const { res: annualYear } = useFetch<AnnualContributionPayload[]>(api.annualContribution);

    useEffect(() => {
        if (!donorId && annualYear && Array.isArray(annualYear?.data)) {
            annualYear.data.forEach((ele) => {
                if (ele.year === currentYear) {
                    setFieldValue("annualYear", ele);
                }
            });
        }
    }, [annualYear]);

    useEffect(() => {
        if (!values?.familyId && families && Array.isArray(families.data) && values?.member?.memberId) {
            families.data.forEach((ele) => {
                if (ele.familyId === values.member?.familyId) {
                    if (ele?.members) setMemberList(ele.members);

                    setFieldValue("familyId", ele.familyId);
                }
            });
        }
    }, [families]);

    const onChangeMemberId = useCallback((value: number) => {
        setFieldValue("memberId", value);
    }, []);

    const onChangeAnnualYear = useCallback((value: AnnualContributionPayload) => {
        setFieldValue("annualYear", value);
    }, []);

    const onChangeFamily = useCallback(
        (value: number) => {
            setFieldValue("memberId", null);
            families?.data?.forEach((ele) => {
                if (ele.familyId === value && ele.members) {
                    setMemberList(ele.members);
                }
            });

            setFieldValue("familyId", value);
        },
        [families]
    );

    return (
        <>
            <Form>
                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                    <div className="intro-y col-span-12 lg:col-span-6">
                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="font-medium text-base mr-auto">{dataEn.donorDescription}</h2>
                            </div>
                            <div id="vertical-form1" className="p-5">
                                <div className="preview">
                                    {/* Primary Member Name */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Primary Member Name</label>
                                        <div className={`tom-select ${errors.familyId && touched.familyId && "dd-border-danger"}`}>
                                            <Listbox value={values?.familyId ?? null} name="familyId" onChange={onChangeFamily}>
                                                <Listbox.Button id="familyId" className={"ts-input"}>
                                                    <div className="item">
                                                        {families?.data?.map(
                                                            (v) => v?.familyId === values?.familyId && v?.primaryMember?.fullNameEn
                                                        ) ?? ""}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("familyId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {families?.data?.map((ele: Family) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || ele?.familyId === values?.familyId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={ele.familyId}
                                                                        key={ele?.familyId}
                                                                    >
                                                                        {ele?.primaryMember?.fullNameEn}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.familyId && touched.familyId && <div className="text-danger">{errors.familyId}</div>}
                                    </div>
                                    {/* Member Name */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Member Name</label>
                                        <div className={`tom-select ${errors.memberId && touched.memberId && "dd-border-danger"}`}>
                                            <Listbox value={values.memberId} name="memberId" onChange={onChangeMemberId}>
                                                <Listbox.Button id="memberId" className={"ts-input"}>
                                                    <div className="item">
                                                        {memberList?.map((v) => v?.memberId === values?.memberId && v?.fullNameEn)}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("memberId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {memberList?.map((mber: Member) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || mber.memberId === values.memberId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={mber.memberId}
                                                                        key={mber.memberId}
                                                                    >
                                                                        {mber?.fullNameEn}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.memberId && touched.memberId && <div className="text-danger">{errors.memberId}</div>}
                                    </div>

                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Annual year</label>
                                        <div className={`tom-select ${errors.annualYear && touched.annualYear && "dd-border-danger"}`}>
                                            <Listbox value={values.annualYear} name="annualYear" onChange={onChangeAnnualYear}>
                                                <Listbox.Button id="annualYear" className={"ts-input"}>
                                                    <div className="item">{values?.annualYear?.year ?? "Select annual year"}</div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("annualYear", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {annualYear?.data?.map((year) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active ||
                                                                                year.annualContributionId ===
                                                                                    values?.annualYear?.annualContributionId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={year}
                                                                        key={year.year}
                                                                    >
                                                                        {year?.year}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.annualYear && touched.annualYear && <div className="text-danger">{errors.annualYear}</div>}
                                    </div>

                                    {/* Amount */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Amount</label>
                                        <div className="input-group">
                                            <div className="input-group-text">₹</div>
                                            <input
                                                id="amount"
                                                name="amount"
                                                type="text"
                                                onChange={handleChange}
                                                value={values.amount ?? ZERO}
                                                onBlur={(e) => setFieldValue("amount", e.target.value)}
                                                className={`form-control ${errors.amount && touched.amount && "border-danger"}`}
                                            />
                                        </div>
                                        {errors.amount && touched.amount && <div className="text-danger">{errors.amount}</div>}
                                    </div>

                                    {/* Date of Donate */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5" style={{ zIndex: zIndex.date }}>
                                        <label htmlFor="date" className="mb-2 form-label">
                                            Date of Donation
                                        </label>
                                        <div className="relative">
                                            <div className="absolute z-10 rounded-l w-10 h-full flex items-center justify-center bg-slate-100 border text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400">
                                                <Calendar />
                                            </div>
                                            <CustomDatePicker
                                                id="date"
                                                name="date"
                                                maxDate={new Date(moment().format("yyyy-MM-DD"))}
                                                selected={values.date ? new Date(moment(values.date).format("yyyy-MM-DD")) : null}
                                                hasError={Boolean(errors.date && touched.date)}
                                                setFieldValue={setFieldValue}
                                                handleBlur={handleBlur}
                                                onCalendarOpen={onDatePickerOpen(setZIndex, "date")}
                                                onCalendarClose={onDatePickerClose(setZIndex, "date")}
                                            />
                                        </div>
                                        {errors.date && touched.date && <div className="text-danger">{`${errors.date}`}</div>}
                                    </div>

                                    {/* Life long */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Life Long</label>
                                        <div className="form-switch form-check" style={{ height: "37px" }}>
                                            <input
                                                id="lifeLong"
                                                name="lifeLong"
                                                onChange={() => setFieldValue("lifeLong", !values.lifeLong)}
                                                checked={values.lifeLong}
                                                className={"form-check-input"}
                                                type="checkbox"
                                            />
                                        </div>
                                    </div>

                                    {/* Description */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">{dataEn.description}</label>
                                        <textarea
                                            id="descriptionEn"
                                            name="descriptionEn"
                                            onChange={handleChange}
                                            value={values.descriptionEn}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.descriptionEn && touched.descriptionEn && "border-danger"}`}
                                        />
                                        {errors.descriptionEn && touched.descriptionEn && (
                                            <div className="text-danger">{errors.descriptionEn}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Gujarati Form */}
                    <div className="intro-y col-span-12 lg:col-span-6">
                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="font-bold text-base mr-auto">{dataGu.donorDescription}</h2>
                            </div>
                            <div id="vertical-form2" className="p-5">
                                <div className="preview">
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="descriptionGu" className="mb-2 form-label">
                                            {dataGu.description}
                                        </label>
                                        <textarea
                                            id="descriptionGu"
                                            name="descriptionGu"
                                            value={values.descriptionGu}
                                            onChange={(e) => setFieldValue("descriptionGu", e.target.value)}
                                            className={`form-control ${errors.descriptionGu && touched.descriptionGu && "border-danger"}`}
                                        />
                                        {errors.descriptionGu && touched.descriptionGu && (
                                            <div className="text-danger">{errors.descriptionGu}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                        <button className="btn btn-secondary w-24" type="reset" disabled={loading || !dirty}>
                            Reset
                        </button>
                        <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                            {loading ? <Loading isButton /> : "Save"}
                        </button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default AddEditForm;
