export const ZERO = 0;
export const ONE = 1;
export const TWO = 2;
export const FIVE = 5;
export const EIGHT = 8;
export const TEN = 10;
export const TWELVE = 12;
export const FIFTEEN = 15;
export const TWENTY = 20;
export const FIFTY = 50;
export const HUNDRED = 100;

export const dateFormat = "YYYY-MM-DD HH:mm:ss";

export const ModeEnum = <const>["light", "dark"];

export const maxTimeForGlobalSetting = TWELVE;

export const EMPTY_ARRAY_LENGTH = ZERO;

export const mobileNumberRegex = /^(\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

export const stringRegex = /[a-zA-Z]/;

export const MIN_PASSWORD_LENGTH = EIGHT;

export const UNAUTHORIZE_STATUS_CODE = 401;

export enum STRING_LIMITS {
    _50 = FIFTY,
}

export enum ADMIN_ROLE {
    normalAdmin = "Admin",
    superAdmin = "Super admin",
}

export enum GENDER {
    MALE = "Male",
    FEMALE = "Female",
}
export enum GENDER_GU {
    MALE = "પુરુષ",
    FEMALE = "સ્ત્રી",
}

export enum LANGUAGE {
    ENGLISH = "en",
    GUJARATI = "gu",
}

export const BLOOD_TYPES = <const>{
    OP: "O+",
    ON: "O-",
    AP: "A+",
    AN: "A-",
    BP: "B+",
    BN: "B-",
    ABP: "AB+",
    ABN: "AB-",
};

export const BLOOD = Object.values(BLOOD_TYPES);

export const maxGlobalSettings = TWELVE;
export const PRIZE_VALUE = <const>{
    FIRST: "First",
    SECOND: "Second",
    THIRD: "Third",
};

export const PRIZE = Object.values(PRIZE_VALUE);

export enum MARKS_TYPE_VALUE {
    PERCENTAGE = "Percentage",
    PERCENTAGE_RANK = "Percentage rank",
    CGPA = "CGPA",
}

export const MARKS_TYPE = Object.values(MARKS_TYPE_VALUE);

export const startYear = 1900,
    endYear = 2099;

export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const currentYear = new Date().getFullYear();

export const FamilySearchBy = <const>{
    VILLAGE: "Village",
    NAME: "Name",
    MOBILE: "Mobile",
    STATUS: "Status",
    VERIFICATION: "Verification",
};

export const FamilySearchData = Object.values(FamilySearchBy);

export const MarkSheetUploadSearchby = <const>{
    YEAR: "Year",
    STANDARD: "Standard",
    BOARD: "Board",
    MEDIUM: "Medium",
    FAMILY: "Family",
    MEMBER: "Member",
};

export const MarkSheetUploadSearchData = Object.values(MarkSheetUploadSearchby);

export const DonorSearchBy = <const>{
    FAMILY: "Family",
    MEMBER: "Member",
    YEAR: "Year",
    MOBILE: "Mobile",
    VILLAGE: "Village",
    LIFE_LONG: "Life long",
};

export const DonorSearchData = Object.values(DonorSearchBy);

export const BusinessSearchBy = <const>{
    FAMILY: "Family",
    MEMBER: "Member",
    CATEGORY: "Category",
    NAME: "Business name",
    MOBILE: "Mobile",
    VERIFICATION: "Verification",
};

export const BusinessSearchData = Object.values(BusinessSearchBy);

export const ContributionHistorySearchBy = <const>{
    FAMILY: "Family",
    YEAR: "Year",
};

export const ContributionHistorySearchData = Object.values(ContributionHistorySearchBy);
