import { Dispatch } from "@reduxjs/toolkit";
import { dataService } from "../config/DataService";
import { setLoading } from "../store/slice/TableDataSlice";
import { api } from "../config/Api";
import { errorToast, successToast } from "../helper/toast";
import { handleCatchResponse } from "../helper/helper";
import { AxiosError } from "axios";
import { ActivateDeactivateFamily, FamilyPayload, Family, Response, EditFamilyPayload, FamilyResponse } from "../helper/interface";

export const onAddFamily = async (
    payload: FamilyPayload,
    dispatch: Dispatch,
    callback: (res: Required<Response<FamilyResponse>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.post(api.family, payload, { headers: { "Content-Type": "multipart/form-data" } });
        const res: Required<Response<FamilyResponse>> = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onEditFamily = async (payload: EditFamilyPayload, dispatch: Dispatch, callback: (res: Required<Response<Family>>) => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.put(api.family, payload, { headers: { "Content-Type": "multipart/form-data" } });
        const res: Required<Response<Family>> = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeleteFamily = async (familyId: number, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.family}/${familyId}`);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onActivateDeactivateFamily = async (data: ActivateDeactivateFamily, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            isActive: data.isActive,
        };

        const response = await dataService.patch(`${api.family}/${data.familyId}`, payload);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
