import { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { Edit, Trash2 } from "react-feather";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CustomTable from "../table/CustomTable";
import useTable from "../../hooks/useTable";
import { api } from "../../config/Api";
import DeleteModal from "../../modal/DeleteModal";
import { onDeleteContributionHistory } from "../../service/contributionHistory";
import usePathName from "../../hooks/usePathName";
import { AnnualContributionForSelect, ContributionHistoryValue, FamiliesMetaFilter, TableStateValue } from "../../helper/interface";
import { Formik } from "formik";
import Filter from "./Filter";
import useFetch from "../../hooks/useFetch";

let initialValues: FamiliesMetaFilter = {
    searchBy: "Year",
};

const ContributionHistory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const tableFilterData = useSelector((state: TableStateValue) => state.table.tableData);

    useEffect(() => {
        if (tableFilterData.metaFilter && tableFilterData.metaFilter.value) {
            const metaFilter = tableFilterData.metaFilter;

            initialValues = {
                searchBy: metaFilter.searchBy,
                value: metaFilter.value,
            };
        } else {
            initialValues = {
                searchBy: "Year",
                value: null,
            };
        }
    }, [tableFilterData.metaFilter]);

    usePathName([{ pathName: "Contribution history", href: "/contribution-history" }]);

    const [contributionHistory, setContributionHistory] = useState<ContributionHistoryValue | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const contributionHistoryTable = useTable<ContributionHistoryValue>("contributionHistory", api.contributionHistory);

    const { res: annualContribution } = useFetch<AnnualContributionForSelect[]>(api.annualContribution);

    useEffect(() => {
        if (annualContribution?.data && annualContribution.data[0] && !tableFilterData.metaFilter) {
            initialValues = {
                searchBy: "Year",
                value: `${annualContribution.data[0].annualContributionId}`,
            };
            contributionHistoryTable.onSearch("", initialValues);
        }
    }, [annualContribution]);

    const onDeleteClick = useCallback((record: ContributionHistoryValue) => {
        setContributionHistory(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (contributionHistory?.contributionHistoryId) {
            onDeleteContributionHistory(contributionHistory.contributionHistoryId, dispatch, () => {
                contributionHistoryTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [contributionHistory, contributionHistoryTable]);

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const advertisementCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: ContributionHistoryValue, srNumber: number) => {
                return <span>{srNumber}</span>;
            },
        },
        {
            title: "YEAR",
            key: "annualContributionId",
            render: (record: ContributionHistoryValue) => {
                return <span>{record?.annualContribution?.year ?? "-"}</span>;
            },
        },
        {
            title: "PRIMARY MEMBER NAME",
            key: "familyId",
            render: (record: ContributionHistoryValue) => {
                return <span>{record?.family?.primaryMember?.fullNameEn ?? "-"}</span>;
            },
        },
        {
            title: "PAID AMOUNT",
            key: "paidAmount",
            sortable: true,
            render: (record: ContributionHistoryValue) => {
                return <span>{record?.paidAmount ?? "-"}</span>;
            },
        },
        {
            title: "PAID DATE",
            key: "paidDate",
            sortable: true,
            render: (record: ContributionHistoryValue) => {
                return <span>{moment(record?.paidDate).format("DD/MM/YYYY") ?? "-"}</span>;
            },
        },
        {
            title: "ACTION",
            key: "action",
            render: (record: ContributionHistoryValue) => {
                return (
                    <div className="flex justify-center items-center text-primary">
                        <Tooltip id="simpleTooltipEditDelete" place="top" />
                        <div
                            className="flex items-center mr-3 cursor-pointer"
                            data-tooltip-id="simpleTooltipEditDelete"
                            data-tooltip-content={"Edit contributionHistory"}
                            onClick={() => navigate(`/contribution-history/edit/${record?.contributionHistoryId}`)}
                        >
                            <Edit className="w-4 h-4 mr-1" />
                            <span className="pt-[2px] leading-[16px]">Edit</span>
                        </div>
                        <div
                            className="flex items-center text-danger cursor-pointer"
                            data-tooltip-id="simpleTooltipEditDelete"
                            data-tooltip-content={"Delete contributionHistory"}
                            onClick={() => onDeleteClick(record)}
                        >
                            <Trash2 className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Delete</span>
                        </div>
                    </div>
                );
            },
        },
    ];

    const onMetaFilteredSubmit = (value: FamiliesMetaFilter) => {
        const metaFilter = {
            searchBy: value.searchBy,
            value: value.value,
        };

        contributionHistoryTable.onSearch("", metaFilter);
    };

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this annual contribution?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={advertisementCols}
                    data={contributionHistoryTable.tableData ?? []}
                    tableKey="contributionHistoryId"
                    reload={contributionHistoryTable.fetchApi}
                    currentOrder={contributionHistoryTable.currentOrder}
                    error={contributionHistoryTable.error}
                    loading={contributionHistoryTable.loading}
                    tableOffset={contributionHistoryTable.tableOffset}
                    onSetOrderBy={contributionHistoryTable.onSetOrderBy}
                    className="xl:justify-between xl:items-center"
                    component={
                        <>
                            <Formik initialValues={initialValues} enableReinitialize onSubmit={onMetaFilteredSubmit}>
                                {(props) => <Filter {...props} reload={contributionHistoryTable.fetchApi} />}
                            </Formik>
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto whitespace-nowrap"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new contribution history"
                                    onClick={() => {
                                        navigate("/contribution-history/add");
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default ContributionHistory;
