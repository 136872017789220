import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik, FormikHelpers } from "formik";
import { ArrowLeftCircle } from "react-feather";

import { addSuccessToast } from "../../helper/toast";
import usePathName from "../../hooks/usePathName";
import { DonorPayload } from "../../helper/interface";
import { onAddDonor } from "../../service/donors";
import { donorValidation } from "../../helper/validationSchema";
import AddEditForm from "./AddEditForm";
import { ZERO } from "../../helper/constant";

const initialValues: DonorPayload = {
    memberId: null,
    amount: ZERO,
    descriptionEn: "",
    descriptionGu: "",
    date: new Date(),
    annualYear: null,
    familyId: null,
    lifeLong: false,
};

const AddDonor = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    usePathName([
        { pathName: "Donor", href: "/donors" },
        { pathName: "Add", href: "/donors/add " },
    ]);

    const onSubmit = useCallback((values: DonorPayload, { resetForm }: FormikHelpers<DonorPayload>) => {
        onAddDonor(values, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Add new",
                primaryLink: "/donors/add",
                secondaryLinkTitle: "Edit",
                secondaryLink: `/donors/edit/${res.data.donorId}`,
            };

            addSuccessToast(res.message, navigate, toastLink);
            resetForm();
            navigate("/donors");
        });
    }, []);

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/donors")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Add new donor</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    <Formik initialValues={initialValues} validationSchema={donorValidation} onSubmit={onSubmit}>
                        {(props) => <AddEditForm {...props} />}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AddDonor;
