import { Form as FormikForm } from "formik";
import { useSelector } from "react-redux";
import { AnnualContributionPayload, FormParams, TableStateValue } from "../../helper/interface";
import Loading from "../../container/Loading";

const Form = ({ errors, touched, handleChange, values, handleBlur, dirty }: FormParams<AnnualContributionPayload>) => {
    const loading = useSelector((state: TableStateValue) => state.table.loading);

    return (
        <FormikForm>
            <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="year" className="form-label">
                        Annual Year
                    </label>
                    <input
                        autoFocus
                        id="name"
                        name="year"
                        type="number"
                        onChange={handleChange}
                        value={values.year}
                        onBlur={handleBlur}
                        className={`form-control ${errors.year && touched.year && "border-danger"}`}
                        placeholder="Child"
                    />
                    {errors.year && touched.year && <div className="text-danger">{errors.year}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="amount" className="form-label">
                        Annual Amount
                    </label>
                    <div className="input-group">
                        <div className="input-group-text">₹</div>
                        <input
                            id="amount"
                            name="amount"
                            type="number"
                            onChange={handleChange}
                            value={values.amount}
                            onBlur={handleBlur}
                            className={`form-control ${errors.amount && touched.amount && "border-danger"}`}
                            placeholder="Child"
                        />
                    </div>
                    {errors.amount && touched.amount && <div className="text-danger">{errors.amount}</div>}
                </div>
                <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                    <button className="btn btn-secondary w-24" type="reset" disabled={loading || !dirty}>
                        Reset
                    </button>
                    <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                        {loading ? <Loading isButton /> : "Save"}
                    </button>
                </div>
            </div>
        </FormikForm>
    );
};

export default Form;
