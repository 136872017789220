import { useCallback, useState, useEffect } from "react";
import { Form as FormikForm } from "formik";
import { useSelector } from "react-redux";
import moment from "moment";
import { Listbox, Transition } from "@headlessui/react";
import { Calendar } from "react-feather";

import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import {
    AnnualContributionPayload,
    ContributionHistoryPayload,
    Family,
    FormParams,
    IncludeApiPayload,
    TableStateValue,
    ZIndexMaintainer,
} from "../../helper/interface";
import CustomDatePicker from "../datepicker/CustomDatePicker";
import { onDatePickerOpen, onDatePickerClose, serializeQueryParams } from "../../helper/helper";
import { currentYear } from "../../helper/constant";
import Loading from "../../container/Loading";
import { useParams } from "react-router-dom";

const Form = ({
    errors,
    touched,
    handleChange,
    values,
    handleBlur,
    dirty,
    setFieldTouched,
    setFieldValue,
}: FormParams<ContributionHistoryPayload>) => {
    const loading = useSelector((state: TableStateValue) => state.table.loading);
    const { contributionHistoryId = "" } = useParams();

    const [zIndex, setZIndex] = useState<ZIndexMaintainer>({});

    const payloadForAPI: IncludeApiPayload = {
        fieldName: "primaryMemberName",
        order: "ASC",
    };

    if (values.familyId) {
        payloadForAPI.includeFamily = values.familyId;
    }

    const { res: family } = useFetch<Family[]>(`${api.family}?${serializeQueryParams(payloadForAPI)}`);
    const { res: annualContribution } = useFetch<AnnualContributionPayload[]>(api.annualContribution);

    useEffect(() => {
        if (!contributionHistoryId && annualContribution && Array.isArray(annualContribution?.data)) {
            annualContribution.data.forEach((ele) => {
                if (ele.year === currentYear) {
                    setFieldValue("annualContribution", ele);
                    if (!values.paidAmount) {
                        setFieldValue("paidAmount", ele.amount);
                    }
                }
            });
        }
    }, [annualContribution]);

    const onChangeFamily = useCallback((value: number) => {
        setFieldValue("familyId", value);
    }, []);

    const onChangeAnnualContribution = useCallback((value: AnnualContributionPayload | null) => {
        setFieldValue("paidAmount", value?.amount);
        setFieldValue("annualContribution", value);
    }, []);

    return (
        <FormikForm>
            <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Primary Member Name</label>
                    <div className={`tom-select ${errors.familyId && touched.familyId && "dd-border-danger"}`}>
                        <Listbox value={values.familyId} name="familyId" onChange={onChangeFamily}>
                            <Listbox.Button id="familyId" className={"ts-input"}>
                                <div className="item">
                                    {family?.data?.map((ele) => ele.familyId === values?.familyId && ele?.primaryMember?.fullNameEn) ??
                                        "Select family"}
                                </div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("familyId", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {family?.data?.map((vlg: Family) => {
                                            return (
                                                <Listbox.Option
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                            active || vlg?.familyId === values?.familyId ? "optionColor" : null
                                                        }`
                                                    }
                                                    value={vlg.familyId}
                                                    key={vlg.familyId}
                                                >
                                                    {vlg?.primaryMember?.fullNameEn}
                                                </Listbox.Option>
                                            );
                                        })}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                    </div>
                    {errors.familyId && touched.familyId && <div className="text-danger">{errors.familyId}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Annual Year</label>
                    <div className={`tom-select ${errors.annualContribution && touched.annualContribution && "dd-border-danger"}`}>
                        <Listbox value={values.annualContribution} name="annualContribution" onChange={onChangeAnnualContribution}>
                            <Listbox.Button id="annualContribution" className={"ts-input"}>
                                <div className="item">{values?.annualContribution?.year ?? "Select annual year"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("annualContribution", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {annualContribution?.data?.map((vlg: AnnualContributionPayload) => {
                                            return (
                                                <Listbox.Option
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                            active ||
                                                            vlg?.annualContributionId === values?.annualContribution?.annualContributionId
                                                                ? "optionColor"
                                                                : null
                                                        }`
                                                    }
                                                    value={vlg}
                                                    key={vlg.annualContributionId}
                                                >
                                                    {vlg?.year}
                                                </Listbox.Option>
                                            );
                                        })}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                    </div>
                    {errors.annualContribution && touched.annualContribution && (
                        <div className="text-danger">{errors.annualContribution}</div>
                    )}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6" style={{ zIndex: zIndex.paidDate }}>
                    <label htmlFor="paidDate" className="mb-2 form-label">
                        Paid Date
                    </label>
                    <div className="relative">
                        <div className="absolute z-10 rounded-l w-10 h-full flex items-center justify-center bg-slate-100 border text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400">
                            <Calendar />
                        </div>
                        <CustomDatePicker
                            id="paidDate"
                            name="paidDate"
                            maxDate={new Date(moment().format("yyyy-MM-DD"))}
                            selected={values.paidDate ? new Date(moment(values.paidDate).format("yyyy-MM-DD")) : null}
                            hasError={Boolean(errors.paidDate && touched.paidDate)}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            onCalendarOpen={onDatePickerOpen(setZIndex, "paidDate")}
                            onCalendarClose={onDatePickerClose(setZIndex, "paidDate")}
                        />
                    </div>
                    {errors.paidDate && touched.paidDate && <div className="text-danger">{`${errors.paidDate}`}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="paidAmount" className="form-label">
                        Paid Amount
                    </label>
                    <div className="input-group">
                        <div className="input-group-text">₹</div>
                        <input
                            id="paidAmount"
                            name="paidAmount"
                            type="number"
                            onChange={handleChange}
                            value={values.paidAmount}
                            onBlur={handleBlur}
                            className={`form-control ${errors.paidAmount && touched.paidAmount && "border-danger"}`}
                        />
                    </div>
                    {errors.paidAmount && touched.paidAmount && <div className="text-danger">{errors.paidAmount}</div>}
                </div>
                <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                    <button className="btn btn-secondary w-24" type="reset" disabled={loading || !dirty}>
                        Reset
                    </button>
                    <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                        {loading ? <Loading isButton /> : "Save"}
                    </button>
                </div>
            </div>
        </FormikForm>
    );
};

export default Form;
