import { AlertTriangle, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from "react-feather";
import Loading from "../../container/Loading";
import { ColumnParams, OrderEnum, TableParams } from "../../helper/interface";
import { useCallback, useEffect, useState } from "react";
import { EMPTY_ARRAY_LENGTH, ONE, TWO } from "../../helper/constant";

const CustomTable = <T,>({
    cols,
    data,
    tableKey,
    loading,
    tableOffset,
    error,
    currentOrder,
    component,
    onSetOrderBy,
    className,
}: TableParams<T>) => {
    const [pagination, setPagination] = useState<number[]>([tableOffset.page]);

    const onExpandView = useCallback(
        (next: boolean) => {
            let counter = 1;
            const fromStart = 1;
            const minLength = 0;
            const customLength = 3;

            if (next) {
                for (let i = Math.max(...pagination) + fromStart; i <= tableOffset.maxPage; i++) {
                    if (counter > TWO) {
                        return;
                    }
                    pagination.push(i);
                    if (pagination.length > customLength) {
                        pagination.shift();
                    }
                    setPagination([...pagination]);

                    counter++;
                }
            } else {
                for (let i = Math.min(...pagination) - fromStart; i > minLength; i--) {
                    if (counter > TWO) {
                        return;
                    }
                    pagination.unshift(i);
                    if (pagination.length > customLength) {
                        pagination.pop();
                    }
                    setPagination([...pagination]);

                    counter++;
                }
            }
        },
        [pagination, tableOffset.maxPage]
    );

    useEffect(() => {
        if (!pagination.includes(tableOffset.page)) {
            setPagination([tableOffset.page]);
        } else {
            const maxLimit = 3;

            const newArr = pagination.filter((num: number) => {
                return num < tableOffset.page + maxLimit && num > tableOffset.page - maxLimit;
            });

            setPagination([...newArr]);
        }
    }, [tableOffset.page]);

    useEffect(() => {
        setPagination([ONE]);
    }, [tableOffset.limit]);

    return (
        <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
            <div className="intro-y box p-5 mt-5">
                <div className={`flex flex-col sm:flex-row sm:items-end xl:items-start ${className}`}>{component}</div>
                <div
                    id="tabulator"
                    className="mt-5 table-report table-report--tabulator tabulator"
                    tabulator-layout="fitColumns"
                    style={{ overflow: "auto" }}
                >
                    {loading && error === null ? (
                        <Loading />
                    ) : error !== null ? (
                        <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                            <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                        </div>
                    ) : data.length === EMPTY_ARRAY_LENGTH ? (
                        <div className="box py-10 sm:py-20 mt-5">
                            <div className="font-medium text-center text-lg">No data found</div>
                        </div>
                    ) : (
                        <>
                            <table className="table sm:mt-2">
                                <thead>
                                    <tr>
                                        <>
                                            {cols.map((data: ColumnParams<T>, i: number) => (
                                                <th
                                                    className={`${data.sortable && "table-col-custom cursor-pointer"} ${
                                                        i !== EMPTY_ARRAY_LENGTH ? "text-center" : ""
                                                    } ${data.align ? `text-${data.align}` : ""} ${data.minWidth ? "!min-w-[200px]" : ""}`}
                                                    key={data.key}
                                                    onClick={() => (data.sortable ? onSetOrderBy(data.key, OrderEnum.DESC) : "")}
                                                >
                                                    <div className="tabulator-col-content">
                                                        <div className="tabulator-col-title-holder">
                                                            <div className="tabulator-col-title whitespace-nowrap">{data.title}</div>
                                                        </div>
                                                        {data.sortable ? (
                                                            <div className="tabulator-col-sorter">
                                                                <div
                                                                    className={
                                                                        currentOrder?.fieldName === data.key
                                                                            ? currentOrder.order === "ASC"
                                                                                ? "arrow-active-asc"
                                                                                : "arrow-active-desc"
                                                                            : "tabulator-arrow"
                                                                    }
                                                                ></div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </th>
                                            ))}
                                        </>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((data: T, index: number) => (
                                        <tr key={data[tableKey] as string}>
                                            {cols.map((col, key) => (
                                                <td
                                                    key={key}
                                                    className={`whitespace-nowrap ${
                                                        key !== EMPTY_ARRAY_LENGTH ? "text-center" : "font-medium"
                                                    }`}
                                                >
                                                    {col.render(data, tableOffset.limit * (tableOffset.page - ONE) + index + ONE)}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="tabulator-footer">
                                <span className="tabulator-paginator flex flex-row">
                                    <div>
                                        <label>Page Size</label>
                                        <select
                                            className="tabulator-page-size"
                                            aria-label="Page Size"
                                            title="Page Size"
                                            defaultValue={tableOffset.limit}
                                            onChange={(e) => tableOffset.onSetLimit(+e.target.value)}
                                        >
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={30}>30</option>
                                            <option value={40}>40</option>
                                        </select>
                                    </div>

                                    <div className="hidden md:block mx-auto text-slate-500">
                                        Total {tableOffset.total} {tableOffset.total === ONE ? "record" : "records"}
                                    </div>
                                    <div className="flex items-center sm:mt-0 mt-2">
                                        <button
                                            className="tabulator-page page-link"
                                            disabled={tableOffset.page === ONE}
                                            onClick={() => tableOffset.onSetPage(ONE)}
                                        >
                                            <ChevronsLeft className="w-4 h-4" />
                                        </button>
                                        <button
                                            className="tabulator-page page-link"
                                            disabled={tableOffset.page - ONE <= EMPTY_ARRAY_LENGTH}
                                            onClick={() => tableOffset.onPrevious()}
                                        >
                                            <ChevronLeft className="w-4 h-4" />
                                        </button>
                                        <button
                                            className="tabulator-page page-link"
                                            disabled={Math.min(...pagination) <= ONE || pagination.includes(tableOffset.page - TWO)}
                                            onClick={() => onExpandView(false)}
                                        >
                                            ...
                                        </button>
                                        {pagination.map((i) => (
                                            <button
                                                className={`tabulator-page page-link ${tableOffset.page === i && "active"}`}
                                                key={i}
                                                onClick={() => tableOffset.onSetPage(i)}
                                            >
                                                {i}
                                            </button>
                                        ))}
                                        <button
                                            className="tabulator-page page-link"
                                            disabled={
                                                Math.max(...pagination) >= tableOffset.maxPage ||
                                                pagination.includes(tableOffset.page + TWO)
                                            }
                                            onClick={() => onExpandView(true)}
                                        >
                                            ...
                                        </button>
                                        <button
                                            className="tabulator-page page-link"
                                            disabled={tableOffset.maxPage <= tableOffset.page}
                                            onClick={() => tableOffset.onNext()}
                                        >
                                            <ChevronRight className="w-4 h-4" />
                                        </button>
                                        <button
                                            className="tabulator-page page-link"
                                            disabled={tableOffset.maxPage === tableOffset.page}
                                            onClick={() => tableOffset.onSetPage(tableOffset.maxPage)}
                                        >
                                            <ChevronsRight className="w-4 h-4" />
                                        </button>
                                    </div>
                                </span>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CustomTable;
