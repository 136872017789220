import { AlertTriangle, ArrowLeftCircle, Mail, Map, Phone } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";

import useFetch from "../../hooks/useFetch";
import { Business } from "../../helper/interface";
import { api } from "../../config/Api";
import Loading from "../../container/Loading";
import { getImageUrl } from "../../helper/helper";
import avatar from "./../../assets/images/userAvatar.png";
import { dataEn } from "../../helper/dataEn";

const ViewBusiness = () => {
    const navigate = useNavigate();
    const { businessId } = useParams();

    const { res, loading, error } = useFetch<Business>(`${api.business}/${businessId}`);

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/business")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">{dataEn.businessDetails}</div>
                </div>
                {error ? (
                    <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                        <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                            <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                        </div>
                    </div>
                ) : loading ? (
                    <Loading />
                ) : (
                    <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                        <div className="intro-y box px-5 pt-5 my-5">
                            <div className="flex flex-col lg:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-5 -mx-5">
                                <div className="flex flex-1 px-5 items-center justify-center lg:justify-start">
                                    <div className="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative">
                                        <img
                                            alt="Profile picture"
                                            className="rounded-full img-bordered"
                                            src={res?.data?.businessImg ? getImageUrl(res?.data?.businessImg) : avatar}
                                        />
                                    </div>
                                    <div className="ml-5">
                                        <div className="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg capitalize">
                                            {res?.data?.businessName ? getImageUrl(res?.data?.businessName) : "Business Name"}
                                        </div>
                                        <div className="text-slate-500 capitalize">
                                            {res?.data?.tags?.map((t: string) => (
                                                <span
                                                    key={t}
                                                    className="capitalize mr-1 py-1 px-2 rounded-full text-xs bg-slate-100 dark:bg-darkmode-400 cursor-pointer font-medium"
                                                >
                                                    {t}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 lg:mt-0 flex-1 px-5 border-l border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0">
                                    <div className="font-medium text-center lg:text-left lg:mt-3">Contact Details</div>
                                    <div className="flex flex-col justify-center items-start mt-4">
                                        {res?.data?.member?.email ? (
                                            <div className="sm:whitespace-normal flex items-center">
                                                <Mail className="mr-4" />
                                                {res?.data?.member?.email}
                                            </div>
                                        ) : null}
                                        {res?.data?.mobile ? (
                                            <div className="sm:whitespace-normal flex items-center mt-3">
                                                <Phone className="mr-4" />
                                                {res?.data?.mobile}
                                            </div>
                                        ) : null}
                                        {res?.data?.location ? (
                                            <div className="sm:whitespace-normal flex items-center mt-3">
                                                <Map className="mr-4" />
                                                {res?.data?.location}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ViewBusiness;
