import { useCallback } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import usePathName from "../../hooks/usePathName";
import { MemberPayload } from "../../helper/interface";
import { memberValidation } from "../../helper/validationSchema";
import { onEditMember } from "../../service/members";
import AddEditForm from "./AddEditForm";
import { api } from "../../config/Api";
import useFetch from "../../hooks/useFetch";
import Loading from "../../container/Loading";
import { editSuccessToast } from "../../helper/toast";

const EditMember = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { memberId = "" } = useParams();

    const location = useLocation();
    const familyIdFromLocation = location?.state?.familyId;

    const { res, loading, error } = useFetch<MemberPayload>(`${api.member}/${memberId}`);

    usePathName([
        { pathName: "Members", href: `${familyIdFromLocation ? "/families/view/" + res?.data?.familyId : "/mambers"}` },
        {
            pathName: "Edit",
            href: `${
                familyIdFromLocation ? "/families/" + res?.data?.familyId + "/members/edit/" + memberId : "/members/edit/" + memberId
            }`,
        },
    ]);

    const onSubmit = useCallback((value: MemberPayload) => {
        const payload = {
            memberId: +memberId,
            gender: value.gender,
            mobile: value.mobile,
            email: value.email || null,
            dob: value.dob || null,
            bloodGroup: value.bloodGroup || null,
            fullNameEn: value.fullNameEn,
            fullNameGu: value.fullNameGu,
            familyId: value.familyId,
            occupationId: value.occupationId,
        };
        onEditMember(payload, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Edit",
                primaryLink: `${
                    familyIdFromLocation
                        ? "/families/" + res?.data?.familyId + "/members/edit/" + res?.data?.memberId ?? payload?.memberId
                        : "/members/edit/" + res?.data?.memberId ?? payload?.memberId
                }`,
            };

            editSuccessToast(res.message, navigate, toastLink);
            navigate(`${familyIdFromLocation ? "/families/view/" + res?.data?.familyId : "/members"}`);
        });
    }, []);

    const initialValues = res?.data;

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div
                        className="flex-2 ml-4"
                        onClick={() => navigate(`${familyIdFromLocation ? "/families/view/" + res?.data?.familyId : "/members"}`)}
                    >
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Edit member</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    {error ? (
                        <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                            <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                        </div>
                    ) : loading ? (
                        <Loading />
                    ) : initialValues ? (
                        <Formik initialValues={initialValues} validationSchema={memberValidation} onSubmit={onSubmit}>
                            {(props) => <AddEditForm {...props} />}
                        </Formik>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default EditMember;
