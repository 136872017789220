import { Form } from "formik";
import { useSelector } from "react-redux";

import { EducationMediumPayload, FormParams, TableStateValue } from "../../../helper/interface";
import { dataGu } from "../../../helper/dataGu";
import { dataEn } from "../../../helper/dataEn";
import Loading from "../../../container/Loading";

const AddEditForm = ({ errors, touched, values, dirty, setFieldValue, handleChange, handleBlur }: FormParams<EducationMediumPayload>) => {
    const loading = useSelector((state: TableStateValue) => state.table.loading);

    return (
        <>
            <Form>
                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <label className="mb-2 form-label">{dataEn.medium}</label>
                        <div className={`tom-select ${errors.nameEn && touched.nameEn && "dd-border-danger"}`}>
                            <input
                                id="nameEn"
                                name="nameEn"
                                type="text"
                                autoFocus
                                onChange={handleChange}
                                value={values.nameEn}
                                onBlur={handleBlur}
                                className={`form-control ${errors.nameEn && touched.nameEn && "border-danger"}`}
                            />
                        </div>
                        {errors.nameEn && touched.nameEn && <div className="text-danger">{errors.nameEn}</div>}
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <label className="mb-2 form-label">{dataGu.medium}</label>
                        <div className={`tom-select ${errors.nameGu && touched.nameGu && "dd-border-danger"}`}>
                            <input
                                id="nameGu"
                                name="nameGu"
                                type="text"
                                value={values.nameGu}
                                onChange={(e) => setFieldValue("nameGu", e.target.value)}
                                className={`form-control ${errors.nameGu && touched.nameGu && "border-danger"}`}
                            />
                        </div>
                        {errors.nameGu && touched.nameGu && <div className="text-danger">{errors.nameGu}</div>}
                    </div>

                    <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                        <button className="btn btn-secondary w-24" type="reset" disabled={loading || !dirty}>
                            Reset
                        </button>
                        <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                            {loading ? <Loading isButton /> : "Save"}
                        </button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default AddEditForm;
