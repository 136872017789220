import { useEffect, FC, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/dashboard/Header";
import MobileMenu from "../components/dashboard/MobileMenu";
import Menu from "../components/dashboard/Menu";
import Heart from "../assets/images/Heart.svg";

const Dashboard: FC = () => {
    const [random, setRandom] = useState(Math.random());

    useEffect(() => {
        document.body.className = "main";
        return () => {
            document.body.className = "";
        };
    }, []);

    const { pathname } = useLocation();

    useEffect(() => {
        setRandom(Math.random());
    }, [pathname]);

    return (
        <>
            <MobileMenu />
            <Header />
            <div className="wrapper">
                <div className="wrapper-box">
                    <Menu />
                    <div className="content relative">
                        <Outlet />
                        <h1 className="intro-y py-3 bottom-0 w-full text-center flex flex-center justify-center gap-1" key={random}>
                            Made with <img src={Heart} className="w-4" />
                            by
                            <a href="https://www.hkinfosoft.com/" target="_blank" className="font-bold text-primary underline">
                                HK Infosoft
                            </a>
                        </h1>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
