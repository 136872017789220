import { Form } from "formik";
import { useSelector } from "react-redux";

import { FormParams, CategoryPayload, TableStateValue } from "../../helper/interface";
import { dataGu } from "../../helper/dataGu";
import { dataEn } from "../../helper/dataEn";
import Loading from "../../container/Loading";

const AddEditForm = ({ errors, touched, handleChange, handleBlur, values, dirty, setFieldValue }: FormParams<CategoryPayload>) => {
    const loading = useSelector((state: TableStateValue) => state.table.loading);

    return (
        <>
            <Form>
                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                    <div className="intro-y col-span-12 lg:col-span-6">
                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="font-medium text-base mr-auto">{dataEn.categoryName}</h2>
                            </div>
                            <div id="vertical-form" className="p-5">
                                <div className="preview">
                                    <div>
                                        <input
                                            id="nameEn"
                                            name="nameEn"
                                            type="text"
                                            autoFocus
                                            onChange={handleChange}
                                            value={values.nameEn}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.nameEn && touched.nameEn && "border-danger"}`}
                                            placeholder={dataEn.writeCategoryName}
                                        />
                                        {errors.nameEn && touched.nameEn && <div className="text-danger">{errors.nameEn}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y col-span-12 lg:col-span-6">
                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="font-bold text-base mr-auto">{dataGu.categoryName}</h2>
                            </div>
                            <div id="vertical-form" className="p-5">
                                <div className="preview">
                                    <div>
                                        <input
                                            id="nameGu"
                                            name="nameGu"
                                            type="text"
                                            value={values.nameGu}
                                            onChange={(e) => setFieldValue("nameGu", e.target.value)}
                                            className={`form-control ${errors.nameGu && touched.nameGu && "border-danger"}`}
                                            placeholder={dataGu.writeCategoryName}
                                        />
                                        {errors.nameGu && touched.nameGu && <div className="text-danger">{errors.nameGu}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                        <button className="btn btn-secondary w-24" type="reset" disabled={loading || !dirty}>
                            Reset
                        </button>
                        <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                            {loading ? <Loading isButton /> : "Save"}
                        </button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default AddEditForm;
