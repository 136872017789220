import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Edit, Trash2 } from "react-feather";
import { Tooltip } from "react-tooltip";

import useTable from "../../hooks/useTable";
import { ActivateDeactivatePrizeRanks, Alignment, PrizeRanks } from "../../helper/interface";
import usePathName from "../../hooks/usePathName";
import DeleteModal from "../../modal/DeleteModal";
import CustomTable from "../table/CustomTable";
import SearchForm from "../table/SearchForm";
import { api } from "../../config/Api";
import { onActivateDeactivatePrizeRanks, onDeletePrizeRanks } from "../../service/prizeRanks";

const PrizeRank = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const prizeRanksTable = useTable<PrizeRanks>("prizeRanks", api.prizeRanks);

    const [isOpen, setIsOpen] = useState(false);
    const [prizeRanks, setPrizeRanks] = useState<PrizeRanks | null>(null);

    const onDeleteClick = useCallback((record: PrizeRanks) => {
        setPrizeRanks(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (prizeRanks?.prizeRankId) {
            onDeletePrizeRanks(prizeRanks?.prizeRankId, dispatch, () => {
                prizeRanksTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [prizeRanks, prizeRanksTable]);

    const onActivateDeactivate = useCallback(
        (payload: ActivateDeactivatePrizeRanks) => {
            onActivateDeactivatePrizeRanks(payload, dispatch, prizeRanksTable.fetchApi);
        },
        [prizeRanksTable]
    );

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const prizeRanksCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: PrizeRanks, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "RANK",
            key: "rank",
            sortable: true,
            render: (record: PrizeRanks) => {
                return <span>{record?.rank}</span>;
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            render: (record: PrizeRanks) => {
                const payload = {
                    prizeRankId: record?.prizeRankId,
                    isActive: !record?.isActive,
                };
                return (
                    <>
                        <Tooltip id="simpleTooltipActiveDeactive" place="top" />
                        <div
                            className="form-switch form-check flex justify-center flex-col"
                            data-tooltip-id="simpleTooltipActiveDeactive"
                            data-tooltip-content={record?.isActive ? "Active" : "Inactive"}
                        >
                            <input
                                id="isActive"
                                name="isActive"
                                onChange={() => onActivateDeactivate(payload)}
                                checked={record?.isActive}
                                className={"form-check-input"}
                                type="checkbox"
                            />
                        </div>
                    </>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: PrizeRanks) => {
                return (
                    <>
                        <div className="flex justify-center items-center text-primary">
                            <Tooltip id="simpleTooltipEditDelete" place="top" />
                            <div
                                className="flex items-center mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Edit prizerank"}
                                onClick={() => navigate(`/ranks/edit/${record?.prizeRankId}`)}
                            >
                                <Edit className="w-4 h-4 mr-1" />
                                <span className="pt-[2px] leading-[16px]">Edit</span>
                            </div>
                            <div
                                className="flex items-center text-danger cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Delete prizerank"}
                                onClick={() => onDeleteClick(record)}
                            >
                                <Trash2 className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Delete</span>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    usePathName([{ pathName: "Prize Ranks", href: "/ranks" }]);

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this prize rank?"
            />

            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={prizeRanksCols}
                    data={prizeRanksTable.tableData ?? []}
                    tableKey="prizeRankId"
                    reload={prizeRanksTable.fetchApi}
                    currentOrder={prizeRanksTable.currentOrder}
                    error={prizeRanksTable.error}
                    loading={prizeRanksTable.loading}
                    tableOffset={prizeRanksTable.tableOffset}
                    onSetOrderBy={prizeRanksTable.onSetOrderBy}
                    component={
                        <>
                            <SearchForm
                                resetSearch={prizeRanksTable.resetSearch}
                                onSearch={prizeRanksTable.onSearch}
                                searchValue={prizeRanksTable.search}
                                searchPlaceHolder="Search by prizerank"
                            />
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new prizerank"
                                    onClick={() => {
                                        navigate("/ranks/add");
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default PrizeRank;
