import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Edit, Eye, Trash2 } from "react-feather";
import { Tooltip } from "react-tooltip";

import { api } from "../../config/Api";
import usePathName from "../../hooks/usePathName";
import useTable from "../../hooks/useTable";
import CustomTable from "../table/CustomTable";
import DeleteModal from "../../modal/DeleteModal";
import { onActivateDeactivateBusiness, onDeleteBusiness } from "../../service/business";
import {
    ActivateDeactivateBusiness,
    Alignment,
    Business,
    FamiliesMetaFilter,
    TableStateValue,
    VerificationStatus,
} from "../../helper/interface";
import avatar from "./../../assets/images/userAvatar.png";
import { Formik } from "formik";
import Filter from "./Filter";
import useFetch from "../../hooks/useFetch";
import { setPendingBusinessCount } from "../../store/slice/BaseSlice";
import { ZERO } from "../../helper/constant";

let initialValues: FamiliesMetaFilter = {
    searchBy: "Family",
};

const BusinessDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const tableFilterData = useSelector((state: TableStateValue) => state.table.tableData);
    const { res } = useFetch<number>(api.getCountOfVerificationPendingBusinesses);

    useEffect(() => {
        if (tableFilterData.metaFilter) {
            const metaFilter = tableFilterData.metaFilter;

            initialValues = {
                searchBy: metaFilter.searchBy,
                value: metaFilter.value,
            };
        } else {
            initialValues = {
                searchBy: "Family",
                value: null,
            };
        }
    }, [tableFilterData.metaFilter]);

    const businessTable = useTable<Business>("business", api.business);

    const [isOpen, setIsOpen] = useState(false);
    const [business, setBusiness] = useState<Business | null>(null);

    usePathName([{ pathName: "Business", href: "/business" }]);

    const onDeleteClick = useCallback((record: Business) => {
        setBusiness(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (business?.businessId) {
            onDeleteBusiness(business?.businessId, dispatch, () => {
                businessTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [business, businessTable]);

    const onActivateDeactivate = useCallback(
        (payload: ActivateDeactivateBusiness) => {
            onActivateDeactivateBusiness(payload, dispatch, businessTable.fetchApi);
        },
        [businessTable]
    );

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    useEffect(() => {
        dispatch(setPendingBusinessCount(res?.data || ZERO));
    }, [res]);


    const businessCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: Business, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "IMAGE",
            key: "businessImg",
            render: (record: Business) => {
                return (
                    <span className="w-[50px] inline-block">
                        <img
                            src={record?.businessImg ?? avatar}
                            alt="Business picture"
                            className="rounded-full object-fit-cover-center thumb-hw-50 mx-auto"
                        />
                    </span>
                );
            },
        },
        {
            title: "BUSINESS NAME",
            key: "businessName",
            sortable: true,
            minWidth: true,
            render: (record: Business) => {
                return <span>{record?.businessName}</span>;
            },
        },
        {
            title: "MEMBER NAME",
            key: "memberName",
            sortable: true,
            minWidth: true,
            render: (record: Business) => {
                return <span>{record?.member?.fullNameEn}</span>;
            },
        },
        {
            title: "CATEGORY",
            key: "category",
            sortable: true,
            render: (record: Business) => {
                return <span>{record?.category?.nameEn ?? "-"}</span>;
            },
        },
        {
            title: "CONTACT",
            key: "mobile",
            render: (record: Business) => {
                return <span>{record?.mobile}</span>;
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            render: (record: Business) => {
                const payload = {
                    businessId: record?.businessId,
                    isActive: !record?.isActive,
                };
                return (
                    <>
                        <Tooltip id="simpleTooltipActiveDeactive" place="top" />
                        <div
                            className="form-switch form-check flex justify-center flex-col"
                            data-tooltip-id="simpleTooltipActiveDeactive"
                            data-tooltip-content={record?.isActive ? "Active" : "Inactive"}
                        >
                            <input
                                id="isActive"
                                name="isActive"
                                onChange={() => onActivateDeactivate(payload)}
                                checked={record?.isActive}
                                className={"form-check-input"}
                                type="checkbox"
                            />
                        </div>
                    </>
                );
            },
        },
        {
            title: "VERIFICATION",
            key: "verified",
            sortable: false,
            render: (record: Business) => {
                return (
                    <span
                        className={`${record.verified === VerificationStatus.REJECTED && "text-danger"} ${
                            record.verified === VerificationStatus.PENDING && "text-warning"
                        } ${record.verified === VerificationStatus.APPROVED && "text-success"}`}
                    >
                        {record.verified}
                    </span>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: Business) => {
                return (
                    <>
                        <div className="flex justify-center items-center text-primary">
                            <Tooltip id="simpleTooltipEditDelete" place="top" />
                            <div
                                className="flex items-center mr-3 cursor-pointer text-success"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"View details"}
                                onClick={() => navigate(`/business/view/${record?.businessId}`)}
                            >
                                <Eye className="w-4 h-4 mr-1" />
                                View
                            </div>
                            <div
                                className="flex items-center mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Edit business"}
                                onClick={() => navigate(`/business/edit/${record?.businessId}`)}
                            >
                                <Edit className="w-4 h-4 mr-1" />
                                <span className="pt-[2px] leading-[16px]">Edit</span>
                            </div>
                            <div
                                className="flex items-center text-danger cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Delete business"}
                                onClick={() => onDeleteClick(record)}
                            >
                                <Trash2 className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Delete</span>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    const onMetaFilteredSubmit = (value: FamiliesMetaFilter) => {
        const metaFilter = {
            searchBy: value.searchBy,
            value: value.value,
        };

        businessTable.onSearch("", metaFilter);
    };

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this business?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={businessCols}
                    data={businessTable.tableData ?? []}
                    tableKey="businessId"
                    reload={businessTable.fetchApi}
                    currentOrder={businessTable.currentOrder}
                    error={businessTable.error}
                    loading={businessTable.loading}
                    tableOffset={businessTable.tableOffset}
                    onSetOrderBy={businessTable.onSetOrderBy}
                    className="xl:justify-between xl:items-center"
                    component={
                        <>
                            <Formik initialValues={initialValues} enableReinitialize onSubmit={onMetaFilteredSubmit}>
                                {(props) => <Filter {...props} reload={businessTable.fetchApi} />}
                            </Formik>
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto whitespace-nowrap"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new business"
                                    onClick={() => {
                                        navigate("/business/add");
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default BusinessDetails;
