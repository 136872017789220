export const dataEn = <const>{
    villageDetails: "Village details",
    writeVillageName: "Please enter village name",
    writeSubDistrictName: "Please enter sub district name",
    writeDistrictName: "Please enter district name",
    primaryMemberName: "Primary Member Name",
    address: "Address",
    gender: "Gender",
    writePrimaryMember: "Please enter primary member name",
    writeAddress: "Please enter address",
    memberName: "Member name",
    occupation: "Occupation",
    familyDetails: "Family Details",
    medium: "Medium",
    writeMedium: "Please enter medium",
    writeBoard: "Please enter education board",
    educationBoard: "Education board",
    writeEducationBoard: "Please enter education board",
    description: "Description",
    writeDescription: "Please enter description",
    donorDescription: "Donor details",
    categoryName: "Category name",
    prizeDescription: "Prize Details",
    writeCategoryName: "Please enter category name",
    advertisementTitle: "Title",
    advertisementDescription: "Description",
    writeAdvertisementTitle: "Please enter title",
    writeAdvertisementDescription: "Please enter description",
    advertisementTitlePlaceholder: "Enter title",
    advertisementDescriptionPlaceholder: "Enter description",
    businessDetails: "Business Details",
    eventTitle: "Event Details",
    eventName: "Event Name",
    location: "Location",
    writeEventName: "Please enter event name",
    writeEventLocation: "Please enter event location",
    occupationName: "Occupation Details",
    writeOccupationName: "Please enter occupation name",
    memberDetails: "Member Details",
    advertisementFormHeading: "Advertisement details",
    prizeRank: "Prize rank",
    writePrizeRank: "Please enter prize rank",
    marksheetUpload: "Marksheet details",
    sendNotification: "Please enter title",
    fieldRequired: "Please fill this field",
};
