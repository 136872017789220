import { useCallback, useState } from "react";
import { Form } from "formik";
import { useSelector } from "react-redux";
import moment from "moment";
import { Calendar } from "react-feather";
import { Listbox, Transition } from "@headlessui/react";

import { Family, FormParams, IncludeApiPayload, MemberPayload, Ocupation, TableStateValue, ZIndexMaintainer } from "../../helper/interface";
import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import { dataEn } from "../../helper/dataEn";
import { dataGu } from "../../helper/dataGu";
import { BLOOD, GENDER } from "../../helper/constant";
import CustomDatePicker from "../datepicker/CustomDatePicker";
import { onDatePickerClose, onDatePickerOpen, serializeQueryParams } from "../../helper/helper";
import { useParams } from "react-router-dom";
import Loading from "../../container/Loading";

const AddEditForm = ({
    errors,
    touched,
    handleChange,
    values,
    dirty,
    setFieldValue,
    setFieldTouched,
    handleBlur,
}: FormParams<MemberPayload>) => {
    const loading = useSelector((state: TableStateValue) => state.table.loading);

    const [zIndex, setZIndex] = useState<ZIndexMaintainer>({});

    const { memberId = "" } = useParams();

    const payloadForAPI: IncludeApiPayload = {
        fieldName: "primaryMemberName",
        order: "ASC",
    };

    const { res: family } = useFetch<Family[]>(`${api.family}?${serializeQueryParams(payloadForAPI)}`);
    const { res: occupation } = useFetch<Ocupation[]>(api.occupation);

    const onChangeGender = useCallback((value: GENDER) => {
        setFieldValue("gender", value);
    }, []);
    const onChangeBloodGroup = useCallback((value: string) => {
        setFieldValue("bloodGroup", value);
    }, []);
    const onChangefamily = useCallback((value: number) => {
        setFieldValue("familyId", value);
    }, []);
    const onChangeOccupation = useCallback((value: number) => {
        setFieldValue("occupationId", value);
    }, []);

    return (
        <>
            <Form>
                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                    <div className="intro-y col-span-12 lg:col-span-6">
                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="font-medium text-base mr-auto">{dataEn.memberDetails}</h2>
                            </div>
                            <div id="vertical-form1" className="p-5">
                                <div className="preview">
                                    {/* Family name */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Primary Member Name</label>
                                        <div className={`tom-select ${errors.familyId && touched.familyId && "dd-border-danger"}`}>
                                            <Listbox
                                                value={values.familyId}
                                                name="familyId"
                                                onChange={onChangefamily}
                                                disabled={values?.family?.primaryMemberId === +memberId}
                                            >
                                                <Listbox.Button
                                                    id="familyId"
                                                    className={`ts-input ${values?.family?.primaryMemberId === +memberId && "disabled"}`}
                                                >
                                                    <div className="item">
                                                        {family?.data?.map(
                                                            (v) => v?.familyId === +values?.familyId && v?.primaryMember?.fullNameEn
                                                        )}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("familyId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {family?.data?.map((fa: Family) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || fa.familyId === +values.familyId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={fa.familyId}
                                                                        key={fa.familyId}
                                                                    >
                                                                        {fa?.primaryMember?.fullNameEn}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.familyId && touched.familyId && <div className="text-danger">{errors.familyId}</div>}
                                    </div>

                                    {/* Member Name */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="fullNameEn" className="form-label">
                                            Family Member Name
                                        </label>
                                        <input
                                            id="fullNameEn"
                                            name="fullNameEn"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.fullNameEn}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.fullNameEn && touched.fullNameEn && "border-danger"}`}
                                        />
                                        {errors.fullNameEn && touched.fullNameEn && <div className="text-danger">{errors.fullNameEn}</div>}
                                    </div>

                                    {/* Occupation */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Occupation</label>
                                        <div className={`tom-select ${errors.occupationId && touched.occupationId && "dd-border-danger"}`}>
                                            <Listbox value={values.occupationId} name="occupationId" onChange={onChangeOccupation}>
                                                <Listbox.Button id="occupationId" className={"ts-input"}>
                                                    <div className="item">
                                                        {occupation?.data?.map(
                                                            (v: Ocupation) => v?.occupationId === values?.occupationId && v?.nameEn
                                                        )}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("occupationId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {occupation?.data?.map((occ: Ocupation) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active ? "optionColor" : null
                                                                            }`
                                                                        }
                                                                        value={occ.occupationId}
                                                                        key={occ.occupationId}
                                                                    >
                                                                        {occ?.nameEn}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.occupationId && touched.occupationId && (
                                            <div className="text-danger">{errors.occupationId}</div>
                                        )}
                                    </div>

                                    {/* Email */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="email" className="form-label">
                                            Email
                                        </label>
                                        <input
                                            id="email"
                                            name="email"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.email}
                                            onBlur={(e) => setFieldValue("email", e.target.value)}
                                            className={`form-control ${errors.email && touched.email && "border-danger"}`}
                                        />
                                        {errors.email && touched.email && <div className="text-danger">{errors.email}</div>}
                                    </div>
                                    {/* Mobile Number */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="mobile" className="form-label">
                                            Mobile Number
                                        </label>
                                        <input
                                            id="mobile"
                                            name="mobile"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.mobile}
                                            onBlur={(e) => setFieldValue("mobile", e.target.value)}
                                            className={`form-control ${errors.mobile && touched.mobile && "border-danger"}`}
                                        />
                                        {errors.mobile && touched.mobile && <div className="text-danger">{errors.mobile}</div>}
                                    </div>
                                    {/* Blood Group */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Blood Group</label>
                                        <div className={`tom-select ${errors.bloodGroup && touched.bloodGroup && "dd-border-danger"}`}>
                                            <Listbox value={values.bloodGroup} name="bloodGroup" onChange={onChangeBloodGroup}>
                                                <Listbox.Button id="bloodGroup" className={"ts-input"}>
                                                    <div className="item">{values?.bloodGroup || "Select a blood group"}</div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("bloodGroup", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            <Listbox.Option
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                        active || !values.bloodGroup ? "optionColor" : null
                                                                    }`
                                                                }
                                                                value={null}
                                                            >
                                                                Select Blood Group
                                                            </Listbox.Option>
                                                            {BLOOD.map((group: string) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || group === values.bloodGroup ? "optionColor" : null
                                                                            }`
                                                                        }
                                                                        value={group}
                                                                        key={group}
                                                                    >
                                                                        {group}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.bloodGroup && touched.bloodGroup && <div className="text-danger">{errors.bloodGroup}</div>}
                                    </div>
                                    {/* Gender */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Gender</label>
                                        <div className={`tom-select form-control ${errors.gender && touched.gender && "dd-border-danger"}`}>
                                            <Listbox value={values.gender} name="gender" onChange={onChangeGender}>
                                                <Listbox.Button id="gender" className={"ts-input"}>
                                                    <div className="item">{values?.gender || "Select a gender"}</div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("gender", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            <Listbox.Option
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                        active || values.gender === GENDER.MALE ? "optionColor" : null
                                                                    }`
                                                                }
                                                                value={GENDER.MALE}
                                                            >
                                                                {GENDER.MALE}
                                                            </Listbox.Option>
                                                            <Listbox.Option
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                        active || values.gender === GENDER.FEMALE ? "optionColor" : null
                                                                    }`
                                                                }
                                                                value={GENDER.FEMALE}
                                                            >
                                                                {GENDER.FEMALE}
                                                            </Listbox.Option>
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.gender && touched.gender && <div className="text-danger">{errors.gender}</div>}
                                    </div>
                                    {/* Date of Birth */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5" style={{ zIndex: zIndex.dob }}>
                                        <label htmlFor="dob" className="mb-2 form-label">
                                            Date of Birth
                                        </label>
                                        <div className="relative">
                                            <div className="absolute z-10 rounded-l w-10 h-full flex items-center justify-center bg-slate-100 border text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400">
                                                <Calendar />
                                            </div>
                                            <CustomDatePicker
                                                id="dob"
                                                name="dob"
                                                maxDate={new Date(moment().format("yyyy-MM-DD"))}
                                                selected={
                                                    values.dob ? new Date(moment(values.dob.toLocaleString()).format("yyyy-MM-DD")) : null
                                                }
                                                hasError={Boolean(errors.dob && touched.dob)}
                                                setFieldValue={setFieldValue}
                                                handleBlur={handleBlur}
                                                onCalendarOpen={onDatePickerOpen(setZIndex, "dob")}
                                                onCalendarClose={onDatePickerClose(setZIndex, "dob")}
                                            />
                                        </div>
                                        {errors.dob && touched.dob && <div className="text-danger">{errors.dob}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Gujarati Form */}
                    <div className="intro-y col-span-12 lg:col-span-6">
                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="font-bold text-base mr-auto">{dataGu.memberDetails}</h2>
                            </div>
                            <div id="vertical-form2" className="p-5">
                                <div className="preview">
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="fullNameGu" className="mb-2 form-label">
                                            {dataGu.memberName}
                                        </label>
                                        <input
                                            id="fullNameGu"
                                            name="fullNameGu"
                                            type="text"
                                            value={values.fullNameGu}
                                            onChange={(e) => setFieldValue("fullNameGu", e.target.value)}
                                            className={`form-control ${errors.fullNameGu && touched.fullNameGu && "border-danger"}`}
                                        />
                                        {errors.fullNameGu && touched.fullNameGu && <div className="text-danger">{errors.fullNameGu}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                        <button className="btn btn-secondary w-24" type="reset" disabled={loading || !dirty}>
                            Reset
                        </button>
                        <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                            {loading ? <Loading isButton /> : "Save"}
                        </button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default AddEditForm;
