import { AdminDirectoryPayload, Response } from "../helper/interface";
import { Dispatch } from "@reduxjs/toolkit";
import { setLoading } from "../store/slice/TableDataSlice";
import { dataService } from "../config/DataService";
import { api } from "../config/Api";
import { errorToast, successToast } from "../helper/toast";
import { handleCatchResponse } from "../helper/helper";
import { AxiosError } from "axios";

export const onAddAdminDirectory = async (
    payload: AdminDirectoryPayload,
    dispatch: Dispatch,
    callback: (res: Required<Response<AdminDirectoryPayload>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.post(api.adminDirectory, payload);
        const res = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onEditAdminDirectory = async (
    payload: AdminDirectoryPayload,
    dispatch: Dispatch,
    callback: (res: Required<Response<AdminDirectoryPayload>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.put(api.adminDirectory, payload);
        const res = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeleteAdminDirectory = async (adminDirectoryId: number, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.adminDirectory}/${adminDirectoryId}`);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onActivateDeactivateAdminDirectory = async (adminDirectoryId: number, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.patch(`${api.adminDirectory}/${adminDirectoryId}`);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
