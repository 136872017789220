import { Formik } from "formik";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";

import { api } from "../../config/Api";
import Loading from "../../container/Loading";
import { OccupationPayload } from "../../helper/interface";
import useFetch from "../../hooks/useFetch";
import usePathName from "../../hooks/usePathName";
import { occupationValidation } from "../../helper/validationSchema";
import Form from "./Form";
import { onEditOccupation } from "../../service/occupations";
import { editSuccessToast } from "../../helper/toast";

const EditOccupation: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { occupationId = "" } = useParams();

    const { res, loading, error } = useFetch<OccupationPayload>(`${api.occupation}/${occupationId}`);

    usePathName([
        { pathName: "Occupation", href: "/occupation" },
        { pathName: "Edit", href: `/occupation/edit/${occupationId}` },
    ]);

    const onSubmit = useCallback((value: OccupationPayload) => {
        const payload = {
            occupationId: +occupationId,
            nameEn: value.nameEn,
            nameGu: value.nameGu,
        };

        onEditOccupation(payload, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Edit",
                primaryLink: `/occupation/edit/${res?.data?.occupationId ?? payload.occupationId}`,
            };

            editSuccessToast(res.message, navigate, toastLink);
            navigate("/occupation");
        });
    }, []);

    const initialValues = res?.data;

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4" onClick={() => navigate("/occupation")}>
                    <ArrowLeftCircle className="cursor-pointer text-primary" />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Edit Occupation</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {error ? (
                    <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                        <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                    </div>
                ) : loading ? (
                    <Loading />
                ) : initialValues ? (
                    <Formik initialValues={initialValues} validationSchema={occupationValidation} onSubmit={onSubmit}>
                        {(props) => <Form {...props} />}
                    </Formik>
                ) : null}
            </div>
        </div>
    );
};

export default EditOccupation;
