import { useCallback } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import usePathName from "../../../hooks/usePathName";
import { api } from "../../../config/Api";
import useFetch from "../../../hooks/useFetch";
import Loading from "../../../container/Loading";
import { editSuccessToast } from "../../../helper/toast";
import { educationMediumValidation } from "../../../helper/validationSchema";
import { EducationMediumPayload } from "../../../helper/interface";
import { onEditEducationMedium } from "../../../service/educationMedium";
import AddEditForm from "./AddEditForm";

const EditEducationMedium = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { educationMediumId = "" } = useParams();

    const { res, loading, error } = useFetch<EducationMediumPayload>(`${api.marksheet.educationMedium}/${educationMediumId}`);

    usePathName([
        { pathName: "Education medium", href: "/marksheet/education-medium" },
        { pathName: "Edit", href: `/marksheet/education-medium/edit/${educationMediumId}` },
    ]);

    const onSubmit = useCallback((value: EducationMediumPayload) => {
        const payload = {
            educationMediumId: +educationMediumId,
            nameEn: value.nameEn,
            nameGu: value.nameGu,
        };
        onEditEducationMedium(payload, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Edit",
                primaryLink: `/marksheet/education-medium/edit/${res?.data?.educationMediumId ?? payload?.educationMediumId}`,
            };

            editSuccessToast(res.message, navigate, toastLink);
            navigate("/marksheet/education-medium");
        });
    }, []);

    const initialValues = res?.data;

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/marksheet/education-medium")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Edit education medium details</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400 mx-auto">
                    {error ? (
                        <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                            <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                        </div>
                    ) : loading ? (
                        <Loading />
                    ) : initialValues ? (
                        <Formik initialValues={initialValues} validationSchema={educationMediumValidation} onSubmit={onSubmit}>
                            {(props) => <AddEditForm {...props} />}
                        </Formik>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default EditEducationMedium;
