import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { dataService } from "../config/DataService";
import { handleErrorForFetch } from "../helper/helper";
import { Response } from "../helper/interface";
import { useDispatch } from "react-redux";

const useFetch = <DataInterface>(url: string, wait?: boolean) => {
    const [loading, setLoading] = useState(true);
    const [res, setRes] = useState<Response<DataInterface>>();
    const [error, setError] = useState<string | null>(null);
    const dispatch = useDispatch();

    const fetchApi = useCallback(
        (updatedUrl?: string) => {
            setLoading(true);
            dataService
                .get(updatedUrl ? updatedUrl : url)
                .then((response: AxiosResponse) => {
                    return response.data;
                })
                .then((json) => {
                    setLoading(false);
                    setRes(json);
                })
                .catch((error: AxiosError) => {
                    handleErrorForFetch(error, setError, dispatch);
                });
        },
        [url, wait]
    );

    useEffect(() => {
        fetchApi();
    }, []);

    return { loading, res, fetchApi, error };
};

export default useFetch;
