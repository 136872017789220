import { Formik } from "formik";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";

import { api } from "../../config/Api";
import Loading from "../../container/Loading";
import { CategoryPayload } from "../../helper/interface";
import useFetch from "../../hooks/useFetch";
import usePathName from "../../hooks/usePathName";
import { categoryValidation } from "../../helper/validationSchema";
import AddEditForm from "./AddEditForm";
import { onEditCategory } from "../../service/category";
import { editSuccessToast } from "../../helper/toast";

const EditCategory: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { categoryId = "" } = useParams();

    const { res, loading, error } = useFetch<CategoryPayload>(`${api.category}/${categoryId}`);

    usePathName([
        { pathName: "Category", href: "/category" },
        { pathName: "Edit", href: `/category/edit/${categoryId}` },
    ]);

    const onSubmit = useCallback((value: CategoryPayload) => {
        const payload = {
            categoryId: +categoryId,
            nameEn: value.nameEn,
            nameGu: value.nameGu,
        };

        onEditCategory(payload, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Edit",
                primaryLink: `/category/edit/${res?.data?.categoryId ?? payload.categoryId}`,
            };

            editSuccessToast(res.message, navigate, toastLink);
            navigate("/category");
        });
    }, []);

    const initialValues = res?.data;

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4" onClick={() => navigate("/category")}>
                    <ArrowLeftCircle className="cursor-pointer text-primary" />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Edit Category</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {error ? (
                    <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                        <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                    </div>
                ) : loading ? (
                    <Loading />
                ) : initialValues ? (
                    <Formik initialValues={initialValues} validationSchema={categoryValidation} onSubmit={onSubmit}>
                        {(props) => <AddEditForm {...props} />}
                    </Formik>
                ) : null}
            </div>
        </div>
    );
};

export default EditCategory;
