import React from "react";
import Modal from "./Modal";
import { useSelector } from "react-redux";
import Loading from "../container/Loading";
import { XCircle } from "react-feather";
import { DeleteModalParams, TableStateValue } from "../helper/interface";

const DeleteModal = ({ isOpen, handleCancel, onDelete, subTitle }: DeleteModalParams) => {
    const loading = useSelector((state: TableStateValue) => state.table.loading);

    return (
        <Modal isOpen={isOpen}>
            <>
                {loading ? (
                    <Loading forTable={false} />
                ) : (
                    <>
                        <div className="p-5 text-center">
                            <XCircle className="w-16 h-16 text-danger mx-auto mt-3" />
                            <div className="text-3xl mt-5">Are you sure?</div>
                            <div className="text-slate-500 mt-2">
                                {subTitle}
                                <br />
                                This process cannot be undone.
                            </div>
                        </div>
                        <div className="px-5 pb-8 text-center">
                            <button
                                type="button"
                                data-tw-dismiss="modal"
                                className="btn btn-outline-secondary w-24 mr-3"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button type="button" className="btn btn-danger w-24" onClick={() => onDelete()}>
                                Delete
                            </button>
                        </div>
                    </>
                )}
            </>
        </Modal>
    );
};

export default DeleteModal;
