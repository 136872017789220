import { AlertTriangle, ArrowLeftCircle, Calendar, Droplet, Edit, Home, Mail, Map, Phone, Trash2 } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import useFetch from "../../hooks/useFetch";
import { ActivateDeactivateMember, Alignment, Family, Member, Ocupation } from "../../helper/interface";
import { api } from "../../config/Api";
import Loading from "../../container/Loading";
import { getImageUrl } from "../../helper/helper";
import avatar from "./../../assets/images/userAvatar.png";
import { calcAge } from "../../config/MethodUtils";
import { dataEn } from "../../helper/dataEn";
import { Tooltip } from "react-tooltip";
import { useCallback, useState } from "react";
import { onActivateDeactivateMember, onDeleteMember } from "../../service/members";
import DeleteModal from "../../modal/DeleteModal";
import { useDispatch } from "react-redux";
import useTable from "../../hooks/useTable";
import CustomTable from "../table/CustomTable";
import SearchForm from "../table/SearchForm";

const FamilyDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { familyId = "" } = useParams();
    const memberTable = useTable<Member>("families", `${api.member}?familyId=${familyId}`, true, familyId);
    const { res: occupation } = useFetch<Ocupation[]>(api.occupation);

    const { res, loading, error } = useFetch<Family>(`${api.family}/${familyId}`);
    const [isOpen, setIsOpen] = useState(false);
    const [member, setMember] = useState<Member | null>(null);

    const onDeleteClick = useCallback((record: Member) => {
        setMember(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (member?.memberId) {
            onDeleteMember(member?.memberId, dispatch, () => {
                memberTable?.fetchApi();
                setIsOpen(false);
            });
        }
    }, [member, memberTable]);

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const onActivateDeactivate = useCallback(
        (payload: ActivateDeactivateMember) => {
            onActivateDeactivateMember(payload, dispatch, memberTable.fetchApi);
        },
        [memberTable]
    );

    const memberCols = [
        {
            title: "SR NO.",
            key: "srNumber",
            render: (record: Member, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "MEMBER NAME",
            key: "fullNameEn",
            render: (record: Member) => {
                return <span>{record.fullNameEn ? record.fullNameEn : "--"}</span>;
            },
        },
        {
            title: "GENDER",
            key: "gender",
            render: (record: Member) => {
                return <span>{record.gender ? record.gender : "--"}</span>;
            },
        },

        {
            title: "DATE OF BIRTH",
            key: "dob",
            render: (record: Member) => {
                return (
                    <span>
                        {moment(record?.dob).format("DD-MM-YYYY")} {record?.dob && `(${calcAge(record?.dob)})`}
                    </span>
                );
            },
        },
        {
            title: "BLOOD GROUP",
            key: "bloodGroup",
            render: (record: Member) => {
                return <span>{record?.bloodGroup}</span>;
            },
        },
        {
            title: "OCCUPATION",
            key: "occupation",
            render: (record: Member) => {
                return <span>{record?.occupation?.nameEn ? record?.occupation?.nameEn : "--"}</span>;
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            render: (record: Member) => {
                const payload = {
                    memberId: record.memberId,
                    isActive: !record.isActive,
                };

                const isPrimaryMember = record?.family?.primaryMemberId === record?.memberId;

                return (
                    <>
                        <Tooltip id="simpleTooltipActiveDeactive" place="top" />
                        <div
                            className="form-switch form-check flex justify-center flex-col"
                            data-tooltip-id="simpleTooltipActiveDeactive"
                            data-tooltip-content={record?.isActive ? "Active" : "Inactive"}
                        >
                            <input
                                id="isActive"
                                name="isActive"
                                onChange={() => onActivateDeactivate(payload)}
                                checked={record.isActive}
                                className={"form-check-input disabled:!bg-[#5a5a5a80] !border-[#5a5a5a80]"}
                                type="checkbox"
                                disabled={isPrimaryMember}
                            />
                        </div>
                    </>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: Member) => {
                const isPrimaryMember = record.memberId === res?.data?.primaryMemberId;
                return (
                    <>
                        <div className="flex justify-center items-center text-primary">
                            <Tooltip id="simpleTooltipViewEditDelete" place="top" />
                            <div
                                className="flex items-center mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipViewEditDelete"
                                data-tooltip-content={"Edit member"}
                                onClick={() =>
                                    navigate(`/families/${familyId}/members/edit/${record.memberId}`, {
                                        state: { familyId },
                                    })
                                }
                            >
                                <Edit className="w-4 h-4 mr-1" />
                                Edit
                            </div>
                            <button
                                className={`flex items-center text-danger ${isPrimaryMember ? "disabled" : "cursor-pointer"}`}
                                data-tooltip-id="simpleTooltipViewEditDelete"
                                data-tooltip-content={"Delete member"}
                                onClick={() => onDeleteClick(record)}
                                disabled={isPrimaryMember}
                            >
                                <Trash2 className="w-4 h-4 mr-1" /> Delete
                            </button>
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/families")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">{dataEn.familyDetails}</div>
                </div>
                {error ? (
                    <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                        <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                            <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                        </div>
                    </div>
                ) : loading ? (
                    <Loading />
                ) : (
                    <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                        <div className="intro-y box px-5 pt-5 my-5">
                            <div className="flex flex-col lg:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-5 -mx-5">
                                <div className="flex flex-1 px-5 items-center justify-center lg:justify-start">
                                    <div className="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative">
                                        <img
                                            alt="Profile picture"
                                            className="rounded-full img-bordered"
                                            src={res?.data?.profileImg ? getImageUrl(res?.data?.profileImg) : avatar}
                                        />
                                    </div>
                                    <div className="ml-5">
                                        <div className="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg capitalize">
                                            {res?.data?.primaryMember?.fullNameEn
                                                ? getImageUrl(res?.data?.primaryMember?.fullNameEn)
                                                : "Primary member name"}
                                        </div>
                                        <div className="text-slate-500 capitalize">
                                            {occupation?.data?.map(
                                                (oc) => oc.occupationId === res?.data?.primaryMember?.occupationId && oc.nameEn
                                            ) ?? "Occupation"}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 lg:mt-0 flex-1 px-5 border-l border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0">
                                    <div className="font-medium text-center lg:text-left lg:mt-3">Contact Details</div>
                                    <div className="flex flex-col justify-center items-start mt-4">
                                        {res?.data?.primaryMember?.email ? (
                                            <div className="overflow-hidden text-ellipsis sm:whitespace-normal flex items-center">
                                                <span className="w-[45px]">
                                                    <Mail className="mr-4" />
                                                </span>
                                                <span> {res?.data?.primaryMember?.email}</span>
                                            </div>
                                        ) : null}
                                        {res?.data?.mobile ? (
                                            <div className="overflow-hidden text-ellipsis sm:whitespace-normal flex items-center mt-3">
                                                <span className="w-[45px]">
                                                    <Phone className="mr-4" />
                                                </span>
                                                <span> {res?.data?.mobile}</span>
                                            </div>
                                        ) : null}
                                        {res?.data?.addressEn ? (
                                            <div className="overflow-hidden text-ellipsis sm:whitespace-normal flex items-center mt-3">
                                                <span className="w-[45px]">
                                                    <Map className="mr-4" />
                                                </span>
                                                <span className="w-[calc(100%-45px)]">{res?.data?.addressEn}</span>
                                            </div>
                                        ) : null}
                                        {res?.data?.village?.nameEn ? (
                                            <div className="overflow-hidden text-ellipsis sm:whitespace-normal flex items-center mt-3">
                                                <span className="w-[45px]">
                                                    <Home className="mr-4" />
                                                </span>
                                                <span> {res?.data?.village?.nameEn}</span>
                                            </div>
                                        ) : null}
                                        {res?.data?.primaryMember?.dob ? (
                                            <div className="overflow-hidden text-ellipsis sm:whitespace-normal flex items-center mt-3">
                                                <span className="w-[45px]">
                                                    <Calendar className="mr-4" />
                                                </span>
                                                <span>
                                                    {moment(res?.data?.primaryMember?.dob).format("DD/MM/YYYY")} (
                                                    {calcAge(res?.data?.primaryMember?.dob)})
                                                </span>
                                            </div>
                                        ) : null}
                                        {res?.data?.primaryMember?.bloodGroup ? (
                                            <div className="overflow-hidden text-ellipsis sm:whitespace-normal flex items-center mt-3">
                                                <span className="w-[45px]">
                                                    <Droplet className="mr-4" />
                                                </span>
                                                <span> "{res?.data?.primaryMember?.bloodGroup}" Blood group</span>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this member?"
            />

            <div className="intro-y box px-8 pt-2 pb-10 mt-5">
                <div className="grid grid-cols-12 gap-6 mt-5">
                    <CustomTable
                        cols={memberCols}
                        data={memberTable.tableData ?? []}
                        tableKey="memberId"
                        reload={memberTable.fetchApi}
                        currentOrder={memberTable.currentOrder}
                        error={memberTable.error}
                        loading={memberTable.loading}
                        tableOffset={memberTable.tableOffset}
                        onSetOrderBy={memberTable.onSetOrderBy}
                        component={
                            <>
                                <SearchForm
                                    searchValue={memberTable.search}
                                    resetSearch={memberTable.resetSearch}
                                    onSearch={memberTable.onSearch}
                                    searchPlaceHolder="Search by member name"
                                />
                                <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                    <Tooltip id="simpleTooltip" place="top" />
                                    <button
                                        className="btn btn-outline-primary w-1/2 sm:w-auto"
                                        data-tooltip-id="simpleTooltip"
                                        data-tooltip-content="Add new member"
                                        onClick={() => {
                                            navigate(`/families/${familyId}/members/add`, {
                                                state: {
                                                    familyId,
                                                },
                                            });
                                        }}
                                    >
                                        Add New
                                    </button>
                                </div>
                            </>
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default FamilyDetails;
