import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { api } from "../config/Api";
import { dataService } from "../config/DataService";
import { setLoading } from "../store/slice/TableDataSlice";
import { errorToast, successToast } from "../helper/toast";
import { handleCatchResponse } from "../helper/helper";
import { Prize, PrizePayload, Response } from "../helper/interface";

export const onAddPrize = async (payload: PrizePayload, dispatch: Dispatch, callback: (res: Required<Response<Prize>>) => void) => {
    try {
        dispatch(setLoading(true));
        const response = await dataService.post(api.prize, payload);
        const res: Required<Response<Prize>> = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onEditPrize = async (payload: PrizePayload, dispatch: Dispatch, callback: (res: Required<Response<PrizePayload>>) => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.put(api.prize, payload);
        const res: Required<Response<Prize>> = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeletePrize = async (prizeId: number, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.prize}/${prizeId}`);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
