import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { ActivateDeactivateSubAdmin, Alignment, SubAdminValue } from "../../helper/interface";
import { useDispatch } from "react-redux";
import usePathName from "../../hooks/usePathName";
import useTable from "../../hooks/useTable";
import { api } from "../../config/Api";
import { Tooltip } from "react-tooltip";
import { Edit, Trash2 } from "react-feather";
import { onActivateDeactivateSubAdmin, onDeleteSubAdmin } from "../../service/subAdmin";
import CustomTable from "../table/CustomTable";
import SearchForm from "../table/SearchForm";
import DeleteModal from "../../modal/DeleteModal";

const SubAdmin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [subAdmin, setSubAdmin] = useState<SubAdminValue | null>(null);

    const subAdminTable = useTable<SubAdminValue>("sub admin", api.admin.subAdmin);

    const onDeleteClick = useCallback((record: SubAdminValue) => {
        setSubAdmin(record);
        setIsOpen(true);
    }, []);

    const ageGroupCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: SubAdminValue, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "NAME",
            key: "name",
            sortable: true,
            render: (record: SubAdminValue) => {
                return <span>{record?.name}</span>;
            },
        },
        {
            title: "EMAIL",
            key: "email",
            sortable: true,
            render: (record: SubAdminValue) => {
                return <span>{record?.email}</span>;
            },
        },
        {
            title: "MOBILE",
            key: "mobile",
            render: (record: SubAdminValue) => {
                return <span>{record?.mobile}</span>;
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            render: (record: SubAdminValue) => {
                const payload = {
                    adminId: record?.adminId,
                    isActive: !record?.isActive,
                };
                return (
                    <>
                        <Tooltip id="simpleTooltipActiveDeactive" place="top" />
                        <div
                            className="form-switch form-check flex justify-center flex-col"
                            data-tooltip-id="simpleTooltipActiveDeactive"
                            data-tooltip-content={record?.isActive ? "Active" : "Inactive"}
                        >
                            <input
                                id="isActive"
                                name="isActive"
                                onChange={() => onActivateDeactivate(payload)}
                                checked={record?.isActive}
                                className={"form-check-input"}
                                type="checkbox"
                            />
                        </div>
                    </>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: SubAdminValue) => {
                return (
                    <>
                        <div className="flex justify-center items-center text-primary">
                            <Tooltip id="simpleTooltipEditDelete" place="top" />
                            <div
                                className="flex items-center mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Edit sub admin"}
                                onClick={() => navigate(`/sub-admin/edit/${record?.adminId}`)}
                            >
                                <Edit className="w-4 h-4 mr-1" />
                                <span className="pt-[2px] leading-[16px]">Edit</span>
                            </div>
                            <div
                                className="flex items-center text-danger cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Delete sub admin"}
                                onClick={() => onDeleteClick(record)}
                            >
                                <Trash2 className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Delete</span>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    const onDelete = useCallback(() => {
        if (subAdmin?.adminId) {
            onDeleteSubAdmin(subAdmin?.adminId, dispatch, () => {
                subAdminTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [subAdmin, subAdminTable]);

    const onActivateDeactivate = useCallback(
        (payload: ActivateDeactivateSubAdmin) => {
            onActivateDeactivateSubAdmin(payload, dispatch, subAdminTable.fetchApi);
        },
        [subAdminTable]
    );

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    usePathName([{ pathName: "Sub Admin", href: "/sub-admin" }]);

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this sub admin?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={ageGroupCols}
                    data={subAdminTable.tableData ?? []}
                    tableKey="adminId"
                    reload={subAdminTable.fetchApi}
                    currentOrder={subAdminTable.currentOrder}
                    error={subAdminTable.error}
                    loading={subAdminTable.loading}
                    tableOffset={subAdminTable.tableOffset}
                    onSetOrderBy={subAdminTable.onSetOrderBy}
                    component={
                        <>
                            <SearchForm
                                resetSearch={subAdminTable.resetSearch}
                                onSearch={subAdminTable.onSearch}
                                searchValue={subAdminTable.search}
                                searchPlaceHolder="Search by name, email or mobile"
                            />
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new sub admin"
                                    onClick={() => {
                                        navigate("/sub-admin/add");
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default SubAdmin;
