import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { AlertTriangle, HelpCircle } from "react-feather";

import { api } from "../../config/Api";
import useFetch from "../../hooks/useFetch";
import Loading from "../../container/Loading";
import usePathName from "../../hooks/usePathName";
import { maxGlobalSettings } from "../../helper/constant";
import { GlobalSettingsPayload, TableStateValue } from "../../helper/interface";
import { onEditGlobalSetting } from "../../service/globalSetting";
import { updateGlobalSettingValidation } from "../../helper/validationSchema";
import { dataGu } from "../../helper/dataGu";

const GlobalSetting = () => {
    const dispatch = useDispatch();

    const updateLoading = useSelector((state: TableStateValue) => state.table.loading);

    usePathName([{ pathName: "Global settings", href: "/global-settings" }]);

    const { res, loading, error, fetchApi } = useFetch<GlobalSettingsPayload>(`${api.globalSettings}`);

    const onSubmit = (value: GlobalSettingsPayload) => {
        const payload = {
            businessLimitForUser: value.businessLimitForUser,
            businessTagLimit: value.businessTagLimit,
            markSheetImageLimit: value.markSheetImageLimit,
            contactUsEmail: value.contactUsEmail,
            deactivationEmail: value.deactivationEmail,
            aboutEn: value.aboutEn,
            aboutGu: value.aboutGu,
        };

        onEditGlobalSetting(payload, dispatch, fetchApi);
    };

    const initialValues = res?.data;

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-1 font-medium text-center text-lg -ml-2">Global settings</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {error ? (
                    <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                        <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                    </div>
                ) : loading ? (
                    <Loading />
                ) : initialValues ? (
                    <Formik initialValues={initialValues} validationSchema={updateGlobalSettingValidation} onSubmit={onSubmit}>
                        {({ values, handleChange, handleBlur, errors, touched, dirty }) => (
                            <Form className="globalsetting-form">
                                <div className="flex flex-col sm:flex-row items-center p-5 px-0 border-b border-slate-200/60 dark:border-darkmode-400">
                                    <h2 className="font-medium text-base mr-auto">Limits</h2>
                                </div>
                                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <Tooltip id="simpleTooltipForBusinessLimitForUser" place="top" />
                                        <label htmlFor="businessLimitForUser" className="form-label">
                                            Business Limit
                                            <HelpCircle
                                                className="inline w-4 ml-1"
                                                data-tooltip-id="simpleTooltipForBusinessLimitForUser"
                                                data-tooltip-content="Maximum number of business a user can add to profile"
                                            />
                                        </label>
                                        <div className="input-group">
                                            <input
                                                id="businessLimitForUser"
                                                name="businessLimitForUser"
                                                type="number"
                                                min={0}
                                                max={maxGlobalSettings}
                                                onChange={handleChange}
                                                value={values.businessLimitForUser}
                                                onBlur={handleBlur}
                                                className={`form-control ${
                                                    errors.businessLimitForUser && touched.businessLimitForUser && "border-danger"
                                                }`}
                                                placeholder="5"
                                            />
                                            <div className="input-group-text">Number</div>
                                        </div>
                                        {errors.businessLimitForUser && touched.businessLimitForUser && (
                                            <div className="text-danger">{errors.businessLimitForUser}</div>
                                        )}
                                    </div>
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <Tooltip id="simpleTooltipForBusinessTagLimit" place="top" />
                                        <label htmlFor="businessTagLimit" className="form-label">
                                            Business Tag Limit
                                            <HelpCircle
                                                className="inline w-4 ml-1"
                                                data-tooltip-id="simpleTooltipForBusinessTagLimit"
                                                data-tooltip-content="Maximum number of tags a user can link to business"
                                            />
                                        </label>
                                        <div className="input-group">
                                            <input
                                                id="businessTagLimit"
                                                name="businessTagLimit"
                                                type="number"
                                                min={0}
                                                max={maxGlobalSettings}
                                                onChange={handleChange}
                                                value={values.businessTagLimit}
                                                onBlur={handleBlur}
                                                className={`form-control ${
                                                    errors.businessTagLimit && touched.businessTagLimit && "border-danger"
                                                }`}
                                                placeholder="5"
                                            />
                                            <div className="input-group-text">Number</div>
                                        </div>
                                        {errors.businessTagLimit && touched.businessTagLimit && (
                                            <div className="text-danger">{errors.businessTagLimit}</div>
                                        )}
                                    </div>
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <Tooltip id="simpleTooltipForMarkSheetImageLimit" place="top" />
                                        <label htmlFor="markSheetImageLimit" className="form-label">
                                            Marksheet Image Limit
                                            <HelpCircle
                                                className="inline w-4 ml-1"
                                                data-tooltip-id="simpleTooltipForMarkSheetImageLimit"
                                                data-tooltip-content="Maximum number of items(image/pdf) a user can upload for the marksheet"
                                            />
                                        </label>
                                        <div className="input-group">
                                            <input
                                                id="markSheetImageLimit"
                                                name="markSheetImageLimit"
                                                type="number"
                                                min={0}
                                                max={maxGlobalSettings}
                                                onChange={handleChange}
                                                value={values.markSheetImageLimit}
                                                onBlur={handleBlur}
                                                className={`form-control ${
                                                    errors.markSheetImageLimit && touched.markSheetImageLimit && "border-danger"
                                                }`}
                                                placeholder="5"
                                            />
                                            <div className="input-group-text">Number</div>
                                        </div>
                                        {errors.markSheetImageLimit && touched.markSheetImageLimit && (
                                            <div className="text-danger">{errors.markSheetImageLimit}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row items-center p-5 px-0 border-b border-slate-200/60 dark:border-darkmode-400 mt-5">
                                    <h2 className="font-medium text-base mr-auto">Emails</h2>
                                </div>
                                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <Tooltip id="simpleTooltipForContactEmail" place="top" />
                                        <label htmlFor="contactUsEmail" className="form-label">
                                            Contact Us Email
                                            <HelpCircle
                                                className="inline w-4 ml-1"
                                                data-tooltip-id="simpleTooltipForContactEmail"
                                                data-tooltip-content="The email on which the app queries will be received"
                                            />
                                        </label>
                                        <div className="input-group">
                                            <input
                                                id="contactUsEmail"
                                                name="contactUsEmail"
                                                type="text"
                                                onChange={handleChange}
                                                value={values.contactUsEmail}
                                                onBlur={handleBlur}
                                                className={`form-control ${
                                                    errors.contactUsEmail && touched.contactUsEmail && "border-danger"
                                                }`}
                                            />
                                            <div className="input-group-text">Email</div>
                                        </div>
                                        {errors.contactUsEmail && touched.contactUsEmail && (
                                            <div className="text-danger">{errors.contactUsEmail}</div>
                                        )}
                                    </div>
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <Tooltip id="simpleTooltipForDeactivationEmail" place="top" />
                                        <label htmlFor="deactivationEmail" className="form-label">
                                            Deactivation Email
                                            <HelpCircle
                                                className="inline w-4 ml-1"
                                                data-tooltip-id="simpleTooltipForDeactivationEmail"
                                                data-tooltip-content="The email on which the user profile deactivation alerts will be sent"
                                            />
                                        </label>
                                        <div className="input-group">
                                            <input
                                                id="deactivationEmail"
                                                name="deactivationEmail"
                                                type="text"
                                                onChange={handleChange}
                                                value={values.deactivationEmail}
                                                onBlur={handleBlur}
                                                className={`form-control ${
                                                    errors.deactivationEmail && touched.deactivationEmail && "border-danger"
                                                }`}
                                            />
                                            <div className="input-group-text">Email</div>
                                        </div>
                                        {errors.deactivationEmail && touched.deactivationEmail && (
                                            <div className="text-danger">{errors.deactivationEmail}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row items-center p-5 px-0 border-b border-slate-200/60 dark:border-darkmode-400 mt-5">
                                    <h2 className="font-medium text-base mr-auto">About Us</h2>
                                </div>
                                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5 sm:grid-cols-6">
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <Tooltip id="simpleTooltipForEnglishUser" place="top" />
                                        <label htmlFor="aboutEn" className="form-label">
                                            About us (English)
                                            <HelpCircle
                                                className="inline w-4 ml-1"
                                                data-tooltip-id="simpleTooltipForEnglishUser"
                                                data-tooltip-content="Description of 'About Us' page"
                                            />
                                        </label>
                                        <div className="input-group">
                                            <textarea
                                                id="aboutEn"
                                                name="aboutEn"
                                                onChange={handleChange}
                                                value={values.aboutEn}
                                                onBlur={handleBlur}
                                                className={`form-control ${errors.aboutEn && touched.aboutEn && "border-danger"}`}
                                            />
                                        </div>
                                        {errors.aboutEn && touched.aboutEn && <div className="text-danger">{errors.aboutEn}</div>}
                                    </div>
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <Tooltip id="simpleTooltipForGujaratiUser" place="top" />
                                        <label htmlFor="aboutGu" className="form-label">
                                            About us ({dataGu.gujarati})
                                            <HelpCircle
                                                className="inline w-4 ml-1"
                                                data-tooltip-id="simpleTooltipForGujaratiUser"
                                                data-tooltip-content={dataGu.aboutUsTooltip}
                                            />
                                        </label>
                                        <div className="input-group">
                                            <textarea
                                                id="aboutGu"
                                                name="aboutGu"
                                                onChange={handleChange}
                                                value={values.aboutGu}
                                                onBlur={handleBlur}
                                                className={`form-control ${errors.aboutGu && touched.aboutGu && "border-danger"}`}
                                            />
                                        </div>
                                        {errors.aboutGu && touched.aboutGu && <div className="text-danger">{errors.aboutGu}</div>}
                                    </div>
                                </div>
                                <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                                    <button className="btn btn-secondary w-24" type="reset" disabled={updateLoading || !dirty}>
                                        Reset
                                    </button>
                                    <button className="btn btn-primary w-24 ml-2" type="submit" disabled={updateLoading || !dirty}>
                                        {updateLoading ? <Loading isButton /> : "Save"}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : null}
            </div>
        </div>
    );
};

export default GlobalSetting;
