import { Form } from "formik";
import { useSelector } from "react-redux";
import { FormParams, TableStateValue, VillagePayload } from "../../helper/interface";
import { dataGu } from "../../helper/dataGu";
import { dataEn } from "../../helper/dataEn";
import Loading from "../../container/Loading";

const AddEditForm = ({ errors, touched, handleChange, handleBlur, values, dirty, setFieldValue }: FormParams<VillagePayload>) => {
    const loading = useSelector((state: TableStateValue) => state.table.loading);

    return (
        <>
            <Form>
                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                    <div className="intro-y col-span-12 lg:col-span-6">
                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="font-medium text-base mr-auto">{dataEn.villageDetails}</h2>
                            </div>
                            <div id="vertical-form" className="p-5">
                                <div className="preview">
                                    {/* Village name  */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="nameEn" className="form-label">
                                            Village Name
                                        </label>
                                        <input
                                            id="nameEn"
                                            name="nameEn"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.nameEn}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.nameEn && touched.nameEn && "border-danger"}`}
                                            placeholder={dataEn.writeVillageName}
                                        />
                                        {errors.nameEn && touched.nameEn && <div className="text-danger">{errors.nameEn}</div>}
                                    </div>
                                    {/* Sub district name  */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="subDistrictEn" className="form-label">
                                            Sub District Name
                                        </label>
                                        <input
                                            id="subDistrictEn"
                                            name="subDistrictEn"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.subDistrictEn}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.subDistrictEn && touched.subDistrictEn && "border-danger"}`}
                                            placeholder={dataEn.writeSubDistrictName}
                                        />
                                        {errors.subDistrictEn && touched.subDistrictEn && (
                                            <div className="text-danger">{errors.subDistrictEn}</div>
                                        )}
                                    </div>
                                    {/* District name  */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="districtEn" className="form-label">
                                            District Name
                                        </label>
                                        <input
                                            id="districtEn"
                                            name="districtEn"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.districtEn}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.districtEn && touched.districtEn && "border-danger"}`}
                                            placeholder={dataEn.writeDistrictName}
                                        />
                                        {errors.districtEn && touched.districtEn && <div className="text-danger">{errors.districtEn}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y col-span-12 lg:col-span-6">
                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="font-bold text-base mr-auto">{dataGu.villageDetails}</h2>
                            </div>
                            <div id="vertical-form" className="p-5">
                                <div className="preview">
                                    {/* Village name gu  */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="nameGu" className="form-label">
                                            {dataGu.villageName}
                                        </label>
                                        <input
                                            id="nameGu"
                                            name="nameGu"
                                            type="text"
                                            value={values.nameGu}
                                            onChange={(e) => setFieldValue("nameGu", e.target.value)}
                                            className={`form-control ${errors.nameGu && touched.nameGu && "border-danger"}`}
                                            placeholder={dataGu.writeVillageName}
                                        />
                                        {errors.nameGu && touched.nameGu && <div className="text-danger">{errors.nameGu}</div>}
                                    </div>
                                    {/* Sub district name gu */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="subDistrictGu" className="form-label">
                                            {dataGu.subDistrictName}
                                        </label>
                                        <input
                                            id="subDistrictGu"
                                            name="subDistrictGu"
                                            type="text"
                                            value={values.subDistrictGu}
                                            onChange={(e) => setFieldValue("subDistrictGu", e.target.value)}
                                            className={`form-control ${errors.subDistrictGu && touched.subDistrictGu && "border-danger"}`}
                                            placeholder={dataGu.writeSubDistrictName}
                                        />
                                        {errors.subDistrictGu && touched.subDistrictGu && (
                                            <div className="text-danger">{errors.subDistrictGu}</div>
                                        )}
                                    </div>
                                    {/* District name gu */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="districtGu" className="form-label">
                                            {dataGu.districtName}
                                        </label>
                                        <input
                                            id="districtGu"
                                            name="districtGu"
                                            type="text"
                                            value={values.districtGu}
                                            onChange={(e) => setFieldValue("districtGu", e.target.value)}
                                            className={`form-control ${errors.districtGu && touched.districtGu && "border-danger"}`}
                                            placeholder={dataGu.writeDistrictName}
                                        />
                                        {errors.districtGu && touched.districtGu && <div className="text-danger">{errors.districtGu}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                        <button className="btn btn-secondary w-24" type="reset" disabled={loading || !dirty}>
                            Reset
                        </button>
                        <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                            {loading ? <Loading isButton /> : "Save"}
                        </button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default AddEditForm;
