import { Formik, FormikHelpers } from "formik";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import usePathName from "../../hooks/usePathName";
import { useNavigate } from "react-router-dom";
import { SubAdminPayload } from "../../helper/interface";
import AddEditSubAdmin from "./AddEditSubAdmin";
import { ArrowLeftCircle } from "react-feather";
import { onAddSubAdmin } from "../../service/subAdmin";
import { addEditSubAdminValidation } from "../../helper/validationSchema";
import { addSuccessToast } from "../../helper/toast";

const initialValues: SubAdminPayload = {
    name: "",
    email: "",
    mobile: "",
};

const AddSubAdmin: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    usePathName([
        { pathName: "Sub admin", href: "/sub-admin" },
        { pathName: "Add", href: "/sub-admin/add " },
    ]);

    const onSubmit = useCallback((values: SubAdminPayload, { resetForm }: FormikHelpers<SubAdminPayload>) => {
        onAddSubAdmin(values, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Add new",
                primaryLink: "/sub-admin/add",
                secondaryLinkTitle: "Edit",
                secondaryLink: `/sub-admin/edit/${res.data.adminId}`,
            };

            addSuccessToast(res.message, navigate, toastLink);
            resetForm();
            navigate("/sub-admin");
        });
    }, []);

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4" onClick={() => navigate("/sub-admin")}>
                    <ArrowLeftCircle className="cursor-pointer text-primary" />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Add new sub admin</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                <Formik initialValues={initialValues} validationSchema={addEditSubAdminValidation} onSubmit={onSubmit}>
                    {(props) => <AddEditSubAdmin {...props} />}
                </Formik>
            </div>
        </div>
    );
};

export default AddSubAdmin;
