import { useCallback } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import usePathName from "../../../hooks/usePathName";
import AddEditForm from "./AddEditForm";
import { api } from "../../../config/Api";
import useFetch from "../../../hooks/useFetch";
import Loading from "../../../container/Loading";
import { editSuccessToast } from "../../../helper/toast";
import { educationYearValidation } from "../../../helper/validationSchema";
import { EducationYearPayload } from "../../../helper/interface";
import { onEditEducationYear } from "../../../service/educationYear";

const EditEducationYear = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { educationYearId = "" } = useParams();

    const { res, loading, error } = useFetch<EducationYearPayload>(`${api.marksheet.educationYear}/${educationYearId}`);

    usePathName([
        { pathName: "Education year", href: "/marksheet/education-year" },
        { pathName: "Edit", href: `/marksheet/education-year/edit/${educationYearId}` },
    ]);

    const onSubmit = useCallback((value: EducationYearPayload) => {
        const payload = {
            educationYearId: +educationYearId,
            year: value.year,
            isMarkSheetUploadable: value.isMarkSheetUploadable,
            isPrizeListVisible: value.isPrizeListVisible,
        };
        onEditEducationYear(payload, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Edit",
                primaryLink: `/marksheet/education-year/edit/${res?.data?.educationYearId ?? payload?.educationYearId}`,
            };

            editSuccessToast(res.message, navigate, toastLink);
            navigate("/marksheet/education-year");
        });
    }, []);

    const initialValues = res?.data;

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/marksheet/education-year")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Edit education year details</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400 mx-auto">
                    {error ? (
                        <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                            <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                        </div>
                    ) : loading ? (
                        <Loading />
                    ) : initialValues ? (
                        <Formik initialValues={initialValues} validationSchema={educationYearValidation} onSubmit={onSubmit}>
                            {(props) => <AddEditForm {...props} />}
                        </Formik>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default EditEducationYear;
