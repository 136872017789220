import { useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import { ArrowLeftCircle } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import usePathName from "../../hooks/usePathName";
import AddEditForm from "./AddEditForm";
import { onAddCategory } from "../../service/category";
import { addSuccessToast } from "../../helper/toast";
import { CategoryPayload } from "../../helper/interface";
import { categoryValidation } from "../../helper/validationSchema";

const initialValues = { nameEn: "", nameGu: "" };

const AddCategory = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    usePathName([
        { pathName: "Category", href: "/category" },
        { pathName: "Add", href: "/category/add" },
    ]);

    const onSubmit = useCallback((values: CategoryPayload, { resetForm }: FormikHelpers<CategoryPayload>) => {
        onAddCategory(values, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Add new",
                primaryLink: "/category/add",
                secondaryLinkTitle: "Edit",
                secondaryLink: `/category/edit/${res.data.categoryId}`,
            };

            addSuccessToast(res.message, navigate, toastLink);
            resetForm();
            navigate("/category");
        });
    }, []);

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/category")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Add new category</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    <Formik initialValues={initialValues} validationSchema={categoryValidation} onSubmit={onSubmit}>
                        {(props) => <AddEditForm {...props} />}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AddCategory;
