import { FC, useCallback, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    BarChart2,
    Home,
    Users,
    Box,
    Cast,
    Globe,
    UserPlus,
    User,
    Layout,
    Archive,
    Briefcase,
    AlignJustify,
    Gift,
    Pocket,
    Bell,
    BookOpen,
} from "react-feather";
import Logo from "../../assets/images/Logo.png";
import { useSelector } from "react-redux";
import { StateValue } from "../../helper/interface";
import { ADMIN_ROLE } from "../../helper/constant";

const MobileMenu: FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const role = useSelector((state: StateValue) => state.base.role);

    const { pathname } = useLocation();

    const setMenuHidden = useCallback(() => {
        setIsMenuOpen(false);
    }, []);

    return (
        <>
            <div className="mobile-menu md:hidden">
                <div className="mobile-menu-bar">
                    <div className="menu__icon flex mr-auto">
                        <img alt="Gondaliya parivar" className="h-8" src={Logo} />
                    </div>
                    <div id="mobile-menu-toggler" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <BarChart2 className="w-8 h-8 text-white transform -rotate-90" />
                    </div>
                </div>
                <ul className={`border-t border-white/[0.08] py-5  ${isMenuOpen ? "" : "hidden"}`}>
                    <li>
                        <Link
                            to="villages"
                            className={`menu ${pathname.includes("villages") && "side-menu--active"} `}
                            onClick={setMenuHidden}
                        >
                            <div className="menu__icon">
                                <Home />
                            </div>
                            <div className="menu__title">Villages</div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="families"
                            className={`menu ${pathname.includes("families") && "side-menu--active"} `}
                            onClick={setMenuHidden}
                        >
                            <div className="menu__icon">
                                <Users />
                            </div>
                            <div className="menu__title">Families</div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="members"
                            className={`menu ${pathname.includes("members") && "side-menu--active"} `}
                            onClick={setMenuHidden}
                        >
                            <div className="menu__icon">
                                <User />
                            </div>
                            <div className="menu__title">Members</div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="category"
                            className={`menu ${pathname.includes("category") && "side-menu--active"} `}
                            onClick={setMenuHidden}
                        >
                            <div className="menu__icon">
                                <AlignJustify />
                            </div>
                            <div className="menu__title">Categories</div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="business"
                            className={`menu ${pathname.includes("business") && "side-menu--active"} `}
                            onClick={setMenuHidden}
                        >
                            <div className="menu__icon">
                                <Pocket />
                            </div>
                            <div className="menu__title">Business</div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="marksheet/education-year"
                            className={`menu ${pathname.includes("marksheet") && "side-menu--active"} `}
                            onClick={setMenuHidden}
                        >
                            <div className="menu__icon">
                                <Layout />
                            </div>
                            <div className="menu__title">Education</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="prize" className={`menu ${pathname.includes("prize") && "side-menu--active"} `} onClick={setMenuHidden}>
                            <div className="menu__icon">
                                <Gift />
                            </div>
                            <div className="menu__title">Prize</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="donors" className={`menu ${pathname.includes("donors") && "side-menu--active"} `} onClick={setMenuHidden}>
                            <div className="menu__icon">
                                <Archive />
                            </div>
                            <div className="menu__title">Donors</div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="advertisement"
                            className={`menu ${pathname.includes("advertisement") && "side-menu--active"} `}
                            onClick={setMenuHidden}
                        >
                            <div className="menu__icon">
                                <Cast />
                            </div>
                            <div className="menu__title">Advertisement</div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="contribution-history"
                            className={`menu ${pathname.includes("contribution-history") && "side-menu--active"} `}
                            onClick={setMenuHidden}
                        >
                            <div className="menu__icon">
                                <Box />
                            </div>
                            <div className="menu__title">Contribution History</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="events" className={`menu ${pathname.includes("events") && "side-menu--active"} `} onClick={setMenuHidden}>
                            <div className="menu__icon">
                                <Box />
                            </div>
                            <div className="menu__title">Events</div>
                        </Link>
                    </li>
                    {role && role === ADMIN_ROLE.superAdmin ? (
                        <>
                            <li>
                                <Link
                                    to="occupation"
                                    className={`menu ${pathname.includes("occupation") && "side-menu--active"} `}
                                    onClick={setMenuHidden}
                                >
                                    <div className="menu__icon">
                                        <Briefcase />
                                    </div>
                                    <div className="menu__title">Occupation</div>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="annual-contribution"
                                    className={`menu  ${pathname.includes("annual-contribution") && "side-menu--active"} `}
                                    onClick={setMenuHidden}
                                >
                                    <div className="menu__icon text-3xl font-light w-[30px] text-center">&#8377;</div>
                                    <div className="menu__title">Annual Contribution</div>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="ranks"
                                    className={`menu ${pathname.includes("ranks") && "side-menu--active"} `}
                                    onClick={setMenuHidden}
                                >
                                    <div className="menu__icon">
                                        <BarChart2 />
                                    </div>
                                    <div className="menu__title">Prize Rank</div>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="sub-admin"
                                    className={`menu ${pathname.includes("sub-admin") && "side-menu--active"} `}
                                    onClick={setMenuHidden}
                                >
                                    <div className="menu__icon">
                                        <UserPlus />
                                    </div>
                                    <div className="menu__title">Sub Admins</div>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="send-notification"
                                    className={`menu ${pathname.includes("send-notification") && "side-menu--active"} `}
                                    onClick={setMenuHidden}
                                >
                                    <div className="menu__icon">
                                        <Bell />
                                    </div>
                                    <div className="menu__title">Send Notification</div>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="admin-directory"
                                    className={`menu  ${pathname.includes("admin-directory") && "side-menu--active"} `}
                                    onClick={setMenuHidden}
                                >
                                    <div className="menu__icon">
                                        <BookOpen />
                                    </div>
                                    <div className="menu__title">Admin Directory</div>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="global-settings"
                                    className={`menu ${pathname.includes("global-settings") && "side-menu--active"} `}
                                    onClick={setMenuHidden}
                                >
                                    <div className="menu__icon">
                                        <Globe />
                                    </div>
                                    <div className="menu__title">Global Settings</div>
                                </Link>
                            </li>
                        </>
                    ) : null}
                </ul>
            </div>
        </>
    );
};

export default MobileMenu;
