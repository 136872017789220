import { Formik, FormikHelpers } from "formik";
import { ArrowLeftCircle } from "react-feather";
import { useNavigate } from "react-router-dom";
import { addSuccessToast } from "../../../helper/toast";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import usePathName from "../../../hooks/usePathName";
import { EducationStandardPayload } from "../../../helper/interface";
import { onAddEducationStandard } from "../../../service/educationStandard";
import { educationStandardValidation } from "../../../helper/validationSchema";
import AddEditForm from "./AddEditForm";

const initialValues: EducationStandardPayload = {
    name: "",
};

const AddEducationStandard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    usePathName([
        { pathName: "Education standard", href: "/marksheet/education-standard" },
        { pathName: "Add", href: "/marksheet/education-standard/add " },
    ]);

    const onSubmit = useCallback((values: EducationStandardPayload, { resetForm }: FormikHelpers<EducationStandardPayload>) => {
        onAddEducationStandard(values, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Add new",
                primaryLink: "/marksheet/education-standard/add",
                secondaryLinkTitle: "Edit",
                secondaryLink: `/marksheet/education-standard/edit/${res.data.educationStandardId}`,
            };

            addSuccessToast(res.message, navigate, toastLink);
            resetForm();
            navigate("/marksheet/education-standard");
        });
    }, []);

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/marksheet/education-standard")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Add new education standard</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    <Formik initialValues={initialValues} validationSchema={educationStandardValidation} onSubmit={onSubmit}>
                        {(props) => <AddEditForm {...props} />}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AddEducationStandard;
