import { Formik } from "formik";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../config/Api";
import Loading from "../../container/Loading";
import useFetch from "../../hooks/useFetch";
import usePathName from "../../hooks/usePathName";
import Form from "./Form";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import { ContributionHistoryPayload } from "../../helper/interface";
import { addEditContributionHistoryValidation } from "../../helper/validationSchema";
import { editSuccessToast } from "../../helper/toast";
import { onEditContributionHistory } from "../../service/contributionHistory";

const EditContributionHistory: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { contributionHistoryId = "" } = useParams();

    const { res, loading, error } = useFetch<ContributionHistoryPayload>(`${api.contributionHistory}/${contributionHistoryId}`);

    usePathName([
        { pathName: "Contribution history", href: "/contribution-history" },
        { pathName: "Edit", href: `/contribution-history/edit/${contributionHistoryId}` },
        { pathName: `${contributionHistoryId}`, href: `/contribution-history/edit/${contributionHistoryId}` },
    ]);

    const onSubmit = useCallback(
        (values: ContributionHistoryPayload) => {
            const payload = {
                contributionHistoryId: +contributionHistoryId,
                annualContributionId: values?.annualContribution?.annualContributionId,
                familyId: values.familyId,
                paidDate: values.paidDate,
                paidAmount: values.paidAmount,
            };

            onEditContributionHistory(payload, dispatch, (res) => {
                const toastLink = {
                    primaryLinkTitle: "Edit",
                    primaryLink: `/contribution-history/edit/${res?.data?.contributionHistoryId}`,
                };

                editSuccessToast(res.message, navigate, toastLink);
                navigate("/contribution-history");
            });
        },
        [contributionHistoryId]
    );

    const initialValues = res?.data;

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4" onClick={() => navigate("/contribution-history")}>
                    <ArrowLeftCircle className="cursor-pointer text-primary" />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Edit contribution history</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {error ? (
                    <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                        <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                    </div>
                ) : loading ? (
                    <Loading />
                ) : initialValues ? (
                    <Formik initialValues={initialValues} validationSchema={addEditContributionHistoryValidation} onSubmit={onSubmit}>
                        {(props) => <Form {...props} />}
                    </Formik>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default EditContributionHistory;
