import { useRef, RefObject } from "react";
import { Form } from "formik";
import { useSelector } from "react-redux";
import { Camera } from "react-feather";

import { FormParams, AdvertisementPayload, TableStateValue } from "../../helper/interface";
import { dataGu } from "../../helper/dataGu";
import { dataEn } from "../../helper/dataEn";
import { getImageUrl } from "../../helper/helper";
import productPlaceholder from "./../../assets/images/productPlaceholder.png";
import Loading from "../../container/Loading";

const AddEditForm = ({ errors, touched, handleChange, handleBlur, values, dirty, setFieldValue }: FormParams<AdvertisementPayload>) => {
    const loading = useSelector((state: TableStateValue) => state.table.loading);
    const imageRef = useRef() as RefObject<HTMLInputElement>;

    return (
        <>
            <Form>
                <div className="">
                    <div className="preview mb-5 col-span-12">
                        <div>
                            <div className="w-[300px] h-[300px] flex-none image-fit relative mx-auto">
                                <img
                                    alt="Profile picture"
                                    className="img-bordered"
                                    src={values?.image ? getImageUrl(values?.image) : productPlaceholder}
                                />
                                <div
                                    className="absolute mb-1 mr-1 flex items-center justify-center -bottom-4 -right-4 bg-primary p-2  border-2 border-solid border-white cursor-pointer"
                                    onClick={() => imageRef?.current?.click()}
                                >
                                    <Camera className="lucide lucide-camera w-4 h-4 text-white" />
                                    <input
                                        ref={imageRef}
                                        name="image"
                                        type="file"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            if (e?.target?.files?.[0]) {
                                                setFieldValue("image", e?.target?.files?.[0]);
                                            }
                                        }}
                                        accept="image/*"
                                        className="hidden"
                                        disabled={loading}
                                    />
                                </div>
                            </div>
                            {errors.image && touched.image && <div className="text-danger">{errors.image}</div>}
                        </div>
                        <div className="mt-4 text-center">
                            <div className="truncate sm:whitespace-normal font-medium text-lg text-center capitalize">
                                {values.titleEn ? values.titleEn : "Advertisement title"}
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-4 gap-y-5 mt-12">
                        <div className="intro-y col-span-12 lg:col-span-6">
                            <div className="intro-y box">
                                <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                    <h2 className="font-medium text-base mr-auto">{dataEn.advertisementFormHeading}</h2>
                                </div>
                                <div id="vertical-form" className="p-5">
                                    <div className="preview">
                                        <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                            <label htmlFor="titleEn" className="form-label">
                                                {dataEn.advertisementTitle}
                                            </label>
                                            <input
                                                id="titleEn"
                                                name="titleEn"
                                                type="text"
                                                onChange={handleChange}
                                                value={values.titleEn}
                                                onBlur={handleBlur}
                                                className={`form-control ${errors.titleEn && touched.titleEn && "border-danger"}`}
                                                disabled={loading}
                                            />
                                            {errors.titleEn && touched.titleEn && <div className="text-danger">{errors.titleEn}</div>}
                                        </div>
                                        <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                            <label htmlFor="descriptionEn" className="form-label">
                                                {dataEn.advertisementDescription}
                                            </label>
                                            <textarea
                                                id="descriptionEn"
                                                name="descriptionEn"
                                                onChange={handleChange}
                                                value={values.descriptionEn}
                                                onBlur={handleBlur}
                                                className={`form-control ${
                                                    errors.descriptionEn && touched.descriptionEn && "border-danger"
                                                }`}
                                                disabled={loading}
                                            />
                                            {errors.descriptionEn && touched.descriptionEn && (
                                                <div className="text-danger">{errors.descriptionEn}</div>
                                            )}
                                        </div>
                                        <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                            <label htmlFor="email" className="form-label">
                                                Email
                                            </label>
                                            <input
                                                id="email"
                                                name="email"
                                                type="text"
                                                onChange={handleChange}
                                                value={values.email}
                                                className={`form-control ${errors.email && touched.email && "border-danger"}`}
                                                disabled={loading}
                                            />
                                            {errors.email && touched.email && <div className="text-danger">{errors.email}</div>}
                                        </div>
                                        <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                            <label htmlFor="mobile" className="form-label">
                                                Mobile
                                            </label>
                                            <input
                                                id="mobile"
                                                name="mobile"
                                                type="text"
                                                onChange={handleChange}
                                                value={values.mobile}
                                                className={`form-control ${errors.mobile && touched.mobile && "border-danger"}`}
                                                disabled={loading}
                                            />
                                            {errors.mobile && touched.mobile && <div className="text-danger">{errors.mobile}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="intro-y col-span-12 lg:col-span-6">
                            <div className="intro-y box">
                                <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                    <h2 className="font-bold text-base mr-auto">{dataGu.advertisementFormHeading}</h2>
                                </div>
                                <div id="vertical-form" className="p-5">
                                    <div className="preview">
                                        <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                            <label htmlFor="titleGu" className="form-label">
                                                {dataGu.advertisementTitle}
                                            </label>
                                            <input
                                                id="titleGu"
                                                name="titleGu"
                                                type="text"
                                                onChange={handleChange}
                                                value={values.titleGu}
                                                className={`form-control ${errors.titleGu && touched.titleGu && "border-danger"}`}
                                                disabled={loading}
                                            />
                                            {errors.titleGu && touched.titleGu && <div className="text-danger">{errors.titleGu}</div>}
                                        </div>
                                        <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                            <label htmlFor="descriptionGu" className="form-label">
                                                {dataGu.advertisementDescription}
                                            </label>
                                            <textarea
                                                id="descriptionGu"
                                                name="descriptionGu"
                                                onChange={handleChange}
                                                value={values.descriptionGu}
                                                className={`form-control ${
                                                    errors.descriptionGu && touched.descriptionGu && "border-danger"
                                                }`}
                                                disabled={loading}
                                            />
                                            {errors.descriptionGu && touched.descriptionGu && (
                                                <div className="text-danger">{errors.descriptionGu}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                            <button className="btn btn-secondary w-24" type="reset" disabled={loading || !dirty}>
                                Reset
                            </button>
                            <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                                {loading ? <Loading isButton /> : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default AddEditForm;
