import { Dispatch } from "@reduxjs/toolkit";
import { api } from "../config/Api";
import { dataService } from "../config/DataService";
import { setLoading } from "../store/slice/TableDataSlice";
import { AxiosError } from "axios";
import { errorToast, successToast } from "../helper/toast";
import { handleCatchResponse } from "../helper/helper";
import { ActivateDeactivateEducationStandard, EducationStandardData, EducationStandardPayload, Response } from "../helper/interface";

export const onAddEducationStandard = async (
    payload: EducationStandardPayload,
    dispatch: Dispatch,
    callback: (res: Required<Response<EducationStandardData>>) => void
) => {
    try {
        dispatch(setLoading(true));
        const response = await dataService.post(api.marksheet.educationStandard, payload);
        const res = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onEditEducationStandard = async (
    payload: EducationStandardPayload,
    dispatch: Dispatch,
    callback: (res: Required<Response<EducationStandardPayload>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.put(api.marksheet.educationStandard, payload);
        const res = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeleteEducationStandard = async (educationStandardId: number, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.marksheet.educationStandard}/${educationStandardId}`);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onActivateDeactivateEducationStandard = async (
    data: ActivateDeactivateEducationStandard,
    dispatch: Dispatch,
    callback: () => void
) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            isActive: data.isActive,
        };

        const response = await dataService.patch(`${api.marksheet.educationStandard}/${data.educationStandardId}`, payload);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
