import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Tooltip } from "react-tooltip";
import { CheckSquare, Edit, Trash2 } from "react-feather";

import DeleteModal from "../../../modal/DeleteModal";
import CustomTable from "../../table/CustomTable";
import useTable from "../../../hooks/useTable";
import { api } from "../../../config/Api";
import usePathName from "../../../hooks/usePathName";
import { ActivateDeactivateEducationYear, Alignment, EducationYearData } from "../../../helper/interface";
import SearchForm from "../../table/SearchForm";
import { onActivateDeactivateEducationYear, onDeleteEducationYear } from "../../../service/educationYear";

const EducationYear = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const educationYearTable = useTable<EducationYearData>("Education-year", api.marksheet.educationYear);

    const [isOpen, setIsOpen] = useState(false);
    const [eduYear, setEduYear] = useState<EducationYearData | null>(null);

    const onDeleteClick = useCallback((record: EducationYearData) => {
        setEduYear(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (eduYear?.educationYearId) {
            onDeleteEducationYear(eduYear?.educationYearId, dispatch, () => {
                educationYearTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [eduYear, educationYearTable]);

    const onActivateDeactivate = useCallback(
        (payload: ActivateDeactivateEducationYear) => {
            onActivateDeactivateEducationYear(payload, dispatch, educationYearTable.fetchApi);
        },
        [educationYearTable]
    );

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const educationYearCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: EducationYearData, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "YEAR",
            key: "year",
            sortable: true,
            render: (record: EducationYearData) => {
                return <span>{record?.year}</span>;
            },
        },
        {
            title: "MARKSHEET UPLOADABLE",
            key: "isMarkSheetUploadable",
            sortable: false,
            render: (record: EducationYearData) => {
                return (
                    <span>
                        {record?.isMarkSheetUploadable ? (
                            <div className="flex items-center justify-center text-success">
                                <CheckSquare className="mr-2 w-4 h-4" />
                            </div>
                        ) : (
                            <div className="flex items-center justify-center text-danger">
                                <CheckSquare className="mr-2 w-4 h-4" />
                            </div>
                        )}
                    </span>
                );
            },
        },
        {
            title: "PRIZE LIST VISIBLE",
            key: "isPrizeListVisible",
            sortable: false,
            render: (record: EducationYearData) => {
                return (
                    <span>
                        {record?.isPrizeListVisible ? (
                            <div className="flex items-center justify-center text-success">
                                <CheckSquare className="mr-2 w-4 h-4" />
                            </div>
                        ) : (
                            <div className="flex items-center justify-center text-danger">
                                <CheckSquare className="mr-2 w-4 h-4" />
                            </div>
                        )}
                    </span>
                );
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            render: (record: EducationYearData) => {
                const payload = {
                    educationYearId: record?.educationYearId,
                    isActive: !record?.isActive,
                };
                return (
                    <>
                        <Tooltip id="simpleTooltipActiveDeactive" place="top" />
                        <div
                            className="form-switch form-check flex justify-center flex-col"
                            data-tooltip-id="simpleTooltipActiveDeactive"
                            data-tooltip-content={record?.isActive ? "Active" : "Inactive"}
                        >
                            <input
                                id="isActive"
                                name="isActive"
                                onChange={() => onActivateDeactivate(payload)}
                                checked={record?.isActive}
                                className={"form-check-input"}
                                type="checkbox"
                            />
                        </div>
                    </>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: EducationYearData) => {
                return (
                    <>
                        <div className="flex justify-center items-center text-primary">
                            <Tooltip id="simpleTooltipEditDelete" place="top" />
                            <div
                                className="flex items-center mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Edit education year"}
                                onClick={() => navigate(`/marksheet/education-year/edit/${record?.educationYearId}`)}
                            >
                                <Edit className="w-4 h-4 mr-1" />
                                <span className="pt-[2px] leading-[16px]">Edit</span>
                            </div>
                            <div
                                className="flex items-center text-danger cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Delete education year"}
                                onClick={() => onDeleteClick(record)}
                            >
                                <Trash2 className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Delete</span>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    usePathName([{ pathName: "Education year", href: "/marksheet/education-year" }]);

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this education year?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={educationYearCols}
                    data={educationYearTable.tableData ?? []}
                    tableKey="educationYearId"
                    reload={educationYearTable.fetchApi}
                    currentOrder={educationYearTable.currentOrder}
                    error={educationYearTable.error}
                    loading={educationYearTable.loading}
                    tableOffset={educationYearTable.tableOffset}
                    onSetOrderBy={educationYearTable.onSetOrderBy}
                    component={
                        <>
                            <SearchForm
                                resetSearch={educationYearTable.resetSearch}
                                onSearch={educationYearTable.onSearch}
                                searchValue={educationYearTable.search}
                                searchPlaceHolder="Search by education year"
                            />
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new education year"
                                    onClick={() => {
                                        navigate("/marksheet/education-year/add");
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default EducationYear;
