import { Formik } from "formik";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../config/Api";
import Loading from "../../container/Loading";
import { AdvertisementPayload, BEEditAdvertisementPayload } from "../../helper/interface";
import useFetch from "../../hooks/useFetch";
import usePathName from "../../hooks/usePathName";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import { addEditAdvertisementValidation } from "../../helper/validationSchema";
import { onEditAdvertisement } from "../../service/advertisement";
import { editSuccessToast } from "../../helper/toast";
import Form from "./Form";

const EditAdvertisement: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { advertisementId = "" } = useParams();

    const { res, loading, error } = useFetch<AdvertisementPayload>(`${api.advertisement}/${advertisementId}`);

    usePathName([
        { pathName: "Advertisement", href: "/advertisement" },
        { pathName: "Edit", href: `/advertisement/edit/${advertisementId}` },
    ]);

    const onSubmit = useCallback((value: AdvertisementPayload) => {
        const payload: BEEditAdvertisementPayload = {
            advertisementId: +advertisementId,
            titleEn: value.titleEn,
            titleGu: value.titleGu,
            descriptionEn: value.descriptionEn,
            descriptionGu: value.descriptionGu,
            email: value.email || null,
            mobile: value.mobile,
        };

        if (typeof value.image === "object") {
            payload.image = value.image;
        }

        onEditAdvertisement(payload, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Edit",
                primaryLink: `/advertisement/edit/${res?.data?.advertisementId ?? payload.advertisementId}`,
            };

            editSuccessToast(res.message, navigate, toastLink);
            navigate("/advertisement");
        });
    }, []);

    const initialValues = res?.data;

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4" onClick={() => navigate("/advertisement")}>
                    <ArrowLeftCircle className="cursor-pointer text-primary" />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Edit advertisement details</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {error ? (
                    <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                        <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                    </div>
                ) : loading ? (
                    <Loading />
                ) : initialValues ? (
                    <Formik initialValues={initialValues} validationSchema={addEditAdvertisementValidation} onSubmit={onSubmit}>
                        {(props) => <Form {...props} />}
                    </Formik>
                ) : null}
            </div>
        </div>
    );
};

export default EditAdvertisement;
