import React, { useCallback } from "react";
import { getMonth, getYear } from "date-fns";
import { months, ONE, TEN } from "../../helper/constant";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { Listbox, Transition } from "@headlessui/react";
import { range } from "lodash";

const MIN_YEAR = 1950;

const CustomDatePickerHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => {
    const years = range(MIN_YEAR, getYear(new Date()) + TEN, ONE);

    const handleYearChange = useCallback((value: number) => {
        changeYear(value);
    }, []);

    const handleMonthChange = useCallback(
        (value: string) => {
            changeMonth(months.indexOf(value));
        },
        [months]
    );

    return (
        <div className="flex justify-between" style={{ margin: "5px" }}>
            <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                {"<"}
            </button>

            {years ? (
                <div className="tom-select" style={{ minWidth: "110px", maxWidth: "110px" }}>
                    <Listbox value={getYear(date)} onChange={handleYearChange}>
                        <Listbox.Button className="ts-input">
                            <div className="item">{getYear(date) ?? "Select year"}</div>
                        </Listbox.Button>
                        <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <Listbox.Options className="ts-dropdown single w-full">
                                <div role="listbox" className="ts-dropdown-content">
                                    {years.map((option) => (
                                        <Listbox.Option
                                            className={({ active }) =>
                                                `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                    active || getYear(date) === option ? "optionColor" : null
                                                }`
                                            }
                                            key={option}
                                            value={option}
                                        >
                                            {option}
                                        </Listbox.Option>
                                    ))}
                                </div>
                            </Listbox.Options>
                        </Transition>
                    </Listbox>
                </div>
            ) : null}

            {months ? (
                <div className="tom-select" style={{ minWidth: "110px", maxWidth: "110px" }}>
                    <Listbox value={months[getMonth(date)]} onChange={handleMonthChange}>
                        <Listbox.Button className="ts-input">
                            <div className="item">{months[getMonth(date)] ?? "Select month"}</div>
                        </Listbox.Button>
                        <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <Listbox.Options className="ts-dropdown single w-full">
                                <div role="listbox" className="ts-dropdown-content">
                                    {months.map((option) => (
                                        <Listbox.Option
                                            className={({ active }) =>
                                                `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                    active || months[getMonth(date)] === option ? "optionColor" : null
                                                }`
                                            }
                                            key={option}
                                            value={option}
                                        >
                                            {option}
                                        </Listbox.Option>
                                    ))}
                                </div>
                            </Listbox.Options>
                        </Transition>
                    </Listbox>
                </div>
            ) : null}

            <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                {">"}
            </button>
        </div>
    );
};

export default CustomDatePickerHeader;
