import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import { useNavigate, useParams } from "react-router";

import { editSuccessToast } from "../../../helper/toast";
import usePathName from "../../../hooks/usePathName";
import useFetch from "../../../hooks/useFetch";
import Loading from "../../../container/Loading";
import { api } from "../../../config/Api";
import { MarksheetPayload } from "../../../helper/interface";
import MarksheetUploadForm from "./Form";
import { onEditMarksheet } from "../../../service/marksheet";
import { marksheetValidation } from "../../../helper/validationSchema";

const EditMarksheetUpload = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { markSheetId = "" } = useParams();

    usePathName([
        { pathName: "Marksheet Upload", href: "/marksheet/marksheet-upload" },
        { pathName: "Edit", href: `/marksheet/marksheet-upload/edit/${markSheetId}` },
    ]);

    const { res, loading, error } = useFetch<MarksheetPayload>(`${api.marksheet.upload}/${markSheetId}`);

    const onSubmit = useCallback((values: MarksheetPayload) => {
        const payload: MarksheetPayload = {
            markSheetId: +markSheetId,
            memberId: values.memberId,
            educationBoardId: values.educationBoardId,
            educationYearId: values.educationYearId,
            educationStandardId: values.educationStandardId,
            educationMediumId: values.educationMediumId,
            marksType: values.marksType,
            marks: values.marks,
            images: values.images?.filter((ele) => ele?.isNew),
            verified: values.verified,
            reason: values.reason,
            deletedImages: values.deletedImages || [],
        };

        onEditMarksheet(payload, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Edit",
                primaryLink: `/marksheet/marksheet-upload/edit/${res?.data?.markSheetId ?? payload.markSheetId}`,
            };

            editSuccessToast(res.message, navigate, toastLink);
            navigate("/marksheet/marksheet-upload");
        });
    }, []);

    const initialValues = res?.data;

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/marksheet/marksheet-upload")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Edit Marksheet</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    {error ? (
                        <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                            <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                        </div>
                    ) : loading ? (
                        <Loading />
                    ) : initialValues ? (
                        <Formik initialValues={initialValues} validationSchema={marksheetValidation} onSubmit={onSubmit}>
                            {(props) => <MarksheetUploadForm {...props} />}
                        </Formik>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default EditMarksheetUpload;
