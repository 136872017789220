import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { handleCatchResponse } from "../helper/helper";
import { setLoading } from "../store/slice/TableDataSlice";
import { ActivateDeactivateEvent, EventPayload, Response } from "../helper/interface";
import { api } from "../config/Api";
import { dataService } from "../config/DataService";
import { errorToast, successToast } from "../helper/toast";

export const onAddEvent = async (payload: EventPayload, dispatch: Dispatch, callback: (res: Required<Response<EventPayload>>) => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.post(api.event.event, payload, { headers: { "Content-Type": "multipart/form-data" } });
        const res = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onEditEvent = async (payload: EventPayload, dispatch: Dispatch, callback: (res: Required<Response<EventPayload>>) => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.put(api.event.event, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });

        const res = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onActivateDeactivateEvent = async (eventId: ActivateDeactivateEvent, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.patch(`${api.event.event}/${eventId.eventId}`);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeleteEvent = async (eventId: number, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.event.event}/${eventId}`);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
