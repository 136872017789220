import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik, FormikHelpers } from "formik";
import { ArrowLeftCircle } from "react-feather";

import usePathName from "../../hooks/usePathName";
import { addSuccessToast } from "../../helper/toast";
import { PrizePayload } from "../../helper/interface";
import AddEditForm from "./AddEditForm";
import { onAddPrize } from "../../service/prize";
import { prizeValidation } from "../../helper/validationSchema";
import { ONE } from "../../helper/constant";

const initialValues: PrizePayload = {
    memberId: null,
    educationBoardId: null,
    educationYearId: null,
    educationStandardId: null,
    educationMediumId: null,
    marksType: "",
    prizeRankId: null,
    marks: ONE,
    familyId: null,
};

const AddPrize = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    usePathName([
        { pathName: "Prize", href: "/prize" },
        { pathName: "Add", href: "/prize/add" },
    ]);

    const onSubmit = useCallback((values: PrizePayload, { resetForm }: FormikHelpers<PrizePayload>) => {
        const payload = {
            memberId: values.memberId,
            educationBoardId: values.educationBoardId,
            educationMediumId: values.educationMediumId,
            educationStandardId: values.educationStandardId,
            educationYearId: values.educationYearId,
            marks: values.marks,
            marksType: values.marksType,
            prizeRankId: values.prizeRankId,
        };
        onAddPrize(payload, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Add new",
                primaryLink: "/prize/add",
                secondaryLinkTitle: "Edit",
                secondaryLink: `/prize/edit/${res.data.prizeId}`,
            };

            addSuccessToast(res.message, navigate, toastLink);
            resetForm();
            navigate("/prize");
        });
    }, []);

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/prize")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Add new prize</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    <Formik initialValues={initialValues} validationSchema={prizeValidation} onSubmit={onSubmit}>
                        {(props) => <AddEditForm {...props} />}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AddPrize;
