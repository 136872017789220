import { Tooltip } from "react-tooltip";
import { Edit, Trash2 } from "react-feather";
import { useDispatch } from "react-redux";

import usePathName from "../../hooks/usePathName";
import { api } from "../../config/Api";
import { ActivateDeactivateOccupation, Alignment, Ocupation } from "../../helper/interface";
import useTable from "../../hooks/useTable";
import CustomTable from "../table/CustomTable";
import { useNavigate } from "react-router";
import SearchForm from "../table/SearchForm";
import DeleteModal from "../../modal/DeleteModal";
import { useCallback, useState } from "react";
import { onActivateDeactivateOccupation, onDeleteOccupation } from "../../service/occupations";

const Occupation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const occupationsTable = useTable<Ocupation>("occupations", api.occupation);

    const [isOpen, setIsOpen] = useState(false);
    const [occupation, setOccupation] = useState<Ocupation | null>(null);

    usePathName([{ pathName: "Occupation", href: "/occupation" }]);

    const onDeleteClick = useCallback((record: Ocupation) => {
        setOccupation(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (occupation?.occupationId) {
            onDeleteOccupation(occupation?.occupationId, dispatch, () => {
                occupationsTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [occupation, occupationsTable]);

    const onActivateDeactivate = useCallback(
        (payload: ActivateDeactivateOccupation) => {
            onActivateDeactivateOccupation(payload, dispatch, occupationsTable.fetchApi);
        },
        [occupationsTable]
    );

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const occupationCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: Ocupation, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "OCCUPATION",
            key: "nameEn",
            sortable: true,
            render: (record: Ocupation) => {
                return <span>{record?.nameEn}</span>;
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            render: (record: Ocupation) => {
                const payload = {
                    occupationId: record?.occupationId,
                    isActive: !record?.isActive,
                };
                return (
                    <>
                        <Tooltip id="simpleTooltipActiveDeactive" place="top" />
                        <div
                            className="form-switch form-check flex justify-center flex-col"
                            data-tooltip-id="simpleTooltipActiveDeactive"
                            data-tooltip-content={record?.isActive ? "Active" : "Inactive"}
                        >
                            <input
                                id="isActive"
                                name="isActive"
                                onChange={() => onActivateDeactivate(payload)}
                                checked={record?.isActive}
                                className={"form-check-input"}
                                type="checkbox"
                            />
                        </div>
                    </>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: Ocupation) => {
                return (
                    <>
                        <div className="flex justify-center items-center text-primary">
                            <Tooltip id="simpleTooltipEditDelete" place="top" />
                            <div
                                className="flex items-center mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Edit occupation"}
                                onClick={() => navigate(`/occupation/edit/${record?.occupationId}`)}
                            >
                                <Edit className="w-4 h-4 mr-1" />
                                <span className="pt-[2px] leading-[16px]">Edit</span>
                            </div>
                            <div
                                className="flex items-center text-danger cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Delete occupation"}
                                onClick={() => onDeleteClick(record)}
                            >
                                <Trash2 className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Delete</span>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this occupation?"
            />

            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={occupationCols}
                    data={occupationsTable.tableData ?? []}
                    tableKey="occupationId"
                    reload={occupationsTable.fetchApi}
                    currentOrder={occupationsTable.currentOrder}
                    error={occupationsTable.error}
                    loading={occupationsTable.loading}
                    tableOffset={occupationsTable.tableOffset}
                    onSetOrderBy={occupationsTable.onSetOrderBy}
                    component={
                        <>
                            <SearchForm
                                resetSearch={occupationsTable.resetSearch}
                                onSearch={occupationsTable.onSearch}
                                searchValue={occupationsTable.search}
                                searchPlaceHolder="Search by occupation name"
                            />
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new occupation"
                                    onClick={() => {
                                        navigate("/occupation/add");
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default Occupation;
