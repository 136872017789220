import { Formik } from "formik";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../config/Api";
import Loading from "../../container/Loading";
import { SubAdminPayload } from "../../helper/interface";
import useFetch from "../../hooks/useFetch";
import usePathName from "../../hooks/usePathName";
import AddEditSubAdmin from "./AddEditSubAdmin";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import { addEditSubAdminValidation } from "../../helper/validationSchema";
import { onEditSubAdmin } from "../../service/subAdmin";
import { editSuccessToast } from "../../helper/toast";

const EditSubAdmin: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { adminId = "" } = useParams();

    const { res, loading, error } = useFetch<SubAdminPayload>(`${api.admin.subAdmin}/${adminId}`);

    usePathName([
        { pathName: "Sub admin", href: "/sub-admin" },
        { pathName: "Edit", href: `/sub-admin/edit/${adminId}` },
    ]);

    const onSubmit = useCallback((value: SubAdminPayload) => {
        const payload = {
            email: value.email,
            mobile: value.mobile,
            name: value.name,
            adminId: +adminId,
        };
        onEditSubAdmin(payload, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Edit",
                primaryLink: `/sub-admin/edit/${res?.data?.adminId ?? payload.adminId}`,
            };

            editSuccessToast(res.message, navigate, toastLink);
            navigate("/sub-admin");
        });
    }, []);

    const initialValues = res?.data;

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4" onClick={() => navigate("/sub-admin")}>
                    <ArrowLeftCircle className="cursor-pointer text-primary" />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Edit sub admin</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {error ? (
                    <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                        <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                    </div>
                ) : loading ? (
                    <Loading />
                ) : initialValues ? (
                    <Formik initialValues={initialValues} validationSchema={addEditSubAdminValidation} onSubmit={onSubmit}>
                        {(props) => <AddEditSubAdmin {...props} />}
                    </Formik>
                ) : null}
            </div>
        </div>
    );
};

export default EditSubAdmin;
