import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FC, useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import { ArrowLeftCircle } from "react-feather";

import usePathName from "../../hooks/usePathName";
import { onAddFamily } from "../../service/families";
import { FamilyPayload } from "../../helper/interface";
import { addFamilyValidation } from "../../helper/validationSchema";
import AddForm from "./AddForm";
import { addSuccessToast } from "../../helper/toast";

const initialValues: FamilyPayload = {
    villageId: null,
    fullNameGu: "",
    fullNameEn: "",
    email: "",
    mobile: "",
    gender: "",
    bloodGroup: "",
    addressGu: "",
    addressEn: "",
    occupationId: null,
    dob: "",
    privacy: false,
};

const AddFamily: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    usePathName([
        { pathName: "Families", href: "/families" },
        { pathName: "Add", href: "/families/add" },
    ]);

    const onSubmit = useCallback((values: FamilyPayload, { resetForm }: FormikHelpers<FamilyPayload>) => {
        onAddFamily(values, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Add new",
                primaryLink: "/families/add",
                secondaryLinkTitle: "Edit",
                secondaryLink: `/families/edit/${res.data.family.familyId}`,
            };
            addSuccessToast(res.message, navigate, toastLink);
            resetForm();
            navigate("/families");
        });
    }, []);

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/families")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Add new family</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    <Formik initialValues={initialValues} validationSchema={addFamilyValidation} onSubmit={onSubmit}>
                        {(props) => <AddForm {...props} />}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AddFamily;
