import { Formik, FormikHelpers } from "formik";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import usePathName from "../../hooks/usePathName";
import { useNavigate } from "react-router-dom";
import { AdminDirectoryPayload } from "../../helper/interface";
import { onAddAdminDirectory } from "../../service/adminDirectory";
import Form from "./Form";
import { ArrowLeftCircle } from "react-feather";
import { adminDirectoryValidation } from "../../helper/validationSchema";
import { addSuccessToast } from "../../helper/toast";

const initialValues: AdminDirectoryPayload = {
    nameEn: "",
    nameGu: "",
    email: "",
    mobile: "",
};

const AddAdminDirectory: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    usePathName([
        { pathName: "Admin directory", href: "/admin-directory" },
        { pathName: "Add", href: "/admin-directory/add " },
    ]);

    const onSubmit = useCallback((values: AdminDirectoryPayload, { resetForm }: FormikHelpers<AdminDirectoryPayload>) => {
        const payload = {
            nameEn: values.nameEn,
            nameGu: values.nameGu,
            mobile: values.mobile,
            email: values?.email ?? null,
        };

        onAddAdminDirectory(payload, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Add new",
                primaryLink: "/admin-directory/add",
                secondaryLinkTitle: "Edit",
                secondaryLink: `/admin-directory/edit/${res.data.adminDirectoryId}`,
            };

            addSuccessToast(res.message, navigate, toastLink);
            resetForm();
            navigate("/admin-directory");
        });
    }, []);

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4" onClick={() => navigate("/admin-directory")}>
                    <ArrowLeftCircle className="cursor-pointer text-primary" />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Add new admin directory</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                <Formik initialValues={initialValues} validationSchema={adminDirectoryValidation} onSubmit={onSubmit}>
                    {(props) => <Form {...props} />}
                </Formik>
            </div>
        </div>
    );
};

export default AddAdminDirectory;
