import { useCallback } from "react";
import { Formik } from "formik";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Loading from "../../container/Loading";
import EventForm from "./Form";
import { eventValidation } from "../../helper/validationSchema";
import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import { EventPayload } from "../../helper/interface";
import usePathName from "../../hooks/usePathName";
import { onEditEvent } from "../../service/event";
import { editSuccessToast } from "../../helper/toast";

const Edit = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { eventId = "" } = useParams();

    usePathName([
        { pathName: "Event", href: "/events" },
        { pathName: "Edit", href: `/events/edit/${eventId}` },
    ]);

    const { res, loading, error } = useFetch<EventPayload>(`${api.event.event}/${eventId}`);

    const initialValues = res?.data;

    const onSubmit = useCallback((values: EventPayload) => {
        const payload: EventPayload = {
            eventId: +eventId,
            nameEn: values.nameEn,
            nameGu: values.nameGu,
            images: values.images?.filter((ele) => ele.isNew),
            locationEn: values.locationEn,
            locationGu: values.locationGu,
            eventDate: values.eventDate,
            coverImg: values.coverImg,
        };

        if (values.deletedImages) {
            payload.deletedImages = values.deletedImages;
        }

        onEditEvent(payload, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Edit",
                primaryLink: `/events/edit/${res?.data?.eventId ?? payload.eventId}`,
            };

            editSuccessToast(res.message, navigate, toastLink);
            navigate("/events");
        });
    }, []);

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/events")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Edit Event</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    {error ? (
                        <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                            <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                        </div>
                    ) : loading ? (
                        <Loading />
                    ) : initialValues ? (
                        <Formik initialValues={initialValues} validationSchema={eventValidation} onSubmit={onSubmit}>
                            {(props) => <EventForm {...props} />}
                        </Formik>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default Edit;
