import { Form } from "formik";
import { useSelector } from "react-redux";

import { EducationStandardPayload, FormParams, TableStateValue } from "../../../helper/interface";
import Loading from "../../../container/Loading";

const AddEditForm = ({ errors, touched, values, dirty, setFieldValue, handleChange }: FormParams<EducationStandardPayload>) => {
    const loading = useSelector((state: TableStateValue) => state.table.loading);

    return (
        <>
            <Form>
                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                    <div className="intro-y col-span-12 flex items-center justify-center">
                        <div className="intro-y box lg:w-[60%] md:w-[100%] sm:w-[100%]">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="font-medium text-base mr-auto">Education Standard Details</h2>
                            </div>
                            <div id="vertical-form" className="p-5">
                                <div className="preview">
                                    {/* Name */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="name" className="form-label">
                                            Name
                                        </label>
                                        <input
                                            id="name"
                                            name="name"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.name}
                                            onBlur={(e) => setFieldValue("name", e.target.value)}
                                            className={`form-control ${errors.name && touched.name && "border-danger"}`}
                                        />
                                        {errors.name && touched.name && <div className="text-danger">{errors.name}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                        <button className="btn btn-secondary w-24" type="reset" disabled={loading || !dirty}>
                            Reset
                        </button>
                        <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                            {loading ? <Loading isButton /> : "Save"}
                        </button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default AddEditForm;
