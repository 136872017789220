import { AlertTriangle, ArrowLeftCircle, Calendar, Mail, Phone } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import Loading from "../../../container/Loading";
import useFetch from "../../../hooks/useFetch";
import { MarksheetPayload } from "../../../helper/interface";
import { api } from "../../../config/Api";
import { dataEn } from "../../../helper/dataEn";
import { ZERO } from "../../../helper/constant";
import avatar from "../../../assets/images/userAvatar.png";
import { calcAge, isPDF } from "../../../config/MethodUtils";
import PDFLogo from "../../../assets/images/PDFLogo.png";

const ViewMarksheetUpload = () => {
    const navigate = useNavigate();
    const { markSheetId = "" } = useParams();

    const { res, loading, error } = useFetch<MarksheetPayload>(`${api.marksheet.upload}/${markSheetId}`);

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/marksheet/marksheet-upload")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">{dataEn.marksheetUpload}</div>
                </div>
                {error ? (
                    <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                        <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                            <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                        </div>
                    </div>
                ) : loading ? (
                    <Loading />
                ) : (
                    <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                        <div className="intro-y box px-5 pt-5 my-5">
                            <div className="flex flex-col lg:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-5 -mx-5">
                                <div className="flex flex-1 px-5 items-center justify-center lg:justify-start">
                                    <div className="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative">
                                        <img
                                            alt="Profile picture"
                                            className="rounded-2xl img-bordered"
                                            src={res?.data?.images ? res?.data?.images[ZERO]?.url : avatar}
                                        />
                                    </div>
                                    <div className="ml-5">
                                        <div className="truncate sm:whitespace-normal font-medium text-lg capitalize">
                                            {res?.data?.member?.fullNameEn}
                                        </div>
                                        {res?.data?.educationStandard?.name ? (
                                            <div className="text-slate-500 capitalize">
                                                Standard: {res?.data?.educationStandard?.name} ({res?.data?.educationYear?.year})
                                            </div>
                                        ) : null}
                                        {res?.data?.marks ? (
                                            <div className="text-slate-500 capitalize">
                                                Result: {res?.data?.marks} ({res?.data?.marksType})
                                            </div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="mt-6 lg:mt-0 flex-1 px-5 border-l border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0">
                                    <div className="font-medium text-center lg:text-left lg:mt-3">Contact Details</div>
                                    {res?.data?.member?.email ? (
                                        <div className="flex flex-col justify-center items-start mt-4">
                                            <div className="truncate sm:whitespace-normal flex items-center">
                                                <Mail className="mr-4" /> {res?.data?.member?.email}
                                            </div>
                                        </div>
                                    ) : null}
                                    {res?.data?.member?.mobile ? (
                                        <div className="flex flex-col justify-center items-start mt-4">
                                            <div className="truncate sm:whitespace-normal flex items-center">
                                                <Phone className="mr-4" /> {res?.data?.member?.mobile}
                                            </div>
                                        </div>
                                    ) : null}
                                    {res?.data?.member?.dob ? (
                                        <div className="flex flex-col justify-center items-start mt-4">
                                            <div className="truncate sm:whitespace-normal flex items-center">
                                                <Calendar className="mr-4" />
                                                {moment(res?.data?.member?.dob).format("DD/MM/YYYY")}
                                                <span className="ml-2">({calcAge(res?.data?.member?.dob)})</span>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="flex flex-col lg:flex-column p-5 -mx-5">
                                <div className="font-bold text-center lg:mt-3 mb-8">Uploaded Documents</div>
                                <div className="flex flex-1 flex-wrap items-center justify-center">
                                    {res?.data?.images?.map((file, i: number) => (
                                        <div
                                            className={`w-20 h-20 sm:w-24 sm:h-24 flex-none m-1 lg:w-32 lg:h-32 image-fit relative rounded-2xl img-bordered cursor-pointer overflow-hidden ${
                                                isPDF(file?.name) ? "p-5" : ""
                                            }`}
                                            key={i}
                                        >
                                            <img
                                                alt="Profile picture"
                                                src={isPDF(file?.name) ? PDFLogo : file?.url}
                                                onClick={() => window.open(file?.url)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ViewMarksheetUpload;
