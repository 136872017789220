import { Formik, FormikHelpers } from "formik";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ArrowLeftCircle } from "react-feather";

import usePathName from "../../hooks/usePathName";
import { ContributionHistoryPayload } from "../../helper/interface";
import { onAddContributionHistory } from "./../../service/contributionHistory";
import Form from "./Form";
import { addEditContributionHistoryValidation } from "../../helper/validationSchema";
import { addSuccessToast } from "../../helper/toast";

const initialValues: ContributionHistoryPayload = {
    paidAmount: 0,
    paidDate: new Date(),
    annualContribution: null,
    familyId: null,
};

const AddContributionHistory: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    usePathName([
        { pathName: "Contribution history", href: "/contribution-history" },
        { pathName: "Add", href: "/contribution-history/add " },
    ]);

    const onSubmit = useCallback((values: ContributionHistoryPayload, { resetForm }: FormikHelpers<ContributionHistoryPayload>) => {
        const payload = {
            annualContributionId: values?.annualContribution?.annualContributionId,
            familyId: values.familyId,
            paidDate: values.paidDate,
            paidAmount: values.paidAmount,
        };

        onAddContributionHistory(payload, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Add new",
                primaryLink: "/contribution-history/add",
                secondaryLinkTitle: "Edit",
                secondaryLink: `/contribution-history/edit/${res.data.annualContributionId}`,
            };

            addSuccessToast(res.message, navigate, toastLink);
            resetForm();
            navigate("/contribution-history");
        });
    }, []);

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4" onClick={() => navigate("/contribution-history")}>
                    <ArrowLeftCircle className="cursor-pointer text-primary" />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Add new contribution history</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                <Formik initialValues={initialValues} validationSchema={addEditContributionHistoryValidation} onSubmit={onSubmit}>
                    {(props) => <Form {...props} />}
                </Formik>
            </div>
        </div>
    );
};

export default AddContributionHistory;
