import { AxiosError } from "axios";
import { Dispatch } from "@reduxjs/toolkit";

import { setLoading } from "../store/slice/TableDataSlice";
import { dataService } from "../config/DataService";
import { api } from "../config/Api";
import { handleCatchResponse } from "../helper/helper";
import { errorToast, successToast } from "../helper/toast";
import { ActivateDeactivateMember, EditMemberPayload, Member, MemberPayload, Response } from "../helper/interface";

export const onAddMember = async (payload: MemberPayload, dispatch: Dispatch, callback: (res: Required<Response<Member>>) => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.post(api.member, payload);
        const res: Required<Response<Member>> = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onEditMember = async (payload: EditMemberPayload, dispatch: Dispatch, callback: (res: Required<Response<Member>>) => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.put(api.member, payload);
        const res: Required<Response<Member>> = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeleteMember = async (memberId: number, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.member}/${memberId}`);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onActivateDeactivateMember = async (data: ActivateDeactivateMember, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            isActive: data.isActive,
        };

        const response = await dataService.patch(`${api.member}/${data.memberId}`, payload);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
