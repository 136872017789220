import { useCallback } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";

import usePathName from "../../../hooks/usePathName";
import { api } from "../../../config/Api";
import useFetch from "../../../hooks/useFetch";
import Loading from "../../../container/Loading";
import { editSuccessToast } from "../../../helper/toast";
import { educationBoardValidation } from "../../../helper/validationSchema";
import { EducationBoardPayload } from "../../../helper/interface";
import { onEditEducationBoard } from "../../../service/educationBoard";
import AddEditForm from "./AddEditForm";

const EditEducationBoard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { educationBoardId = "" } = useParams();

    const { res, loading, error } = useFetch<EducationBoardPayload>(`${api.marksheet.educationBoard}/${educationBoardId}`);

    usePathName([
        { pathName: "Education board", href: "/marksheet/education-board" },
        { pathName: "Edit", href: `/marksheet/education-board/edit/${educationBoardId}` },
    ]);

    const onSubmit = useCallback((value: EducationBoardPayload) => {
        const payload = {
            educationBoardId: +educationBoardId,
            nameEn: value.nameEn,
            nameGu: value.nameGu,
        };
        onEditEducationBoard(payload, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Edit",
                primaryLink: `/marksheet/education-board/edit/${res?.data?.educationBoardId ?? payload?.educationBoardId}`,
            };

            editSuccessToast(res.message, navigate, toastLink);
            navigate("/marksheet/education-board");
        });
    }, []);

    const initialValues = res?.data;

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/marksheet/education-board")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Edit education board details</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400 mx-auto">
                    {error ? (
                        <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                            <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                        </div>
                    ) : loading ? (
                        <Loading />
                    ) : initialValues ? (
                        <Formik initialValues={initialValues} validationSchema={educationBoardValidation} onSubmit={onSubmit}>
                            {(props) => <AddEditForm {...props} />}
                        </Formik>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default EditEducationBoard;
