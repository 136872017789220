import React, { useCallback } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginPayload, TableStateValue } from "../../helper/interface";
import { onLogin } from "../../service/auth";
import { loginValidation } from "../../helper/validationSchema";
import Loading from "../../container/Loading";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loading = useSelector((state: TableStateValue) => state.table.loading);

    const onLoginSubmit = useCallback((value: LoginPayload) => {
        onLogin(value, dispatch, navigate);
    }, []);

    return (
        <div className="my-auto mx-auto lg:ml-20 bg-white dark:bg-darkmode-600 lg:bg-transparent px-5 sm:px-8 py-8 lg:p-0 rounded-md shadow-md lg:!shadow-none w-full sm:w-3/4 lg:w-3/4 xl:w-auto login-logo-section">
            <h2 className="lg:intro-x font-bold text-2xl lg:text-3xl text-center lg:text-left">Welcome</h2>
            <div className="lg:intro-x mt-8">
                <Formik
                    initialValues={{
                        email: "",
                        password: "",
                    }}
                    validationSchema={loginValidation}
                    onSubmit={onLoginSubmit}
                >
                    {({ errors, touched, handleChange, values, handleBlur }) => (
                        <>
                            <Form>
                                <input
                                    autoFocus
                                    name="email"
                                    type="text"
                                    className={`lg:intro-x login__input form-control py-3 px-4 block ${
                                        errors.email && touched.email && "border-danger"
                                    }`}
                                    value={values.email}
                                    onChange={handleChange}
                                    placeholder="Enter email"
                                    onBlur={handleBlur}
                                />
                                {errors.email && touched.email && <div className="text-danger">{errors.email}</div>}
                                <input
                                    name="password"
                                    type="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    className={`lg:intro-x login__input form-control py-3 px-4 block mt-4 ${
                                        errors.password && touched.password && "border-danger"
                                    }`}
                                    placeholder="Enter password"
                                    onBlur={handleBlur}
                                />
                                {errors.password && touched.password && <div className="text-danger">{errors.password}</div>}
                                <div className="flex items-center justify-content-end mt-2 mb-2 cursor-pointer">
                                    <span onClick={() => navigate("/auth/forgot-password")}>Forgot Password?</span>
                                </div>
                                <div className="lg:intro-x text-center xl:text-left">
                                    <button
                                        type="submit"
                                        className="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                                        disabled={loading}
                                    >
                                        {loading ? <Loading isButton /> : "Sign in"}
                                    </button>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default Login;
