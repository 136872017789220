import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Tooltip } from "react-tooltip";
import { Edit, Trash2 } from "react-feather";

import DeleteModal from "../../../modal/DeleteModal";
import CustomTable from "../../table/CustomTable";
import useTable from "../../../hooks/useTable";
import { api } from "../../../config/Api";
import usePathName from "../../../hooks/usePathName";
import { ActivateDeactivateEducationBoard, Alignment, EducationBoardData } from "../../../helper/interface";
import SearchForm from "../../table/SearchForm";
import { onActivateDeactivateEducationBoard, onDeleteEducationBoard } from "../../../service/educationBoard";

const EducationBoard = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const EducationBoardTable = useTable<EducationBoardData>("Education-board", api.marksheet.educationBoard);

    const [isOpen, setIsOpen] = useState(false);
    const [eduBoard, setEduBoard] = useState<EducationBoardData | null>(null);

    const onDeleteClick = useCallback((record: EducationBoardData) => {
        setEduBoard(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (eduBoard?.educationBoardId) {
            onDeleteEducationBoard(eduBoard?.educationBoardId, dispatch, () => {
                EducationBoardTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [eduBoard, EducationBoardTable]);

    const onActivateDeactivate = useCallback(
        (payload: ActivateDeactivateEducationBoard) => {
            onActivateDeactivateEducationBoard(payload, dispatch, EducationBoardTable.fetchApi);
        },
        [EducationBoardTable]
    );

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const educationYearCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: EducationBoardData, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "NAME",
            key: "nameEn",
            sortable: true,
            render: (record: EducationBoardData) => {
                return <span>{record?.nameEn}</span>;
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            render: (record: EducationBoardData) => {
                const payload = {
                    educationBoardId: record?.educationBoardId,
                    isActive: !record?.isActive,
                };
                return (
                    <>
                        <Tooltip id="simpleTooltipActiveDeactive" place="top" />
                        <div
                            className="form-switch form-check flex justify-center flex-col"
                            data-tooltip-id="simpleTooltipActiveDeactive"
                            data-tooltip-content={record?.isActive ? "Active" : "Inactive"}
                        >
                            <input
                                id="isActive"
                                name="isActive"
                                onChange={() => onActivateDeactivate(payload)}
                                checked={record?.isActive}
                                className={"form-check-input"}
                                type="checkbox"
                            />
                        </div>
                    </>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: EducationBoardData) => {
                return (
                    <>
                        <div className="flex justify-center items-center text-primary">
                            <Tooltip id="simpleTooltipEditDelete" place="top" />
                            <div
                                className="flex items-center mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Edit education board"}
                                onClick={() => navigate(`/marksheet/education-board/edit/${record?.educationBoardId}`)}
                            >
                                <Edit className="w-4 h-4 mr-1" />
                                <span className="pt-[2px] leading-[16px]">Edit</span>
                            </div>
                            <div
                                className="flex items-center text-danger cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Delete education board"}
                                onClick={() => onDeleteClick(record)}
                            >
                                <Trash2 className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Delete</span>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    usePathName([{ pathName: "Education board", href: "/marksheet/education-board" }]);

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this education board?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={educationYearCols}
                    data={EducationBoardTable.tableData ?? []}
                    tableKey="educationBoardId"
                    reload={EducationBoardTable.fetchApi}
                    currentOrder={EducationBoardTable.currentOrder}
                    error={EducationBoardTable.error}
                    loading={EducationBoardTable.loading}
                    tableOffset={EducationBoardTable.tableOffset}
                    onSetOrderBy={EducationBoardTable.onSetOrderBy}
                    component={
                        <>
                            <SearchForm
                                resetSearch={EducationBoardTable.resetSearch}
                                onSearch={EducationBoardTable.onSearch}
                                searchValue={EducationBoardTable.search}
                                searchPlaceHolder="Search by education board"
                            />
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new education board"
                                    onClick={() => {
                                        navigate("/marksheet/education-board/add");
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default EducationBoard;
