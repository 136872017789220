import { RefObject, useCallback, useMemo, useRef } from "react";
import { AlertTriangle, ArrowLeftCircle, Camera } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Listbox, Transition } from "@headlessui/react";

import Loading from "../../container/Loading";
import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import { EditFamilyPayload, GetPrimaryMember, Member, TableStateValue, VillagePayload } from "../../helper/interface";
import { dataGu } from "../../helper/dataGu";
import usePathName from "../../hooks/usePathName";
import { onEditFamily } from "../../service/families";
import { editFamilyValidation } from "../../helper/validationSchema";
import { getImageUrl } from "../../helper/helper";
import avatar from "./../../assets/images/userAvatar.png";
import { dataEn } from "../../helper/dataEn";
import { editSuccessToast } from "../../helper/toast";

const EditFamily = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileImgRef = useRef() as RefObject<HTMLInputElement>;
    const { familyId = "" } = useParams();

    const updateLoading = useSelector((state: TableStateValue) => state.table.loading);

    const { res, loading, error } = useFetch<EditFamilyPayload>(`${api.family}/${familyId}`);
    const { res: memberResponse } = useFetch<GetPrimaryMember[]>(`${api.member}?familyId=${familyId}`);
    const { res: villageResponse } = useFetch<VillagePayload[]>(api.village);

    const editInitValues: EditFamilyPayload = useMemo(() => {
        const initials = {
            familyId: +familyId,
            primaryMember: res?.data?.primaryMember || null,
            villageId: res?.data?.villageId || null,
            addressGu: res?.data?.addressGu || "",
            addressEn: res?.data?.addressEn || "",
            mobile: res?.data?.mobile || "",
            profileImg: res?.data?.profileImg || "",
            privacy: res?.data?.privacy || false,
        };
        return initials;
    }, [res]);

    const initialValues = res?.data || editInitValues;

    usePathName([
        { pathName: "Family", href: "/families" },
        { pathName: "Edit", href: `/families/edit/${familyId}` },
    ]);

    const onSubmit = useCallback((value: EditFamilyPayload) => {
        const payload = {
            familyId: +familyId,
            primaryMemberId: value?.primaryMember?.memberId,
            villageId: value.villageId,
            mobile: value.mobile,
            addressGu: value.addressGu,
            addressEn: value.addressEn,
            profileImg: value.profileImg,
            privacy: value.privacy,
        };

        onEditFamily(payload, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Edit",
                primaryLink: `/families/edit/${res?.data?.familyId ?? payload?.familyId}`,
            };

            editSuccessToast(res.message, navigate, toastLink);
            navigate("/families");
        });
    }, []);

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema: editFamilyValidation,
        validateOnBlur: true,
        validateOnMount: true,
        enableReinitialize: true,
    });
    const { values, errors, touched, handleSubmit, dirty, setFieldTouched, setFieldValue, handleChange, handleBlur, resetForm } = formik;

    const onChangePrimaryMemberId = useCallback((value: Member) => {
        setFieldValue("primaryMember", value);
    }, []);

    const onChangeVillageId = useCallback((value: number) => {
        setFieldValue("villageId", value);
    }, []);

    const isFormDisabled = Boolean(values.deletedAt);

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/families")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Edit Family</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    {error ? (
                        <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                            <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                        </div>
                    ) : loading ? (
                        <Loading />
                    ) : initialValues ? (
                        <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                            <div className="preview mb-5 col-span-12">
                                <div className="flex flex-1 px-5 items-center justify-center lg:justify-start flex-col">
                                    <div className="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative">
                                        <img
                                            alt="Profile picture"
                                            className="rounded-full img-bordered"
                                            src={values?.profileImg ? getImageUrl(values?.profileImg) : avatar}
                                        />
                                        <div
                                            className={`absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-primary rounded-full p-2 border-2 border-solid border-white ${
                                                isFormDisabled ? "disabled" : "cursor-pointer"
                                            } `}
                                            onClick={() => profileImgRef?.current?.click()}
                                        >
                                            <Camera className={"lucide lucide-camera w-4 h-4 text-white"} />
                                            <input
                                                ref={profileImgRef}
                                                name="profileImg"
                                                type="file"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    if (e?.target?.files?.[0]) {
                                                        setFieldValue("profileImg", e?.target?.files?.[0]);
                                                    }
                                                }}
                                                accept="image/*"
                                                className="hidden"
                                                disabled={isFormDisabled}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-4 text-center">
                                        <div className="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg capitalize">
                                            {values?.primaryMember?.fullNameEn ? values?.primaryMember?.fullNameEn : "Member name"}
                                        </div>
                                        <div className="text-slate-500 capitalize">
                                            {values?.primaryMember?.occupation ? values?.primaryMember?.occupation?.nameEn : "Occupation"}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="intro-y col-span-12 lg:col-span-6">
                                <div className="intro-y box">
                                    <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                        <h2 className="font-medium text-base mr-auto">{dataEn.familyDetails}</h2>
                                    </div>
                                    <div id="vertical-form1" className="p-5">
                                        <div className="preview">
                                            {/* Primary Member */}
                                            <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                                <label className="mb-2 form-label">Primary Member</label>
                                                <div
                                                    className={`tom-select ${
                                                        errors.primaryMemberId && touched.primaryMemberId && "dd-border-danger"
                                                    }`}
                                                >
                                                    <Listbox
                                                        value={values?.primaryMember}
                                                        name="primaryMember"
                                                        onChange={onChangePrimaryMemberId}
                                                        disabled={isFormDisabled}
                                                    >
                                                        <Listbox.Button id="primaryMember" className="ts-input">
                                                            <div className="item">{values?.primaryMember?.fullNameEn}</div>
                                                        </Listbox.Button>
                                                        <Transition
                                                            enter="transition duration-100 ease-out"
                                                            enterFrom="transform scale-95 opacity-0"
                                                            enterTo="transform scale-100 opacity-100"
                                                            leave="transition duration-75 ease-out"
                                                            leaveFrom="transform scale-100 opacity-100"
                                                            leaveTo="transform scale-95 opacity-0"
                                                            onBlur={() => setFieldTouched("primaryMember", true)}
                                                        >
                                                            <Listbox.Options className="ts-dropdown single w-full">
                                                                <div role="listbox" className="ts-dropdown-content">
                                                                    {memberResponse?.data?.map((member: GetPrimaryMember) => {
                                                                        return (
                                                                            <Listbox.Option
                                                                                className={({ active }) =>
                                                                                    `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                        active ||
                                                                                        member.memberId === values?.primaryMember?.memberId
                                                                                            ? "optionColor"
                                                                                            : null
                                                                                    }`
                                                                                }
                                                                                value={member}
                                                                                key={member?.memberId}
                                                                            >
                                                                                {member?.fullNameEn}
                                                                            </Listbox.Option>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </Listbox.Options>
                                                        </Transition>
                                                    </Listbox>
                                                </div>
                                                {errors.primaryMemberId && touched.primaryMemberId && (
                                                    <div className="text-danger">{errors.primaryMemberId}</div>
                                                )}
                                            </div>

                                            {/* Village */}
                                            <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                                <label className="mb-2 form-label">Village</label>
                                                <div
                                                    className={`tom-select ${errors.villageId && touched.villageId && "dd-border-danger"}`}
                                                >
                                                    <Listbox
                                                        value={values.villageId}
                                                        name="villageId"
                                                        onChange={onChangeVillageId}
                                                        disabled={isFormDisabled}
                                                    >
                                                        <Listbox.Button
                                                            id="villageId"
                                                            className={`ts-input ${!values.villageId && "disabled"}`}
                                                        >
                                                            <div className="item">
                                                                {villageResponse?.data?.map(
                                                                    (v) => v?.villageId === values?.villageId && v?.nameEn
                                                                )}
                                                            </div>
                                                        </Listbox.Button>
                                                        <Transition
                                                            enter="transition duration-100 ease-out"
                                                            enterFrom="transform scale-95 opacity-0"
                                                            enterTo="transform scale-100 opacity-100"
                                                            leave="transition duration-75 ease-out"
                                                            leaveFrom="transform scale-100 opacity-100"
                                                            leaveTo="transform scale-95 opacity-0"
                                                            onBlur={() => setFieldTouched("villageId", true)}
                                                        >
                                                            <Listbox.Options className="ts-dropdown single w-full">
                                                                <div role="listbox" className="ts-dropdown-content">
                                                                    {villageResponse?.data?.map((village: VillagePayload) => {
                                                                        return (
                                                                            <Listbox.Option
                                                                                className={({ active }) =>
                                                                                    `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                        active || values.villageId === village.villageId
                                                                                            ? "optionColor"
                                                                                            : null
                                                                                    }`
                                                                                }
                                                                                value={village.villageId}
                                                                                key={village.villageId}
                                                                            >
                                                                                {village.nameEn}
                                                                            </Listbox.Option>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </Listbox.Options>
                                                        </Transition>
                                                    </Listbox>
                                                </div>
                                                {errors.villageId && touched.villageId && (
                                                    <div className="text-danger">{errors.villageId}</div>
                                                )}
                                            </div>

                                            {/* Mobile Number */}
                                            <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                                <label htmlFor="mobile" className="form-label">
                                                    Mobile Number
                                                </label>
                                                <input
                                                    id="mobile"
                                                    name="mobile"
                                                    type="text"
                                                    onChange={(e) => setFieldValue("mobile", e.target.value)}
                                                    value={values.mobile}
                                                    onBlur={handleBlur}
                                                    disabled={isFormDisabled}
                                                    className={`form-control ${errors.mobile && touched.mobile && "border-danger"}`}
                                                />
                                                {errors.mobile && touched.mobile && <div className="text-danger">{errors.mobile}</div>}
                                            </div>

                                            {/* Address */}
                                            <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                                <label htmlFor="addressEn" className="form-label">
                                                    {dataEn.address}
                                                </label>
                                                <textarea
                                                    id="addressEn"
                                                    name="addressEn"
                                                    onChange={handleChange}
                                                    value={values.addressEn}
                                                    disabled={isFormDisabled}
                                                    className={`form-control ${errors.addressEn && touched.addressEn && "border-danger"}`}
                                                />
                                                {errors.addressEn && touched.addressEn && (
                                                    <div className="text-danger">{errors.addressEn}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Gujarati Form */}
                            <div className="intro-y col-span-12 lg:col-span-6">
                                <div className="intro-y box">
                                    <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                        <h2 className="font-bold text-base mr-auto">{dataGu.familyDetails}</h2>
                                    </div>
                                    <div id="vertical-form2" className="p-5">
                                        <div className="preview">
                                            <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                                <label htmlFor="addressGu" className="mb-2 form-label">
                                                    {dataGu.address}
                                                </label>
                                                <textarea
                                                    id="addressGu"
                                                    name="addressGu"
                                                    value={values.addressGu}
                                                    onChange={(e) => setFieldValue("addressGu", e.target.value)}
                                                    onBlur={handleBlur}
                                                    disabled={isFormDisabled}
                                                    className={`form-control ${errors.addressGu && touched.addressGu && "border-danger"}`}
                                                />
                                                {errors.addressGu && touched.addressGu && (
                                                    <div className="text-danger">{errors.addressGu}</div>
                                                )}
                                            </div>
                                            <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                                <label className="mb-2 form-label">User privacy</label>
                                                <div className="form-switch form-check" style={{ height: "37px" }}>
                                                    <input
                                                        id="privacy"
                                                        name="privacy"
                                                        onChange={() => setFieldValue("privacy", !values.privacy)}
                                                        checked={values.privacy}
                                                        className={"form-check-input"}
                                                        type="checkbox"
                                                        disabled={isFormDisabled}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!isFormDisabled ? (
                                <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                                    <button
                                        className="btn btn-secondary w-24"
                                        type="reset"
                                        disabled={updateLoading || !dirty}
                                        onClick={() => resetForm()}
                                    >
                                        Reset
                                    </button>
                                    <button
                                        className="btn btn-primary w-24 ml-2"
                                        type="submit"
                                        disabled={updateLoading || !dirty || loading || isFormDisabled}
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    >
                                        {updateLoading ? <Loading isButton /> : "Save"}
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default EditFamily;
