import { Formik, FormikHelpers } from "formik";
import { ArrowLeftCircle } from "react-feather";
import { useNavigate } from "react-router-dom";
import { addSuccessToast } from "../../../helper/toast";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import usePathName from "../../../hooks/usePathName";
import { EducationMediumPayload } from "../../../helper/interface";
import { onAddEducationMedium } from "../../../service/educationMedium";
import { educationMediumValidation } from "../../../helper/validationSchema";
import AddEditForm from "./AddEditForm";

const initialValues: EducationMediumPayload = {
    nameEn: "",
    nameGu: "",
};

const AddEducationMedium = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    usePathName([
        { pathName: "Education medium", href: "/marksheet/education-medium" },
        { pathName: "Add", href: "/marksheet/education-medium/add " },
    ]);

    const onSubmit = useCallback((values: EducationMediumPayload, { resetForm }: FormikHelpers<EducationMediumPayload>) => {
        onAddEducationMedium(values, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Add new",
                primaryLink: "/marksheet/education-medium/add",
                secondaryLinkTitle: "Edit",
                secondaryLink: `/marksheet/education-medium/edit/${res.data.educationMediumId}`,
            };

            addSuccessToast(res.message, navigate, toastLink);
            resetForm();
            navigate("/marksheet/education-medium");
        });
    }, []);

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/marksheet/education-medium")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Add new education medium</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    <Formik initialValues={initialValues} validationSchema={educationMediumValidation} onSubmit={onSubmit}>
                        {(props) => <AddEditForm {...props} />}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AddEducationMedium;
