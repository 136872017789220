import { Dispatch } from "@reduxjs/toolkit";
import { ActivateDeactivateOccupation, Response, OccupationPayload, Ocupation } from "../helper/interface";
import { setLoading } from "../store/slice/TableDataSlice";
import { handleCatchResponse } from "../helper/helper";
import { AxiosError } from "axios";
import { dataService } from "../config/DataService";
import { api } from "../config/Api";
import { errorToast, successToast } from "../helper/toast";

export const onAddOccupation = async (
    payload: OccupationPayload,
    dispatch: Dispatch,
    callback: (res: Required<Response<Ocupation>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.post(api.occupation, payload);
        const res: Required<Response<Ocupation>> = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onEditOccupation = async (
    payload: OccupationPayload,
    dispatch: Dispatch,
    callback: (res: Required<Response<Ocupation>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.put(api.occupation, payload);
        const res: Required<Response<Ocupation>> = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onActivateDeactivateOccupation = async (data: ActivateDeactivateOccupation, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            isActive: data.isActive,
        };

        const response = await dataService.patch(`${api.occupation}/${data.occupationId}`, payload);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeleteOccupation = async (occupationId: number, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.occupation}/${occupationId}`);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
