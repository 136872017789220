import { Formik, FormikHelpers } from "formik";
import { FC, useCallback } from "react";
import { ArrowLeftCircle } from "react-feather";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import usePathName from "../../hooks/usePathName";
import { onAddOccupation } from "../../service/occupations";
import { OccupationPayload } from "../../helper/interface";
import { occupationValidation } from "../../helper/validationSchema";
import Form from "./Form";
import { addSuccessToast } from "../../helper/toast";

const initialValues: OccupationPayload = {
    nameEn: "",
    nameGu: "",
};

const AddOccupations: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    usePathName([
        { pathName: "Occupation", href: "/occupation" },
        { pathName: "Add", href: "/occupation/add " },
    ]);

    const onSubmit = useCallback((values: OccupationPayload, { resetForm }: FormikHelpers<OccupationPayload>) => {
        onAddOccupation(values, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Add new",
                primaryLink: "/occupation/add",
                secondaryLinkTitle: "Edit",
                secondaryLink: `/occupation/edit/${res.data.occupationId}`,
            };

            addSuccessToast(res.message, navigate, toastLink);
            resetForm();
            navigate("/occupation");
        });
    }, []);

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/occupation")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Add new occupation</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    <Formik initialValues={initialValues} validationSchema={occupationValidation} onSubmit={onSubmit}>
                        {(props) => <Form {...props} />}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AddOccupations;
