import { FC, Suspense } from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes as ReactRouter } from "react-router-dom";
import { checkAdminAuth, checkIsSuperAdmin } from "../helper/helper";

import Loading from "../container/Loading";
import Marksheet from "../components/Marksheet";

import NotFoundPage from "../components/notFound/NotFoundPage";
import Auth from "../container/Auth";
import Login from "../components/auth/Login";
import SetPassword from "../components/auth/SetPassword";
import ForgotPassword from "../components/auth/ForgotPassword";
import ChangePassword from "../components/auth/ChangePassword";
import Dashboard from "../container/Dashboard";

import SubAdmin from "../components/subAdmin/SubAdmin";
import AddSubAdmin from "../components/subAdmin/AddSubAdmin";
import EditSubAdmin from "../components/subAdmin/EditSubAdmin";

import Villages from "../components/Villages";
import AddVillages from "../components/Villages/Add";
import EditVillage from "../components/Villages/Edit";

import Families from "../components/Families";
import AddFamily from "../components/Families/Add";
import EditFamily from "../components/Families/Edit";
import FamilyDetails from "../components/Families/View";

import Members from "../components/Members";
import AddMember from "../components/Members/Add";
import EditMember from "../components/Members/Edit";

import EducationYear from "../components/Marksheet/Year";
import AddEducationYear from "../components/Marksheet/Year/Add";
import EditEducationYear from "../components/Marksheet/Year/Edit";

import EducationStandard from "../components/Marksheet/Standard";
import AddEducationStandard from "../components/Marksheet/Standard/Add";
import EditEducationStandard from "../components/Marksheet/Standard/Edit";

import EducationMedium from "../components/Marksheet/Medium";
import AddEducationMedium from "../components/Marksheet/Medium/Add";
import EditEducationMedium from "../components/Marksheet/Medium/Edit";

import EducationBoard from "../components/Marksheet/Board";
import AddEducationBoard from "../components/Marksheet/Board/Add";
import EditEducationBoard from "../components/Marksheet/Board/Edit";

import MarksheetUpload from "../components/Marksheet/Upload";
import AddMarksheetUpload from "../components/Marksheet/Upload/Add";
import EditMarksheetUpload from "../components/Marksheet/Upload/Edit";
import ViewMarksheetUpload from "../components/Marksheet/Upload/View";

import Donors from "../components/Donors";
import AddDonor from "../components/Donors/Add";
import EditDonor from "../components/Donors/Edit";

import BusinessDetails from "../components/Business";
import AddBusiness from "../components/Business/Add";
import EditBusiness from "../components/Business/Edit";
import ViewBusiness from "../components/Business/View";

import Category from "../components/Category";
import AddCategory from "../components/Category/Add";
import EditCategory from "../components/Category/Edit";

import Event from "../components/Event";
import AddEvent from "../components/Event/Add";
import EditEvent from "../components/Event/Edit";

import Occupation from "../components/Occupation";
import AddOccupation from "../components/Occupation/Add";
import EditOccupation from "../components/Occupation/Edit";

import Advertisement from "../components/Advertisement";
import AddAdvertisement from "../components/Advertisement/Add";
import EditAdvertisement from "../components/Advertisement/Edit";

import GlobalSettings from "../components/GlobalSettings/Form";

import SendNotification from "../components/Notification/Form";

import AnnualContribution from "../components/AnnualContribution";
import AddAnnualContribution from "../components/AnnualContribution/Add";
import EditAnnualContribution from "../components/AnnualContribution/Edit";

import ContributionHistory from "../components/ContributionHistory";
import AddContributionHistory from "../components/ContributionHistory/Add";
import EditContributionHistory from "../components/ContributionHistory/Edit";

import Prize from "../components/Prize";
import AddPrize from "../components/Prize/Add";
import EditPrize from "../components/Prize/Edit";

import PrizeRanks from "../components/PrizeRanks";
import AddPrizeRanks from "../components/PrizeRanks/Add";
import EditPrizeRanks from "../components/PrizeRanks/Edit";
import AdminDirectory from "../components/AdminDirectories";
import AddAdminDirectory from "../components/AdminDirectories/Add";
import EditAdminDirectory from "../components/AdminDirectories/Edit";

const ProtectedRoutes = () => {
    const isLogin = checkAdminAuth();
    return <>{isLogin ? <Outlet /> : <Navigate to={"/auth/login"} />}</>;
};

const SuperAdminRoutes = () => {
    const isSuperAdmin = checkIsSuperAdmin();
    return <>{isSuperAdmin ? <Outlet /> : <Navigate to={"/"} />}</>;
};

const loadingMarkup = (
    <div className="py-5 text-center">
        <Loading />
    </div>
);

const Routes: FC = () => {
    return (
        <BrowserRouter>
            <ReactRouter>
                <Route
                    path="auth"
                    element={
                        <Suspense fallback={loadingMarkup}>
                            <Auth />
                        </Suspense>
                    }
                >
                    <Route
                        path="login"
                        element={
                            <Suspense fallback={loadingMarkup}>
                                <Login />
                            </Suspense>
                        }
                    />
                    <Route
                        path="forgot-password"
                        element={
                            <Suspense fallback={loadingMarkup}>
                                <ForgotPassword />
                            </Suspense>
                        }
                    />
                    <Route
                        path="set-password/:verification"
                        element={
                            <Suspense fallback={loadingMarkup}>
                                <SetPassword />
                            </Suspense>
                        }
                    />
                </Route>
                <Route
                    path=""
                    element={
                        <Suspense fallback={loadingMarkup}>
                            <ProtectedRoutes />
                        </Suspense>
                    }
                >
                    <Route
                        path=""
                        element={
                            <Suspense fallback={loadingMarkup}>
                                <Dashboard />
                            </Suspense>
                        }
                    >
                        <Route path="" element={<Navigate to="families" />} />
                        <Route
                            path="sub-admin"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <SuperAdminRoutes />
                                </Suspense>
                            }
                        >
                            <Route
                                path=""
                                element={
                                    <Suspense fallback={loadingMarkup}>
                                        <SubAdmin />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="add"
                                element={
                                    <Suspense fallback={loadingMarkup}>
                                        <AddSubAdmin />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="edit/:adminId"
                                element={
                                    <Suspense fallback={loadingMarkup}>
                                        <EditSubAdmin />
                                    </Suspense>
                                }
                            />
                        </Route>
                        <Route
                            path=""
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <SuperAdminRoutes />
                                </Suspense>
                            }
                        >
                            <Route
                                path="global-settings"
                                element={
                                    <Suspense fallback={loadingMarkup}>
                                        <GlobalSettings />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="send-notification"
                                element={
                                    <Suspense fallback={loadingMarkup}>
                                        <SendNotification />
                                    </Suspense>
                                }
                            />
                        </Route>

                        <Route
                            path="ranks"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <SuperAdminRoutes />
                                </Suspense>
                            }
                        >
                            <Route
                                path=""
                                element={
                                    <Suspense fallback={loadingMarkup}>
                                        <PrizeRanks />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="add"
                                element={
                                    <Suspense fallback={loadingMarkup}>
                                        <AddPrizeRanks />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="edit/:prizeRankId"
                                element={
                                    <Suspense fallback={loadingMarkup}>
                                        <EditPrizeRanks />
                                    </Suspense>
                                }
                            />
                        </Route>

                        <Route
                            path="annual-contribution"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <SuperAdminRoutes />
                                </Suspense>
                            }
                        >
                            <Route
                                path=""
                                element={
                                    <Suspense fallback={loadingMarkup}>
                                        <AnnualContribution />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="add"
                                element={
                                    <Suspense fallback={loadingMarkup}>
                                        <AddAnnualContribution />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="edit/:annualContributionId"
                                element={
                                    <Suspense fallback={loadingMarkup}>
                                        <EditAnnualContribution />
                                    </Suspense>
                                }
                            />
                        </Route>
                        <Route
                            path="admin-directory"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <SuperAdminRoutes />
                                </Suspense>
                            }
                        >
                            <Route
                                path=""
                                element={
                                    <Suspense fallback={loadingMarkup}>
                                        <AdminDirectory />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="add"
                                element={
                                    <Suspense fallback={loadingMarkup}>
                                        <AddAdminDirectory />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="edit/:adminDirectoryId"
                                element={
                                    <Suspense fallback={loadingMarkup}>
                                        <EditAdminDirectory />
                                    </Suspense>
                                }
                            />
                        </Route>
                        <Route
                            path="occupation"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <SuperAdminRoutes />
                                </Suspense>
                            }
                        >
                            <Route
                                path=""
                                element={
                                    <Suspense fallback={loadingMarkup}>
                                        <Occupation />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="add"
                                element={
                                    <Suspense fallback={loadingMarkup}>
                                        <AddOccupation />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="edit/:occupationId"
                                element={
                                    <Suspense fallback={loadingMarkup}>
                                        <EditOccupation />
                                    </Suspense>
                                }
                            />
                        </Route>

                        <Route
                            path="villages"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Villages />
                                </Suspense>
                            }
                        />
                        <Route
                            path="contribution-history"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <ContributionHistory />
                                </Suspense>
                            }
                        />
                        <Route
                            path="contribution-history/add"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <AddContributionHistory />
                                </Suspense>
                            }
                        />
                        <Route
                            path="contribution-history/edit/:contributionHistoryId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <EditContributionHistory />
                                </Suspense>
                            }
                        />
                        <Route
                            path="villages/add"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <AddVillages />
                                </Suspense>
                            }
                        />
                        <Route
                            path="villages/edit/:villageId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <EditVillage />
                                </Suspense>
                            }
                        />
                        <Route
                            path="families"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Families />
                                </Suspense>
                            }
                        />
                        <Route
                            path="families/add"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <AddFamily />
                                </Suspense>
                            }
                        />
                        <Route
                            path="families/edit/:familyId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <EditFamily />
                                </Suspense>
                            }
                        />
                        <Route
                            path="/families/view/:familyId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <FamilyDetails />
                                </Suspense>
                            }
                        />
                        <Route
                            path="members"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Members />
                                </Suspense>
                            }
                        />
                        <Route
                            path="members/add"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <AddMember />
                                </Suspense>
                            }
                        />
                        <Route
                            path="members/edit/:memberId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <EditMember />
                                </Suspense>
                            }
                        />
                        <Route
                            path="families/:familyId/members/add"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <AddMember />
                                </Suspense>
                            }
                        />
                        <Route
                            path="/families/:familyId/members/edit/:memberId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <EditMember />
                                </Suspense>
                            }
                        />
                        <Route
                            path="marksheet/education-year"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Marksheet>
                                        <EducationYear />
                                    </Marksheet>
                                </Suspense>
                            }
                        />
                        <Route
                            path="marksheet/education-year/add"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Marksheet>
                                        <AddEducationYear />
                                    </Marksheet>
                                </Suspense>
                            }
                        />
                        <Route
                            path="marksheet/education-year/edit/:educationYearId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Marksheet>
                                        <EditEducationYear />
                                    </Marksheet>
                                </Suspense>
                            }
                        />
                        <Route
                            path="marksheet/education-standard"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Marksheet>
                                        <EducationStandard />
                                    </Marksheet>
                                </Suspense>
                            }
                        />
                        <Route
                            path="marksheet/education-standard/add"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Marksheet>
                                        <AddEducationStandard />
                                    </Marksheet>
                                </Suspense>
                            }
                        />
                        <Route
                            path="marksheet/education-standard/edit/:educationStandardId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Marksheet>
                                        <EditEducationStandard />
                                    </Marksheet>
                                </Suspense>
                            }
                        />
                        <Route
                            path="marksheet/education-board"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Marksheet>
                                        <EducationBoard />
                                    </Marksheet>
                                </Suspense>
                            }
                        />
                        <Route
                            path="marksheet/education-board/add"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Marksheet>
                                        <AddEducationBoard />
                                    </Marksheet>
                                </Suspense>
                            }
                        />
                        <Route
                            path="marksheet/education-board/edit/:educationBoardId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Marksheet>
                                        <EditEducationBoard />
                                    </Marksheet>
                                </Suspense>
                            }
                        />
                        <Route
                            path="marksheet/education-medium"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Marksheet>
                                        <EducationMedium />
                                    </Marksheet>
                                </Suspense>
                            }
                        />
                        <Route
                            path="marksheet/education-medium/add"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Marksheet>
                                        <AddEducationMedium />
                                    </Marksheet>
                                </Suspense>
                            }
                        />
                        <Route
                            path="marksheet/education-medium/edit/:educationMediumId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Marksheet>
                                        <EditEducationMedium />
                                    </Marksheet>
                                </Suspense>
                            }
                        />
                        <Route
                            path="/marksheet/marksheet-upload"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Marksheet>
                                        <MarksheetUpload />
                                    </Marksheet>
                                </Suspense>
                            }
                        />
                        <Route
                            path="/marksheet/marksheet-upload/add"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Marksheet>
                                        <AddMarksheetUpload />
                                    </Marksheet>
                                </Suspense>
                            }
                        />
                        <Route
                            path="/marksheet/marksheet-upload/edit/:markSheetId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Marksheet>
                                        <EditMarksheetUpload />
                                    </Marksheet>
                                </Suspense>
                            }
                        />
                        <Route
                            path="/marksheet/marksheet-upload/view/:markSheetId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Marksheet>
                                        <ViewMarksheetUpload />
                                    </Marksheet>
                                </Suspense>
                            }
                        />
                        <Route
                            path="change-password"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <ChangePassword />
                                </Suspense>
                            }
                        />
                        <Route
                            path="donors"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Donors />
                                </Suspense>
                            }
                        />
                        <Route
                            path="donors/add"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <AddDonor />
                                </Suspense>
                            }
                        />
                        <Route
                            path="donors/edit/:donorId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <EditDonor />
                                </Suspense>
                            }
                        />
                        <Route
                            path="business"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <BusinessDetails />
                                </Suspense>
                            }
                        />
                        <Route
                            path="business/add"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <AddBusiness />
                                </Suspense>
                            }
                        />
                        <Route
                            path="business/edit/:businessId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <EditBusiness />
                                </Suspense>
                            }
                        />
                        <Route
                            path="business/view/:businessId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <ViewBusiness />
                                </Suspense>
                            }
                        />
                        <Route
                            path="category"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Category />
                                </Suspense>
                            }
                        />
                        <Route
                            path="category/add"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <AddCategory />
                                </Suspense>
                            }
                        />
                        <Route
                            path="category/edit/:categoryId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <EditCategory />
                                </Suspense>
                            }
                        />
                        <Route
                            path="prize"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Prize />
                                </Suspense>
                            }
                        />
                        <Route
                            path="prize/add"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <AddPrize />
                                </Suspense>
                            }
                        />
                        <Route
                            path="prize/edit/:prizeId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <EditPrize />
                                </Suspense>
                            }
                        />
                        <Route
                            path="advertisement"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Advertisement />
                                </Suspense>
                            }
                        />
                        <Route
                            path="advertisement/add"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <AddAdvertisement />
                                </Suspense>
                            }
                        />
                        <Route
                            path="advertisement/edit/:advertisementId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <EditAdvertisement />
                                </Suspense>
                            }
                        />
                        <Route
                            path="events"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <Event />
                                </Suspense>
                            }
                        />
                        <Route
                            path="events/add"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <AddEvent />
                                </Suspense>
                            }
                        />
                        <Route
                            path="events/edit/:eventId"
                            element={
                                <Suspense fallback={loadingMarkup}>
                                    <EditEvent />
                                </Suspense>
                            }
                        />
                    </Route>
                </Route>
                <Route
                    path="*"
                    element={
                        <Suspense fallback={loadingMarkup}>
                            <NotFoundPage />
                        </Suspense>
                    }
                />
            </ReactRouter>
        </BrowserRouter>
    );
};

export default Routes;
