import { FC, useEffect } from "react";
import {
    Home,
    Users,
    Cast,
    Globe,
    UserPlus,
    Layout,
    Archive,
    Briefcase,
    AlignJustify,
    Calendar,
    FileText,
    Award,
    Bold,
    BarChart2,
    User,
    Bell,
    BookOpen,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { StateValue } from "../../helper/interface";
import { ADMIN_ROLE, ZERO } from "../../helper/constant";
import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import { setPendingBusinessCount } from "../../store/slice/BaseSlice";

const Menu: FC = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const pendingBusinessCount = useSelector((state: StateValue) => state.base.pendingBusinessCount);
    const role = useSelector((state: StateValue) => state.base.role);

    const { res } = useFetch<number>(api.getCountOfVerificationPendingBusinesses);

    useEffect(() => {
        dispatch(setPendingBusinessCount(res?.data || ZERO));
    }, [res]);

    return (
        <>
            <nav className="side-nav">
                <ul>
                    <li>
                        <Link to="villages" className={`side-menu ${pathname.includes("villages") && "side-menu--active"} `}>
                            <div className="side-menu__icon">
                                <Home />
                            </div>
                            <div className="side-menu__title">Villages</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="families" className={`side-menu  ${pathname.includes("families") && "side-menu--active"} `}>
                            <div className="side-menu__icon">
                                <User />
                            </div>
                            <div className="side-menu__title">Families</div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="members"
                            className={`side-menu  ${
                                pathname.includes("members") && !pathname.includes("families") && "side-menu--active"
                            } `}
                        >
                            <div className="side-menu__icon">
                                <Users />
                            </div>
                            <div className="side-menu__title">Members</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="category" className={`side-menu  ${pathname.includes("category") && "side-menu--active"} `}>
                            <div className="side-menu__icon">
                                <AlignJustify />
                            </div>
                            <div className="side-menu__title">Categories</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="business" className={`side-menu  ${pathname.includes("business") && "side-menu--active"} `}>
                            <div className="side-menu__icon">
                                <Bold />
                            </div>
                            <div className="side-menu__title">
                                Business{" "}
                                {pendingBusinessCount > ZERO ? (
                                    <span className="ml-2 bg-red-500 text-white text-xs font-bold rounded-full px-2 py-1">
                                        {pendingBusinessCount}
                                    </span>
                                ) : null}
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="marksheet/education-year"
                            className={`side-menu  ${pathname.includes("marksheet") && "side-menu--active"} `}
                        >
                            <div className="side-menu__icon">
                                <Layout />
                            </div>
                            <div className="side-menu__title">Education</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="prize" className={`side-menu  ${pathname.includes("prize") && "side-menu--active"} `}>
                            <div className="side-menu__icon">
                                <Award />
                            </div>
                            <div className="side-menu__title">Prize</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="donors" className={`side-menu  ${pathname.includes("donors") && "side-menu--active"} `}>
                            <div className="side-menu__icon">
                                <Archive />
                            </div>
                            <div className="side-menu__title">Donors</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="advertisement" className={`side-menu  ${pathname.includes("advertisement") && "side-menu--active"} `}>
                            <div className="side-menu__icon">
                                <Cast />
                            </div>
                            <div className="side-menu__title">Advertisement</div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="contribution-history"
                            className={`side-menu  ${pathname.includes("contribution-history") && "side-menu--active"} `}
                        >
                            <div className="side-menu__icon">
                                <FileText />
                            </div>
                            <div className="side-menu__title">Contribution History</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="events" className={`side-menu  ${pathname.includes("events") && "side-menu--active"} `}>
                            <div className="side-menu__icon">
                                <Calendar />
                            </div>
                            <div className="side-menu__title">Events</div>
                        </Link>
                    </li>
                    {role && role === ADMIN_ROLE.superAdmin ? (
                        <>
                            <li>
                                <Link to="occupation" className={`side-menu  ${pathname.includes("occupation") && "side-menu--active"} `}>
                                    <div className="side-menu__icon">
                                        <Briefcase />
                                    </div>
                                    <div className="side-menu__title">Occupation</div>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="annual-contribution"
                                    className={`side-menu  ${pathname.includes("annual-contribution") && "side-menu--active"} `}
                                >
                                    <div className="side-menu__icon text-3xl font-light w-[30px] text-center">&#8377;</div>
                                    <div className="side-menu__title">Annual Contribution</div>
                                </Link>
                            </li>
                            <li>
                                <Link to="ranks" className={`side-menu  ${pathname.includes("ranks") && "side-menu--active"} `}>
                                    <div className="side-menu__icon">
                                        <BarChart2 />
                                    </div>
                                    <div className="side-menu__title">Prize Rank</div>
                                </Link>
                            </li>
                            <li>
                                <Link to="sub-admin" className={`side-menu  ${pathname.includes("sub-admin") && "side-menu--active"} `}>
                                    <div className="side-menu__icon">
                                        <UserPlus />
                                    </div>
                                    <div className="side-menu__title">Sub Admins</div>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="send-notification"
                                    className={`side-menu  ${pathname.includes("send-notification") && "side-menu--active"} `}
                                >
                                    <div className="side-menu__icon">
                                        <Bell />
                                    </div>
                                    <div className="side-menu__title">Send Notification</div>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="admin-directory"
                                    className={`side-menu  ${pathname.includes("admin-directory") && "side-menu--active"} `}
                                >
                                    <div className="side-menu__icon">
                                        <BookOpen />
                                    </div>
                                    <div className="side-menu__title">Admin Directory</div>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="global-settings"
                                    className={`side-menu  ${pathname.includes("global-settings") && "side-menu--active"} `}
                                >
                                    <div className="side-menu__icon">
                                        <Globe />
                                    </div>
                                    <div className="side-menu__title">Global Settings</div>
                                </Link>
                            </li>
                        </>
                    ) : null}
                </ul>
            </nav>
        </>
    );
};

export default Menu;
