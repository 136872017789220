import { useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import { ArrowLeftCircle } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import usePathName from "../../hooks/usePathName";
import AddEditForm from "./Form";
import { addSuccessToast } from "../../helper/toast";
import { AdvertisementPayload, BEAddAdvertisementPayload } from "../../helper/interface";
import { addEditAdvertisementValidation } from "../../helper/validationSchema";
import { onAddAdvertisement } from "../../service/advertisement";

const initialValues: AdvertisementPayload = {
    mobile: "",
    email: "",
    titleEn: "",
    titleGu: "",
    descriptionEn: "",
    descriptionGu: "",
    image: null,
};

const AddAdvertisement = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    usePathName([
        { pathName: "Advertisement", href: "/advertisement" },
        { pathName: "Add", href: "/advertisement/add" },
    ]);

    const onSubmit = useCallback((values: AdvertisementPayload, { resetForm }: FormikHelpers<AdvertisementPayload>) => {
        const payload: BEAddAdvertisementPayload = {
            ...values,
            email: values?.email || null,
        };

        onAddAdvertisement(payload, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Add new",
                primaryLink: "/advertisement/add",
                secondaryLinkTitle: "Edit",
                secondaryLink: `/advertisement/edit/${res.data.advertisementId}`,
            };

            addSuccessToast(res.message, navigate, toastLink);
            resetForm();
            navigate("/advertisement");
        });
    }, []);
    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/advertisement")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Add new advertisement</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    <Formik initialValues={initialValues} validationSchema={addEditAdvertisementValidation} onSubmit={onSubmit}>
                        {(props) => <AddEditForm {...props} />}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AddAdvertisement;
