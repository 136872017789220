import { useCallback, useEffect, useState } from "react";
import { Form } from "formik";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { Listbox, Transition } from "@headlessui/react";
import { v4 as uuidv4 } from "uuid";
import {
    Family,
    FormParams,
    MarksheetBoard,
    MarksheetMedium,
    MarksheetMember,
    MarksheetPayload,
    MarksheetStandard,
    VerificationStatus,
    MarksheetYear,
    PreviewFile,
    TableStateValue,
    Member,
    IncludeApiPayload,
} from "../../../helper/interface";
import useFetch from "../../../hooks/useFetch";
import { api } from "../../../config/Api";
import PDFLogo from "../../../assets/images/PDFLogo.png";

import { FIVE, HUNDRED, MARKS_TYPE, ONE } from "../../../helper/constant";
import { errorToast } from "../../../helper/toast";
import Loading from "../../../container/Loading";
import { serializeQueryParams } from "../../../helper/helper";

const MarksheetUploadForm = ({
    values,
    errors,
    dirty,
    touched,
    handleChange,
    setFieldValue,
    setFieldTouched,
    handleBlur,
}: FormParams<MarksheetPayload>) => {
    const loading = useSelector((state: TableStateValue) => state.table.loading);

    const [memberList, setMemberList] = useState<Member[]>([]);

    const payloadForAPI: IncludeApiPayload = {
        listFor: "markSheet",
        fieldName: "primaryMemberName",
        order: "ASC",
    };

    if (values.memberId) {
        payloadForAPI.includeMember = values.memberId;
    }

    const yearPayload: IncludeApiPayload = {
        listFor: "markSheet",
    };

    if (values.educationYearId) {
        yearPayload.includeYear = values.educationYearId;
    }

    const { res: families } = useFetch<Family[]>(`${api.family}?${serializeQueryParams(payloadForAPI)}`);

    const { res: board } = useFetch<MarksheetBoard[]>(api.marksheet.educationBoard);
    const { res: year } = useFetch<MarksheetYear[]>(`${api.marksheet.educationYear}?${serializeQueryParams(yearPayload)}`);
    const { res: standard } = useFetch<MarksheetStandard[]>(api.marksheet.educationStandard);
    const { res: medium } = useFetch<MarksheetMedium[]>(api.marksheet.educationMedium);

    useEffect(() => {
        if (!values?.familyId && families && Array.isArray(families.data) && values?.member?.memberId) {
            families.data.forEach((ele) => {
                if (ele.familyId === values.member?.familyId) {
                    if (ele?.members) setMemberList(ele.members);

                    setFieldValue("familyId", ele.familyId);
                }
            });
        }
    }, [families]);

    const onChangeMemberId = useCallback((value: number) => {
        setFieldValue("memberId", value);
    }, []);
    const onChangeEducationYearId = useCallback((value: number) => {
        setFieldValue("educationYearId", value);
    }, []);
    const onChangeEducationBoardId = useCallback((value: number) => {
        setFieldValue("educationBoardId", value);
    }, []);
    const onChangeEducationStandardId = useCallback((value: number) => {
        setFieldValue("educationStandardId", value);
    }, []);
    const onChangeEducationMediumId = useCallback((value: number) => {
        setFieldValue("educationMediumId", value);
    }, []);
    const onChangeMarksType = useCallback((value: string) => {
        setFieldValue("marksType", value);
    }, []);

    const onChangeFamily = useCallback(
        (value: number) => {
            setFieldValue("memberId", null);
            families?.data?.forEach((ele) => {
                if (ele.familyId === value && ele.members) {
                    setMemberList(ele.members);
                }
            });

            setFieldValue("familyId", value);
        },
        [families]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (values.images.length >= FIVE) return errorToast(`Maximum ${FIVE} files are allowed`);

            setFieldValue("images", [
                ...values.images,
                ...acceptedFiles.map((file) =>
                    Object.assign(file, {
                        markSheetImageId: uuidv4(),
                        preview: URL.createObjectURL(file),
                        isNew: true,
                    })
                ),
            ]);
        },
        accept: {
            "application/pdf": [".pdf"],
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
        },
    });

    const removeFile = useCallback(
        (file: PreviewFile) => {
            const oldFiles = [...values.images];

            const index = oldFiles.indexOf(file);

            if (file?.markSheetImageId && file?.isNew !== true) {
                const deletedImages = values.deletedImages ? [...values.deletedImages] : [];
                deletedImages.push(file.markSheetImageId);
                setFieldValue("deletedImages", deletedImages);

                oldFiles.splice(index, ONE);
            } else {
                oldFiles.splice(index, ONE);
            }

            setFieldValue("images", oldFiles);
        },
        [values.images]
    );

    return (
        <>
            <Form noValidate>
                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                    <div className="intro-y col-span-12 flex items-center justify-center">
                        <div className="intro-y box lg:w-[60%] md:w-[100%] sm:w-[100%]">
                            <div id="vertical-form" className="p-5">
                                <div className="preview">
                                    {/* Marks */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="images" className="form-label">
                                            Upload Marksheet
                                        </label>
                                        <div
                                            className="dropzone fallback dz-message flex items-center justify-center w-full"
                                            data-dz-message
                                        >
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                            </div>
                                        </div>
                                        <ul className="render-upload-list flex flex-wrap">
                                            {values.images.map((file) => (
                                                <li
                                                    key={file.markSheetImageId}
                                                    className="upload-thumb rounded-md after:block after:absolute after:w-full after:h-full after:top-0 after:left-0 after:z-10 relative m-1"
                                                >
                                                    {file.url ? (
                                                        <img src={file.url} className="w-20 h-20 object-cover object-center rounded-md" />
                                                    ) : (
                                                        file.preview && (
                                                            <img
                                                                src={file.preview}
                                                                onLoad={() => {
                                                                    URL.revokeObjectURL(file.preview);
                                                                }}
                                                                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                                                                    ((e.target as HTMLImageElement).src = PDFLogo)
                                                                }
                                                                className="w-20 h-20 object-cover object-center rounded-md"
                                                            />
                                                        )
                                                    )}
                                                    <div role="button" className="remove" onClick={() => removeFile(file)}>
                                                        <span className="cursor-pointer">x</span>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        {typeof errors.images === "string" && touched.images && (
                                            <div className="text-danger">{errors.images}</div>
                                        )}
                                    </div>
                                    {/* Education Year */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Education Year</label>
                                        <div
                                            className={`tom-select form-control ${
                                                errors.educationYearId && touched.educationYearId && "dd-border-danger"
                                            }`}
                                        >
                                            <Listbox
                                                value={values.educationYearId}
                                                name="educationYearId"
                                                onChange={onChangeEducationYearId}
                                            >
                                                <Listbox.Button id="educationYearId" className={"ts-input"}>
                                                    <div className="item">
                                                        {year?.data?.map((v) => v?.educationYearId === values?.educationYearId && v?.year)}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("educationYearId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {year?.data?.map((year: MarksheetYear) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || year.educationYearId === values.educationYearId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={year.educationYearId}
                                                                        key={year.educationYearId}
                                                                    >
                                                                        {year.year}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.educationYearId && touched.educationYearId && (
                                            <div className="text-danger">{errors.educationYearId}</div>
                                        )}
                                    </div>
                                    {/* Primary Member Name */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Primary Member Name</label>
                                        <div className={`tom-select ${errors.familyId && touched.familyId && "dd-border-danger"}`}>
                                            <Listbox value={values?.familyId ?? null} name="familyId" onChange={onChangeFamily}>
                                                <Listbox.Button id="familyId" className={"ts-input"}>
                                                    <div className="item">
                                                        {families?.data?.map(
                                                            (v) => v?.familyId === values?.familyId && v?.primaryMember?.fullNameEn
                                                        ) ?? ""}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("familyId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {families?.data?.map((ele: Family) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || ele?.familyId === values?.familyId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={ele.familyId}
                                                                        key={ele?.familyId}
                                                                    >
                                                                        {ele?.primaryMember?.fullNameEn}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.familyId && touched.familyId && <div className="text-danger">{errors.familyId}</div>}
                                    </div>
                                    {/* Member */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Member</label>
                                        <div
                                            className={`tom-select form-control ${
                                                errors.memberId && touched.memberId && "dd-border-danger"
                                            }`}
                                        >
                                            <Listbox value={values.memberId} name="memberId" onChange={onChangeMemberId}>
                                                <Listbox.Button id="memberId" className={"ts-input"}>
                                                    <div className="item">
                                                        {memberList?.map((v) => v?.memberId === values?.memberId && v?.fullNameEn)}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("memberId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {memberList?.map((mber: MarksheetMember) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || mber.memberId === values.memberId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={mber.memberId}
                                                                        key={mber.memberId}
                                                                    >
                                                                        {mber.fullNameEn}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.memberId && touched.memberId && <div className="text-danger">{errors.memberId}</div>}
                                    </div>
                                    {/* Education Standard */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Education Standard</label>
                                        <div
                                            className={`tom-select form-control ${
                                                errors.educationStandardId && touched.educationStandardId && "dd-border-danger"
                                            }`}
                                        >
                                            <Listbox
                                                value={values.educationStandardId}
                                                name="educationStandardId"
                                                onChange={onChangeEducationStandardId}
                                            >
                                                <Listbox.Button id="educationStandardId" className={"ts-input"}>
                                                    <div className="item">
                                                        {standard?.data?.map(
                                                            (v) => v?.educationStandardId === values?.educationStandardId && v?.name
                                                        )}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("educationStandardId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {standard?.data?.map((std: MarksheetStandard) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active ||
                                                                                std.educationStandardId === values.educationStandardId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={std.educationStandardId}
                                                                        key={std.educationStandardId}
                                                                    >
                                                                        {std.name}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.educationStandardId && touched.educationStandardId && (
                                            <div className="text-danger">{errors.educationStandardId}</div>
                                        )}
                                    </div>
                                    {/* Education Board */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Education Board Name</label>
                                        <div
                                            className={`tom-select form-control ${
                                                errors.educationBoardId && touched.educationBoardId && "dd-border-danger"
                                            }`}
                                        >
                                            <Listbox
                                                value={values.educationBoardId}
                                                name="educationBoardId"
                                                onChange={onChangeEducationBoardId}
                                            >
                                                <Listbox.Button id="educationBoardId" className={"ts-input"}>
                                                    <div className="item">
                                                        {board?.data?.map(
                                                            (v) => v?.educationBoardId === values?.educationBoardId && v?.nameEn
                                                        )}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("educationBoardId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {board?.data?.map((board: MarksheetBoard) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || board.educationBoardId === values.educationBoardId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={board.educationBoardId}
                                                                        key={board.educationBoardId}
                                                                    >
                                                                        {board.nameEn}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.educationBoardId && touched.educationBoardId && (
                                            <div className="text-danger">{errors.educationBoardId}</div>
                                        )}
                                    </div>

                                    {/* Education Medium */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Education Medium</label>
                                        <div
                                            className={`tom-select form-control ${
                                                errors.educationMediumId && touched.educationMediumId && "dd-border-danger"
                                            }`}
                                        >
                                            <Listbox
                                                value={values.educationMediumId}
                                                name="educationMediumId"
                                                onChange={onChangeEducationMediumId}
                                            >
                                                <Listbox.Button id="educationMediumId" className={"ts-input"}>
                                                    <div className="item">
                                                        {medium?.data?.map(
                                                            (v) => v?.educationMediumId === values?.educationMediumId && v?.nameEn
                                                        )}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("educationMediumId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {medium?.data?.map((med: MarksheetMedium) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || med.educationMediumId === values.educationMediumId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={med.educationMediumId}
                                                                        key={med.educationMediumId}
                                                                    >
                                                                        {med.nameEn}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.educationMediumId && touched.educationMediumId && (
                                            <div className="text-danger">{errors.educationMediumId}</div>
                                        )}
                                    </div>

                                    {/* Marks Type */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Marks Type</label>
                                        <div className={`tom-select ${errors.marksType && touched.marksType && "dd-border-danger"}`}>
                                            <Listbox value={values.marksType} name="marksType" onChange={onChangeMarksType}>
                                                <Listbox.Button id="marksType" className={"ts-input"}>
                                                    <div className="item">{values?.marksType}</div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("marksType", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {MARKS_TYPE.map((mt: string) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || mt === values.marksType ? "optionColor" : null
                                                                            }`
                                                                        }
                                                                        value={mt}
                                                                        key={mt}
                                                                    >
                                                                        {mt}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.marksType && touched.marksType && <div className="text-danger">{errors.marksType}</div>}
                                    </div>

                                    {/* Marks */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="marks" className="form-label">
                                            Marks
                                        </label>
                                        <input
                                            id="marks"
                                            name="marks"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.marks}
                                            onBlur={(e) => setFieldValue("marks", e.target.value)}
                                            className={`form-control ${errors.marks && touched.marks && "border-danger"}`}
                                            max={HUNDRED}
                                            min={ONE}
                                        />
                                        {errors.marks && touched.marks && <div className="text-danger">{errors.marks}</div>}
                                    </div>

                                    {/* Marksheet verified */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label>Verification</label>
                                        <div className="flex flex-col sm:flex-row mt-2">
                                            <div className="form-check mr-2">
                                                <input
                                                    id="isVerifiedPending"
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="verified"
                                                    checked={values.verified === VerificationStatus.PENDING}
                                                    onChange={() => setFieldValue("verified", VerificationStatus.PENDING)}
                                                />
                                                <label className="form-check-label" htmlFor="isVerifiedPending">
                                                    {VerificationStatus.PENDING}
                                                </label>
                                            </div>
                                            <div className="form-check mr-2 mt-2 sm:mt-0">
                                                <input
                                                    id="isVerifiedApproved"
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="verified"
                                                    checked={values.verified === VerificationStatus.APPROVED}
                                                    onChange={() => setFieldValue("verified", VerificationStatus.APPROVED)}
                                                />
                                                <label className="form-check-label" htmlFor="isVerifiedApproved">
                                                    {VerificationStatus.APPROVED}
                                                </label>
                                            </div>
                                            <div className="form-check mr-2 mt-2 sm:mt-0">
                                                <input
                                                    id="isVerifiedRejected"
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="verified"
                                                    checked={values.verified === VerificationStatus.REJECTED}
                                                    onChange={() => setFieldValue("verified", VerificationStatus.REJECTED)}
                                                />
                                                <label className="form-check-label" htmlFor="isVerifiedRejected">
                                                    {VerificationStatus.REJECTED}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Reason */}
                                    {values.verified === VerificationStatus.REJECTED ? (
                                        <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                            <label htmlFor="reason" className="form-label">
                                                Reason
                                            </label>
                                            <textarea
                                                id="reason"
                                                name="reason"
                                                value={values?.reason ?? ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={`form-control ${errors.reason && touched.reason && "border-danger"}`}
                                            />
                                            {errors.reason && touched.reason && <div className="text-danger">{errors.reason}</div>}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                        <button className="btn btn-secondary w-24" type="reset" disabled={loading || !dirty}>
                            Reset
                        </button>
                        <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                            {loading ? <Loading isButton /> : "Save"}
                        </button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default MarksheetUploadForm;
