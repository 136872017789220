export const dataGu = <const>{
    villageDetails: "ગામની વિગતો",
    villageName: "ગામનું નામ",
    writeVillageName: "ગામનું નામ લખો",
    subDistrictName: "તાલુકાનું નામ",
    writeSubDistrictName: "તાલુકાનું નામ લખો",
    districtName: "જિલ્લાનું નામ",
    writeDistrictName: "જિલ્લાનું નામ લખો",
    primaryMemberName: "મુખ્ય વ્યક્તિનું નામ",
    contactNumber: "મોબાઈલ નંબર",
    address: "સરનામું",
    gender: "જાતિ",
    writePrimaryMember: "મુખ્ય વ્યક્તિનું નામ લખો",
    writeAddress: "સરનામું લખો",
    memberName: "વ્યક્તિનું નામ",
    occupation: "વ્યવસાય",
    familyDetails: "પરિવારની વિગતો",
    medium: "માધ્યમ",
    writeMedium: "માધ્યમ લખો",
    writeBoard: "શિક્ષણ બોર્ડ દાખલ કરો",
    educationBoard: "શિક્ષણ બોર્ડ",
    writeEducationBoard: "શિક્ષણ બોર્ડનું નામ લખો",
    description: "વિગત",
    writeDescription: "વિગત લખો",
    donorDescription: "દાતાની વિગતો",
    categoryName: "કેટેગરીનું નામ",
    writeCategoryName: "કેટેગરીનું નામ લખો",
    writeOccupationName: "વ્યવસાયનું નામ લખો",
    writeAdvertisementDescription: "કૃપા કરીને વર્ણન દાખલ કરો",
    advertisementTitlePlaceholder: "શીર્ષક લખો",
    advertisementDescriptionPlaceholder: "વર્ણન દાખલ કરો",
    advertisementTitle: "શીર્ષક",
    writeAdvertisementTitle: "કૃપા કરીને શીર્ષક લખો",
    advertisementDescription: "વર્ણન",
    businessDetails: "બિઝનેસની વિગતો",
    eventTitle: "ઇવેન્ટની વિગતો",
    eventName: "ઇવેન્ટનું નામ",
    location: "સ્થળ",
    writeEventName: "ઇવેન્ટનું નામ લખો",
    writeEventLocation: "ઇવેન્ટનું સ્થળ લખો",
    occupationName: "વ્યવસાયનું નામ લખો",
    memberDetails: "સભ્યની વિગતો",
    advertisement: "જાહેરાત",
    advertisementFormHeading: "જાહેરાતની વિગતો",
    prizeRank: "ઇનામ રેન્ક",
    fieldRequired: "કૃપા કરીને આ ફીલ્ડ ભરો",
    writePrizeRank: "કૃપા કરીને ઇનામ રેન્ક દાખલ કરો",
    title: "શીર્ષક",
    describe: "વર્ણન",
    aboutUsTooltip: "'અમારા વિશે' પૃષ્ઠનું વર્ણન",
    gujarati: "ગુજરાતી",
};
