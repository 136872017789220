import { useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import { useDispatch } from "react-redux";
import { ArrowLeftCircle } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";

import usePathName from "../../hooks/usePathName";
import { MemberPayload } from "../../helper/interface";
import { memberValidation } from "../../helper/validationSchema";
import { onAddMember } from "../../service/members";
import AddEditForm from "./AddEditForm";
import { addSuccessToast } from "../../helper/toast";

const AddMember = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();
    const familyIdFromLocation = location?.state?.familyId;

    usePathName([
        { pathName: "Members", href: `${familyIdFromLocation ? "/families/" + familyIdFromLocation : "/members"} ` },
        { pathName: "Add", href: `${familyIdFromLocation ? "/families/" + familyIdFromLocation + "/members/add" : ""}` },
    ]);

    const initialValues: MemberPayload = {
        fullNameEn: "",
        fullNameGu: "",
        mobile: "",
        email: "",
        familyId: familyIdFromLocation ?? null,
        gender: "",
        dob: "",
        bloodGroup: "",
        occupationId: null,
    };

    const onSubmit = useCallback((values: MemberPayload, { resetForm }: FormikHelpers<MemberPayload>) => {
        onAddMember(values, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Add new",
                primaryLink: `${familyIdFromLocation ? "/families/" + res?.data?.familyId + "/members/add" : "/members/add"}`,
                secondaryLinkTitle: "Edit",
                secondaryLink: `${
                    familyIdFromLocation
                        ? "/families/" + res?.data?.familyId + "/members/edit/" + res.data.memberId
                        : "/members/edit/" + res.data.memberId
                }`,
            };

            addSuccessToast(res.message, navigate, toastLink);
            resetForm();
            navigate(`${familyIdFromLocation ? "/families/view/" + res?.data?.familyId : "/members"}`);
        });
    }, []);

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div
                        className="flex-2 ml-4"
                        onClick={() => navigate(`${familyIdFromLocation ? "/families/view/" + location?.state?.familyId : "/members"}`)}
                    >
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Add new member</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    <Formik initialValues={initialValues} validationSchema={memberValidation} onSubmit={onSubmit}>
                        {(props) => <AddEditForm {...props} />}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AddMember;
