import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { api } from "../config/Api";
import { SendNotificationPayload } from "../helper/interface";
import { setLoading } from "../store/slice/TableDataSlice";
import { dataService } from "../config/DataService";
import { handleCatchResponse } from "../helper/helper";
import { errorToast, successToast } from "../helper/toast";

export const onEditSendNotification = async (data: Required<SendNotificationPayload>, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.post(api.notifyUsers, data);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
