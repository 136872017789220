import { Dispatch } from "@reduxjs/toolkit";
import { setLoading } from "../store/slice/TableDataSlice";
import { handleCatchResponse } from "../helper/helper";
import { AxiosError } from "axios";
import { dataService } from "../config/DataService";
import { api } from "../config/Api";
import { errorToast, successToast } from "../helper/toast";
import { Response, PrizeRanksPayload, PrizeRanks, ActivateDeactivatePrizeRanks } from "../helper/interface";

export const onAddPrizeRanks = async (
    payload: PrizeRanksPayload,
    dispatch: Dispatch,
    callback: (res: Required<Response<PrizeRanks>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.post(api.prizeRanks, payload);
        const res = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onEditPrizeRanks = async (
    payload: PrizeRanksPayload,
    dispatch: Dispatch,
    callback: (res: Required<Response<PrizeRanks>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.put(api.prizeRanks, payload);
        const res = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onActivateDeactivatePrizeRanks = async (data: ActivateDeactivatePrizeRanks, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            isActive: data.isActive,
        };

        const response = await dataService.patch(`${api.prizeRanks}/${data.prizeRankId}`, payload);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeletePrizeRanks = async (prizeRankId: number, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.prizeRanks}/${prizeRankId}`);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
