import { useCallback } from "react";
import { Form } from "formik";
import { Listbox, Transition } from "@headlessui/react";
import Select, { MultiValue } from "react-select";
import { useDispatch, useSelector } from "react-redux";

import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import { setTableData } from "../../store/slice/TableDataSlice";
import { ContributionHistorySearchBy, ContributionHistorySearchData } from "../../helper/constant";
import { TableStateValue, FilterForFamilies, FamilyForSelect, AnnualContributionForSelect } from "../../helper/interface";

const Filter = ({ values, setFieldValue, setFieldTouched, resetForm, reload }: FilterForFamilies) => {
    const dispatch = useDispatch();

    const tableOffsetData = useSelector((state: TableStateValue) => state.table.tableData);

    const { res: families } = useFetch<FamilyForSelect[]>(api.family);
    const { res: annualContribution } = useFetch<AnnualContributionForSelect[]>(api.annualContribution);

    const handleOptionChange = (value: string) => {
        setFieldValue("searchBy", value);
        setFieldValue("value", null);
    };

    const handleFamilyChange = useCallback((selectedItem: MultiValue<FamilyForSelect>) => {
        const familyIds = selectedItem?.map((ele) => ele.familyId).join(",");
        setFieldValue("value", familyIds);
    }, []);

    const handleYearChange = useCallback((selectedItem: MultiValue<AnnualContributionForSelect>) => {
        const yearIds = selectedItem?.map((ele) => ele.annualContributionId).join(",");
        setFieldValue("value", yearIds);
    }, []);

    const handleReset = useCallback(() => {
        dispatch(
            setTableData({
                page: 1,
                limit: 10,
                maxPage: 1,
                search: null,
                orderBy: null,
                tableFor: "contributionHistory",
                metaFilter: {
                    searchBy: "Year",
                },
            })
        );
        reload(`${api.contributionHistory}?page=1&limit=10`);
        resetForm();
    }, [tableOffsetData]);

    const splitIds = values?.value?.split(",");

    return (
        <>
            <Form id="tabulator-html-filter-form" className="flex items-center job-pending-style align-items-baseline">
                <div className="flex items-center sm:mr-4 py-1 job-filter-form z-50">
                    <label className="flex-none mr-2 mb-0">Select filter</label>
                    <div className="tom-select">
                        <Listbox value={values.searchBy} onChange={handleOptionChange}>
                            <Listbox.Button className="form-control ts-input">
                                <div className="item">{values?.searchBy ?? "Select filter type"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("searchBy", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {ContributionHistorySearchData.map((fltr: string, i: number) => {
                                            return (
                                                <Listbox.Option
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                            active || fltr === values.searchBy ? "optionColor" : null
                                                        }`
                                                    }
                                                    key={i}
                                                    value={fltr}
                                                >
                                                    {fltr}
                                                </Listbox.Option>
                                            );
                                        })}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                    </div>
                </div>
                {values.searchBy === ContributionHistorySearchBy.FAMILY && families?.data ? (
                    <div className="sm:flex flex items-center sm:mr-4">
                        <label className="form-label">Family</label>
                        <div>
                            <Select
                                isMulti
                                name="families"
                                options={families?.data}
                                value={families?.data?.filter((e) => splitIds?.includes(String(e.familyId)))}
                                onChange={(selectedItem) => handleFamilyChange(selectedItem)}
                                classNamePrefix="select"
                                className={"form-control job-filter-style"}
                                getOptionLabel={(option) => option.primaryMember.fullNameEn}
                                getOptionValue={(option) => option.familyId}
                            />
                        </div>
                    </div>
                ) : null}
                {values.searchBy === ContributionHistorySearchBy.YEAR && annualContribution?.data ? (
                    <div className="flex items-center sm:mr-4">
                        <label className="form-label mr-2 mt-2">Year</label>
                        <div>
                            <Select
                                isMulti
                                name="year"
                                options={annualContribution?.data}
                                value={annualContribution?.data?.filter((e) => splitIds?.includes(String(e.annualContributionId)))}
                                onChange={(selectedItem) => handleYearChange(selectedItem)}
                                classNamePrefix="select"
                                className={"form-control job-filter-style py-1"}
                                getOptionLabel={(option) => option.year}
                                getOptionValue={(option) => option.annualContributionId}
                            />
                        </div>
                    </div>
                ) : null}

                <div className="flex mt-2 md:mt-0">
                    <button id="tabulator-html-filter-go" type="submit" className="btn btn-primary w-16" disabled={Boolean(!values.value)}>
                        Go
                    </button>
                    <button id="tabulator-html-filter-reset" type="button" className="btn btn-secondary w-16 ml-2" onClick={handleReset}>
                        Reset
                    </button>
                </div>
            </Form>
        </>
    );
};

export default Filter;
