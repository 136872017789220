import { FormikErrors, FormikHandlers, FormikProps, FormikTouched } from "formik";
import { ADMIN_ROLE, MARKS_TYPE_VALUE } from "./constant";

export interface Option {
    readonly label: string;
    readonly value: string;
}

export enum DOCUMENT_TITLE {
    SUPER_ADMIN = "Gondaliya Parivar - Super Admin",
    ADMIN = "Gondaliya Parivar - Admin",
}

export interface Response<DataInterface> {
    status: number;
    message: string;
    data?: DataInterface;
    metaData?: MetaData;
    success: boolean;
}

export interface MetaData {
    total: number;
    page: number;
}

export interface AdvertisementPayload {
    advertisementId?: number;
    image?: File | string | null;
    titleEn: string;
    titleGu: string;
    descriptionEn: string;
    descriptionGu: string;
    email: string;
    mobile: string;
}

export interface Advertisement extends AdvertisementPayload {
    advertisementId: number;
    isActive?: boolean;
}

export interface BEAddAdvertisementPayload {
    mobile: string;
    email: string | null;
    titleEn: string;
    titleGu: string;
    descriptionEn: string;
    descriptionGu: string;
    image?: File | string | null;
}

export interface BEEditAdvertisementPayload {
    advertisementId: number;
    mobile: string;
    email: string | null;
    titleEn: string;
    titleGu: string;
    descriptionEn: string;
    descriptionGu: string;
    image?: File | string | null;
}
export interface LoadingParams {
    forTable?: boolean;
    isButton?: boolean;
}

export interface AnnualContributionPayload {
    annualContributionId?: number;
    year: number;
    amount: number;
}

export interface ContributionHistoryPayload {
    contributionHistoryId?: number;
    annualContributionId?: number;
    paidDate: Date;
    familyId?: number | null;
    annualContribution?: AnnualContributionPayload | null;
    paidAmount: number;
}

export interface ContributionHistoryValue extends ContributionHistoryPayload {
    annualContribution: AnnualContributionPayload;
    family: Family;
    contributionHistoryId: number;
}

export interface IncludeApiPayload {
    listFor?: string;
    includeFamily?: number;
    includeMember?: number;
    includeYear?: number;
    fieldName?: string;
    order?: string;
}

export interface StateValue {
    base: BaseSliceValue;
}

export interface GlobalSettingsPayload {
    markSheetImageLimit: number;
    businessTagLimit: number;
    businessLimitForUser: number;
    contactUsEmail: string;
    deactivationEmail: string;
    aboutEn: string;
    aboutGu: string;
}

export interface SendNotificationPayload {
    titleEn: string;
    titleGu: string;
    descriptionEn: string;
    descriptionGu: string;
}

export interface BaseSliceValue {
    pagePath: PathValue[];
    token: string | null;
    role: ADMIN_ROLE | null;
    pendingBusinessCount: number;
}

export interface TableStateValue {
    table: TableSliceValue;
}

export interface TableSliceValue {
    tableData: TableDataValue;
    loading: boolean;
}

export interface TableDataValue {
    page: number;
    limit: number;
    maxPage: number;
    search: string | null;
    orderBy: OrderPayload | null;
    tableFor: string | null;
    metaFilter?: MetaFilterPayload | null;
    child?: TableDataValue;
}

export interface MetaFilterPayload {
    searchBy?: string;
    value?: string | null;
}

export enum FamilySearchBy {
    village = "Village",
    name = "Name",
    mobile = "Mobile",
    status = "Status",
    verification = "Verification",
}

export interface VillageForSelect {
    nameEn: string;
    villageId: string;
}

export interface FamiliesMetaFilter {
    searchBy?: string;
    value?: string | null;
    village?: VillageForSelect | null;
    educationYear?: EducationYearForSelect | null;
    family?: FamilyForSelect | null;
    status?: "active" | "inactive" | null;
}
export interface FilterForFamilies extends FormParams<FamiliesMetaFilter> {
    reload: (url?: string) => void;
}

export interface EducationYearForSelect {
    year: string;
    educationYearId: string;
}
export interface EducationStandardForSelect {
    name: string;
    educationStandardId: string;
}
export interface EducationBoardForSelect {
    educationBoardId: string;
    nameEn: string;
}
export interface EducationMediumForSelect {
    educationMediumId: string;
    nameEn: string;
}
export interface MemberForSelect {
    memberId: string;
    fullNameEn: string;
}
export interface FamilyForSelect {
    familyId: string;
    primaryMember: Member;
}

export interface AnnualContributionForSelect {
    annualContributionId: string;
    year: string;
}
export interface CategoryForSelect {
    categoryId: string;
    nameEn: string;
}
export interface TableDataPayload {
    payload: TableDataValue;
    type: string;
}

export interface PathValue {
    pathName: string;
    href: string;
}

export interface SetPagePathStatePayload {
    payload: PathValue[];
    type: string;
}

export interface SetBooleanPayload {
    payload: boolean;
    type: string;
}

export interface TokenPayload {
    payload: string | null;
    type: string;
}

export interface PendingBusinessCountPayload {
    payload: number;
    type: string;
}

export interface RolePayload {
    payload: ADMIN_ROLE | null;
    type: string;
}

export enum OrderEnum {
    ASC = "ASC",
    DESC = "DESC",
}

export interface TableParams<T> {
    cols: ColumnParams<T>[];
    data: T[];
    tableKey: keyof T;
    reload: () => void;
    component?: JSX.Element;
    currentOrder?: OrderPayload | null;
    tableOffset: TableOffset;
    error?: string | null;
    loading: boolean;
    onSetOrderBy: (fieldName: string, order: OrderEnum) => void;
    className?: string;
}

export interface QueryFunctionParams {
    page: number;
    limit: number;
    search?: string | null;
    fieldName?: string;
    order?: string;
    searchBy?: string;
    value?: string | null;
}

export interface TableOffset {
    onNext: () => void;
    onPrevious: () => void;
    onSetPage: (pageNum: number) => void;
    onSetLimit: (limitNum: number) => void;
    total: number;
    page: number;
    limit: number;
    maxPage: number;
}

export enum Alignment {
    RIGHT = "right",
    LEFT = "left",
    CENTER = "center",
}

export interface ColumnParams<T> {
    key: string;
    sortable?: boolean;
    title: string;
    render: (record: T, index: number) => JSX.Element;
    align?: Alignment;
    minWidth?: boolean;
}

export interface FormParams<T> extends FormikProps<T> {
    errors: FormikErrors<T>;
    touched: FormikTouched<T>;
    handleChange: FormikHandlers["handleChange"];
    values: T;
    handleBlur: FormikHandlers["handleBlur"];
}

export interface OrderPayload {
    fieldName: string;
    order: OrderEnum;
}

export interface PreviewFile extends File {
    preview: string;
    isNew: boolean | undefined;
    url?: string;
    markSheetImageId?: number;
    eventImageId?: number;
}

export interface ToastLinkParams {
    primaryLinkTitle: string;
    primaryLink: string;
    secondaryLinkTitle?: string;
    secondaryLink?: string;
}

export interface SearchFormParams {
    search: string;
}

export interface SearchFormComponentParams {
    resetSearch: () => void;
    onSearch: (value: string) => void;
    searchPlaceHolder?: string;
    searchValue: string | null;
}

export interface LoginPayload {
    email: string;
    password: string;
}

export interface ForgotPasswordPayload {
    email: string;
}

export interface SetPasswordPayload {
    newPassword: string;
    confirmPassword?: string;
    verification?: string;
}

export interface ChangePasswordPayload {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export interface SubAdminPayload {
    email: string;
    name: string;
    mobile: string;
}

export interface SubAdminValue extends SubAdminPayload {
    adminId: number;
    isActive?: boolean;
    role?: ADMIN_ROLE;
}

export interface LoginResponse {
    admin: SubAdminValue;
    token: string;
}

export interface ActivateDeactivateSubAdmin {
    adminId: number;
    isActive: boolean;
}

export interface ModalParams {
    isOpen: boolean;
    children: React.ReactNode;
}
export interface DeleteModalParams {
    isOpen: boolean;
    handleCancel: () => void;
    subTitle: string;
    onDelete: () => void;
}

export interface FormParams<T> extends FormikProps<T> {
    errors: FormikErrors<T>;
    touched: FormikTouched<T>;
    handleChange: FormikHandlers["handleChange"];
    values: T;
    handleBlur: FormikHandlers["handleBlur"];
}

export interface ActivateDeactivateVillage {
    villageId: number;
    isActive: boolean;
}

export interface Village extends ActivateDeactivateVillage {
    nameEn: string;
    nameGu: string;
    subDistrictEn: string;
    subDistrictGu: string;
    districtEn: string;
    districtGu: string;
}

export interface VillagePayload {
    villageId?: number;
    nameEn: string;
    nameGu: string;
    subDistrictEn: string;
    subDistrictGu: string;
    districtEn: string;
    districtGu: string;
}

export interface ActivateDeactivateFamily {
    familyId: number;
    isActive: boolean;
}

export interface Occupation {
    occupationId: number;
    nameEn: string;
    nameGu: string;
    isActive: boolean;
}
export interface Member {
    memberId: number;
    familyId: number;
    email: string | null;
    mobile: string;
    fullNameEn: string;
    fullNameGu: string;
    occupationEn: string | null;
    occupationGu: string | null;
    dob: string | null;
    gender: string;
    bloodGroup: string | null;
    isActive: boolean;
    family: Family;
    occupation: Occupation;
    occupationId?: number;
}

export interface FamilyResponse {
    family: Family;
    member: Member;
}

export interface Family {
    familyId: number;
    villageId: number;
    primaryMemberId: number;
    mobile: string;
    privacy: boolean;
    profileImg: string;
    addressGu: string;
    addressEn: string;
    isVerified: boolean;
    isActive: boolean;
    deletedAt?: Date | null;
    primaryMember: Member;
    village: Village;
    members?: Member[];
}
export interface FamilyPayload {
    familyId?: number;
    villageId: number | null;
    email: string;
    mobile: string;
    fullNameGu: string;
    fullNameEn: string;
    profileImg?: string;
    gender: string;
    addressGu: string;
    addressEn: string;
    bloodGroup: string;
    occupationId: number | null;
    dob: string;
    privacy: boolean;
    family?: Family;
}
export interface EditFamilyPayload {
    familyId: number;
    primaryMemberId?: number | undefined;
    villageId: number | null;
    mobile: string;
    addressGu: string;
    addressEn: string;
    profileImg: string;
    privacy: boolean;
    primaryMember?: Member | null;
    deletedAt?: Date | null;
}

export interface GetPrimaryMember {
    memberId: number;
    fullNameEn: string;
    fullNameGu: string;
}
export interface Member extends GetPrimaryMember {
    bloodGroup: string | null;
    dob: string | null;
    email: string | null;
    family: Family;
    familyId: number;
    gender: string;
    isActive: boolean;
    mobile: string;
}
export interface ActivateDeactivateMember {
    memberId: number;
    isActive: boolean;
}
export interface MemberPayload {
    fullNameEn: string;
    fullNameGu: string;
    mobile: string;
    email: string;
    familyId: number;
    gender: string;
    dob: string;
    bloodGroup: string;
    occupationId: number | null;
    isActive?: boolean;
    family?: Family;
}
export interface EditMemberPayload {
    memberId: number;
}

export interface EducationYearData {
    educationYearId: number;
    isActive: boolean;
    year: string;
    isMarkSheetUploadable: boolean;
    isPrizeListVisible: boolean;
}
export interface ActivateDeactivateEducationYear {
    educationYearId: number;
    isActive: boolean;
}
export interface EducationYearPayload {
    educationYearId?: number;
    year: string;
    isMarkSheetUploadable: boolean;
    isPrizeListVisible: boolean;
}
export interface EducationYear extends EducationYearPayload {
    isActive: boolean;
}

export interface EducationStandardPayload {
    educationStandardId?: number;
    name: string;
}
export interface EducationStandardData {
    educationStandardId: number;
    name: string;
    isActive: boolean;
}
export interface ActivateDeactivateEducationStandard {
    educationStandardId: number;
    isActive: boolean;
}

export interface EducationMediumData {
    educationMediumId: number;
    nameEn: string;
    nameGu: string;
    isActive: boolean;
}
export interface EducationMediumPayload {
    educationMediumId?: number;
    nameEn: string;
    nameGu: string;
}
export interface ActivateDeactivateEducationMedium {
    educationMediumId: number;
    isActive: boolean;
}

export interface EducationBoardData {
    educationBoardId: number;
    nameEn: string;
    nameGu: string;
    isActive: boolean;
}
export interface EducationBoardPayload {
    educationBoardId?: number;
    nameEn: string;
    nameGu: string;
}
export interface ActivateDeactivateEducationBoard {
    educationBoardId: number;
    isActive: boolean;
}

export interface DonorPayload {
    donorId?: number;
    memberId: number | null;
    amount: number;
    lifeLong?: boolean;
    descriptionEn: string;
    descriptionGu: string;
    date: Date;
    annualContributionId?: number;
    familyId?: number | null;
    member?: Member | null;
    annualYear?: AnnualContributionPayload | null;
}
export interface Donor {
    donorId: number;
    memberId: number;
    amount: number;
    descriptionEn: string;
    descriptionGu: string;
    date: string;
    isActive: boolean;
    member: Member;
    lifeLong: boolean
}

export interface ActivateDeactivateDonor {
    donorId: number;
    isActive: boolean;
}

export interface Category {
    categoryId: number;
    nameEn: string;
    nameGu: string;
    isActive: boolean;
}
export interface CategoryPayload {
    categoryId?: number;
    nameEn: string;
    nameGu: string;
}
export interface ActivateDeactivateCategory {
    categoryId: number;
    isActive: boolean;
}

export interface ActivateDeactivateBusiness {
    businessId: number;
    isActive: boolean;
}
export interface Business {
    businessId: number;
    memberId: number;
    categoryId: number;
    businessName: string;
    mobile: string;
    location: string;
    businessImg: string;
    isActive: boolean;
    member: Member;
    tags: string[];
    verified?: string;
    category: Category;
}

export interface BusinessPayload {
    businessId?: number | null;
    memberId: number | null;
    categoryId: number | null;
    businessName: string;
    mobile: string;
    location: string;
    businessImg: string;
    tags: string[];
    verified?: string;
    familyId?: number | null;
    reason?: string | null;
    member?: Member | null;
}

export interface Tags {
    businessId: number;
    name: string;
}

export interface EventImages {
    eventImageId: number;
    eventId: number;
    url: string;
}
export interface Event {
    eventId: number;
    nameEn: string;
    nameGu: string;
    locationEn: string | null;
    locationGu: string | null;
    eventDate: string;
    isActive: boolean;
    images: EventImages[];
}

export interface ActivateDeactivateEvent {
    eventId: number;
}

export interface EventPayload {
    eventId?: number;
    nameGu: string;
    nameEn: string;
    locationEn: string;
    locationGu: string;
    eventDate: string;
    images: PreviewFile[];
    deletedImages?: number[];
    coverImg: string;
}

export interface ActivateDeactivateOccupation {
    occupationId: number;
    isActive: boolean;
}

export interface Ocupation extends ActivateDeactivateOccupation {
    nameEn: string;
    nameGu: string;
}

export interface OccupationPayload {
    occupationId?: number;
    nameEn: string;
    nameGu: string;
}

export enum VerificationStatus {
    PENDING = "Pending",
    APPROVED = "Approved",
    REJECTED = "Rejected",
}

export interface Marksheet {
    markSheetId?: number | null;
    memberId: number | null;
    educationBoardId: number | null;
    educationYearId: number | null;
    educationStandardId: number | null;
    educationMediumId: number | null;
    marksType: string;
    marks: number | null;
    verified: VerificationStatus;
    educationBoard: EducationBoardData;
    educationYear: EducationYear;
    educationMedium: EducationMediumData;
    member: Member;
    educationStandard: EducationStandardData;
}

export interface MarksheetImage {
    markSheetId: number;
    markSheetImageId: number;
    url: string;
}
export interface MarksheetPayload {
    markSheetId?: number | null;
    memberId: number | null;
    educationBoardId: number | null;
    educationYearId: number | null;
    educationStandardId: number | null;
    educationMediumId: number | null;
    marksType: MARKS_TYPE_VALUE;
    marks: string;
    verified?: VerificationStatus;
    images: PreviewFile[];
    deletedImages?: number[];
    member?: Member | null;
    familyId?: number | null;
    educationStandard?: EducationStandardData;
    educationMedium?: MarksheetMedium;
    educationBoard?: EducationBoardData;
    educationYear?: EducationYearData;
    reason?: string | null;
}

export interface MarksheetMember {
    memberId: number;
    fullNameEn: string;
}
export interface MarksheetBoard {
    educationBoardId: number;
    nameEn: string;
}
export interface MarksheetYear {
    educationYearId: number;
    year: string;
}
export interface MarksheetStandard {
    educationStandardId: number;
    name: string;
}
export interface MarksheetMedium {
    educationMediumId: number;
    isActive: boolean;
    nameEn: string;
    nameGu: string;
}
export interface Prize {
    prizeId: number;
    educationYearId: number;
    educationStandardId: number;
    educationBoardId: number;
    educationMediumId: number;
    memberId: number;
    prizeRankId: number;
    marksType: string;
    marks: number;
    member: Member;
    educationBoard: EducationBoardData;
    educationYear: EducationYearData;
    educationStandard: EducationStandardData;
    educationMedium: EducationMediumData;
    rank: PrizeRanks;
}
export interface PrizePayload {
    prizeId?: number;
    memberId: number | null;
    educationBoardId: number | null;
    educationYearId: number | null;
    educationStandardId: number | null;
    educationMediumId: number | null;
    marksType: string;
    prizeRankId: number | null;
    marks: number | null;
    familyId?: number | null;
    member?: Member | null;
}
export interface CustomDatePickerProps {
    name: string;
    id: string;
    selected?: Date | null;
    minDate?: Date | null;
    hasError?: boolean;
    maxDate?: Date | null;
    setFieldValue?: (field: string, value: Date | null | string, shouldValidate?: boolean) => void;
    handleBlur?: FormikHandlers["handleBlur"];
    disabled?: boolean;
    customChangeFunction?: (index: number, expiryDate: Date | null) => void;
    metaData?: number;
    onCalendarOpen?: () => void;
    onCalendarClose?: () => void;
}

export interface ZIndexMaintainer {
    [key: string]: string | undefined;
}

export interface ActivateDeactivatePrizeRanks {
    prizeRankId: number;
    isActive: boolean;
}
export interface PrizeRanks extends ActivateDeactivatePrizeRanks {
    rank: string;
}
export interface PrizeRanksPayload {
    prizeRankId?: number;
    rank: number;
}

export interface AdminDirectoryPayload {
    adminDirectoryId?: number;
    nameEn: string;
    nameGu: string;
    mobile: string;
    isActive?: boolean;
    email: string | null;
}
