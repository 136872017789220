import { AxiosError } from "axios";
import { api } from "../config/Api";
import { dataService } from "../config/DataService";
import { handleCatchResponse } from "../helper/helper";
import { errorToast, successToast } from "../helper/toast";
import { setLoading } from "../store/slice/TableDataSlice";
import { Dispatch } from "redux";
import { AnnualContributionPayload, Response } from "../helper/interface";

export const onAddAnnualContribution = async (
    payload: AnnualContributionPayload,
    dispatch: Dispatch,
    callback: (res: Required<Response<AnnualContributionPayload>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.post(api.annualContribution, payload);
        const res: Required<Response<AnnualContributionPayload>> = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onEditAnnualContribution = async (
    payload: AnnualContributionPayload,
    dispatch: Dispatch,
    callback: (res: Required<Response<AnnualContributionPayload>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.put(api.annualContribution, payload);
        const res = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeleteAnnualContribution = async (annualContributionId: number, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.annualContribution}/${annualContributionId}`);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
