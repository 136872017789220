import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Tooltip } from "react-tooltip";
import { Edit, Trash2 } from "react-feather";

import DeleteModal from "../../../modal/DeleteModal";
import CustomTable from "../../table/CustomTable";
import useTable from "../../../hooks/useTable";
import { api } from "../../../config/Api";
import usePathName from "../../../hooks/usePathName";
import { ActivateDeactivateEducationStandard, Alignment, EducationStandardData } from "../../../helper/interface";
import SearchForm from "../../table/SearchForm";
import { onActivateDeactivateEducationStandard, onDeleteEducationStandard } from "../../../service/educationStandard";

const EducationStandard = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const educationStandardTable = useTable<EducationStandardData>("Education-standard", api.marksheet.educationStandard);

    const [isOpen, setIsOpen] = useState(false);
    const [eduStandard, setEduStandard] = useState<EducationStandardData | null>(null);

    const onDeleteClick = useCallback((record: EducationStandardData) => {
        setEduStandard(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (eduStandard?.educationStandardId) {
            onDeleteEducationStandard(eduStandard?.educationStandardId, dispatch, () => {
                educationStandardTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [eduStandard, educationStandardTable]);

    const onActivateDeactivate = useCallback(
        (payload: ActivateDeactivateEducationStandard) => {
            onActivateDeactivateEducationStandard(payload, dispatch, educationStandardTable.fetchApi);
        },
        [educationStandardTable]
    );

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const educationYearCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: EducationStandardData, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "NAME",
            key: "name",
            sortable: true,
            render: (record: EducationStandardData) => {
                return <span>{record?.name}</span>;
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            render: (record: EducationStandardData) => {
                const payload = {
                    educationStandardId: record?.educationStandardId,
                    isActive: !record?.isActive,
                };
                return (
                    <>
                        <Tooltip id="simpleTooltipActiveDeactive" place="top" />
                        <div
                            className="form-switch form-check flex justify-center flex-col"
                            data-tooltip-id="simpleTooltipActiveDeactive"
                            data-tooltip-content={record?.isActive ? "Active" : "Inactive"}
                        >
                            <input
                                id="isActive"
                                name="isActive"
                                onChange={() => onActivateDeactivate(payload)}
                                checked={record?.isActive}
                                className={"form-check-input"}
                                type="checkbox"
                            />
                        </div>
                    </>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: EducationStandardData) => {
                return (
                    <>
                        <div className="flex justify-center items-center text-primary">
                            <Tooltip id="simpleTooltipEditDelete" place="top" />
                            <div
                                className="flex items-center mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Edit education standard"}
                                onClick={() => navigate(`/marksheet/education-standard/edit/${record?.educationStandardId}`)}
                            >
                                <Edit className="w-4 h-4 mr-1" />
                                <span className="pt-[2px] leading-[16px]">Edit</span>
                            </div>
                            <div
                                className="flex items-center text-danger cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Delete education standard"}
                                onClick={() => onDeleteClick(record)}
                            >
                                <Trash2 className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Delete</span>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    usePathName([{ pathName: "Education standard", href: "/marksheet/education-standard" }]);

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this education standard?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={educationYearCols}
                    data={educationStandardTable.tableData ?? []}
                    tableKey="educationStandardId"
                    reload={educationStandardTable.fetchApi}
                    currentOrder={educationStandardTable.currentOrder}
                    error={educationStandardTable.error}
                    loading={educationStandardTable.loading}
                    tableOffset={educationStandardTable.tableOffset}
                    onSetOrderBy={educationStandardTable.onSetOrderBy}
                    component={
                        <>
                            <SearchForm
                                resetSearch={educationStandardTable.resetSearch}
                                onSearch={educationStandardTable.onSearch}
                                searchValue={educationStandardTable.search}
                                searchPlaceHolder="Search by education standard"
                            />
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new education standard"
                                    onClick={() => {
                                        navigate("/marksheet/education-standard/add");
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default EducationStandard;
