import React, { useCallback } from "react";
import { Form } from "formik";
import { Listbox, Transition } from "@headlessui/react";
import Select, { MultiValue } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";

import { FamilySearchBy, FilterForFamilies, TableStateValue, VillageForSelect } from "../../helper/interface";
import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import { setTableData } from "../../store/slice/TableDataSlice";
import { FamilySearchData } from "../../helper/constant";

const Filter = ({ values, setFieldValue, setFieldTouched, resetForm, reload }: FilterForFamilies) => {
    const dispatch = useDispatch();

    const tableOffsetData = useSelector((state: TableStateValue) => state.table.tableData);

    const { res: village } = useFetch<VillageForSelect[]>(api.village);

    const handleOptionChange = (value: string) => {
        setFieldValue("searchBy", value);
        setFieldValue("value", "");
    };

    const handleVillageChange = useCallback((selectedItem: MultiValue<VillageForSelect>) => {
        const villageIds = selectedItem?.map((ele) => ele.villageId).join(",");
        setFieldValue("value", villageIds);
    }, []);

    const handleReset = useCallback(() => {
        dispatch(
            setTableData({
                page: 1,
                limit: 10,
                maxPage: tableOffsetData.maxPage,
                search: null,
                orderBy: null,
                tableFor: "families",
                metaFilter: null,
            })
        );
        reload(`${api.family}?page=1&limit=10`);
        resetForm();
    }, [tableOffsetData, tableOffsetData.maxPage]);

    const onChangeValueMemberName = useCallback(
        (value: string) => {
            setFieldValue("value", value);
            setFieldValue("name", FamilySearchBy.name);
        },
        [tableOffsetData]
    );

    const onChangeValueMobile = useCallback((value: string) => {
        setFieldValue("value", value);
        setFieldValue("mobile", FamilySearchBy.mobile);
    }, []);

    const splitVillageIds = values?.value?.split(",");

    return (
        <>
            <Form id="tabulator-html-filter-form" className="flex items-center job-pending-style align-items-baseline">
                <div className="flex items-center sm:mr-4 py-1 job-filter-form z-50">
                    <label className="flex-none mr-2 mb-0">Select filter</label>
                    <div className="tom-select">
                        <Listbox value={values.searchBy} onChange={handleOptionChange}>
                            <Listbox.Button className="form-control ts-input">
                                <div className="item">{values?.searchBy ?? "Select filter type"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("searchBy", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {FamilySearchData.map((fltr: string, i: number) => {
                                            return (
                                                <Listbox.Option
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                            active || fltr === values.searchBy ? "optionColor" : null
                                                        }`
                                                    }
                                                    key={i}
                                                    value={fltr}
                                                >
                                                    {fltr}
                                                </Listbox.Option>
                                            );
                                        })}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                    </div>
                </div>

                {values.searchBy === FamilySearchBy.village && village?.data ? (
                    <div className="sm:flex flex items-center sm:mr-4">
                        <label className="form-label">Villages</label>
                        <div>
                            <Select
                                isMulti
                                name="villageId"
                                options={village?.data}
                                value={village?.data?.filter((e) => splitVillageIds?.includes(String(e.villageId)))}
                                onChange={(selectedItem) => handleVillageChange(selectedItem)}
                                classNamePrefix="select"
                                className={"form-control job-filter-style"}
                                getOptionLabel={(option) => option.nameEn}
                                getOptionValue={(option) => option.villageId}
                            />
                        </div>
                    </div>
                ) : null}

                {values.searchBy === FamilySearchBy.status ? (
                    <div className="flex items-center sm:mr-4 py-1 job-filter-form z-50">
                        <label className="flex-none mr-2 mb-0">Status</label>
                        <div className="tom-select">
                            <Listbox
                                value={values.value === "Active" ? "Active" : values.value === "Inactive" ? "Inactive" : undefined}
                                onChange={(value: string) => {
                                    setFieldValue("status", value);
                                    setFieldValue("value", value);
                                }}
                            >
                                <Listbox.Button className="form-control ts-input">
                                    <div className="item">{values?.value || "Select status"}</div>
                                </Listbox.Button>
                                <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                    onBlur={() => setFieldTouched("status", true)}
                                >
                                    <Listbox.Options className="ts-dropdown single w-full">
                                        <div role="listbox" className="ts-dropdown-content">
                                            {["Active", "Inactive"].map((fltr: string, i: number) => {
                                                return (
                                                    <Listbox.Option
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                active || fltr === values.value ? "optionColor" : null
                                                            }`
                                                        }
                                                        key={i}
                                                        value={fltr}
                                                    >
                                                        {fltr}
                                                    </Listbox.Option>
                                                );
                                            })}
                                        </div>
                                    </Listbox.Options>
                                </Transition>
                            </Listbox>
                        </div>
                    </div>
                ) : null}

                {values.searchBy === FamilySearchBy.verification ? (
                    <div className="flex items-center sm:mr-4 py-1 job-filter-form z-50">
                        <label className="flex-none mr-2 mb-0">Verification</label>
                        <div className="tom-select">
                            <Listbox
                                value={values.value === "Verified" ? "Verified" : values.value === "Not verified" ? "Not verified" : undefined}
                                onChange={(value: string) => {
                                    setFieldValue("verification", value);
                                    setFieldValue("value", value);
                                }}
                            >
                                <Listbox.Button className="form-control ts-input">
                                    <div className="item">{values?.value || "Select verification"}</div>
                                </Listbox.Button>
                                <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                    onBlur={() => setFieldTouched("status", true)}
                                >
                                    <Listbox.Options className="ts-dropdown single w-full">
                                        <div role="listbox" className="ts-dropdown-content">
                                            {["Verified", "Not verified"].map((fltr: string, i: number) => {
                                                return (
                                                    <Listbox.Option
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                active || fltr === values.value ? "optionColor" : null
                                                            }`
                                                        }
                                                        key={i}
                                                        value={fltr}
                                                    >
                                                        {fltr}
                                                    </Listbox.Option>
                                                );
                                            })}
                                        </div>
                                    </Listbox.Options>
                                </Transition>
                            </Listbox>
                        </div>
                    </div>
                ) : null}

                {values.searchBy === FamilySearchBy.name ? (
                    <div>
                        <div className="flex items-center sm:mr-4 py-1 job-filter-form datePicker">
                            <Tooltip id="simpleTooltipSearch" place="top" />
                            <input
                                name="search"
                                type="text"
                                autoComplete="off"
                                data-tooltip-id="simpleTooltipSearch"
                                data-tooltip-content="Member name"
                                value={values?.value ?? ""}
                                className={"form-control mr-2 sm:w-40 2xl:w-full mt-2 sm:mt-0"}
                                placeholder="Type member name"
                                onChange={(e) => onChangeValueMemberName(e.target.value)}
                            />
                        </div>
                    </div>
                ) : null}

                {values.searchBy === FamilySearchBy.mobile ? (
                    <>
                        <div>
                            <div className="flex items-center sm:mr-4 py-1 job-filter-form">
                                <Tooltip id="simpleTooltipSearch" place="top" />
                                <input
                                    name="search"
                                    type="text"
                                    autoComplete="off"
                                    data-tooltip-id="simpleTooltipSearch"
                                    data-tooltip-content="Mobile number"
                                    value={values?.value ?? ""}
                                    className={"form-control mr-2 sm:w-40 2xl:w-full mt-2 sm:mt-0"}
                                    placeholder="Type mobile number"
                                    onChange={(e) => onChangeValueMobile(e.target.value)}
                                />
                            </div>
                        </div>
                    </>
                ) : null}

                <div className="flex mt-2 md:mt-0">
                    <button id="tabulator-html-filter-go" type="submit" className="btn btn-primary w-16" disabled={Boolean(!values.value)}>
                        Go
                    </button>
                    <button id="tabulator-html-filter-reset" type="button" className="btn btn-secondary w-16 ml-2" onClick={handleReset}>
                        Reset
                    </button>
                </div>
            </Form>
        </>
    );
};

export default Filter;
