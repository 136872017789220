import { useState, useCallback } from "react";
import { Edit, Trash2 } from "react-feather";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Advertisement, Alignment } from "../../helper/interface";
import usePathName from "../../hooks/usePathName";
import CustomTable from "../table/CustomTable";
import useTable from "../../hooks/useTable";
import { api } from "../../config/Api";
import SearchForm from "../table/SearchForm";
import DeleteModal from "../../modal/DeleteModal";
import { onActivateDeactivateAdvertisement, onDeleteAdvertisement } from "../../service/advertisement";

const Advertisements = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [advertisement, setAdvertisement] = useState<Advertisement | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const advertisementTable = useTable<Advertisement>("advertisement", api.advertisement);

    usePathName([{ pathName: "Advertisement", href: "/advertisement" }]);

    const onDeleteClick = useCallback((record: Advertisement) => {
        setAdvertisement(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (advertisement?.advertisementId) {
            onDeleteAdvertisement(advertisement.advertisementId, dispatch, () => {
                advertisementTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [advertisement, advertisementTable]);

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const onActivateDeactivate = useCallback(
        (advertisementId: number) => {
            onActivateDeactivateAdvertisement(advertisementId, dispatch, advertisementTable.fetchApi);
        },
        [advertisementTable]
    );

    const advertisementCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: Advertisement, srNumber: number) => {
                return <span>{srNumber}</span>;
            },
        },
        {
            title: "TITLE",
            key: "titleEn",
            sortable: true,
            render: (record: Advertisement) => {
                return <span>{record.titleEn}</span>;
            },
        },
        {
            title: "MOBILE NO",
            key: "mobile",
            render: (record: Advertisement) => {
                return <span>{record.mobile}</span>;
            },
        },
        {
            title: "EMAIL",
            key: "email",
            sortable: true,
            render: (record: Advertisement) => {
                return <span>{record.email ?? "-"}</span>;
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            sortable: true,
            render: (record: Advertisement) => {
                return (
                    <>
                        <Tooltip id="simpleTooltipActiveDeactive" place="top" />
                        <div
                            className="form-switch form-check flex justify-center flex-col"
                            data-tooltip-id="simpleTooltipActiveDeactive"
                            data-tooltip-content={record?.isActive ? "Active" : "Inactive"}
                        >
                            <input
                                id="isActive"
                                name="isActive"
                                onChange={() => onActivateDeactivate(record.advertisementId)}
                                checked={record.isActive}
                                className={"form-check-input"}
                                type="checkbox"
                            />
                        </div>
                    </>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: Advertisement) => {
                return (
                    <div className="flex justify-center items-center text-primary">
                        <Tooltip id="simpleTooltipEditDelete" place="top" />
                        <div
                            className="flex items-center mr-3 cursor-pointer"
                            data-tooltip-id="simpleTooltipEditDelete"
                            data-tooltip-content={"Edit advertisement"}
                            onClick={() => navigate(`/advertisement/edit/${record.advertisementId}`)}
                        >
                            <Edit className="w-4 h-4 mr-1" />
                            <span className="pt-[2px] leading-[16px]">Edit</span>
                        </div>
                        <div
                            className="flex items-center text-danger cursor-pointer"
                            data-tooltip-id="simpleTooltipEditDelete"
                            data-tooltip-content={"Delete advertisement"}
                            onClick={() => onDeleteClick(record)}
                        >
                            <Trash2 className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Delete</span>
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this advertisement?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={advertisementCols}
                    data={advertisementTable.tableData ?? []}
                    tableKey="advertisementId"
                    reload={advertisementTable.fetchApi}
                    currentOrder={advertisementTable.currentOrder}
                    error={advertisementTable.error}
                    loading={advertisementTable.loading}
                    tableOffset={advertisementTable.tableOffset}
                    onSetOrderBy={advertisementTable.onSetOrderBy}
                    component={
                        <>
                            <SearchForm
                                resetSearch={advertisementTable.resetSearch}
                                onSearch={advertisementTable.onSearch}
                                searchValue={advertisementTable.search}
                                searchPlaceHolder="Search by advertisement title or mobile number"
                            />
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new advertisement"
                                    onClick={() => {
                                        navigate("/advertisement/add");
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default Advertisements;
