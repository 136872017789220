import { useState, useCallback } from "react";

import { Edit, Trash2 } from "react-feather";
import { Alignment, AdminDirectoryPayload } from "../../helper/interface";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import CustomTable from "../table/CustomTable";
import useTable from "../../hooks/useTable";
import { api } from "../../config/Api";
import SearchForm from "../table/SearchForm";
import DeleteModal from "../../modal/DeleteModal";
import { useDispatch } from "react-redux";
import { onDeleteAdminDirectory, onActivateDeactivateAdminDirectory } from "../../service/adminDirectory";
import usePathName from "../../hooks/usePathName";

const AdminDirectory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    usePathName([{ pathName: "Admin directory", href: "/admin-directory" }]);

    const [adminDirectory, setAdminDirectory] = useState<Required<AdminDirectoryPayload> | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const adminDirectoryTable = useTable<Required<AdminDirectoryPayload>>("adminDirectory", api.adminDirectory);

    const onDeleteClick = useCallback((record: Required<AdminDirectoryPayload>) => {
        setAdminDirectory(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (adminDirectory?.adminDirectoryId) {
            onDeleteAdminDirectory(adminDirectory.adminDirectoryId, dispatch, () => {
                adminDirectoryTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [adminDirectory, adminDirectoryTable]);

    const onActivateDeactivate = useCallback(
        (adminDirectoryId: number) => {
            onActivateDeactivateAdminDirectory(adminDirectoryId, dispatch, adminDirectoryTable.fetchApi);
        },
        [adminDirectoryTable]
    );

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const advertisementCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: Required<AdminDirectoryPayload>, srNumber: number) => {
                return <span>{srNumber}</span>;
            },
        },
        {
            title: "NAME",
            key: "nameEn",
            sortable: true,
            render: (record: Required<AdminDirectoryPayload>) => {
                return <span>{record.nameEn}</span>;
            },
        },
        {
            title: "PHONE",
            key: "mobile",
            sortable: true,
            render: (record: Required<AdminDirectoryPayload>) => {
                return <span>{record.mobile}</span>;
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            render: (record: Required<AdminDirectoryPayload>) => {
                return (
                    <>
                        <Tooltip id="simpleTooltipActiveDeactive" place="top" />
                        <div
                            className="form-switch form-check flex justify-center flex-col"
                            data-tooltip-id="simpleTooltipActiveDeactive"
                            data-tooltip-content={record?.isActive ? "Active" : "Inactive"}
                        >
                            <input
                                id="isActive"
                                name="isActive"
                                onChange={() => onActivateDeactivate(record.adminDirectoryId)}
                                checked={record?.isActive}
                                className={"form-check-input"}
                                type="checkbox"
                            />
                        </div>
                    </>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: Required<AdminDirectoryPayload>) => {
                return (
                    <div className="flex justify-center items-center text-primary">
                        <Tooltip id="simpleTooltipEditDelete" place="top" />
                        <div
                            className="flex items-center mr-3 cursor-pointer"
                            data-tooltip-id="simpleTooltipEditDelete"
                            data-tooltip-content={"Edit admin directory"}
                            onClick={() => navigate(`/admin-directory/edit/${record.adminDirectoryId}`)}
                        >
                            <Edit className="w-4 h-4 mr-1" />
                            <span className="pt-[2px] leading-[16px]">Edit</span>
                        </div>
                        <div
                            className="flex items-center text-danger cursor-pointer"
                            data-tooltip-id="simpleTooltipEditDelete"
                            data-tooltip-content={"Delete admin directory"}
                            onClick={() => onDeleteClick(record)}
                        >
                            <Trash2 className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Delete</span>
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this admin directory?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={advertisementCols}
                    data={adminDirectoryTable.tableData ?? []}
                    tableKey="adminDirectoryId"
                    reload={adminDirectoryTable.fetchApi}
                    currentOrder={adminDirectoryTable.currentOrder}
                    error={adminDirectoryTable.error}
                    loading={adminDirectoryTable.loading}
                    tableOffset={adminDirectoryTable.tableOffset}
                    onSetOrderBy={adminDirectoryTable.onSetOrderBy}
                    component={
                        <>
                            <SearchForm
                                resetSearch={adminDirectoryTable.resetSearch}
                                onSearch={adminDirectoryTable.onSearch}
                                searchValue={adminDirectoryTable.search}
                                searchPlaceHolder="Search by name or email or mobile"
                            />
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new admin directory"
                                    onClick={() => {
                                        navigate("/admin-directory/add");
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default AdminDirectory;
