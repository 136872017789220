import { Dispatch } from "@reduxjs/toolkit";
import { api } from "../config/Api";
import { dataService } from "../config/DataService";
import { setLoading } from "../store/slice/TableDataSlice";
import { AxiosError } from "axios";
import { errorToast, successToast } from "../helper/toast";
import { handleCatchResponse } from "../helper/helper";
import { ActivateDeactivateEducationYear, EducationYear, EducationYearPayload, Response } from "../helper/interface";

export const onAddEducationYear = async (
    payload: EducationYearPayload,
    dispatch: Dispatch,
    callback: (res: Required<Response<EducationYear>>) => void
) => {
    try {
        dispatch(setLoading(true));
        const response = await dataService.post(api.marksheet.educationYear, payload);
        const res: Required<Response<EducationYear>> = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onEditEducationYear = async (
    payload: EducationYearPayload,
    dispatch: Dispatch,
    callback: (res: Required<Response<EducationYearPayload>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.put(api.marksheet.educationYear, payload);
        const res = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeleteEducationYear = async (educationYearId: number, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.marksheet.educationYear}/${educationYearId}`);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onActivateDeactivateEducationYear = async (
    data: ActivateDeactivateEducationYear,
    dispatch: Dispatch,
    callback: () => void
) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            isActive: data.isActive,
        };

        const response = await dataService.patch(`${api.marksheet.educationYear}/${data.educationYearId}`, payload);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
