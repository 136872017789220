import { useCallback, useRef, RefObject, useEffect, useState } from "react";
import { Camera } from "react-feather";
import { Form } from "formik";
import { useSelector } from "react-redux";
import { Listbox, Transition } from "@headlessui/react";
import CreatableSelect from "react-select/creatable";

import useFetch from "../../hooks/useFetch";
import {
    FormParams,
    TableStateValue,
    BusinessPayload,
    Member,
    Category,
    Tags,
    Family,
    VerificationStatus,
    IncludeApiPayload,
} from "../../helper/interface";
import { api } from "../../config/Api";
import { getImageUrl, serializeQueryParams } from "../../helper/helper";
import avatar from "./../../assets/images/userAvatar.png";
import { selectTagsOptions } from "../../config/MethodUtils";
import { dataEn } from "../../helper/dataEn";
import Loading from "../../container/Loading";

const AddEditForm = ({
    errors,
    touched,
    handleChange,
    values,
    dirty,
    setFieldValue,
    setFieldTouched,
    handleBlur,
}: FormParams<BusinessPayload>) => {
    const businessImgRef = useRef() as RefObject<HTMLInputElement>;

    const loading = useSelector((state: TableStateValue) => state.table.loading);

    const [memberList, setMemberList] = useState<Member[]>([]);

    const payloadForAPI: IncludeApiPayload = {
        fieldName: "primaryMemberName",
        order: "ASC",
    };

    if (values.memberId) {
        payloadForAPI.includeMember = values.memberId;
    }

    const { res: families } = useFetch<Family[]>(`${api.family}?${serializeQueryParams(payloadForAPI)}`);
    const { res: category } = useFetch<Category[]>(api.category);
    const { res: tags } = useFetch<Tags[]>(api.tags);

    useEffect(() => {
        if (!values?.familyId && families && Array.isArray(families.data) && values?.member?.memberId) {
            families.data.forEach((ele) => {
                if (ele.familyId === values.member?.familyId) {
                    if (ele?.members) setMemberList(ele.members);

                    setFieldValue("familyId", ele.familyId);
                }
            });
        }
    }, [families]);

    const onChangeMemberId = useCallback((value: number) => {
        setFieldValue("memberId", value);
    }, []);

    const onChangeCategory = useCallback((value: number) => {
        setFieldValue("categoryId", value);
    }, []);

    const onChangeFamily = useCallback(
        (value: number) => {
            setFieldValue("memberId", null);
            families?.data?.forEach((ele) => {
                if (ele.familyId === value && ele.members) {
                    setMemberList(ele.members);
                }
            });

            setFieldValue("familyId", value);
        },
        [families]
    );

    return (
        <>
            <Form>
                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                    <div className="preview mb-5 col-span-12">
                        <div className="flex flex-1 px-5 items-center justify-center lg:justify-start flex-col">
                            <div className="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative">
                                <img
                                    alt="Profile picture"
                                    className="rounded-full img-bordered"
                                    src={values?.businessImg ? getImageUrl(values?.businessImg) : avatar}
                                />
                                <div
                                    className="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-primary rounded-full p-2 border-2 border-solid border-white cursor-pointer"
                                    onClick={() => businessImgRef?.current?.click()}
                                >
                                    <Camera className="lucide lucide-camera w-4 h-4 text-white" />
                                    <input
                                        ref={businessImgRef}
                                        name="businessImg"
                                        type="file"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            if (e?.target?.files?.[0]) {
                                                setFieldValue("businessImg", e?.target?.files?.[0]);
                                            }
                                        }}
                                        accept="image/jpg,image/png,image/jpeg"
                                        className="hidden"
                                        disabled={loading}
                                    />
                                </div>
                            </div>
                            <div className="mt-4 text-center">
                                <div className="w-36 sm:w-40 truncate sm:whitespace-normal font-medium text-lg capitalize">
                                    {values.businessName ? values.businessName : "Business name"}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="intro-y col-span-12 flex items-center justify-center">
                        <div className="intro-y box lg:w-[60%] md:w-[100%] sm:w-[100%]">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="font-medium text-base mr-auto">{dataEn.businessDetails}</h2>
                            </div>
                            <div id="vertical-form" className="p-5">
                                <div className="preview">
                                    {/* Primary Member Name */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Primary Member Name</label>
                                        <div className={`tom-select ${errors.familyId && touched.familyId && "dd-border-danger"}`}>
                                            <Listbox value={values?.familyId ?? null} name="familyId" onChange={onChangeFamily}>
                                                <Listbox.Button id="familyId" className={"ts-input"}>
                                                    <div className="item">
                                                        {families?.data?.map(
                                                            (v) => v?.familyId === values?.familyId && v?.primaryMember?.fullNameEn
                                                        ) ?? ""}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("familyId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {families?.data?.map((ele: Family) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || ele?.familyId === values?.familyId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={ele.familyId}
                                                                        key={ele?.familyId}
                                                                    >
                                                                        {ele?.primaryMember?.fullNameEn}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.familyId && touched.familyId && <div className="text-danger">{errors.familyId}</div>}
                                    </div>
                                    {/* Member name */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Member Name</label>
                                        <div
                                            className={`tom-select form-control ${
                                                errors.memberId && touched.memberId && "dd-border-danger"
                                            }`}
                                        >
                                            <Listbox value={values.memberId} name="memberId" onChange={onChangeMemberId} disabled={loading}>
                                                <Listbox.Button id="memberId" className={"ts-input"}>
                                                    <div className="item">
                                                        {memberList?.map((v) => v?.memberId === values?.memberId && v?.fullNameEn) ??
                                                            "Select member name"}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("memberId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {memberList?.map((mber: Member) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || mber.memberId === values.memberId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={mber.memberId}
                                                                        key={mber.memberId}
                                                                    >
                                                                        {mber.fullNameEn}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.memberId && touched.memberId && <div className="text-danger">{errors.memberId}</div>}
                                    </div>

                                    {/* Category name */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Category</label>
                                        <div
                                            className={`tom-select form-control ${
                                                errors.categoryId && touched.categoryId && "dd-border-danger"
                                            }`}
                                        >
                                            <Listbox
                                                value={values.categoryId}
                                                name="categoryId"
                                                onChange={onChangeCategory}
                                                disabled={loading}
                                            >
                                                <Listbox.Button id="categoryId" className={"ts-input"}>
                                                    <div className="item">
                                                        {category?.data?.map((v) => v?.categoryId === values?.categoryId && v?.nameEn) ||
                                                            "Select category"}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("categoryId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {category?.data?.map((cat: Category) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || cat.categoryId === values.categoryId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={cat.categoryId}
                                                                        key={cat.categoryId}
                                                                    >
                                                                        {cat.nameEn}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.categoryId && touched.categoryId && <div className="text-danger">{errors.categoryId}</div>}
                                    </div>

                                    {/* Business Name */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="businessName" className="form-label">
                                            Business Name
                                        </label>
                                        <input
                                            id="businessName"
                                            name="businessName"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.businessName}
                                            onBlur={(e) => setFieldValue("businessName", e.target.value)}
                                            className={`form-control ${errors.businessName && touched.businessName && "border-danger"}`}
                                            disabled={loading}
                                        />
                                        {errors.businessName && touched.businessName && (
                                            <div className="text-danger">{errors.businessName}</div>
                                        )}
                                    </div>

                                    {/* Location */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="location" className="form-label">
                                            Location
                                        </label>
                                        <input
                                            id="location"
                                            name="location"
                                            type="text"
                                            onChange={(e) => setFieldValue("location", e.target.value)}
                                            value={values.location}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.location && touched.location && "border-danger"}`}
                                            disabled={loading}
                                        />
                                        {errors.location && touched.location && <div className="text-danger">{errors.location}</div>}
                                    </div>

                                    {/* Mobile Number */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="mobile" className="form-label">
                                            Mobile Number
                                        </label>
                                        <input
                                            id="mobile"
                                            name="mobile"
                                            type="text"
                                            onChange={(e) => setFieldValue("mobile", e.target.value)}
                                            value={values.mobile}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.mobile && touched.mobile && "border-danger"}`}
                                            disabled={loading}
                                        />
                                        {errors.mobile && touched.mobile && <div className="text-danger">{errors.mobile}</div>}
                                    </div>

                                    {/* Products */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label htmlFor="products" className="form-label">
                                            Tags
                                        </label>
                                        <CreatableSelect
                                            options={selectTagsOptions(tags?.data?.map((data: Tags) => data?.name) ?? [])}
                                            id="products"
                                            escapeClearsValue={false}
                                            isMulti
                                            isSearchable={true}
                                            isLoading={false}
                                            className="onFocusBorderNone"
                                            placeholder={"Select tag"}
                                            onChange={(tag) =>
                                                setFieldValue(
                                                    "tags",
                                                    tag.map((i) => i.value)
                                                )
                                            }
                                            value={values?.tags?.map((t: string) => ({
                                                value: t,
                                                label: t,
                                            }))}
                                            isDisabled={loading}
                                        />
                                    </div>

                                    {/* Marksheet verified */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label>Verification</label>
                                        <div className="flex flex-col sm:flex-row mt-2">
                                            <div className="form-check mr-2">
                                                <input
                                                    id="isVerifiedPending"
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="verified"
                                                    checked={values.verified === VerificationStatus.PENDING}
                                                    onChange={() => setFieldValue("verified", VerificationStatus.PENDING)}
                                                />
                                                <label className="form-check-label" htmlFor="isVerifiedPending">
                                                    {VerificationStatus.PENDING}
                                                </label>
                                            </div>
                                            <div className="form-check mr-2 mt-2 sm:mt-0">
                                                <input
                                                    id="isVerifiedApproved"
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="verified"
                                                    checked={values.verified === VerificationStatus.APPROVED}
                                                    onChange={() => setFieldValue("verified", VerificationStatus.APPROVED)}
                                                />
                                                <label className="form-check-label" htmlFor="isVerifiedApproved">
                                                    {VerificationStatus.APPROVED}
                                                </label>
                                            </div>
                                            <div className="form-check mr-2 mt-2 sm:mt-0">
                                                <input
                                                    id="isVerifiedRejected"
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="verified"
                                                    checked={values.verified === VerificationStatus.REJECTED}
                                                    onChange={() => setFieldValue("verified", VerificationStatus.REJECTED)}
                                                />
                                                <label className="form-check-label" htmlFor="isVerifiedRejected">
                                                    {VerificationStatus.REJECTED}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Reason */}
                                    {values.verified === VerificationStatus.REJECTED ? (
                                        <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                            <label htmlFor="reason" className="form-label">
                                                Reason
                                            </label>
                                            <textarea
                                                id="reason"
                                                name="reason"
                                                value={values?.reason ?? ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={`form-control ${errors.reason && touched.reason && "border-danger"}`}
                                            />
                                            {errors.reason && touched.reason && <div className="text-danger">{errors.reason}</div>}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                        <button className="btn btn-secondary w-24" type="reset" disabled={loading || !dirty}>
                            Reset
                        </button>
                        <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                            {loading ? <Loading isButton /> : "Save"}
                        </button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default AddEditForm;
