import { useState, useCallback } from "react";
import { Edit, Trash2 } from "react-feather";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ActivateDeactivateEvent, Alignment, Event } from "../../helper/interface";
import CustomTable from "../table/CustomTable";
import useTable from "../../hooks/useTable";
import { api } from "../../config/Api";
import usePathName from "../../hooks/usePathName";
import SearchForm from "../table/SearchForm";
import DeleteModal from "../../modal/DeleteModal";
import { onActivateDeactivateEvent, onDeleteEvent } from "../../service/event";

const EventDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const eventsTable = useTable<Event>("events", api.event.event);

    const [isOpen, setIsOpen] = useState(false);
    const [event, setEvent] = useState<Event>();

    usePathName([{ pathName: "Events", href: "/events" }]);

    const onDeleteClick = useCallback((record: Event) => {
        setEvent(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (event?.eventId) {
            onDeleteEvent(event.eventId, dispatch, () => {
                eventsTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [event, eventsTable]);

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const onActivateDeactivate = useCallback(
        (payload: ActivateDeactivateEvent) => {
            onActivateDeactivateEvent(payload, dispatch, eventsTable.fetchApi);
        },
        [eventsTable]
    );

    const eventCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: Event, srNumber: number) => {
                return <span>{srNumber}</span>;
            },
        },
        {
            title: "EVENT NAME",
            key: "nameEn",
            sortable: true,
            render: (record: Event) => {
                return <span>{record?.nameEn}</span>;
            },
        },
        {
            title: "LOCATION",
            key: "locationEn",
            render: (record: Event) => {
                return <span>{record?.locationEn}</span>;
            },
        },
        {
            title: "DATE",
            key: "eventDate",
            sortable: true,
            render: (record: Event) => {
                return <span>{record?.eventDate}</span>;
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            render: (record: Event) => {
                const payload = {
                    eventId: record?.eventId,
                };
                return (
                    <>
                        <Tooltip id="simpleTooltipActiveDeactive" place="top" />
                        <div
                            className="form-switch form-check flex justify-center flex-col"
                            data-tooltip-id="simpleTooltipActiveDeactive"
                            data-tooltip-content={record?.isActive ? "Active" : "Inactive"}
                        >
                            <input
                                id="isActive"
                                name="isActive"
                                onChange={() => onActivateDeactivate(payload)}
                                checked={record?.isActive}
                                className={"form-check-input"}
                                type="checkbox"
                            />
                        </div>
                    </>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: Event) => {
                return (
                    <div className="flex justify-center items-center text-primary">
                        <Tooltip id="simpleTooltipEditDelete" place="top" />
                        <div
                            className="flex items-center mr-3 cursor-pointer"
                            data-tooltip-id="simpleTooltipEditDelete"
                            data-tooltip-content={"Edit event"}
                            onClick={() => navigate(`/events/edit/${record?.eventId}`)}
                        >
                            <Edit className="w-4 h-4 mr-1" />
                            <span className="pt-[2px] leading-[16px]">Edit</span>
                        </div>
                        <div
                            className="flex items-center text-danger cursor-pointer"
                            data-tooltip-id="simpleTooltipEditDelete"
                            data-tooltip-content={"Delete event"}
                            onClick={() => onDeleteClick(record)}
                        >
                            <Trash2 className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Delete</span>
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this events?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={eventCols}
                    data={eventsTable.tableData ?? []}
                    tableKey="eventId"
                    reload={eventsTable.fetchApi}
                    currentOrder={eventsTable.currentOrder}
                    error={eventsTable.error}
                    loading={eventsTable.loading}
                    tableOffset={eventsTable.tableOffset}
                    onSetOrderBy={eventsTable.onSetOrderBy}
                    component={
                        <>
                            <SearchForm
                                resetSearch={eventsTable.resetSearch}
                                onSearch={eventsTable.onSearch}
                                searchValue={eventsTable.search}
                                searchPlaceHolder="Search by event name"
                            />
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new event"
                                    onClick={() => {
                                        navigate("/events/add");
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default EventDetails;
