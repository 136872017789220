import { useState, useCallback } from "react";
import { Edit, Trash2 } from "react-feather";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ActivateDeactivateCategory, Alignment, Category } from "../../helper/interface";
import usePathName from "../../hooks/usePathName";
import CustomTable from "../table/CustomTable";
import useTable from "../../hooks/useTable";
import { api } from "../../config/Api";
import SearchForm from "../table/SearchForm";
import DeleteModal from "../../modal/DeleteModal";
import { onActivateDeactivateCategory, onDeleteCategory } from "../../service/category";

const Categories = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [category, setCategory] = useState<Category | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const categoriesTable = useTable<Category>("categories", api.category);

    usePathName([{ pathName: "Category", href: "/category" }]);

    const onDeleteClick = useCallback((record: Category) => {
        setCategory(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (category?.categoryId) {
            onDeleteCategory(category.categoryId, dispatch, () => {
                categoriesTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [category, categoriesTable]);

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const onActivateDeactivate = useCallback(
        (payload: ActivateDeactivateCategory) => {
            onActivateDeactivateCategory(payload, dispatch, categoriesTable.fetchApi);
        },
        [categoriesTable]
    );

    const categoryCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: Category, srNumber: number) => {
                return <span>{srNumber}</span>;
            },
        },
        {
            title: "NAME",
            key: "nameEn",
            sortable: true,
            render: (record: Category) => {
                return <span>{record?.nameEn}</span>;
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            render: (record: Category) => {
                const payload = {
                    categoryId: record?.categoryId,
                    isActive: !record?.isActive,
                };
                return (
                    <>
                        <Tooltip id="simpleTooltipActiveDeactive" place="top" />
                        <div
                            className="form-switch form-check flex justify-center flex-col"
                            data-tooltip-id="simpleTooltipActiveDeactive"
                            data-tooltip-content={record?.isActive ? "Active" : "Inactive"}
                        >
                            <input
                                id="isActive"
                                name="isActive"
                                onChange={() => onActivateDeactivate(payload)}
                                checked={record?.isActive}
                                className={"form-check-input"}
                                type="checkbox"
                            />
                        </div>
                    </>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: Category) => {
                return (
                    <div className="flex justify-center items-center text-primary">
                        <Tooltip id="simpleTooltipEditDelete" place="top" />
                        <div
                            className="flex items-center mr-3 cursor-pointer"
                            data-tooltip-id="simpleTooltipEditDelete"
                            data-tooltip-content={"Edit category"}
                            onClick={() => navigate(`/category/edit/${record?.categoryId}`)}
                        >
                            <Edit className="w-4 h-4 mr-1" />
                            <span className="pt-[2px] leading-[16px]">Edit</span>
                        </div>
                        <div
                            className="flex items-center text-danger cursor-pointer"
                            data-tooltip-id="simpleTooltipEditDelete"
                            data-tooltip-content={"Delete category"}
                            onClick={() => onDeleteClick(record)}
                        >
                            <Trash2 className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Delete</span>
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this categories?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={categoryCols}
                    data={categoriesTable.tableData ?? []}
                    tableKey="categoryId"
                    reload={categoriesTable.fetchApi}
                    currentOrder={categoriesTable.currentOrder}
                    error={categoriesTable.error}
                    loading={categoriesTable.loading}
                    tableOffset={categoriesTable.tableOffset}
                    onSetOrderBy={categoriesTable.onSetOrderBy}
                    component={
                        <>
                            <SearchForm
                                resetSearch={categoriesTable.resetSearch}
                                onSearch={categoriesTable.onSearch}
                                searchValue={categoriesTable.search}
                                searchPlaceHolder="Search by category name"
                            />
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new category"
                                    onClick={() => {
                                        navigate("/category/add");
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default Categories;
