import { number, object, ref, string, date, array, mixed, boolean } from "yup";
import {
    FIVE,
    HUNDRED,
    MIN_PASSWORD_LENGTH,
    ONE,
    STRING_LIMITS,
    ZERO,
    mobileNumberRegex,
    passwordRegex,
    stringRegex,
    maxGlobalSettings,
    TEN,
    TWENTY,
    EIGHT,
    FIFTEEN,
} from "./constant";
import { dataGu } from "./dataGu";
import { dataEn } from "./dataEn";
import { VerificationStatus } from "./interface";

const ANNUAL_YEAR = {
    MIN: 2000,
    MAX: 3000,
};

const AMOUNT = {
    MIN: 100,
    MAX: 10000,
};

const stringValidation = string().trim();
const numberValidation = number();
const integerValidation = numberValidation.integer("Number should be valid integer").typeError("Please enter valid number");
const dateValidation = date();
const dateOfBirthValidation = date().max(new Date(), "Please select valid birth date").required("Please enter date of birth");

const MOBILE_LENGTH = {
    min: TEN,
    max: TWENTY,
};

const MARKS_LENGTH = {
    min: ONE,
    max: HUNDRED,
};

export const mobileValidation = stringValidation
    .matches(mobileNumberRegex, "Mobile number is not valid")
    .typeError("Invalid phone number")
    .min(MOBILE_LENGTH.min, `Mobile length should be at least ${TEN} digits`)
    .max(MOBILE_LENGTH.max, `Mobile length can't greater then ${FIFTEEN} digits`);

export const searchFormValidation = object({
    search: string().required("Please enter value"),
});

export const stringValidationWithLimit = stringValidation
    .max(STRING_LIMITS._50, `Maximum ${STRING_LIMITS._50} character allowed`)
    .matches(stringRegex, "Must contain at least one character");

const emailValidation = stringValidation.email("Invalid email").matches(/@[^.]*\./, "Invalid email");

export const loginValidation = object({
    email: emailValidation.required("Please enter email"),
    password: string().min(MIN_PASSWORD_LENGTH, `Password length must be minimum ${EIGHT} character`).required("Please enter password"),
});

export const forgotPasswordValidation = object({
    email: emailValidation.required("Please enter email"),
});

export const setPasswordValidation = object({
    newPassword: string()
        .required("Please enter new password")
        .matches(passwordRegex, `Must Contain ${EIGHT} Characters, One Uppercase, One Lowercase and One Number`),
    confirmPassword: string()
        .oneOf([ref("newPassword")], "Passwords must match")
        .required("Please enter password again"),
});

export const changePasswordValidation = object({
    oldPassword: stringValidation.required("Please enter your current password"),
    newPassword: stringValidation
        .required("Please enter new password")
        .notOneOf([ref("oldPassword")], "Passwords must be different from old password")
        .matches(passwordRegex, `Must Contain ${EIGHT} Characters, One Uppercase, One Lowercase and One Number`),
    confirmPassword: stringValidation.oneOf([ref("newPassword")], "Passwords must match").required("Please enter new password again"),
});

export const addEditSubAdminValidation = object({
    name: stringValidation.required("Please enter name"),
    email: emailValidation.required("Please enter email"),
    mobile: mobileValidation.required("Please enter mobile number"),
});

export const addVillageValidation = object({
    nameEn: stringValidation.required(dataEn.writeVillageName),
    nameGu: stringValidation.required(dataGu.writeVillageName),
    subDistrictEn: stringValidation.required(dataEn.writeSubDistrictName),
    subDistrictGu: stringValidation.required(dataGu.writeSubDistrictName),
    districtEn: stringValidation.required(dataEn.writeDistrictName),
    districtGu: stringValidation.required(dataGu.writeDistrictName),
});

export const addFamilyValidation = object({
    villageId: numberValidation.required("Please select village"),
    email: emailValidation.required("Please enter email"),
    mobile: mobileValidation.required("Please enter mobile number"),
    gender: stringValidation.required("Please select gender"),
    fullNameEn: stringValidation.required(dataEn.writePrimaryMember),
    fullNameGu: stringValidation.required(dataGu.writePrimaryMember),
    addressEn: stringValidation.required(dataEn.writeAddress),
    addressGu: stringValidation.required(dataGu.writeAddress),
    occupationId: numberValidation.required("Please select occupation"),
    dob: dateOfBirthValidation.required("Please select date of birth"),
});

export const editFamilyValidation = object({
    primaryMemberId: numberValidation.required("Please select member"),
    familyId: numberValidation.required("Please select family"),
    mobile: mobileValidation.required("Please enter mobile number"),
    villageId: numberValidation.required("Please select village"),
    addressEn: stringValidation.required(dataEn.writeAddress),
    addressGu: stringValidation.required(dataGu.writeAddress),
});

export const memberValidation = object({
    fullNameEn: stringValidation.required(dataEn.writePrimaryMember),
    fullNameGu: stringValidation.required(dataGu.writePrimaryMember),
    mobile: mobileValidation.nullable(),
    familyId: numberValidation.required("Please select family"),
    gender: stringValidation.required("Please select gender"),
    occupationId: numberValidation.required("Please select occupation"),
    dob: dateOfBirthValidation.required("Please select date of birth"),
});

export const educationYearValidation = object({
    year: stringValidation.required("Please select year"),
});

export const educationStandardValidation = object({
    name: stringValidation.required("Please write name"),
});

export const educationMediumValidation = object({
    nameEn: stringValidation.required(dataEn.writeMedium),
    nameGu: stringValidation.required(dataGu.writeMedium),
});

export const educationBoardValidation = object({
    nameEn: stringValidation.required(dataEn.writeBoard),
    nameGu: stringValidation.required(dataGu.writeBoard),
});

export const donorValidation = object({
    memberId: numberValidation.required("Please select member"),
    lifeLong: boolean().required(),
    amount: numberValidation
        .nullable()
        .typeError("Please enter valid amount")
        .when("lifeLong", (value) => {
            if (value[0]) {
                return numberValidation.required("Please enter amount");
            }
            return numberValidation.nullable();
        }),
    descriptionEn: stringValidation.required(dataEn.writeDescription),
    descriptionGu: stringValidation.required(dataGu.writeDescription),
    annualYear: object().required("Please select annual year"),
    date: dateValidation.required("Please select date"),
    familyId: integerValidation.required("Please select a family"),
});

export const categoryValidation = object({
    nameEn: stringValidation.required(dataEn.writeCategoryName),
    nameGu: stringValidation.required(dataGu.writeCategoryName),
});

export const addEditAdvertisementValidation = object({
    titleEn: stringValidation.required(dataEn.writeAdvertisementTitle),
    titleGu: stringValidation.required(dataGu.writeAdvertisementTitle),
    descriptionEn: stringValidation.required(dataEn.writeAdvertisementDescription),
    descriptionGu: stringValidation.required(dataGu.writeAdvertisementDescription),
    email: emailValidation.notRequired(),
    mobile: mobileValidation.required("Please enter owner mobile"),
    image: mixed().required("Please select a image you want to display in application"),
});

const integerValidationForGlobalSettings = integerValidation
    .min(ZERO, `Value should be not less than ${ZERO}`)
    .max(maxGlobalSettings, `Value should be not greater than ${maxGlobalSettings}`);

export const updateGlobalSettingValidation = object({
    businessLimitForUser: integerValidationForGlobalSettings,
    businessTagLimit: integerValidationForGlobalSettings,
    markSheetImageLimit: integerValidationForGlobalSettings,
    contactUsEmail: emailValidation.required("Please enter contact us email"),
    deactivationEmail: emailValidation.required("Please enter deactivation email"),
    aboutEn: stringValidation.required(dataEn.fieldRequired),
    aboutGu: stringValidation.required(dataEn.fieldRequired),
});

export const businessValidation = object({
    memberId: numberValidation.required("Please select member"),
    categoryId: numberValidation.required("Please select category"),
    businessName: stringValidation.required("Please enter business name"),
    mobile: mobileValidation.required("Please enter mobile number"),
    familyId: integerValidation.required("Please select a family"),
    location: stringValidation.required("Please enter location"),
    verified: stringValidation.required("Please select value"),
    reason: stringValidation.when("verified", (value) => {
        if (String(value) === VerificationStatus.REJECTED) {
            return stringValidation.required("Please enter reason");
        }
        return stringValidation.nullable();
    }),
});

export const eventValidation = object({
    nameEn: stringValidation.required(dataEn.writeEventName),
    nameGu: stringValidation.required(dataGu.writeEventName),
    locationEn: stringValidation.required(dataEn.writeEventLocation),
    locationGu: stringValidation.required(dataGu.writeEventLocation),
    eventDate: dateValidation.required("Please enter date"),
    images: array().of(mixed()),
    coverImg: mixed().required("Please select a image"),
});

export const addEditAnnualContributionValidation = object({
    year: integerValidation
        .min(ANNUAL_YEAR.MIN, `Please enter year more than ${ANNUAL_YEAR.MIN}`)
        .max(ANNUAL_YEAR.MAX, `Please enter year less than ${ANNUAL_YEAR.MAX}`)
        .required("Please enter year")
        .typeError("Enter valid year"),
    amount: integerValidation
        .min(AMOUNT.MIN, `Please enter amount more than ${AMOUNT.MIN}`)
        .max(AMOUNT.MAX, `Please enter amount less than ${AMOUNT.MAX}`)
        .required("Please enter amount")
        .typeError("Enter valid amount"),
});

export const annualContributionId = object({
    annualContributionId: numberValidation.required("Please select a annual year").typeError("Select valid year"),
});

export const addEditContributionHistoryValidation = object({
    paidDate: dateValidation.required("Please select paid date").typeError("Please select valid date"),
    paidAmount: integerValidation
        .min(AMOUNT.MIN, `Please enter amount more than ${AMOUNT.MIN}`)
        .required("Please enter amount")
        .typeError("Enter valid amount"),
    familyId: integerValidation.required("Please select a family"),
    annualContribution: object().required("Please select a annual year"),
});

export const occupationValidation = object({
    nameEn: stringValidation.required(dataEn.writeOccupationName),
    nameGu: stringValidation.required(dataGu.writeOccupationName),
});

export const marksheetValidation = object({
    memberId: numberValidation.required("Please select member"),
    educationBoardId: numberValidation.required("Please select education board"),
    educationYearId: numberValidation.required("Please select education year"),
    educationStandardId: numberValidation.required("Please select education standard"),
    educationMediumId: numberValidation.required("Please select education medium"),
    marksType: stringValidation.required("Please enter marks type"),
    marks: number()
        .typeError("Please enter valid number")
        .required("Please enter total marks")
        .positive("Please enter valid number")
        .min(ONE, `The marks must be greater than ${ZERO}!`)
        .max(HUNDRED, `The marks must be less than ${HUNDRED}!`),
    familyId: integerValidation.required("Please select a family"),
    verified: stringValidation.required("Please select value"),
    reason: stringValidation.when("verified", (value) => {
        if (String(value) === VerificationStatus.REJECTED) {
            return stringValidation.required("Please enter reason");
        }
        return stringValidation.nullable();
    }),
    images: array().of(mixed()).min(ONE, `Please upload atleast ${ONE} image`).max(FIVE, `Maximum ${FIVE} images are allowed`),
});

export const prizeValidation = object({
    memberId: stringValidation.required("Please select member"),
    educationBoardId: stringValidation.required("Please select education-board"),
    educationYearId: stringValidation.required("Please select education-year"),
    educationStandardId: stringValidation.required("Please select education-standard"),
    educationMediumId: stringValidation.required("Please select education-medium"),
    marksType: stringValidation.required("Please select marks type"),
    prizeRankId: stringValidation.required("Please select rank"),
    familyId: integerValidation.required("Please select a family"),
    marks: numberValidation
        .min(MARKS_LENGTH.min, `Marks should be at least ${ONE}`)
        .max(MARKS_LENGTH.max, `Marks can't greater than ${HUNDRED}`)
        .typeError("That doesn't look like a marks")
        .positive("A marks can't start with a minus")
        .nullable()
        .required("Please write marks"),
});

export const prizeRanksValidation = object({
    rank: integerValidation
        .min(ZERO, "Value must be greater than 0")
        .max(HUNDRED, "Value must be less than 100")
        .required(dataEn.writePrizeRank),
});

export const sendNotificationValidation = object({
    titleEn: stringValidation.required(dataEn.fieldRequired),
    titleGu: stringValidation.required(dataGu.fieldRequired),
    descriptionEn: stringValidation.required(dataEn.fieldRequired),
    descriptionGu: stringValidation.required(dataGu.fieldRequired),
});

export const adminDirectoryValidation = object({
    nameEn: stringValidation.required(dataEn.fieldRequired),
    nameGu: stringValidation.required(dataGu.fieldRequired),
    email: emailValidation.nullable(),
    mobile: mobileValidation.required(dataEn.fieldRequired),
});
