import { Tooltip } from "react-tooltip";
import { api } from "../../config/Api";
import { ActivateDeactivateVillage, Alignment, Village } from "../../helper/interface";
import useTable from "../../hooks/useTable";
import CustomTable from "../table/CustomTable";
import { useNavigate } from "react-router";
import { Edit, Trash2 } from "react-feather";
import SearchForm from "../table/SearchForm";
import DeleteModal from "../../modal/DeleteModal";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import usePathName from "../../hooks/usePathName";
import { onActivateDeactivateVillage, onDeleteVillage } from "../../service/villages";

const Villages = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const villagesTable = useTable<Village>("villages", api.village);

    const [isOpen, setIsOpen] = useState(false);
    const [village, setVillage] = useState<Village | null>(null);

    const onDeleteClick = useCallback((record: Village) => {
        setVillage(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (village?.villageId) {
            onDeleteVillage(village?.villageId, dispatch, () => {
                villagesTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [village, villagesTable]);

    const onActivateDeactivate = useCallback(
        (payload: ActivateDeactivateVillage) => {
            onActivateDeactivateVillage(payload, dispatch, villagesTable.fetchApi);
        },
        [villagesTable]
    );

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const villageCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: Village, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "VILLAGE",
            key: "nameEn",
            sortable: true,
            render: (record: Village) => {
                return <span>{record?.nameEn}</span>;
            },
        },
        {
            title: "SUB DISTRICT",
            key: "subDistrictEn",
            sortable: true,
            minWidth: true,
            render: (record: Village) => {
                return <span>{record?.subDistrictEn}</span>;
            },
        },
        {
            title: "DISTRICT",
            key: "districtEn",
            sortable: true,
            render: (record: Village) => {
                return <span>{record?.districtEn}</span>;
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            render: (record: Village) => {
                const payload = {
                    villageId: record?.villageId,
                    isActive: !record?.isActive,
                };
                return (
                    <>
                        <Tooltip id="simpleTooltipActiveDeactive" place="top" />
                        <div
                            className="form-switch form-check flex justify-center flex-col"
                            data-tooltip-id="simpleTooltipActiveDeactive"
                            data-tooltip-content={record?.isActive ? "Active" : "Inactive"}
                        >
                            <input
                                id="isActive"
                                name="isActive"
                                onChange={() => onActivateDeactivate(payload)}
                                checked={record?.isActive}
                                className={"form-check-input"}
                                type="checkbox"
                            />
                        </div>
                    </>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: Village) => {
                return (
                    <>
                        <div className="flex justify-center items-center text-primary">
                            <Tooltip id="simpleTooltipEditDelete" place="top" />
                            <div
                                className="flex items-center mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Edit village"}
                                onClick={() => navigate(`/villages/edit/${record?.villageId}`)}
                            >
                                <Edit className="w-4 h-4 mr-1" />
                                <span className="pt-[2px] leading-[16px]">Edit</span>
                            </div>
                            <div
                                className="flex items-center text-danger cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Delete village"}
                                onClick={() => onDeleteClick(record)}
                            >
                                <Trash2 className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Delete</span>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    usePathName([{ pathName: "Villages", href: "/villages" }]);

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this village?"
            />

            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={villageCols}
                    data={villagesTable.tableData ?? []}
                    tableKey="villageId"
                    reload={villagesTable.fetchApi}
                    currentOrder={villagesTable.currentOrder}
                    error={villagesTable.error}
                    loading={villagesTable.loading}
                    tableOffset={villagesTable.tableOffset}
                    onSetOrderBy={villagesTable.onSetOrderBy}
                    component={
                        <>
                            <SearchForm
                                resetSearch={villagesTable.resetSearch}
                                onSearch={villagesTable.onSearch}
                                searchValue={villagesTable.search}
                                searchPlaceHolder="Search by village name or district or sub district"
                            />
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new village"
                                    onClick={() => {
                                        navigate("/villages/add");
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default Villages;
