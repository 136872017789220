import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { Edit, Trash2 } from "react-feather";

import useTable from "../../hooks/useTable";
import { api } from "../../config/Api";
import DeleteModal from "../../modal/DeleteModal";
import CustomTable from "../table/CustomTable";
import usePathName from "../../hooks/usePathName";
import { Prize, Alignment, TableStateValue, FamiliesMetaFilter, EducationYearForSelect } from "../../helper/interface";
import { onDeletePrize } from "../../service/prize";
import Filter from "./Filter";
import { Formik } from "formik";
import useFetch from "../../hooks/useFetch";

let initialValues: FamiliesMetaFilter = {
    searchBy: "Year",
};

const Prizes = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const tableFilterData = useSelector((state: TableStateValue) => state.table.tableData);

    useEffect(() => {
        if (tableFilterData.metaFilter && tableFilterData.metaFilter.value) {
            const metaFilter = tableFilterData.metaFilter;

            initialValues = {
                searchBy: metaFilter.searchBy,
                value: metaFilter.value,
            };
        } else {
            initialValues = {
                searchBy: "Year",
                value: null,
            };
        }
    }, [tableFilterData.metaFilter]);

    const prizeTable = useTable<Prize>("Prize", api.prize);

    const { res: educationYear } = useFetch<EducationYearForSelect[]>(api.marksheet.educationYear);

    useEffect(() => {
        if (educationYear?.data && educationYear.data[0] && !tableFilterData.metaFilter) {
            initialValues = {
                searchBy: "Year",
                value: `${educationYear.data[0].educationYearId}`,
            };
            prizeTable.onSearch("", initialValues);
        }
    }, [educationYear]);

    const [isOpen, setIsOpen] = useState(false);
    const [prize, setPrize] = useState<Prize | null>(null);

    usePathName([{ pathName: "Prize", href: "/prize" }]);

    const onDeleteClick = useCallback((record: Prize) => {
        setPrize(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (prize?.prizeId) {
            onDeletePrize(prize?.prizeId, dispatch, () => {
                prizeTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [prize, prizeTable]);

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const prizeCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: Prize, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "NAME",
            key: "fullNameEn",
            sortable: false,
            render: (record: Prize) => {
                return <span>{record?.member?.fullNameEn}</span>;
            },
        },
        {
            title: "YEAR",
            key: "educationYear",
            sortable: true,
            render: (record: Prize) => {
                return <span>{record?.educationYear?.year ?? "-"}</span>;
            },
        },
        {
            title: "STANDARD",
            key: "educationStandard",
            sortable: true,
            minWidth: true,
            render: (record: Prize) => {
                return <span>{record?.educationStandard.name ?? "-"}</span>;
            },
        },
        {
            title: "BOARD",
            key: "educationBoard",
            sortable: true,
            render: (record: Prize) => {
                return <span>{record?.educationBoard?.nameEn ?? "-"}</span>;
            },
        },
        {
            title: "MEDIUM",
            key: "educationMedium",
            sortable: true,
            render: (record: Prize) => {
                return <span>{record?.educationMedium?.nameEn ?? "-"}</span>;
            },
        },
        {
            title: "MOBILE",
            key: "mobile",
            sortable: false,
            render: (record: Prize) => {
                return <span>{record?.member?.family?.mobile ?? "-"}</span>;
            },
        },
        {
            title: "RANK",
            key: "rank",
            sortable: true,
            render: (record: Prize) => {
                return <span>{record?.rank?.rank ?? "-"}</span>;
            },
        },
        {
            title: "MARKS",
            key: "marks",
            sortable: true,
            render: (record: Prize) => {
                return <span>{record?.marks}</span>;
            },
        },
        {
            title: "MARKS TYPE",
            key: "markstype",
            render: (record: Prize) => {
                return <span>{record?.marksType}</span>;
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: Prize) => {
                return (
                    <>
                        <div className="flex justify-center items-center text-primary">
                            <Tooltip id="simpleTooltipEditDelete" place="top" />
                            <div
                                className="flex items-center mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Edit prize"}
                                onClick={() => navigate(`/prize/edit/${record?.prizeId}`)}
                            >
                                <Edit className="w-4 h-4 mr-1" />
                                <span className="pt-[2px] leading-[16px]">Edit</span>
                            </div>
                            <div
                                className="flex items-center text-danger cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Delete prize"}
                                onClick={() => onDeleteClick(record)}
                            >
                                <Trash2 className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Delete</span>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    const onMetaFilteredSubmit = (value: FamiliesMetaFilter) => {
        const metaFilter = {
            searchBy: value.searchBy,
            value: value.value,
        };

        prizeTable.onSearch("", metaFilter);
    };

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this prize?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={prizeCols}
                    data={prizeTable.tableData ?? []}
                    tableKey="prizeId"
                    reload={prizeTable.fetchApi}
                    currentOrder={prizeTable.currentOrder}
                    error={prizeTable.error}
                    loading={prizeTable.loading}
                    tableOffset={prizeTable.tableOffset}
                    onSetOrderBy={prizeTable.onSetOrderBy}
                    className="xl:justify-between xl:items-center"
                    component={
                        <>
                            <Formik initialValues={initialValues} enableReinitialize onSubmit={onMetaFilteredSubmit}>
                                {(props) => <Filter {...props} reload={prizeTable.fetchApi} />}
                            </Formik>
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto whitespace-nowrap"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new prize"
                                    onClick={() => {
                                        navigate("/prize/add");
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default Prizes;
