import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { PathValue } from "../helper/interface";
import { setPagePath } from "../store/slice/BaseSlice";

const usePathName = (props: PathValue[]) => {
    const dispatch = useDispatch();

    const onSetPage = useCallback(() => {
        dispatch(setPagePath(props));
    }, [props]);

    useEffect(() => {
        onSetPage();
    }, []);

    return { onSetPage };
};

export default usePathName;
