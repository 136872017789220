import { Formik, FormikHelpers } from "formik";
import { FC, useCallback } from "react";
import { ArrowLeftCircle } from "react-feather";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import usePathName from "../../hooks/usePathName";
import { PrizeRanksPayload } from "../../helper/interface";
import { prizeRanksValidation } from "../../helper/validationSchema";
import { addSuccessToast } from "../../helper/toast";
import { onAddPrizeRanks } from "../../service/prizeRanks";
import AddEditForm from "./AddEditForm";

const initialValues: PrizeRanksPayload = {
    rank: 0,
};

const AddPrizeRank: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    usePathName([
        { pathName: "Prize Ranks", href: "/ranks" },
        { pathName: "Add", href: "/ranks/add " },
    ]);

    const onSubmit = useCallback((values: PrizeRanksPayload, { resetForm }: FormikHelpers<PrizeRanksPayload>) => {
        onAddPrizeRanks(values, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Add new",
                primaryLink: "/ranks/add",
                secondaryLinkTitle: "Edit",
                secondaryLink: `/ranks/edit/${res.data.prizeRankId}`,
            };

            addSuccessToast(res.message, navigate, toastLink);
            resetForm();
            navigate("/ranks");
        });
    }, []);

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/ranks")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Add new prize ranks</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    <Formik initialValues={initialValues} validationSchema={prizeRanksValidation} onSubmit={onSubmit}>
                        {(props) => <AddEditForm {...props} />}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AddPrizeRank;
