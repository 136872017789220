import { Formik, Form, FormikHelpers } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../container/Loading";
import usePathName from "../../hooks/usePathName";
import { SendNotificationPayload, TableStateValue } from "../../helper/interface";
import { onEditSendNotification } from "../../service/sendNotification";
import { sendNotificationValidation } from "../../helper/validationSchema";
import { dataGu } from "../../helper/dataGu";

const initialValues = {
    titleEn: "",
    titleGu: "",
    descriptionEn: "",
    descriptionGu: "",
};

const SendNotification = () => {
    const dispatch = useDispatch();

    const loading = useSelector((state: TableStateValue) => state.table.loading);

    usePathName([{ pathName: "Send Notification", href: "/send-notification" }]);

    const onSubmit = (value: SendNotificationPayload, { resetForm }: FormikHelpers<SendNotificationPayload>) => {
        const payload = {
            titleEn: value.titleEn,
            titleGu: value.titleGu,
            descriptionEn: value.descriptionEn,
            descriptionGu: value.descriptionGu,
        };

        onEditSendNotification(payload, dispatch, () => {
            resetForm();
        });
    };

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-1 font-medium text-center text-lg -ml-2">Send Notification</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {loading ? (
                    <Loading />
                ) : initialValues ? (
                    <Formik initialValues={initialValues} validationSchema={sendNotificationValidation} onSubmit={onSubmit}>
                        {({ values, handleChange, handleBlur, errors, touched, dirty }) => (
                            <Form className="globalsetting-form">
                                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <label htmlFor="titleEn" className="form-label">
                                            Title (English)
                                        </label>
                                        <div className="input-group">
                                            <input
                                                id="titleEn"
                                                name="titleEn"
                                                type="text"
                                                onChange={handleChange}
                                                value={values.titleEn}
                                                onBlur={handleBlur}
                                                className={`form-control ${errors.titleEn && touched.titleEn && "border-danger"}`}
                                            />
                                        </div>
                                        {errors.titleEn && touched.titleEn && <div className="text-danger">{errors.titleEn}</div>}
                                    </div>
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <label htmlFor="titleGu" className="form-label">
                                            {dataGu.title} ({dataGu.gujarati})
                                        </label>
                                        <div className="input-group">
                                            <input
                                                id="titleGu"
                                                name="titleGu"
                                                type="text"
                                                onChange={handleChange}
                                                value={values.titleGu}
                                                onBlur={handleBlur}
                                                className={`form-control ${errors.titleGu && touched.titleGu && "border-danger"}`}
                                            />
                                        </div>
                                        {errors.titleGu && touched.titleGu && <div className="text-danger">{errors.titleGu}</div>}
                                    </div>
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <label htmlFor="descriptionEn" className="form-label">
                                            Description (English)
                                        </label>
                                        <div className="input-group">
                                            <textarea
                                                id="descriptionEn"
                                                name="descriptionEn"
                                                onChange={handleChange}
                                                value={values.descriptionEn}
                                                onBlur={handleBlur}
                                                className={`form-control ${
                                                    errors.descriptionEn && touched.descriptionEn && "border-danger"
                                                }`}
                                            />
                                        </div>
                                        {errors.descriptionEn && touched.descriptionEn && (
                                            <div className="text-danger">{errors.descriptionEn}</div>
                                        )}
                                    </div>
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <label htmlFor="descriptionGu" className="form-label">
                                            {dataGu.describe} ({dataGu.gujarati})
                                        </label>
                                        <div className="input-group">
                                            <textarea
                                                id="descriptionGu"
                                                name="descriptionGu"
                                                onChange={handleChange}
                                                value={values.descriptionGu}
                                                onBlur={handleBlur}
                                                className={`form-control ${
                                                    errors.descriptionGu && touched.descriptionGu && "border-danger"
                                                }`}
                                            />
                                        </div>
                                        {errors.descriptionGu && touched.descriptionGu && (
                                            <div className="text-danger">{errors.descriptionGu}</div>
                                        )}
                                    </div>

                                    <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                                        <button className="btn btn-secondary w-24" type="reset" disabled={loading || !dirty}>
                                            Reset
                                        </button>
                                        <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                                            {loading ? <Loading isButton /> : "Send"}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : null}
            </div>
        </div>
    );
};

export default SendNotification;
