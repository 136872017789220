import { AxiosError } from "axios";
import { setLoading } from "../store/slice/TableDataSlice";
import { handleCatchResponse } from "../helper/helper";
import { Dispatch } from "redux";
import { dataService } from "../config/DataService";
import { api } from "../config/Api";
import { errorToast, successToast } from "../helper/toast";
import { Marksheet, MarksheetPayload, Response } from "../helper/interface";

export const onAddMarksheet = async (
    data: MarksheetPayload,
    dispatch: Dispatch,
    callback: (res: Required<Response<Marksheet>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            educationBoardId: data.educationBoardId,
            educationMediumId: data.educationMediumId,
            educationStandardId: data.educationStandardId,
            educationYearId: data.educationYearId,
            marks: data.marks,
            images: data.images,
            marksType: data.marksType,
            memberId: data.memberId,
            reason: data.reason,
            verified: data.verified,
        };

        const response = await dataService.post(api.marksheet.upload, payload, { headers: { "Content-Type": "multipart/form-data" } });
        const res: Required<Response<Marksheet>> = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onEditMarksheet = async (
    payload: MarksheetPayload,
    dispatch: Dispatch,
    callback: (res: Required<Response<Marksheet>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.put(api.marksheet.upload, payload, { headers: { "Content-Type": "multipart/form-data" } });
        const res: Required<Response<Marksheet>> = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeleteMarksheet = async (markSheetId: number, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.marksheet.upload}/${markSheetId}`);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
