import { useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import { ArrowLeftCircle } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import usePathName from "../../hooks/usePathName";
import AddEditForm from "./AddEditForm";
import { onAddBusiness } from "../../service/business";
import { addSuccessToast } from "../../helper/toast";
import { BusinessPayload, VerificationStatus } from "../../helper/interface";
import { businessValidation } from "../../helper/validationSchema";

const AddBusiness = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const initialValues: BusinessPayload = {
        memberId: null,
        categoryId: null,
        businessName: "",
        mobile: "",
        location: "",
        businessImg: "",
        familyId: null,
        tags: [],
        verified: VerificationStatus.APPROVED,
        reason: "",
    };

    usePathName([
        { pathName: "Business", href: "/business" },
        { pathName: "Add", href: "/business/add" },
    ]);

    const onSubmit = useCallback((values: BusinessPayload, { resetForm }: FormikHelpers<BusinessPayload>) => {
        onAddBusiness(values, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Add new",
                primaryLink: "/business/add",
                secondaryLinkTitle: "Edit",
                secondaryLink: `/business/edit/${res.data.businessId}`,
            };

            addSuccessToast(res.message, navigate, toastLink);
            resetForm();
            navigate("/business");
        });
    }, []);

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/business")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Add new business</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    <Formik initialValues={initialValues} validationSchema={businessValidation} onSubmit={onSubmit}>
                        {(props) => <AddEditForm {...props} />}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AddBusiness;
