import { Dispatch } from "@reduxjs/toolkit";
import { api } from "../config/Api";
import { dataService } from "../config/DataService";
import { setLoading } from "../store/slice/TableDataSlice";
import { AxiosError } from "axios";
import { errorToast, successToast } from "../helper/toast";
import { handleCatchResponse } from "../helper/helper";
import { ActivateDeactivateDonor, Donor, DonorPayload, Response } from "../helper/interface";

export const onAddDonor = async (data: DonorPayload, dispatch: Dispatch, callback: (res: Required<Response<Donor>>) => void) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            annualContributionId: data?.annualYear?.annualContributionId,
            amount: data?.amount ? data.amount : null,
            date: data.date,
            descriptionEn: data.descriptionEn,
            descriptionGu: data.descriptionGu,
            memberId: data.memberId,
            lifeLong: data.lifeLong,
        };

        const response = await dataService.post(api.donor, payload);
        const res: Required<Response<Donor>> = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onEditDonor = async (data: DonorPayload, dispatch: Dispatch, callback: (res: Required<Response<DonorPayload>>) => void) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            annualContributionId: data?.annualYear?.annualContributionId,
            amount: data?.amount ? data.amount : null,
            date: data.date,
            descriptionEn: data.descriptionEn,
            descriptionGu: data.descriptionGu,
            memberId: data.memberId,
            donorId: data.donorId,
            lifeLong: data.lifeLong,
        };

        const response = await dataService.put(api.donor, payload);
        const res = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeleteDonor = async (educationStandardId: number, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.donor}/${educationStandardId}`);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onActivateDeactivateDonor = async (data: ActivateDeactivateDonor, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            isActive: data.isActive,
        };

        const response = await dataService.patch(`${api.donor}/${data.donorId}`, payload);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
