import { SearchFormComponentParams, TableStateValue } from "../../helper/interface";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import { useCallback, useState } from "react";
import { TWENTY, ZERO } from "../../helper/constant";

const SearchForm = ({ resetSearch, onSearch, searchPlaceHolder, searchValue }: SearchFormComponentParams) => {
    const tableOffsetData = useSelector((state: TableStateValue) => state.table.tableData);
    const [search, setSearch] = useState(searchValue ?? "");

    const onChangeValue = useCallback((value: string) => {
        setSearch(value);
    }, []);

    const onSubmit = useCallback(() => {
        if (!search?.trim()) return;
        if (search?.trim()) {
            onSearch(search);
        } else {
            resetSearch();
        }
    }, [search]);

    const onReset = useCallback(() => {
        setSearch("");

        if (tableOffsetData.search || tableOffsetData?.child?.search) {
            resetSearch();
        }
    }, [tableOffsetData]);

    return (
        <div className="xl:flex sm:mr-auto">
            <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                <Tooltip id="simpleTooltipSearch" place="top" />
                <input
                    name="search"
                    type="text"
                    onKeyDown={(event) => event.key === "Enter" && onSubmit()}
                    autoComplete="off"
                    data-tooltip-id="simpleTooltipSearch"
                    data-tooltip-content={searchPlaceHolder ?? ""}
                    value={search ?? ""}
                    className={"form-control mr-2 sm:w-40 2xl:w-full mt-2 sm:mt-0"}
                    placeholder={searchPlaceHolder ? searchPlaceHolder?.slice(ZERO, TWENTY) + "..." : "Search..."}
                    onChange={(e) => onChangeValue(e.target.value)}
                />
            </div>
            <div className="mt-2 xl:mt-0">
                <button
                    type="submit"
                    disabled={tableOffsetData.search === search.trim() || !search.trim()}
                    className="btn btn-primary w-full sm:w-16"
                    onClick={() => onSubmit()}
                >
                    Go
                </button>
                <button
                    type="reset"
                    className="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-2"
                    disabled={Boolean(!(tableOffsetData.search || search?.trim()))}
                    onClick={onReset}
                >
                    Reset
                </button>
            </div>
        </div>
    );
};

export default SearchForm;
