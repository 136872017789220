import { useState, useCallback } from "react";

import { Edit, Trash2 } from "react-feather";
import { Alignment, AnnualContributionPayload } from "../../helper/interface";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import CustomTable from "../table/CustomTable";
import useTable from "../../hooks/useTable";
import { api } from "../../config/Api";
import SearchForm from "../table/SearchForm";
import DeleteModal from "../../modal/DeleteModal";
import { useDispatch } from "react-redux";
import { onDeleteAnnualContribution } from "../../service/annualContribution";
import usePathName from "../../hooks/usePathName";

const AnnualContribution = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    usePathName([{ pathName: "Annual contribution", href: "/annual-contribution" }]);

    const [annualContribution, setAnnualContribution] = useState<AnnualContributionPayload | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const annualContributionTable = useTable<AnnualContributionPayload>("annualContribution", api.annualContribution);

    const onDeleteClick = useCallback((record: AnnualContributionPayload) => {
        setAnnualContribution(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (annualContribution?.annualContributionId) {
            onDeleteAnnualContribution(annualContribution.annualContributionId, dispatch, () => {
                annualContributionTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [annualContribution, annualContributionTable]);

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const advertisementCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: AnnualContributionPayload, srNumber: number) => {
                return <span>{srNumber}</span>;
            },
        },
        {
            title: "YEAR",
            key: "year",
            sortable: true,
            render: (record: AnnualContributionPayload) => {
                return <span>{record.year}</span>;
            },
        },
        {
            title: "AMOUNT",
            key: "amount",
            sortable: true,
            render: (record: AnnualContributionPayload) => {
                return <span>{record.amount}</span>;
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: AnnualContributionPayload) => {
                return (
                    <div className="flex justify-center items-center text-primary">
                        <Tooltip id="simpleTooltipEditDelete" place="top" />
                        <div
                            className="flex items-center mr-3 cursor-pointer"
                            data-tooltip-id="simpleTooltipEditDelete"
                            data-tooltip-content={"Edit annualContribution"}
                            onClick={() => navigate(`/annual-contribution/edit/${record.annualContributionId}`)}
                        >
                            <Edit className="w-4 h-4 mr-1" />
                            <span className="pt-[2px] leading-[16px]">Edit</span>
                        </div>
                        <div
                            className="flex items-center text-danger cursor-pointer"
                            data-tooltip-id="simpleTooltipEditDelete"
                            data-tooltip-content={"Delete annualContribution"}
                            onClick={() => onDeleteClick(record)}
                        >
                            <Trash2 className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Delete</span>
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this annual contribution?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={advertisementCols}
                    data={annualContributionTable.tableData ?? []}
                    tableKey="annualContributionId"
                    reload={annualContributionTable.fetchApi}
                    currentOrder={annualContributionTable.currentOrder}
                    error={annualContributionTable.error}
                    loading={annualContributionTable.loading}
                    tableOffset={annualContributionTable.tableOffset}
                    onSetOrderBy={annualContributionTable.onSetOrderBy}
                    component={
                        <>
                            <SearchForm
                                resetSearch={annualContributionTable.resetSearch}
                                onSearch={annualContributionTable.onSearch}
                                searchValue={annualContributionTable.search}
                                searchPlaceHolder="Search by annual year or amount"
                            />
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new annualContribution"
                                    onClick={() => {
                                        navigate("/annual-contribution/add");
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default AnnualContribution;
