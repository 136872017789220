import { Formik } from "formik";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../config/Api";
import Loading from "../../container/Loading";
import useFetch from "../../hooks/useFetch";
import usePathName from "../../hooks/usePathName";
import Form from "./Form";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import { AdminDirectoryPayload } from "../../helper/interface";
import { adminDirectoryValidation } from "../../helper/validationSchema";
import { editSuccessToast } from "../../helper/toast";
import { onEditAdminDirectory } from "../../service/adminDirectory";

const EditAdminDirectory: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { adminDirectoryId = "" } = useParams();

    const { res, loading, error } = useFetch<AdminDirectoryPayload>(`${api.adminDirectory}/${adminDirectoryId}`);

    usePathName([
        { pathName: "Admin Directories", href: "/admin-directory" },
        { pathName: "Edit", href: `/admin-directory/edit/${adminDirectoryId}` },
        { pathName: `${adminDirectoryId}`, href: `/admin-directory/edit/${adminDirectoryId}` },
    ]);

    const onSubmit = useCallback(
        (values: AdminDirectoryPayload) => {
            const payload = {
                adminDirectoryId: +adminDirectoryId,
                nameEn: values.nameEn,
                nameGu: values.nameGu,
                mobile: values.mobile,
                email: values?.email ?? null,
            };

            onEditAdminDirectory(payload, dispatch, (res) => {
                const toastLink = {
                    primaryLinkTitle: "Edit",
                    primaryLink: `/admin-directory/edit/${res?.data?.adminDirectoryId ?? payload.adminDirectoryId}`,
                };

                editSuccessToast(res.message, navigate, toastLink);
                navigate("/admin-directory");
            });
        },
        [adminDirectoryId]
    );

    const initialValues = res?.data;

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4" onClick={() => navigate("/admin-directory")}>
                    <ArrowLeftCircle className="cursor-pointer text-primary" />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Edit admin directory</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {error ? (
                    <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                        <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                    </div>
                ) : loading ? (
                    <Loading />
                ) : initialValues ? (
                    <Formik initialValues={initialValues} validationSchema={adminDirectoryValidation} onSubmit={onSubmit}>
                        {(props) => <Form {...props} />}
                    </Formik>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default EditAdminDirectory;
