import { useCallback } from "react";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ArrowLeftCircle, AlertTriangle } from "react-feather";

import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import Loading from "../../container/Loading";
import usePathName from "../../hooks/usePathName";
import { editSuccessToast } from "../../helper/toast";
import { PrizePayload } from "../../helper/interface";
import AddEditForm from "./AddEditForm";
import { prizeValidation } from "../../helper/validationSchema";
import { onEditPrize } from "../../service/prize";

const EditPrize = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { prizeId = "" } = useParams();

    const { res, loading, error } = useFetch<PrizePayload>(`${api.prize}/${prizeId}`);

    usePathName([
        { pathName: "Prize", href: "/prize" },
        { pathName: "Edit", href: `/prize/edit/${prizeId}` },
    ]);

    const onSubmit = useCallback((value: PrizePayload) => {
        const payload = {
            prizeId: +prizeId,
            memberId: value.memberId,
            educationBoardId: value.educationBoardId,
            educationYearId: value.educationYearId,
            educationStandardId: value.educationStandardId,
            educationMediumId: value.educationMediumId,
            marksType: value.marksType,
            prizeRankId: value.prizeRankId,
            marks: value.marks,
        };
        onEditPrize(payload, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Edit",
                primaryLink: `/prize/edit/${res?.data?.prizeId ?? payload?.prizeId}`,
            };

            editSuccessToast(res.message, navigate, toastLink);
            navigate("/prize");
        });
    }, []);

    const initialValues = res?.data;

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/prize")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Edit prize details</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400 mx-auto">
                    {error ? (
                        <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                            <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                        </div>
                    ) : loading ? (
                        <Loading />
                    ) : initialValues ? (
                        <Formik initialValues={initialValues} validationSchema={prizeValidation} onSubmit={onSubmit}>
                            {(props) => <AddEditForm {...props} />}
                        </Formik>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default EditPrize;
