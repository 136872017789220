import { Form } from "formik";
import { useSelector } from "react-redux";

import { SubAdminPayload, FormParams, TableStateValue } from "../../helper/interface";
import Loading from "../../container/Loading";

const AddEditSubAdmin = ({ errors, touched, handleChange, values, handleBlur, dirty }: FormParams<SubAdminPayload>) => {
    const loading = useSelector((state: TableStateValue) => state.table.loading);

    return (
        <Form>
            <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="name" className="form-label">
                        Name
                    </label>
                    <input
                        id="name"
                        name="name"
                        type="text"
                        autoFocus
                        onChange={handleChange}
                        value={values.name}
                        onBlur={handleBlur}
                        className={`form-control ${errors.name && touched.name && "border-danger"}`}
                        placeholder="Example"
                    />
                    {errors.name && touched.name && <div className="text-danger">{errors.name}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="email" className="form-label">
                        Email
                    </label>
                    <input
                        id="email"
                        name="email"
                        type="text"
                        onChange={handleChange}
                        value={values.email}
                        onBlur={handleBlur}
                        className={`form-control ${errors.email && touched.email && "border-danger"}`}
                        placeholder="example@gmail.com"
                    />
                    {errors.email && touched.email && <div className="text-danger">{errors.email}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="mobile" className="form-label">
                        Mobile Number
                    </label>
                    <input
                        id="mobile"
                        name="mobile"
                        type="text"
                        onChange={handleChange}
                        value={values.mobile}
                        onBlur={handleBlur}
                        className={`form-control ${errors.mobile && touched.mobile && "border-danger"}`}
                        placeholder="+91 845778 45877"
                    />
                    {errors.mobile && touched.mobile && <div className="text-danger">{errors.mobile}</div>}
                </div>
                <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                    <button className="btn btn-secondary w-24" type="reset" disabled={loading || !dirty}>
                        Reset
                    </button>
                    <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                        {loading ? <Loading isButton /> : "Save"}
                    </button>
                </div>
            </div>
        </Form>
    );
};

export default AddEditSubAdmin;
