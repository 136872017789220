import { Formik, Form } from "formik";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Eye, EyeOff } from "react-feather";
import usePathName from "../../hooks/usePathName";
import { changePasswordValidation } from "../../helper/validationSchema";
import { ChangePasswordPayload, TableStateValue } from "../../helper/interface";
import { onChangePasswordSubmit } from "../../service/auth";
import Loading from "../../container/Loading";

const ChangePassword = () => {
    const dispatch = useDispatch();

    const loading = useSelector((state: TableStateValue) => state.table.loading);

    const [show, setShow] = useState({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false,
    });

    const onSubmit = useCallback((value: ChangePasswordPayload) => {
        onChangePasswordSubmit(value, dispatch);
    }, []);

    usePathName([{ pathName: "Change Password", href: "/change-password" }]);

    return (
        <div className="col-span-12 lg:col-span-8 2xl:col-span-9 w-80">
            <div className="intro-y box lg:mt-5">
                <Formik
                    initialValues={{ oldPassword: "", newPassword: "", confirmPassword: "" }}
                    validationSchema={changePasswordValidation}
                    onSubmit={(value, { resetForm }) => {
                        onSubmit(value);
                        resetForm();
                    }}
                >
                    {({ errors, touched, handleChange, values, handleBlur, resetForm, dirty }) => (
                        <>
                            <Form>
                                <div className="flex items-center p-4 border-b border-slate-200/60 dark:border-darkmode-400">
                                    <h2 className="font-medium text-base mr-auto">Change Password</h2>
                                </div>
                                <div className="p-5">
                                    <div className="mt-3">
                                        <label htmlFor="old-password" className="form-label">
                                            Current Password
                                        </label>
                                        <div className="relative w-full">
                                            <input
                                                id="old-password"
                                                type={`${show.oldPassword ? "text" : "password"}`}
                                                className="form-control"
                                                placeholder="Enter current password"
                                                name="oldPassword"
                                                value={values.oldPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <span
                                                className="absolute inset-y-0 right-0 flex items-center px-2 mr-3 cursor-pointer"
                                                onClick={() => setShow({ ...show, oldPassword: !show.oldPassword })}
                                            >
                                                {show.oldPassword ? <Eye className="w-4 h-4" /> : <EyeOff className="w-4 h-4" />}
                                            </span>
                                        </div>
                                        {errors.oldPassword && touched.oldPassword && (
                                            <div className="text-danger">{errors.oldPassword}</div>
                                        )}
                                    </div>
                                    <div className="mt-3">
                                        <label htmlFor="new-password" className="form-label">
                                            New Password
                                        </label>
                                        <div className="relative w-full">
                                            <input
                                                id="new-password"
                                                type={`${show.newPassword ? "text" : "password"}`}
                                                className="form-control"
                                                placeholder="Enter new password"
                                                name="newPassword"
                                                value={values.newPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <span
                                                className="absolute inset-y-0 right-0 flex items-center px-2 mr-3 cursor-pointer"
                                                onClick={() => setShow({ ...show, newPassword: !show.newPassword })}
                                            >
                                                {show.newPassword ? <Eye className="w-4 h-4" /> : <EyeOff className="w-4 h-4" />}
                                            </span>
                                        </div>
                                        {errors.newPassword && touched.newPassword && (
                                            <div className="text-danger">{errors.newPassword}</div>
                                        )}
                                    </div>
                                    <div className="mt-3">
                                        <label htmlFor="confirm-password" className="form-label">
                                            Confirm New Password
                                        </label>
                                        <div className="relative w-full">
                                            <input
                                                id="confirm-password"
                                                type={`${show.confirmPassword ? "text" : "password"}`}
                                                className="form-control"
                                                placeholder="Enter new password again"
                                                name="confirmPassword"
                                                value={values.confirmPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <span
                                                className="absolute inset-y-0 right-0 flex items-center px-2 mr-3 cursor-pointer"
                                                onClick={() => setShow({ ...show, confirmPassword: !show.confirmPassword })}
                                            >
                                                {show.confirmPassword ? <Eye className="w-4 h-4" /> : <EyeOff className="w-4 h-4" />}
                                            </span>
                                        </div>
                                        {errors.confirmPassword && touched.confirmPassword && (
                                            <div className="text-danger">{errors.confirmPassword}</div>
                                        )}
                                    </div>
                                    <div className="ml-16">
                                        <button
                                            className="btn btn-secondary mt-5 mr-3"
                                            type="reset"
                                            disabled={loading || !dirty}
                                            onClick={() => {
                                                resetForm();
                                            }}
                                        >
                                            Reset
                                        </button>
                                        <button className="btn btn-primary mt-5" type="submit" disabled={loading || !dirty}>
                                            {loading ? <Loading isButton /> : "Change Password"}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default ChangePassword;
