import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useCallback, useState, useEffect } from "react";
import { Formik } from "formik";
import { Edit, Eye, Trash2 } from "react-feather";
import { Tooltip } from "react-tooltip";

import useTable from "../../../hooks/useTable";
import usePathName from "../../../hooks/usePathName";
import DeleteModal from "../../../modal/DeleteModal";
import CustomTable from "../../table/CustomTable";
import { api } from "../../../config/Api";
import { onDeleteMarksheet } from "../../../service/marksheet";
import {
    Alignment,
    FamiliesMetaFilter,
    Marksheet,
    VerificationStatus,
    TableStateValue,
    EducationYearForSelect,
} from "../../../helper/interface";
import Filter from "./Filter";
import useFetch from "../../../hooks/useFetch";

let initialValues: FamiliesMetaFilter = {
    searchBy: "Year",
};

const MarksheetUpload = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const tableFilterData = useSelector((state: TableStateValue) => state.table.tableData);

    useEffect(() => {
        if (tableFilterData.metaFilter && tableFilterData.metaFilter.value) {
            const metaFilter = tableFilterData.metaFilter;

            initialValues = {
                searchBy: metaFilter.searchBy,
                value: metaFilter.value,
            };
        } else {
            initialValues = {
                searchBy: "Year",
                value: null,
            };
        }
    }, [tableFilterData.metaFilter]);

    const { res: educationYear } = useFetch<EducationYearForSelect[]>(api.marksheet.educationYear);

    useEffect(() => {
        if (educationYear?.data && educationYear.data[0] && !tableFilterData.metaFilter) {
            initialValues = {
                searchBy: "Year",
                value: `${educationYear.data[0].educationYearId}`,
            };
            MarksheetUploadTable.onSearch("", initialValues);
        }
    }, [educationYear]);

    const MarksheetUploadTable = useTable<Marksheet>("Marksheet Upload", api.marksheet.upload);

    const [isOpen, setIsOpen] = useState(false);
    const [markSheet, setMarkSheet] = useState<Marksheet | null>(null);

    const onDeleteClick = useCallback((record: Marksheet) => {
        setMarkSheet(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (markSheet?.markSheetId) {
            onDeleteMarksheet(markSheet?.markSheetId, dispatch, () => {
                MarksheetUploadTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [markSheet, MarksheetUploadTable]);

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const educationYearCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: Marksheet, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "NAME",
            key: "memberId",
            sortable: false,
            render: (record: Marksheet) => {
                return <span>{record?.member?.fullNameEn ?? "--"}</span>;
            },
        },
        {
            title: "Standard",
            key: "educationStandard",
            sortable: true,
            minWidth: true,
            render: (record: Marksheet) => {
                return <span>{record?.educationStandard?.name ?? "--"}</span>;
            },
        },
        {
            title: "Year",
            key: "educationYear",
            sortable: true,
            render: (record: Marksheet) => {
                return <span>{record?.educationYear?.year ?? "--"}</span>;
            },
        },
        {
            title: "Board",
            key: "educationBoardId",
            sortable: true,
            render: (record: Marksheet) => {
                return <span>{record?.educationBoard?.nameEn ?? "--"}</span>;
            },
        },
        {
            title: "Medium",
            key: "educationMediumId",
            sortable: true,
            render: (record: Marksheet) => {
                return <span>{record?.educationMedium?.nameEn ?? "--"}</span>;
            },
        },
        {
            title: "Marks",
            key: "marks",
            sortable: true,
            render: (record: Marksheet) => {
                return <span>{record.marks}</span>;
            },
        },
        {
            title: "Marks Type",
            key: "marksType",
            sortable: false,
            render: (record: Marksheet) => {
                return <span>{record?.marksType}</span>;
            },
        },
        {
            title: "VERIFICATION",
            key: "verified",
            sortable: false,
            render: (record: Marksheet) => {
                return (
                    <span
                        className={`${record.verified === VerificationStatus.REJECTED && "text-danger"} ${
                            record.verified === VerificationStatus.PENDING && "text-warning"
                        } ${record.verified === VerificationStatus.APPROVED && "text-success"}`}
                    >
                        {record.verified}
                    </span>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: Marksheet) => {
                return (
                    <>
                        <div className="flex justify-center items-center text-primary">
                            <Tooltip id="simpleTooltipEditDelete" place="top" />
                            <div
                                className="flex items-center mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"View marksheet details"}
                                onClick={() => navigate(`/marksheet/marksheet-upload/view/${record?.markSheetId}`)}
                            >
                                <Eye className="w-4 h-4 mr-1" /> View
                            </div>
                            <div
                                className="flex items-center mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Edit marksheet details"}
                                onClick={() => navigate(`/marksheet/marksheet-upload/edit/${record?.markSheetId}`)}
                            >
                                <Edit className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Edit</span>
                            </div>
                            <div
                                className="flex items-center text-danger cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Delete marksheet"}
                                onClick={() => onDeleteClick(record)}
                            >
                                <Trash2 className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Delete</span>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    const onMetaFilteredSubmit = (value: FamiliesMetaFilter) => {
        const metaFilter = {
            searchBy: value.searchBy,
            value: value.value,
        };

        MarksheetUploadTable.onSearch("", metaFilter);
    };

    usePathName([{ pathName: "Marksheet Upload", href: "/marksheet/marksheet-upload" }]);

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this education board?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={educationYearCols}
                    data={MarksheetUploadTable.tableData ?? []}
                    tableKey="markSheetId"
                    reload={MarksheetUploadTable.fetchApi}
                    currentOrder={MarksheetUploadTable.currentOrder}
                    error={MarksheetUploadTable.error}
                    loading={MarksheetUploadTable.loading}
                    tableOffset={MarksheetUploadTable.tableOffset}
                    onSetOrderBy={MarksheetUploadTable.onSetOrderBy}
                    className="xl:justify-between xl:items-center"
                    component={
                        <>
                            <Formik initialValues={initialValues} enableReinitialize onSubmit={onMetaFilteredSubmit}>
                                {(props) => <Filter {...props} reload={MarksheetUploadTable.fetchApi} />}
                            </Formik>
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto whitespace-nowrap"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new marksheet"
                                    onClick={() => {
                                        navigate("/marksheet/marksheet-upload/add");
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default MarksheetUpload;
