import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { handleCatchResponse } from "../helper/helper";
import { setLoading } from "../store/slice/TableDataSlice";
import { Advertisement, AdvertisementPayload, BEAddAdvertisementPayload, BEEditAdvertisementPayload, Response } from "../helper/interface";
import { api } from "../config/Api";
import { dataService } from "../config/DataService";
import { errorToast, successToast } from "../helper/toast";

export const onAddAdvertisement = async (
    payload: BEAddAdvertisementPayload,
    dispatch: Dispatch,
    callback: (res: Required<Response<AdvertisementPayload>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.post(api.advertisement, payload, { headers: { "Content-Type": "multipart/form-data" } });
        const res = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onEditAdvertisement = async (
    payload: BEEditAdvertisementPayload,
    dispatch: Dispatch,
    callback: (res: Required<Response<Advertisement>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.put(api.advertisement, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
        const res = response.data;

        if (res.success) {
            callback(res);
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onActivateDeactivateAdvertisement = async (advertisementId: number, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.patch(`${api.advertisement}/${advertisementId}`);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeleteAdvertisement = async (advertisementId: number, dispatch: Dispatch, callback: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.advertisement}/${advertisementId}`);
        const res = response.data;

        if (res.success) {
            successToast(res.message);
            callback();
        } else {
            errorToast(res.message);
        }
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
