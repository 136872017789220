import { useCallback, useRef, RefObject, useState } from "react";
import { Form } from "formik";
import { useSelector } from "react-redux";
import moment from "moment";
import { Calendar, Camera } from "react-feather";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

import { dataEn } from "../../helper/dataEn";
import { EventPayload, FormParams, PreviewFile, TableStateValue, ZIndexMaintainer } from "../../helper/interface";
import { dataGu } from "../../helper/dataGu";
import { ONE } from "../../helper/constant";
import { getImageUrl, onDatePickerOpen, onDatePickerClose } from "../../helper/helper";
import avatar from "./../../assets/images/userAvatar.png";
import CustomDatePicker from "../datepicker/CustomDatePicker";
import Loading from "../../container/Loading";

const EventForm = ({ values, errors, setFieldValue, handleChange, handleBlur, touched, dirty }: FormParams<EventPayload>) => {
    const loading = useSelector((state: TableStateValue) => state.table.loading);
    const coverImgRef = useRef() as RefObject<HTMLInputElement>;

    const [zIndex, setZIndex] = useState<ZIndexMaintainer>({});

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setFieldValue("images", [
                ...values.images,
                ...acceptedFiles.map((file) =>
                    Object.assign(file, {
                        eventImageId: uuidv4(),
                        preview: URL.createObjectURL(file),
                        isNew: true,
                    })
                ),
            ]);
        },
        accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
        },
    });

    const removeFile = useCallback(
        (file: PreviewFile) => {
            const oldFiles = [...values.images];

            const index = oldFiles.indexOf(file);

            if (file?.eventImageId && file?.isNew !== true) {
                const deletedImages = values.deletedImages ? [...values.deletedImages] : [];
                deletedImages.push(file.eventImageId);
                setFieldValue("deletedImages", deletedImages);

                oldFiles.splice(index, ONE);
            } else {
                oldFiles.splice(index, ONE);
            }

            setFieldValue("images", oldFiles);
        },
        [values.images]
    );

    return (
        <>
            <Form>
                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                    <div className="preview mb-5 col-span-12">
                        <div className="bg-cover flex px-0 items-center justify-center flex-col relative">
                            {values?.coverImg ? (
                                <span className="bg-cover-img absolute">
                                    <img alt="Profile picture" className="w-full absolute" src={getImageUrl(values?.coverImg)} />
                                </span>
                            ) : (
                                <span className="bg-cover-color img-bordered w-full absolute"></span>
                            )}
                            <div className="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative">
                                <img
                                    alt="Profile picture"
                                    className={`rounded-full img-bordered ${errors.coverImg && touched.coverImg && "!border-danger"}`}
                                    src={values?.coverImg ? getImageUrl(values?.coverImg) : avatar}
                                />
                                <div
                                    className={`absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-primary rounded-full p-2  border-2 border-solid border-white cursor-pointer ${
                                        errors.coverImg && touched.coverImg && "!border-danger"
                                    }`}
                                    onClick={() => coverImgRef?.current?.click()}
                                >
                                    <Camera className="lucide lucide-camera w-4 h-4 text-white" />
                                    <input
                                        ref={coverImgRef}
                                        name="coverImg"
                                        type="file"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            if (e?.target?.files?.[0]) {
                                                setFieldValue("coverImg", e?.target?.files?.[0]);
                                            }
                                        }}
                                        accept="image/jpg,image/png,image/jpeg"
                                        className="hidden"
                                        disabled={loading}
                                    />
                                </div>
                            </div>
                            {errors.coverImg && touched.coverImg && <div className="text-danger">{errors.coverImg}</div>}
                            <div className="mt-4 text-center bg-cover-title-shadow">
                                <div className="truncate sm:whitespace-normal font-medium text-lg capitalize px-5 py-1">
                                    {values.nameEn ? values.nameEn : "Event name"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                    <div className="intro-y col-span-12 lg:col-span-6">
                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="font-medium text-base mr-auto">{dataEn.eventTitle}</h2>
                            </div>
                            <div id="vertical-form" className="p-5">
                                <div className="preview">
                                    {/* Event Pictures */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="form-label">Event Images</label>
                                        <div
                                            className="dropzone fallback dz-message flex items-center justify-center w-full"
                                            data-dz-message
                                        >
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} disabled={loading} />
                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                            </div>
                                        </div>
                                        <ul className="render-upload-list flex flex-wrap">
                                            {values.images.map((file) => (
                                                <li
                                                    key={file.eventImageId}
                                                    className="upload-thumb rounded-md after:block after:absolute after:w-full after:h-full after:top-0 after:left-0 after:z-10 relative m-1"
                                                >
                                                    {file.url ? (
                                                        <img src={file.url} className="w-20 h-20 object-cover object-center rounded-md" />
                                                    ) : (
                                                        file.preview && (
                                                            <img
                                                                src={file.preview}
                                                                onLoad={() => {
                                                                    URL.revokeObjectURL(file.preview);
                                                                }}
                                                                className="w-20 h-20 object-cover object-center rounded-md"
                                                            />
                                                        )
                                                    )}
                                                    <div role="button" className="remove" onClick={() => removeFile(file)}>
                                                        <span className="cursor-pointer">x</span>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        {typeof errors.images === "string" && touched.images && (
                                            <div className="text-danger">{errors.images}</div>
                                        )}
                                    </div>

                                    {/* Event Name */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="form-label">Event Name</label>
                                        <input
                                            name="nameEn"
                                            type="text"
                                            autoFocus
                                            onChange={handleChange}
                                            value={values.nameEn}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.nameEn && touched.nameEn && "border-danger"}`}
                                            disabled={loading}
                                        />
                                        {errors.nameEn && touched.nameEn && <div className="text-danger">{errors.nameEn}</div>}
                                    </div>

                                    {/* Location */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="form-label">Location</label>
                                        <input
                                            name="locationEn"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.locationEn}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.locationEn && touched.locationEn && "border-danger"}`}
                                            disabled={loading}
                                        />
                                        {errors.locationEn && touched.locationEn && <div className="text-danger">{errors.locationEn}</div>}
                                    </div>

                                    {/* Event Date */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5" style={{ zIndex: zIndex.eventDate }}>
                                        <label className="form-label">Event Date</label>

                                        <div className="relative">
                                            <div className="absolute z-10 rounded-l w-10 h-full flex items-center justify-center bg-slate-100 border text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400">
                                                <Calendar />
                                            </div>
                                            <CustomDatePicker
                                                id="eventDate"
                                                name="eventDate"
                                                selected={
                                                    values.eventDate
                                                        ? new Date(moment(values.eventDate.toLocaleString()).format("yyyy-MM-DD"))
                                                        : null
                                                }
                                                hasError={Boolean(errors.eventDate && touched.eventDate)}
                                                setFieldValue={setFieldValue}
                                                handleBlur={handleBlur}
                                                onCalendarOpen={onDatePickerOpen(setZIndex, "eventDate")}
                                                onCalendarClose={onDatePickerClose(setZIndex, "eventDate")}
                                                disabled={loading}
                                            />
                                        </div>
                                        {errors.eventDate && touched.eventDate && <div className="text-danger">{errors.eventDate}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y col-span-12 lg:col-span-6">
                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="font-bold text-base mr-auto">{dataGu.eventTitle}</h2>
                            </div>
                            <div id="vertical-form" className="p-5">
                                <div className="preview">
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="form-label">{dataGu.eventName}</label>
                                        <input
                                            id="nameGu"
                                            name="nameGu"
                                            type="text"
                                            value={values.nameGu}
                                            onChange={(e) => setFieldValue("nameGu", e.target.value)}
                                            className={`form-control ${errors.nameGu && touched.nameGu && "border-danger"}`}
                                            disabled={loading}
                                        />
                                        {errors.nameGu && touched.nameGu && <div className="text-danger">{errors.nameGu}</div>}
                                    </div>

                                    {/* Location */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="form-label">{dataGu.location}</label>
                                        <input
                                            name="locationGu"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.locationGu}
                                            onBlur={(e) => setFieldValue("locationGu", e.target.value)}
                                            className={`form-control ${errors.locationGu && touched.locationGu && "border-danger"}`}
                                            disabled={loading}
                                        />
                                        {errors.locationGu && touched.locationGu && <div className="text-danger">{errors.locationGu}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                        <button className="btn btn-secondary w-24" type="reset" disabled={loading || !dirty}>
                            Reset
                        </button>
                        <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                            {loading ? <Loading isButton /> : "Save"}
                        </button>
                    </div>
                </div>
            </Form>
        </>
    );
};
export default EventForm;
