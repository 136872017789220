import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "formik";
import { Listbox, Transition } from "@headlessui/react";

import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import {
    PrizePayload,
    FormParams,
    Member,
    EducationBoardData,
    EducationStandardData,
    EducationYearData,
    EducationMediumData,
    TableStateValue,
    PrizeRanks,
    Family,
    IncludeApiPayload,
} from "../../helper/interface";
import { dataEn } from "../../helper/dataEn";
import { MARKS_TYPE, ZERO, ONE, HUNDRED } from "../../helper/constant";
import Loading from "../../container/Loading";
import { serializeQueryParams } from "../../helper/helper";

const AddEditForm = ({
    errors,
    touched,
    values,
    dirty,
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleBlur,
}: FormParams<PrizePayload>) => {
    const loading = useSelector((state: TableStateValue) => state.table.loading);

    const [memberList, setMemberList] = useState<Member[]>([]);

    const payloadForAPI: IncludeApiPayload = {
        listFor: "markSheet",
        fieldName: "primaryMemberName",
        order: "ASC",
    };

    if (values.memberId) {
        payloadForAPI.includeMember = values.memberId;
    }

    const { res: families } = useFetch<Family[]>(`${api.family}?${serializeQueryParams(payloadForAPI)}`);
    const { res: educationBoard } = useFetch<EducationBoardData[]>(api.marksheet.educationBoard);
    const { res: educationStandard } = useFetch<EducationStandardData[]>(api.marksheet.educationStandard);
    const { res: educationYear } = useFetch<EducationYearData[]>(api.marksheet.educationYear);
    const { res: educationMedium } = useFetch<EducationMediumData[]>(api.marksheet.educationMedium);
    const { res: prizeRank } = useFetch<PrizeRanks[]>(api.prizeRanks);

    useEffect(() => {
        if (!values?.familyId && families && Array.isArray(families.data) && values?.member?.memberId) {
            families.data.forEach((ele) => {
                if (ele.familyId === values.member?.familyId) {
                    if (ele?.members) setMemberList(ele.members);

                    setFieldValue("familyId", ele.familyId);
                }
            });
        }
    }, [families]);

    const onChangeMemberId = useCallback((value: number) => {
        setFieldValue("memberId", value);
    }, []);

    const onChangeEducationBoardId = useCallback((value: number) => {
        setFieldValue("educationBoardId", value);
    }, []);

    const onChangeEducationStandardId = useCallback((value: number) => {
        setFieldValue("educationStandardId", value);
    }, []);

    const onChangeEducationYearId = useCallback((value: number) => {
        setFieldValue("educationYearId", value);
    }, []);

    const onChangeEducationMediumId = useCallback((value: number) => {
        setFieldValue("educationMediumId", value);
    }, []);

    const onChangePrizeRankId = useCallback((value: number) => {
        setFieldValue("prizeRankId", value);
    }, []);

    const onChangeMarksType = useCallback((value: string) => {
        setFieldValue("marksType", value);
    }, []);

    const onChangeFamily = useCallback(
        (value: number) => {
            setFieldValue("memberId", null);
            families?.data?.forEach((ele) => {
                if (ele.familyId === value && ele.members) {
                    setMemberList(ele.members);
                }
            });

            setFieldValue("familyId", value);
        },
        [families]
    );

    return (
        <>
            <Form>
                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                    <div className="intro-y col-span-12 flex items-center justify-center">
                        <div className="intro-y box lg:w-[60%] md:w-[100%] sm:w-[100%]">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="font-medium text-base mr-auto">{dataEn.prizeDescription}</h2>
                            </div>
                            <div id="vertical-form1" className="p-5">
                                <div className="preview">
                                    {/*Education Year */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Education Year</label>
                                        <div
                                            className={`tom-select ${
                                                errors.educationYearId && touched.educationYearId && "dd-border-danger"
                                            }`}
                                        >
                                            <Listbox
                                                value={values.educationYearId}
                                                name="educationYearId"
                                                onChange={onChangeEducationYearId}
                                            >
                                                <Listbox.Button id="educationYearId" className={"ts-input"}>
                                                    <div className="item">
                                                        {educationYear?.data?.map(
                                                            (eduYear: EducationYearData) =>
                                                                eduYear?.educationYearId === values?.educationYearId && eduYear?.year
                                                        )}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("educationYearId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {educationYear?.data?.map((eduYear: EducationYearData) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || eduYear.educationYearId === values.educationYearId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={eduYear.educationYearId}
                                                                        key={eduYear.educationYearId}
                                                                    >
                                                                        {eduYear?.year}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.educationYearId && touched.educationYearId && (
                                            <div className="text-danger">{errors.educationYearId}</div>
                                        )}
                                    </div>
                                    {/* Primary Member Name */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Primary Member Name</label>
                                        <div className={`tom-select ${errors.familyId && touched.familyId && "dd-border-danger"}`}>
                                            <Listbox value={values?.familyId ?? null} name="familyId" onChange={onChangeFamily}>
                                                <Listbox.Button id="familyId" className={"ts-input"}>
                                                    <div className="item">
                                                        {families?.data?.map(
                                                            (v) => v?.familyId === values?.familyId && v?.primaryMember?.fullNameEn
                                                        ) ?? ""}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("familyId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {families?.data?.map((ele: Family) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || ele?.familyId === values?.familyId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={ele.familyId}
                                                                        key={ele?.familyId}
                                                                    >
                                                                        {ele?.primaryMember?.fullNameEn}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.familyId && touched.familyId && <div className="text-danger">{errors.familyId}</div>}
                                    </div>
                                    {/* Member Name */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Member Name</label>
                                        <div className={`tom-select ${errors.memberId && touched.memberId && "dd-border-danger"}`}>
                                            <Listbox value={values.memberId} name="memberId" onChange={onChangeMemberId}>
                                                <Listbox.Button id="memberId" className={"ts-input"}>
                                                    <div className="item">
                                                        {memberList?.map((mem) => mem?.memberId === values?.memberId && mem?.fullNameEn)}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("memberId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {memberList?.map((mber: Member) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || mber.memberId === values.memberId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={mber.memberId}
                                                                        key={mber.memberId}
                                                                    >
                                                                        {mber?.fullNameEn}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.memberId && touched.memberId && <div className="text-danger">{errors.memberId}</div>}
                                    </div>
                                    {/*Education Standard */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Education Standard</label>
                                        <div
                                            className={`tom-select ${
                                                errors.educationStandardId && touched.educationStandardId && "dd-border-danger"
                                            }`}
                                        >
                                            <Listbox
                                                value={values.educationStandardId}
                                                name="educationStandardId"
                                                onChange={onChangeEducationStandardId}
                                            >
                                                <Listbox.Button id="educationStandardId" className={"ts-input"}>
                                                    <div className="item">
                                                        {educationStandard?.data?.map(
                                                            (eduSta: EducationStandardData) =>
                                                                eduSta?.educationStandardId === values?.educationStandardId && eduSta?.name
                                                        )}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("educationStandardId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {educationStandard?.data?.map((eduSta: EducationStandardData) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active ||
                                                                                eduSta.educationStandardId === values.educationStandardId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={eduSta.educationStandardId}
                                                                        key={eduSta.educationStandardId}
                                                                    >
                                                                        {eduSta?.name}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.educationStandardId && touched.educationStandardId && (
                                            <div className="text-danger">{errors.educationStandardId}</div>
                                        )}
                                    </div>
                                    {/*Education Board */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Education Board</label>
                                        <div
                                            className={`tom-select ${
                                                errors.educationBoardId && touched.educationBoardId && "dd-border-danger"
                                            }`}
                                        >
                                            <Listbox
                                                value={values.educationBoardId}
                                                name="educationBoardId"
                                                onChange={onChangeEducationBoardId}
                                            >
                                                <Listbox.Button id="educationBoardId" className={"ts-input"}>
                                                    <div className="item">
                                                        {educationBoard?.data?.map(
                                                            (eduBoa: EducationBoardData) =>
                                                                eduBoa?.educationBoardId === values?.educationBoardId && eduBoa?.nameEn
                                                        )}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("educationBoardId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {educationBoard?.data?.map((eduBoa: EducationBoardData) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active ||
                                                                                eduBoa.educationBoardId === values.educationBoardId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={eduBoa.educationBoardId}
                                                                        key={eduBoa.educationBoardId}
                                                                    >
                                                                        {eduBoa?.nameEn}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.educationBoardId && touched.educationBoardId && (
                                            <div className="text-danger">{errors.educationBoardId}</div>
                                        )}
                                    </div>
                                    {/*Education Medium */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Education Medium</label>
                                        <div
                                            className={`tom-select ${
                                                errors.educationMediumId && touched.educationMediumId && "dd-border-danger"
                                            }`}
                                        >
                                            <Listbox
                                                value={values.educationMediumId}
                                                name="educationMediumId"
                                                onChange={onChangeEducationMediumId}
                                            >
                                                <Listbox.Button id="educationMediumId" className={"ts-input"}>
                                                    <div className="item">
                                                        {educationMedium?.data?.map(
                                                            (eduMed: EducationMediumData) =>
                                                                eduMed?.educationMediumId === values?.educationMediumId && eduMed?.nameEn
                                                        )}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("educationMediumId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {educationMedium?.data?.map((eduMed: EducationMediumData) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active ||
                                                                                eduMed.educationMediumId === values.educationMediumId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={eduMed.educationMediumId}
                                                                        key={eduMed.educationMediumId}
                                                                    >
                                                                        {eduMed?.nameEn}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.educationMediumId && touched.educationMediumId && (
                                            <div className="text-danger">{errors.educationMediumId}</div>
                                        )}
                                    </div>
                                    {/*Marks Type */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Marks Type</label>
                                        <div className={`tom-select ${errors.marksType && touched.marksType && "dd-border-danger"}`}>
                                            <Listbox value={values.marksType} name="marksType" onChange={onChangeMarksType}>
                                                <Listbox.Button id="marksType" className={"ts-input"}>
                                                    <div className="item">{values?.marksType}</div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("marksType", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {MARKS_TYPE.map((mt: string) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || mt === values.marksType ? "optionColor" : null
                                                                            }`
                                                                        }
                                                                        value={mt}
                                                                        key={mt}
                                                                    >
                                                                        {mt}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.marksType && touched.marksType && <div className="text-danger">{errors.marksType}</div>}
                                    </div>
                                    {/*Marks */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Marks</label>
                                        <input
                                            id="marks"
                                            name="marks"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.marks ?? ZERO}
                                            onBlur={handleBlur}
                                            min={ONE}
                                            max={HUNDRED}
                                            className={`form-control ${errors.marks && touched.marks && "border-danger"}`}
                                        />
                                        {errors.marks && touched.marks && <div className="text-danger">{errors.marks}</div>}
                                    </div>
                                    {/*Ranks */}
                                    <div className="intro-y col-span-12 sm:col-span-6 my-5">
                                        <label className="mb-2 form-label">Rank</label>
                                        <div className={`tom-select ${errors.prizeRankId && touched.prizeRankId && "dd-border-danger"}`}>
                                            <Listbox value={values.prizeRankId} name="ranks" onChange={onChangePrizeRankId}>
                                                <Listbox.Button id="ranks" className={"ts-input"}>
                                                    <div className="item">
                                                        {prizeRank?.data?.map(
                                                            (pr: PrizeRanks) => pr?.prizeRankId === values?.prizeRankId && pr?.rank
                                                        )}
                                                    </div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("prizeRankId", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {prizeRank?.data?.map((pr: PrizeRanks) => {
                                                                return (
                                                                    <Listbox.Option
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                active || pr.prizeRankId === values.prizeRankId
                                                                                    ? "optionColor"
                                                                                    : null
                                                                            }`
                                                                        }
                                                                        value={pr.prizeRankId}
                                                                        key={pr.prizeRankId}
                                                                    >
                                                                        {pr.rank}
                                                                    </Listbox.Option>
                                                                );
                                                            })}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.prizeRankId && touched.prizeRankId && (
                                            <div className="text-danger">{errors.prizeRankId}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                        <button className="btn btn-secondary w-24" type="reset" disabled={loading || !dirty}>
                            Reset
                        </button>
                        <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                            {loading ? <Loading isButton /> : "Save"}
                        </button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default AddEditForm;
