import { createSlice } from "@reduxjs/toolkit";
import { BaseSliceValue, PendingBusinessCountPayload, RolePayload, SetPagePathStatePayload, TokenPayload } from "../../helper/interface";
import { ADMIN_ROLE } from "../../helper/constant";

const initialState: BaseSliceValue = {
    pagePath: [{ pathName: "Families", href: "/families" }],
    token: null,
    role: ADMIN_ROLE.normalAdmin,
    pendingBusinessCount: 0
};

export const BaseSlice = createSlice({
    name: "base",
    initialState,
    reducers: {
        setPagePath: (state, action: SetPagePathStatePayload) => {
            state.pagePath = action.payload;
        },
        setToken: (state, action: TokenPayload) => {
            state.token = action.payload;
        },
        setRole: (state, action: RolePayload) => {
            state.role = action.payload;
        },
        setPendingBusinessCount: (state, action: PendingBusinessCountPayload) => {
            state.pendingBusinessCount = action.payload;
        },
    },
});

export const { setPagePath, setToken, setRole, setPendingBusinessCount } = BaseSlice.actions;
export default BaseSlice.reducer;
