import { Formik } from "formik";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../config/Api";
import Loading from "../../container/Loading";
import useFetch from "../../hooks/useFetch";
import usePathName from "../../hooks/usePathName";
import Form from "./Form";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import { AnnualContributionPayload } from "../../helper/interface";
import { addEditAnnualContributionValidation } from "../../helper/validationSchema";
import { editSuccessToast } from "../../helper/toast";
import { onEditAnnualContribution } from "../../service/annualContribution";

const EditAnnualContribution: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { annualContributionId = "" } = useParams();

    const { res, loading, error } = useFetch<AnnualContributionPayload>(`${api.annualContribution}/${annualContributionId}`);

    usePathName([
        { pathName: "Annual contribution", href: "/annual-contribution" },
        { pathName: "Edit", href: `/annual-contribution/edit/${annualContributionId}` },
        { pathName: `${annualContributionId}`, href: `/annual-contribution/edit/${annualContributionId}` },
    ]);

    const onSubmit = useCallback(
        (values: AnnualContributionPayload) => {
            const payload = {
                amount: values.amount,
                year: values.year,
                annualContributionId: +annualContributionId,
            };

            onEditAnnualContribution(payload, dispatch, (res) => {
                const toastLink = {
                    primaryLinkTitle: "Edit",
                    primaryLink: `/annual-contribution/edit/${res?.data?.annualContributionId ?? payload.annualContributionId}`,
                };

                editSuccessToast(res.message, navigate, toastLink);
                navigate("/annual-contribution");
            });
        },
        [annualContributionId]
    );

    const initialValues = res?.data;

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4" onClick={() => navigate("/annual-contribution")}>
                    <ArrowLeftCircle className="cursor-pointer text-primary" />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Edit annual contribution</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {error ? (
                    <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                        <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                    </div>
                ) : loading ? (
                    <Loading />
                ) : initialValues ? (
                    <Formik initialValues={initialValues} validationSchema={addEditAnnualContributionValidation} onSubmit={onSubmit}>
                        {(props) => <Form {...props} />}
                    </Formik>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default EditAnnualContribution;
