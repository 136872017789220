import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { NavigateFunction } from "react-router-dom";
import { api } from "../config/Api";
import { dataService } from "../config/DataService";
import { handleCatchResponse } from "../helper/helper";
import {
    ForgotPasswordPayload,
    LoginPayload,
    SetPasswordPayload,
    ChangePasswordPayload,
    LoginResponse,
    Response,
} from "../helper/interface";
import { setRole, setToken } from "../store/slice/BaseSlice";
import { setLoading } from "../store/slice/TableDataSlice";
import { successToast } from "../helper/toast";

export const onLogin = async (payload: LoginPayload, dispatch: Dispatch, navigate: NavigateFunction) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.post(api.admin.login, payload);

        const res: Required<Response<LoginResponse>> = response.data;

        dispatch(setToken(res.data.token));

        dispatch(setRole(res.data?.admin?.role ?? null));

        navigate("/families");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onForgotPassword = async (payload: ForgotPasswordPayload, dispatch: Dispatch, navigate: NavigateFunction) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.put(api.admin.forgotPassword, payload);

        const res = response.data;

        successToast(res.message);

        navigate("/auth/login");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onSetPasswordSubmit = async (data: SetPasswordPayload, navigate: NavigateFunction, dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            newPassword: data.newPassword,
            verification: data.verification,
        };

        const response = await dataService.put(api.admin.setPassword, payload);

        const res = response.data;

        successToast(res.message);

        navigate("/auth/login");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onChangePasswordSubmit = async (payload: ChangePasswordPayload, dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.put(api.admin.changePassword, payload);
        const res = response.data;

        successToast(res.message);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
