import { Formik, FormikHelpers } from "formik";
import { ArrowLeftCircle } from "react-feather";
import { useNavigate } from "react-router-dom";
import { addSuccessToast } from "../../../helper/toast";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import usePathName from "../../../hooks/usePathName";
import { EducationYearPayload } from "../../../helper/interface";
import { onAddEducationYear } from "../../../service/educationYear";
import { educationYearValidation } from "../../../helper/validationSchema";
import AddEditForm from "./AddEditForm";

const initialValues: EducationYearPayload = {
    year: "",
    isMarkSheetUploadable: true,
    isPrizeListVisible: false,
};

const AddEducationYear = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    usePathName([
        { pathName: "Education year", href: "/marksheet/education-year" },
        { pathName: "Add", href: "/marksheet/education-year/add " },
    ]);

    const onSubmit = useCallback((values: EducationYearPayload, { resetForm }: FormikHelpers<EducationYearPayload>) => {
        onAddEducationYear(values, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Add new",
                primaryLink: "/marksheet/education-year/add",
                secondaryLinkTitle: "Edit",
                secondaryLink: `/marksheet/education-year/edit/${res.data.educationYearId}`,
            };

            addSuccessToast(res.message, navigate, toastLink);
            resetForm();
            navigate("/marksheet/education-year");
        });
    }, []);

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/marksheet/education-year")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Add new education year</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    <Formik initialValues={initialValues} validationSchema={educationYearValidation} onSubmit={onSubmit}>
                        {(props) => <AddEditForm {...props} />}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AddEducationYear;
