import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";
import { ArrowLeftCircle } from "react-feather";

import { addSuccessToast } from "../../../helper/toast";
import usePathName from "../../../hooks/usePathName";
import { EducationBoardPayload } from "../../../helper/interface";
import { onAddEducationBoard } from "../../../service/educationBoard";
import { educationBoardValidation } from "../../../helper/validationSchema";
import AddEditForm from "./AddEditForm";

const initialValues: EducationBoardPayload = {
    nameEn: "",
    nameGu: "",
};

const AddEducationBoard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    usePathName([
        { pathName: "Education board", href: "/marksheet/education-board" },
        { pathName: "Add", href: "/marksheet/education-board/add" },
    ]);

    const onSubmit = useCallback((values: EducationBoardPayload, { resetForm }: FormikHelpers<EducationBoardPayload>) => {
        onAddEducationBoard(values, dispatch, (res) => {
            const toastLink = {
                primaryLinkTitle: "Add new",
                primaryLink: "/marksheet/education-board/add",
                secondaryLinkTitle: "Edit",
                secondaryLink: `/marksheet/education-board/edit/${res.data.educationBoardId}`,
            };

            addSuccessToast(res.message, navigate, toastLink);
            resetForm();
            navigate("/marksheet/education-board");
        });
    }, []);

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4" onClick={() => navigate("/marksheet/education-board")}>
                        <ArrowLeftCircle className="cursor-pointer text-primary" />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Add new education board</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    <Formik initialValues={initialValues} validationSchema={educationBoardValidation} onSubmit={onSubmit}>
                        {(props) => <AddEditForm {...props} />}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AddEducationBoard;
