import { FC, useEffect } from "react";
import Logo from "../assets/images/Frame.png";
import { Outlet } from "react-router-dom";

const Auth: FC = () => {
    useEffect(() => {
        document.body.className = "login";
        return () => {
            document.body.className = "";
        };
    }, []);

    return (
        <div>
            <div className="container sm:px-10">
                <div className="lg:grid grid-cols-2 gap-4 h-screen content-vh-center">
                    <div className="hidden lg:flex flex-col min-h-screen">
                        <div className="my-auto text-center w-full xl:max-w-1/2 lg:max-w-2/3">
                            <div className="flex justify-center">
                                <img alt="Gondaliya parivar" className="-intro-x w-32" src={Logo} />
                            </div>
                            <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">Gondaliya Family</div>
                            <div className="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-slate-400">
                                Empowering Family Connections,
                                <br />
                                Inspiring Collective Growth
                            </div>
                        </div>
                    </div>
                    <div className="hidden max-[1024px]:!block auth-logo">
                        <div className="flex justify-center">
                            <img alt="Gondaliya parivar" className="-intro-x w-32" src={Logo} />
                        </div>
                    </div>
                    <div className="lg:h-screen flex items-center justify-center py-5 lg:py-0 mt-0 lg:my-auto">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Auth;
