import { useCallback, useState } from "react";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Edit, Trash2 } from "react-feather";
import DeleteModal from "../../modal/DeleteModal";
import CustomTable from "../table/CustomTable";
import SearchForm from "../table/SearchForm";
import useTable from "../../hooks/useTable";
import { api } from "../../config/Api";
import { ActivateDeactivateMember, Alignment, Member } from "../../helper/interface";
import { onActivateDeactivateMember, onDeleteMember } from "../../service/members";
import avatar from "./../../assets/images/userAvatar.png";
import usePathName from "../../hooks/usePathName";
import { calcAge } from "../../config/MethodUtils";
import moment from "moment";

const Members = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const memberTable = useTable<Member>("members", api.member);

    const [isOpen, setIsOpen] = useState(false);
    const [member, setMember] = useState<Member | null>(null);

    const onDeleteClick = useCallback((record: Member) => {
        setMember(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (member?.memberId) {
            onDeleteMember(member?.memberId, dispatch, () => {
                memberTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [member, memberTable]);

    const onActivateDeactivate = useCallback(
        (payload: ActivateDeactivateMember) => {
            onActivateDeactivateMember(payload, dispatch, memberTable.fetchApi);
        },
        [memberTable]
    );

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    usePathName([{ pathName: "Members", href: "/members" }]);

    const memberCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: Member, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "PROFILE PIC.",
            key: "profileImg",
            render: (record: Member) => {
                if (record?.family?.primaryMemberId === record?.memberId) {
                    return (
                        <span>
                            <img
                                src={record?.family?.profileImg ?? avatar}
                                alt="Profile picture"
                                className="rounded-full object-fit-cover-center thumb-hw-50 mx-auto"
                            />
                        </span>
                    );
                }
                return <span>-</span>;
            },
        },
        {
            title: "MEMBER NAME",
            key: "fullNameEn",
            sortable: true,
            minWidth: true,
            render: (record: Member) => {
                return <span>{record?.fullNameEn ?? "-"}</span>;
            },
        },
        {
            title: "PHONE",
            key: "mobile",
            sortable: false,
            render: (record: Member) => {
                return <span>{record?.mobile ?? "-"}</span>;
            },
        },
        {
            title: "GENDER",
            key: "gender",
            render: (record: Member) => {
                return <span>{record?.gender ?? "-"}</span>;
            },
        },
        {
            title: "BLOOD GROUP",
            key: "bloodGroup",
            render: (record: Member) => {
                return <span>{record?.bloodGroup ?? "-"}</span>;
            },
        },
        {
            title: "DATE OF BIRTH",
            key: "dob",
            render: (record: Member) => {
                return <span>{record.dob ? moment(record.dob).format("DD-MM-YYYY") + " (" + calcAge(record.dob) + ")" : "-"}</span>;
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            render: (record: Member) => {
                const payload = {
                    memberId: record?.memberId,
                    isActive: !record?.isActive,
                };

                const isPrimaryMember = record?.family?.primaryMemberId === record?.memberId;

                return (
                    <>
                        <Tooltip id="simpleTooltipActiveDeactive" place="top" />
                        <div
                            className="form-switch form-check flex justify-center flex-col"
                            data-tooltip-id="simpleTooltipActiveDeactive"
                            data-tooltip-content={record?.isActive ? "Active" : "Inactive"}
                        >
                            <input
                                id="isActive"
                                name="isActive"
                                onChange={() => onActivateDeactivate(payload)}
                                checked={record?.isActive}
                                className={"form-check-input"}
                                type="checkbox"
                                disabled={isPrimaryMember}
                            />
                        </div>
                    </>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: Member) => {
                const isPrimaryMember = record?.family?.primaryMemberId === record?.memberId;

                return (
                    <>
                        <div className="flex justify-center items-center text-primary">
                            <Tooltip id="simpleTooltipViewEditDelete" place="top" />
                            <div
                                className="flex items-center mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipViewEditDelete"
                                data-tooltip-content={"Edit member"}
                                onClick={() => navigate(`/members/edit/${record?.memberId}`)}
                            >
                                <Edit className="w-4 h-4 mr-1" />
                                <span className="pt-[2px] leading-[16px]">Edit</span>
                            </div>
                            <button
                                className={`flex items-center text-danger ${isPrimaryMember ? "disabled" : "cursor-pointer"}`}
                                data-tooltip-id="simpleTooltipViewEditDelete"
                                data-tooltip-content={"Delete member"}
                                onClick={() => onDeleteClick(record)}
                                disabled={isPrimaryMember}
                            >
                                <Trash2 className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Delete</span>
                            </button>
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this member?"
            />

            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={memberCols}
                    data={memberTable.tableData ?? []}
                    tableKey="memberId"
                    reload={memberTable.fetchApi}
                    currentOrder={memberTable.currentOrder}
                    error={memberTable.error}
                    loading={memberTable.loading}
                    tableOffset={memberTable.tableOffset}
                    onSetOrderBy={memberTable.onSetOrderBy}
                    component={
                        <>
                            <SearchForm
                                resetSearch={memberTable.resetSearch}
                                onSearch={memberTable.onSearch}
                                searchValue={memberTable.search}
                                searchPlaceHolder="Search by member name or mobile or blood group"
                            />
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new member"
                                    onClick={() => {
                                        navigate("/members/add");
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default Members;
